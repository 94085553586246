import { Link as MuiLink, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { ArrowOutward, OpenInNew } from "@mui/icons-material";
import Immutable from "immutable";
import { trackClick, trackingCategory } from "utils/tracking";
import { EventDate } from "../../components/events/eventDate";
import { PrivacyPolicyWordsEN } from "../../components/legal/privacyPolicy/PrivacyPolicyWords_EN";
import { TermsAndConditionsWordsEN } from "../../components/legal/termsAndConditions/TermsAndConditionsWords_EN";
import { T } from "../../components/util/t";
import { formatPrice, formatPriceSmall } from "../../utils/utils";

const ordinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;

    return s[(v - 20) % 10] || s[v] || s[0];
};

export const HeatHeaderTitle = ({ heat, className }) =>
    <span className={className}>
        <span>{heat.eventDivision.division.name}</span>
        <span> : </span>
        <span><T>{heat.round}</T></span>
        <span> : </span>
        <span>
            {heat.config.runBased ?
                <T number={heat.position + 1}>run_number</T> :
                <T number={heat.position + 1}>heat_number</T>}
        </span>
    </span>;

const heatHeaderTitle = ({ heat }) =>
    <span>
        <span>{heat.get("division")}</span>
        <span> : </span>
        <span>{heat.get("round")}</span>
        {heat.get("number") &&
        <>
            <span> : </span>
            <span>
                <T>{heat.get("type")}</T>
                {" "}
                {heat.get("number")}
            </span>
        </>}
        {heat.get("bank") && <span> ({heat.get("bank")})</span>}
    </span>;

const joinBy = (separator, lastSeparator, list) => {
    list = Immutable.List.isList(list) ? list : Immutable.List(list);
    const items = [];
    for (let i = 0; i < list.size; i++) {
        if (i === list.size - 1) items.push(<Fragment key={i}>{list.get(i)}</Fragment>);
        else if (i === list.size - 2) items.push(<Fragment key={i}>{list.get(i)}{lastSeparator}</Fragment>);
        else items.push(<Fragment key={i}>{list.get(i)}{separator}</Fragment>);
    }
    return items;
};

const plural = (size) => size !== 1 ? "s" : "";

const pluralEntries = (size, language) => language === "en" ? (size === 1 ? "entry" : "entries") :
    language === "pt" ? (size === 1 ? "inscrição" : "inscrições") : language === "es" ? (size === 1 ? "inscripción" : "inscripciones") : "";

const japaneseFormatDate = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
};

/* Everything below this line will be replaced when importing new translations */

export default ({
    "Please update your browser": {
        pt: "Por favor atualize seu navegador",
        es: "Actualiza el navegador",
        ja: "ブラウザをアップデートしてください",
    },
    "It seems you are using an unsupported browser. Please update to a recent version of one of the following to continue enjoying Liveheats:": {
        pt: "Parece que você está usando um navegador não compatível. Por favor atualize para uma versão recente de um dos seguintes navegadores para continuar desfrutando do Liveheats:",
        es: "Parece que estás usando un navegador no compatible. Actualiza a una de las siguientes versiones más recientes para seguir disfrutando de Liveheats:",
        ja: "サポートされていないブラウザを使用しているようです。引き続きLiveheatsをお楽しみいただくため、以下のいずれかの最新バージョンにアップデートしてください。",
    },
    "Continue anyway": {
        pt: "Continuar mesmo assim",
        es: "Continuar de todos modos",
        ja: "アップデートしないで続ける",
    },
    "heat": {
        en: "Heat",
        pt: "Bateria",
        es: "Serie",
        ja: "ヒート",
    },
    "run": {
        en: "Run",
        pt: "Volta",
        es: "Tanda",
        ja: "ラン",
    },
    "My events": {
        pt: "Meus eventos",
        ja: "マイイベント",
        es: "Mis eventos",
    },
    "Events": {
        pt: "Eventos",
        ja: "イベント",
        es: "Eventos",
    },
    "Email": {
        pt: "E-mail",
        ja: "Eメール",
        es: "Correo electrónico",
    },
    "Password": {
        pt: "Senha",
        ja: "パスワード",
        es: "Contraseña",
    },
    "Reset your password": {
        pt: "Altere sua senha",
        ja: "パスワードをリセットします",
        es: "Restablecer contraseña",
    },
    "Your Events": {
        pt: "Seus Eventos",
        ja: "あなたのイベント",
        es: "Tus eventos",
    },
    "Create event": {
        pt: "Criar evento",
        ja: "イベントの作成",
        es: "Crear evento",
    },
    "Create new event": {
        pt: "Criar novo evento",
        es: "Crear evento nuevo",
        ja: "新しいイベントを作成する",
    },
    "Create your first event": {
        pt: "Crie seu primeiro evento",
        es: "Crea tu primer evento",
        ja: "最初のイベントを作成する",
    },
    "Nothing to see here! You have no upcoming events - is it time to create another?": {
        pt: "Nada pra ver aqui. Você não tem nenhum evento - está na hora de criar outro?",
        es: "No hay nada para mostrar. No tienes eventos próximos. ¿Es hora de crear otro?",
        ja: "ここには何もありません！イベントの予定がありません。新しいイベントを作成しましょうか？",
    },
    "director_welcome_get_started": {
        en: <>Welcome to Liveheats! Get started by creating your first event.<br/>Check out our <MuiLink href="https://support.liveheats.com/hc/en-us" target="_blank" rel="noopener noreferrer">Help centre</MuiLink> for instructions and how-tos.</>,
        pt: <>Bem vindo ao Liveheats! Para começar, crie o seu primeiro evento.<br/>Consulte a nossa <MuiLink href="https://support.liveheats.com/hc/en-us" target="_blank" rel="noopener noreferrer">Ajuda</MuiLink> para obter instruções e guias.</>,
        es: <>¡Te damos la bienvenida a Liveheats! Para comenzar, crea tu primer evento.<br/>Consulta el <MuiLink href="https://support.liveheats.com/hc/en-us" target="_blank" rel="noopener noreferrer">Centro de ayuda</MuiLink> para conocer los pasos a seguir.</>,
        ja: <>Liveheatsへようこそ！まずは最初のイベントを作成しましょう。<br/>手順ややり方については、<MuiLink href="https://support.liveheats.com/hc/en-us" target="_blank" rel="noopener noreferrer">ヘルプセンター</MuiLink>をご覧ください。</>,
    },
    "director_welcome_create_event": {
        en: <><Typography variant="h4">Welcome to Liveheats</Typography> <br/> Super-easy events are just around the corner. Ready to get started?</>,
        pt: <><Typography variant="h4">Bem vindo ao Liveheats</Typography> <br/> Eventos super fáceis estão logo ali. Pronto para começar?</>,
        es: <><Typography variant="h4">Bienvenido a Liveheats</Typography> <br/> Los eventos súper fáciles están a la vuelta de la esquina. ¿Listo para empezar?</>,
        ja: <><Typography variant="h4">Liveheatsへようこそ</Typography> <br/> 超簡単なイベントがすぐそこにあります。準備はいいですか？</>,
    },
    "director_welcome_onboarding_support": {
        en: <>Have questions? <MuiLink variant="button2" onClick={trackClick("Schedule onboarding call link", trackingCategory.orgnaiserOnboarding)} href="https://calendly.com/d/3pt-zcg-vp9/liveheats-onboarding-call" target="_blank" rel="noreferrer">Schedule an onboarding call</MuiLink> with a Liveheats team member or email <MuiLink onClick={trackClick("Community email mailto", trackingCategory.orgnaiserOnboarding)} variant="button2" href="mailto:community@liveheats.com" target="_blank" rel="noreferrer">community@liveheats.com</MuiLink></>,
        pt: <>Tem perguntas? <MuiLink variant="button2" onClick={trackClick("Schedule onboarding call link", trackingCategory.orgnaiserOnboarding)} href="https://calendly.com/d/3pt-zcg-vp9/liveheats-onboarding-call" target="_blank" rel="noreferrer">Agende uma chamada de integração</MuiLink> com um membro da equipe Liveheats ou email <MuiLink onClick={trackClick("Community email mailto", trackingCategory.orgnaiserOnboarding)} variant="button2" href="mailto:community@liveheats.com" target="_blank" rel="noreferrer">community@liveheats.com</MuiLink></>,
        es: <>¿Tienes preguntas? <MuiLink variant="button2" onClick={trackClick("Schedule onboarding call link", trackingCategory.orgnaiserOnboarding)} href="https://calendly.com/d/3pt-zcg-vp9/liveheats-onboarding-call" target="_blank" rel="noreferrer">Programa una llamada de incorporación</MuiLink> con un miembro del equipo de Liveheats o correo electrónico <MuiLink onClick={trackClick("Community email mailto", trackingCategory.orgnaiserOnboarding)} variant="button2" href="mailto:community@liveheats.com" target="_blank" rel="noreferrer">community@liveheats.com</MuiLink></>,
        ja: <>質問はありますか？<MuiLink variant="button2" onClick={trackClick("Schedule onboarding call link", trackingCategory.orgnaiserOnboarding)} href="https://calendly.com/d/3pt-zcg-vp9/liveheats-onboarding-call" target="_blank" rel="noreferrer">オンボーディングコールをスケジュールする</MuiLink>Liveheatsのチームメンバーまたはメールで<MuiLink onClick={trackClick("Community email mailto", trackingCategory.orgnaiserOnboarding)} variant="button2" href="mailto:community@liveheats.com" target="_blank" rel="noreferrer">community@liveheats.com</MuiLink></>,
    },
    "Cancel": {
        pt: "Cancelar",
        ja: "取消",
        es: "Cancelar",
    },
    "Edit": {
        pt: "Editar",
        es: "Editar",
        ja: "編集",
    },
    "Results": {
        pt: "Resultados",
        ja: "結果",
        es: "Resultados",
    },
    "Share Results": {
        pt: "Compartilhar resultados",
        ja: "結果を共有する",
        es: "Compartir resultados",
    },
    "Share": {
        pt: "Compartilhar",
        ja: "共有",
        es: "Compartir",
    },
    "Select template": {
        pt: "Selecione o modelo",
        ja: "テンプレートを選択",
        es: "Seleccionar plantilla",
    },
    "Upload background image": {
        pt: "Carregar imagem de fundo",
        ja: "背景画像をアップロードする",
        es: "Subir imagen de fondo",
    },
    "Change background image": {
        pt: "Alterar imagem de fundo",
        ja: "背景画像を変更する",
        es: "Cambiar imagen de fondo",
    },
    "Download image": {
        pt: "Baixar imagem",
        ja: "画像をダウンロード",
        es: "Descargar imagen",
    },
    "We love to share your wins too! Download your shareable image and tag Liveheats or use #LHmakehistory in your social post for a chance to be featured.": {
        pt: "Adoramos compartilhar suas vitórias também! Baixe sua imagem compartilhável e marque Liveheats ou use #LHmakehistory em sua postagem social para ter a chance de aparecer.",
        ja: "あなたの勝利もぜひ共有したいと思います!Liveheats にタグを付けるか、ソーシャル投稿で #LHmakehistory を使用すると、注目されるチャンスが得られます。",
        es: "¡También nos encanta compartir tus victorias! Descargue su imagen para compartir y etiquete a Liveheats o use #LHmakehistory en su publicación social para tener la oportunidad de aparecer.",
    },
    "Total score": {
        pt: "Pontuação total",
        ja: "合計スコア",
        es: "Puntaje total",
    },
    "Total rides": {
        pt: "Total de viagens",
        ja: "総乗車数",
        es: "Viajes totales",
    },
    "Oops, something went wrong!": {
        pt: "Ops, deu algo errado!",
        ja: "エラーが発生しました！",
        es: "Algo salió mal",
    },
    "Log out": {
        pt: "Sair",
        ja: "ログアウト",
        es: "Cerrar sesión",
    },
    "Links": {
        ja: "リンク集",
        es: "Enlaces",
    },
    "Total": {
        ja: "合計",
        es: "Total",
    },
    "Athlete": {
        pt: "Atleta",
        ja: "選手",
        es: "Atleta",
    },
    "Place": {
        pt: "Lugar",
        es: "Puesto",
        ja: "場所",
    },
    "Time": {
        pt: "Tempo",
        es: "Hora",
        ja: "時間",
    },
    "Division": {
        pt: "Categoria",
        ja: "部門",
        es: "Categoría",
    },
    "Schedule": {
        pt: "Agenda",
        ja: "スケジュール",
        es: "Cronograma",
    },
    "live": {
        pt: "ao vivo",
        ja: "ライブ",
        es: "en directo",
    },
    "Division settings": {
        pt: "Configurar categoria",
        ja: "部門設定",
        es: "Configuración de la categoría",
    },
    "Seed list": {
        pt: "Lista de atletas",
        ja: "シードリスト",
        es: "Lista de cabezas de serie",
    },
    "Event dashboard": {
        pt: "Painel do evento",
        ja: "イベントダッシュボード",
        es: "Panel del evento",
    },
    "Athlete name:": {
        pt: "Nome do atleta:",
        ja: "選手名：",
        es: "Nombre del atleta:",
    },
    "Team name:": {
        pt: "Nome do time:",
        ja: "チーム名:",
        es: "Nombre del equipo:",
    },
    "heatDurationLabel": {
        en: ({ value }) => `Set to ${value} minutes`,
        pt: ({ value }) => `Configurar para ${value} minutos`,
        ja: ({ value }) => `${value}選手に設定`,
        es: ({ value }) => `Establecer en ${value} minutos`,
    },
    "setToAthletesCreateLabel": {
        en: ({ value }) => `Set to ${value} athletes`,
        pt: ({ value }) => `Configurar para ${value} atletas`,
        ja: ({ value }) => `${value}選手に設定`,
        es: ({ value }) => `Establecer en ${value} atletas`,
    },
    "setToRoundsCreateLabel": {
        en: ({ value }) => `Set to ${value} rounds`,
        pt: ({ value }) => `Configurar para ${value} rounds`,
        ja: ({ value }) => `${value}ラウンドに設定`,
        es: ({ value }) => `Establecer en ${value} rondas`,
    },
    "start": {
        pt: "iniciar",
        ja: "開始",
        es: "iniciar",
    },
    "end": {
        pt: "finalizar",
        ja: "終了",
        es: "finalizar",
    },
    "undo": {
        pt: "voltar",
        ja: "取消",
        es: "deshacer",
    },
    "Draw": {
        pt: "Chave",
        ja: "ヒート抽選",
        es: "Sorteo",
    },
    "Team": {
        pt: "Time",
        ja: "チーム",
        es: "Equipo",
    },
    "Teams": {
        pt: "Times",
        es: "Equipos",
        ja: "チーム",
    },
    "Type or select": {
        pt: "Digite ou selecione",
        ja: "入力または選択",
        es: "Escribir o seleccionar",
    },
    "Select profile": {
        pt: "Selecione o perfil",
        es: "Seleccionar perfil",
        ja: "プロフィールを選択する",
    },
    "Loading profiles…": {
        pt: "Buscando perfis…",
        es: "Cargando perfiles…",
        ja: "プロフィールを読み込んでいます…",
    },
    "Loading teams…":{
        pt: "Buscando times…",
        es: "Cargando equipos…",
        ja: "チームを読み込んでいます…",
    },
    "showing_x_results_per_page": {
        en: ({ x }) => `Showing the first ${x} matches. Keep typing to filter.`,
        pt: ({ x }) => `Mostrando ${x} resultados. Continue digitando para filtrar.`,
        es: ({ x }) => `Estas son las primeras ${x} coincidencias. Sigue escribiendo para filtrar.`,
        ja: ({ x }) => `一致した最初の${x}件を表示しています。入力を続けて絞り込んでください。`,
    },
    "add_value_as_new_profile": {
        en: ({ value }) => `+ Add '${value}' as new profile`,
        pt: ({ value }) => `+ Adicionar '${value}' como novo perfil`,
        es: ({ value }) => `+ Agregar a '${value}' como nuevo perfil`,
        ja: ({ value }) => `+ 新しいプロフィールとして '${value}' を追加`,
    },
    "add_value_as_new_team": {
        en: ({ value }) => `+ Add '${value}' as new team`,
        pt: ({ value }) => `+ Adicionar '${value}' como novo times`,
        es: ({ value }) => `+ Agregar a '${value}' como nuevo equipo`,
        ja: ({ value }) => `+ 新しいチームとして '${value}' を追加`,
    },
    "Yes": {
        pt: "Sim",
        ja: "はい",
        es: "Sí",
    },
    "No": {
        pt: "Não",
        ja: "いいえ",
        es: "No",
    },
    "Find competition:": {
        pt: "Encontrar competição",
        es: "Buscar competencia:",
        ja: "大会を探す：",
    },
    "Enter organisation name (e.g. Surfing NSW)": {
        pt: "Coloque o nome da organização (e.g. feserj)",
        es: "Ingresar el nombre del organizador (p.ej., Freeride Sudamerica)",
        ja: "団体名を入力（例：Surfing NSW）",
    },
    "Search": {
        pt: "Buscar",
        es: "Buscar",
        ja: "検索",
    },
    "Email address": {
        pt: "Seu e-mail",
        ja: "Eメール アドレス",
        es: "Dirección de correo electrónico",
    },
    "Rankings & leaderboards": {
        pt: "Rankings automáticos",
        es: "Clasificaciones y tablas de posiciones",
        ja: "ランキング＆リーダーボード",
    },
    "Privacy policy": {
        pt: "Política de privacidade",
        ja: "プライバシーポリシー",
        es: "Política de privacidad",
    },
    "Terms of use": {
        pt: "Termos de uso",
        ja: "利用規約",
        es: "Condiciones de uso",
    },
    "footer_contact": {
        en: <>Got a question? Contact us at <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink></>,
        pt: <>Tem alguma dúvida? Mande um e-mail para <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink></>,
        ja: <>ご質問ですか?<MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>までお問い合わせください</>,
        es: <>¿Tienes alguna pregunta? Envíanos un correo electrónico a <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink></>,
    },
    "Made with ❤ in Bondi Beach": {
        pt: "Feito com ❤ em Bondi Beach",
        ja: "ボンダイビーチより❤を込めて",
        es: "Hecho con ❤ en Bondi Beach",
    },
    "Next Athlete": {
        pt: "Próximo Atleta",
        ja: "次の選手",
        es: "Próximo atleta",
    },
    "Select ride": {
        pt: "Selecionar onda",
        ja: "ライドを選択",
        es: "Seleccionar vuelta",
    },
    "missing score": {
        pt: "nota faltando",
        ja: "スコアがありません",
        es: "Falta el puntaje",
    },
    "Interference": {
        pt: "Interferência",
        ja: "インターフェアレンス",
        es: "Interferencia",
    },
    "Priority Interference": {
        pt: "Interferência da Prioridade",
        ja: "プライオリティ・インターフェアレンス",
        es: "Interferencia prioritaria",
    },
    "Double Whammy": {
        pt: "Dobrar nota",
        ja: "ダブルワミー",
        es: "Doble revés",
    },
    "Remove modifier": {
        pt: "Remover modificador",
        ja: "変更点の削除",
        es: "Eliminar modificador",
    },
    "Waiting for director to start heat": {
        pt: "Aguardando o início da bateria",
        ja: "ディレクターがヒートを開始するのを待っています",
        es: "En espera del director para iniciar la serie",
    },
    "Waiting for director to start run": {
        pt: "Aguardando o início da volta",
        ja: "ディレクターがランを開始するのを待っています",
        es: "En espera del director para iniciar la tanda",
    },
    "What are you judging?": {
        pt: "O que você está arbitrando?",
        ja: "何をジャッジしますか？",
        es: "¿Qué vas a evaluar?",
    },
    "Select podium": {
        pt: "Selecione a bancada",
        ja: "ポディウムの選択",
        es: "Seleccionar podio",
    },
    "Select category": {
        pt: "Selecione a categoria",
        ja: "カテゴリの選択",
        es: "Seleccionar categoría",
    },
    "There are no heats in your selected podium.": {
        pt: "Não há bateria na bancada selecionada.",
        ja: "選択したポディウムにヒートがありません。",
        es: "En el podio que seleccionaste no hay ninguna serie.",
    },
    "JudgeSheet_heatTitle": {
        en: ({ selectedHeat, selectedCategory, heat }) => <span>{selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Main bank - " : "Secondary bank - " : ""}{heatHeaderTitle({ heat })}</span>,
        pt: ({ selectedHeat, selectedCategory, heat }) => <span>{selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Bancada principal - " : "Bancada secundária - " : ""}{heatHeaderTitle({ heat })}</span>,
        es: ({ selectedHeat, selectedCategory, heat }) => <span>{selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Podio principal - " : "Podio secundario - " : ""}{heatHeaderTitle({ heat })}</span>,
        ja: ({ selectedHeat, selectedCategory, heat }) => <span>{selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "メイン銀行 - " : "サブ銀行 - " : ""}{heatHeaderTitle({ heat })}</span>,
    },
    "TakeMeTo_JudgeSheet_heatTitle": {
        en: ({ selectedHeat, selectedCategory, heat }) => <>Take me to {selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Main bank - " : "Secondary bank - " : ""}{heatHeaderTitle({ heat })}</>,
        pt: ({ selectedHeat, selectedCategory, heat }) => <>Mudar para {selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Bancada principal - " : "Bancada secundária - " : ""}{heatHeaderTitle({ heat })}</>,
        es: ({ selectedHeat, selectedCategory, heat }) => <>Llévame al {selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "Podio principal - " : "Podio secundario - " : ""}{heatHeaderTitle({ heat })}</>,
        ja: ({ selectedHeat, selectedCategory, heat }) => <>{selectedCategory ? `${selectedCategory} - ` : ""}{selectedHeat != null ? selectedHeat === 0 ? "メイン銀行に移動 - " : "サブ銀行に移動 - " : ""}{heatHeaderTitle({ heat })}に移動</>,
    },
    "That's a wrap! All heats are finished.": {
        pt: "Fim! Todas as baterias foram finalizadas.",
        ja: "完了です！全てのヒートが終了しました。",
        es: "¡Listo! Finalizaron todas las series.",
    },
    "You're off the hook! The current heat is configured to use placing, not scores.": {
        pt: "Ta liberado! Esta bateria não usa arbitragem.",
        es: "¡Nada que hacer aquí! Esta serie tiene configuradas posiciones, no puntajes.",
        ja: "入力の必要はありません！現在のヒートはスコアではなく、順位を使用するように設定されています。",
    },
    "Log in with Facebook": {
        pt: "Entrar com Facebook",
        ja: "Facebookでログイン",
        es: "Iniciar sesión con Facebook",
    },
    "or": {
        pt: "ou",
        ja: "または",
        es: "o",
    },
    "Forgot your password?": {
        pt: "Esqueceu sua senha?",
        ja: "パスワードをお忘れですか？",
        es: "¿Olvidaste tu contraseña?",
    },
    "Get reset link": {
        pt: "Resgatar Senha",
        ja: "リセットリンクを取得",
        es: "Recibe un enlace para restablecerla",
    },
    "Log in": {
        pt: "Entrar",
        ja: "ログイン",
        es: "Iniciar sesión",
    },
    "An email has been sent with instructions on how to reset your password.": {
        pt: "Enviamos um email com instruções para alterar a sua senha.",
        ja: "パスワードをリセットする方法を記載したメールが送信されました。",
        es: "Te enviamos un correo electrónico con instrucciones para restablecer la contraseña.",
    },
    "Uh oh. That email address does not have a Liveheats account yet.": {
        pt: "Ops. Este email ainda não possui uma conta Liveheats.",
        ja: "そのEメールアドレスが登録されているライブヒートアカウントはありません。",
        es: "¡Uy! La dirección de correo electrónico no está registrada en Liveheats.",
    },
    "These passwords don't match": {
        pt: "As senhas não são iguais",
        ja: "これらのパスワードが一致しません",
        es: "Las contraseñas no coinciden",
    },
    "Password:": {
        pt: "Senha:",
        ja: "パスワード：",
        es: "Contraseña:",
    },
    "Password confirmation:": {
        pt: "Confirmar senha:",
        ja: "パスワード確認:",
        es: "Confirmación de contraseña:",
    },
    "invalid_token": {
        en: <>This reset link has either been used already, or has expired. <Link to="/forgot-password">Click here</Link> to generate another reset password email.</>,
        pt: <>Este link para alterar senha já foi utilizado, ou expirou. <Link to="/forgot-password">Click aqui</Link> para receber outro email com um link novo para alterar sua senha.</>,
        ja: <>このリセットリンクは既に使用されているか有効期限が切れています。パスワード再設定Eメールを再度生成するには<Link to="/forgot-password">ここ</Link>をクリックしてください。</>,
        es: <>El enlace para restablecer la contraseña ya se usó o venció. <Link to="/forgot-password">Haz clic aquí</Link> y genera otro correo electrónico para restablecer la contraseña.</>,
    },
    "Sign up with Facebook": {
        pt: "Cadastrar com Facebook",
        ja: "Facebookで登録",
        es: "Regístrate con Facebook",
    },
    "Full name:": {
        pt: "Nome completo:",
        ja: "氏名:",
        es: "Nombre completo:",
    },
    "Create account": {
        pt: "Criar conta",
        ja: "アカウント作成",
        es: "Crear cuenta",
    },
    "email_taken": {
        en: <>Oops, that email address is already in use, please use another one or <Link to="/login">login here</Link>.</>,
        pt: <>Ops, esse endereço de e-mail já está em uso, por favor utilize outro ou <Link to="/login">faça o login aqui</Link>.</>,
        ja: <>このメールアドレスは既に使用されています。別のメールアドレスを使用するか、<Link to="/login">こちら</Link>からログインしてください。</>,
        es: <>La dirección de correo electrónico ya está en uso. Usa una nueva o <Link to="/login">inicia sesión aquí</Link>.</>,
    },
    "Password reset": {
        pt: "Resgatar senha",
        ja: "パスワードのリセット",
        es: "Restablecer contraseña",
    },
    "To reset your password, enter the email address you use to sign in.": {
        pt: "Para resgatar sua senha, coloque o e-mail que você utiliza pra fazer log in.",
        ja: "パスワードをリセットするには、サインインに使用するメールアドレスを入力します。",
        es: "Para restablecer la contraseña, ingresa la dirección de correo electrónico con la que te registraste.",
    },
    "upcoming": {
        pt: "em breve",
        ja: "間もなく",
        es: "en breve",
    },
    "Registration Open": {
        pt: "Inscrições Abertas",
        ja: "登録開始",
        es: "Inscripción abierta",
    },
    "Upcoming": {
        pt: "Em Breve",
        ja: "間もなく",
        es: "En breve",
    },
    "Live": {
        pt: "Ao Vivo",
        ja: "ライブ",
        es: "En directo",
    },
    "Off": {
        pt: "Em Espera",
        ja: "オフ",
        es: "En espera",
    },
    "Complete": {
        pt: "Finalizado",
        ja: "完了",
        es: "Completado",
    },
    "Scheduled": {
        pt: "Agendado",
        ja: "予定",
        es: "Programado",
    },
    "Entry open": {
        pt: "Inscrições abertas",
        ja: "エントリーオープン",
        es: "Inscripción abierta",
    },
    "Entry closed": {
        pt: "Inscrições fechadas",
        ja: "エントリーが締め切られました",
        es: "Inscripción cerrada",
    },
    "Draw published": {
        pt: "Chaves publicadas",
        ja: "抽選が公開されました",
        es: "Sorteo publicado",
    },
    "On hold": {
        pt: "Em espera",
        ja: "保留中",
        es: "En espera",
    },
    "Results published": {
        pt: "Resultados publicados",
        ja: "結果が公開されました",
        es: "Resultados publicados",
    },
    "Cancelled": {
        pt: "Cancelado",
        es: "Cancelado",
        ja: "キャンセル済み",
    },
    "Past Events": {
        pt: "Eventos Anteriores",
        ja: "過去のイベント",
        es: "Eventos anteriores",
    },
    "Downloads": {
        ja: "ダウンロード",
        es: "Descargas",
    },
    "Download members": {
        pt: "Baixar sócios",
        es: "Descargar miembros",
        ja: "会員をダウンロード",
    },
    "Download payments": {
        pt: "Baixar pagamentos",
        ja: "支払いダウンロード",
        es: "Descargar pagos",
    },
    "View payments in Stripe": {
        pt: "Ver pagamentos no Stripe",
        es: "Ver pagos en Stripe",
        ja: "Stripeで支払いを表示",
    },
    "You're all set - membership for this series is active. Members will appear here as they register. You can also add members manually.": {
        pt: "Já está tudo configurado - associação a esta temporada está configurada. Sócios aparecerão aqui quando se inscreverem. Você também pode adicionar associados manualmente.",
        es: "¡Todo listo! La membresía a este grupo está activada. Los miembros aparecerán aquí a medida que se inscriban. También puedes agregar miembros de forma manual.",
        ja: "準備が完了しました。このシリーズの会員登録は有効になっています。会員が登録されると、ここに表示されます。手動で会員を追加することもできます。",
    },
    "SeriesMembers_membership_registration_not_enabled": {
        en: ({ seriesId }) => <>Athletes will appear here once they register as a member, or you add them manually. You can activate membership registration for this series in the <MuiLink underline="none" to={`/series/${seriesId}/edit`} component={Link}>Series settings</MuiLink> under the <b>Membership</b> tab.</>,
        pt: ({ seriesId }) => <>Atletas aparecerão na lista assim que se inscreveram ou forem adicionados manualmente. Você pode ativar inscrição de associados para esta temporada nas <MuiLink underline="none" to={`/series/${seriesId}/edit`} component={Link}>configurações da temporada</MuiLink> na aba de <b>Associação</b>.</>,
        es: ({ seriesId }) => <>Los atletas aparecerán aquí después de que se registren como miembros o que los agregues de forma manual. Puedes activar la inscripción a la membresía de este grupo en la pestaña <b>Membresía</b> de la sección <MuiLink underline="none" to={`/series/${seriesId}/edit`} component={Link}>Configuración del grupo</MuiLink>.</>,
        ja: ({ seriesId }) => <>選手が会員として登録を行うか、あなたが手動で会員を追加すると、ここに表示されます。<b>会員</b>タブの <MuiLink underline="none" to={`/series/${seriesId}/edit`} component={Link}>シリーズ設定</MuiLink>で、このシリーズの会員登録を有効にできます。</>,
    },
    "SeriesMembers_registrationNotActive": {
        en: ({ seriesId }) => <>Heads up, membership registration is not set up for this series. You can set this up in the <Link to={`/series/${seriesId}/edit`}>series settings</Link>, or add members manually.</>,
        pt: ({ seriesId }) => <>Aviso, associação não está configurada para esta temporada. Você pode ativá-la nas <Link to={`/series/${seriesId}/edit`}>configurações da temporada</Link> ou adicionar associados manualmente.</>,
        es: ({ seriesId }) => <>¡Atención! No se configuró la inscripción a la membresía de este grupo. Puedes configurarla desde la sección <Link to={`/series/${seriesId}/edit`}>Configuración del grupo</Link> o agregar a los miembros de forma manual.</>,
        ja: ({ seriesId }) => <>注意！このシリーズの会員登録が設定されていません。<Link to={`/series/${seriesId}/edit`}>シリーズ設定</Link>で設定するか、手動で会員を追加してください。</>,
    },
    "Contact number": {
        pt: "Telefone de contato",
        ja: "電話番号",
        es: "Número de contacto",
    },
    "Organisation": {
        pt: "Organização",
        es: "Organización",
        ja: "団体",
    },
    "members_total": {
        en: ({ total }) => `${total} members`,
        pt: ({ total }) => `${total} associados`,
        es: ({ total }) => `${total} miembros`,
        ja: ({ total }) => `${total}名の会員`,
    },
    "Registration date": {
        pt: "Data de inscrição",
        ja: "登録日",
        es: "Fecha de inscripción",
    },
    "expired": {
        pt: "vencido",
        es: "vencida",
        ja: "期限切れ",
    },
    "Name": {
        pt: "Nome",
        ja: "氏名",
        es: "Nombre",
    },
    "Search name, email, or division": {
        pt: "Buscar por nome, email ou categoria",
        ja: "氏名、Eメール、または部門で検索",
        es: "Buscar nombre, correo electrónico o categoría",
    },
    "Search name, email, or organisation": {
        pt: "Buscar por nome, email ou organização",
        es: "Buscar nombre, correo electrónico u organización",
        ja: "名前、Eメール、団体で検索",
    },
    "Search name or email": {
        pt: "Buscar por nome ou email",
        es: "Buscar nombre o correo electrónico",
        ja: "名前またはEメールで検索",
    },
    "Search name": {
        pt: "Buscar por nome",
        es: "Buscar nombre",
        ja: "名前で検索",
    },
    "View all athletes": {
        pt: "Ver todos atletas",
        es: "Ver todos los atletas",
        ja: "すべての選手を表示する",
    },
    "Sorry, we can't seem to find that member 😞": {
        pt: "Desculpe-nos, não conseguimos encontrar esse sócio 😞",
        ja: "申し訳ありません。お探しの会員は見つかりませんでした 😞",
        es: "Lo sentimos. No podemos encontrar al miembro que buscas 😞",
    },
    "Clear your search to view all members": {
        pt: "Limpe o campo de busca para ver todos os associados",
        ja: "検索をクリアして全会員を表示する",
        es: "Borra la búsqueda para ver a todos los miembros",
    },
    "Event status:": {
        pt: "Status do evento:",
        ja: "イベントステータス：",
        es: "Estado del evento:",
    },
    "General": {
        pt: "Geral",
        es: "General",
        ja: "一般",
    },
    "Membership": {
        pt: "Associação",
        es: "Membresía",
        ja: "会員",
    },
    "Accept membership": {
        pt: "Aceitar associações",
        es: "Aceptar membresía",
        ja: "会員を承認する",
    },
    "Membership registration": {
        pt: "Inscrição de associados",
        es: "Inscripción en la membresía",
        ja: "会員登録",
    },
    "Set up membership": {
        pt: "Configurar associação",
        es: "Configurar membresía",
        ja: "会員を設定する",
    },
    "Set single price": {
        pt: "Usar um preço único",
        es: "Fijar un precio único",
        ja: "単一価格を設定する",
    },
    "Customize by division": {
        pt: "Customizar por categoria",
        es: "Personalizar por categoría",
        ja: "部門別にカスタマイズ",
    },
    "Add item": {
        pt: "Adicionar ítem",
        es: "Agregar elemento",
        ja: "項目を追加する",
    },
    "+ Add item": {
        pt: "+ Adicionar ítem",
        es: "+ Agregar elemento",
        ja: "+ 項目を追加する",
    },
    "Set discounted total price for multiple athletes (e.g. family price)": {
        pt: "Adicionar preço total com desconto pra multiplos atletas (e.g. desconto pra família)",
        es: "Fijar un precio total con descuento para varios atletas (p.ej., precio familiar)",
        ja: "複数の選手に対して割引された合計価格を設定する（例：ファミリー価格）",
    },
    "Discounted total for multiple athletes": {
        pt: "Total com desconto pra multiplos atletas",
        es: "Total con descuento para varios atletas",
        ja: "複数の選手の割引された合計価格",
    },
    "Discounted total for multiple athletes:": {
        pt: "Total com desconto pra multiplos atletas:",
        es: "Total con descuento para varios atletas:",
        ja: "複数の選手の割引された合計価格：",
    },
    "You’re worth more than that 😉! You’ll need a price to open membership.": {
        pt: "Você vale mais que isso 😉! É necessário adicinar um preço pra abrir as associações",
        es: "¡Vales mucho más que eso! 😉 Necesitarás fijar un precio para activar la membresía.",
        ja: "あなたにはそれ以上の価値があります😉！会員を受け付けるには価格が必要です。",
    },
    "accept_membership_payments_enabled": {
        en: ({ seriesName }) => `Ready to have your members register and pay to join ${seriesName} via Liveheats? Get started below.`,
        pt: ({ seriesName }) => `Preparado para que seus sócios passam pagar pra se inscrever em ${seriesName} através do Liveheats? Comece abaixo.`,
        es: ({ seriesName }) => `¿Tienes todo listo para que tus miembros se registren y paguen para participar en ${seriesName} desde Liveheats? Sigue los pasos a continuación.`,
        ja: ({ seriesName }) => `Liveheatsで会員が登録し、支払いをして${seriesName}に参加できるようにしましょう。以下から始めることができます。`,
    },
    "Public registration link": {
        pt: "Link público pra inscrições",
        es: "Enlace a la inscripción pública",
        ja: "一般登録リンク",
    },
    "Additional registration notes:": {
        pt: "Notas adicionais pra inscrição",
        es: "Otras notas sobre la inscripción:",
        ja: "追加登録の注意事項：",
    },
    "Add registration notes": {
        pt: "Adicionar notas pra inscrição",
        es: "Agregar notas sobre la inscripción",
        ja: "登録の注意事項を追加する",
    },
    "Edit email confirmation message": {
        pt: "Editar mensagem do email de confirmação",
        es: "Editar el mensaje del correo electrónico de confirmación",
        ja: "メールの確認メッセージを編集する",
    },
    "Email confirmation message:": {
        pt: "Mensagem do email de confirmação:",
        es: "Mensaje del correo electrónico de confirmación:",
        ja: "メールの確認メッセージ：",
    },
    "email_confirmation_message_helptext": {
        en: <>Here you can add things like instructions, links, or contact details to be included in the confirmation email sent to registants. <a href="https://support.liveheats.com/hc/en-us/articles/15580422504212" target="_blank" rel="noopener noreferrer">Learn more</a>.</>,
        pt: <>Aqui você pode adicionar instruções, links ou detalhes de contato que serão incluídos no email de confirmação enviado aos inscritos. <a href="https://support.liveheats.com/hc/en-us/articles/15580422504212" target="_blank" rel="noopener noreferrer">Saiba mais</a>.</>,
        es: <>Aquí puedes agregar instrucciones, enlaces o información de contacto que quieras incluir en el correo electrónico de confirmación para las personas inscritas. <a href="https://support.liveheats.com/hc/en-us/articles/15580422504212" target="_blank" rel="noopener noreferrer">Obtén más información</a>.</>,
        ja: <>ここで、登録ユーザーに送信される確認メールに記載する手順、リンク、連絡先情報などを追加することができます。<a href="https://support.liveheats.com/hc/en-us/articles/15580422504212" target="_blank" rel="noopener noreferrer">詳しくはこちら</a>。</>,
    },
    "Price": {
        pt: "Preço",
        es: "Precio",
        ja: "価格",
    },
    "Set the price that members pay to register for a division": {
        pt: "Adicione o preço que sócios pagam pra se inscrever numa categoria",
        es: "Fija el precio que los miembros deben pagar para inscribirse en una categoría",
        ja: "会員が部門に登録する際に支払う価格を設定します",
    },
    "Advanced registration rules": {
        pt: "Regras avançadas de inscrição",
        es: "Reglas avanzadas de inscripción",
        ja: "事前の登録ルール",
    },
    "Allows you to restrict registrations, for example, by athlete age, or number of divisions per athlete": {
        pt: "Permite restringir inscrições, por exemplo por idade, ou número de categorias por atleta",
        es: "Te permite limitar las inscripciones, por ejemplo, por la edad o la cantidad de categorías de cada atleta",
        ja: "選手の年齢や選手1名あたりの部門数などで登録を制限することができます",
    },
    "Set the rules that will automatically apply as people register": {
        pt: "Adicione as regras que vão se aplicar automaticamente quando os sócios se inscreverem",
        es: "Define las reglas que se aplicarán automáticamente durante la inscripción",
        ja: "登録時に自動的に適用されるルールを設定します",
    },
    "Add any items included in the registration that require option selection, like T-shirt sizes": {
        pt: "Quaisquer ítens inclusos na inscrição que necessitam a escolha de uma opção, por exemplo camisetas",
        es: "Agrega los elementos de la inscripción que requieren que se seleccione una opción, como la talla de las playeras",
        ja: "Tシャツのサイズなど、オプションの選択が必要な登録項目があれば追加します",
    },
    "Optional add-ons": {
        pt: "Adicionais opcionais",
        es: "Complementos opcionales",
        ja: "オプションのアドオン",
    },
    "Add any items that can be purchased with the registration, like merchandise or extra insurance": {
        pt: "Quaisquer ítens que podem ser comprados junto da inscrição, como produtos ou seguro",
        es: "Agrega los elementos que pueden comprarse con la inscripción, como productos o un seguro adicional",
        ja: "グッズや追加保険など、登録時に購入できる項目を追加します",
    },
    "Registration documents and information": {
        pt: "Documentos e informações da inscrição",
        es: "Información y documentos de la inscripción",
        ja: "登録ドキュメントと情報",
    },
    "Include any notices, terms, or instructions you want people to see during and after registration": {
        pt: "Coloque quaisquer avisos, termos ou instruções que atletas devem ver durante e depois da inscrição",
        es: "Incluye los avisos, condiciones o instrucciones que quieres que estén visibles durante la inscripción",
        ja: "登録中や登録後に確認してもらいたい通知、規約、手順を追加します",
    },
    "Start from scratch": {
        pt: "Criar do zero",
        es: "Comienza de cero",
        ja: "ゼロから作成する",
    },
    "Recommended if you use Liveheats for registrations, or would like to import/add entries later.": {
        pt: "Recomendado se você utiliza Liveheats para inscrições, ou quer importart/adicionar competidores mais tarde.",
        es: "Opción recomendada si usas Liveheats para los registros o si deseas importar o agregar inscripciones más adelante.",
        ja: "登録にLiveheatsを使用する場合、または後からエントリーをインポート/追加する場合にお勧めです。",
    },
    "Copy event": {
        pt: "Copiar evento",
        es: "Copiar evento",
        ja: "イベントをコピーする",
    },
    "Copy from existing event": {
        pt: "Copiar um evento",
        es: "Copiar desde un evento actual",
        ja: "既存のイベントからコピーします",
    },
    "Pre-populates your event with same divisions and event settings as another event.": {
        pt: "Crie o seu evento com as mesmas categorias e configurações de um outro evento.",
        es: "Completa automáticamente la información de tu evento con las mismas categorías y configuraciones que otro evento.",
        ja: "別のイベントと同じ部門やイベント設定をあなたのイベントに事前入力します。",
    },
    "Select event you want to copy": {
        pt: "Selecione o evento a ser copiado",
        es: "Selecciona el evento que quieres copiar",
        ja: "コピーしたいイベントを選択する",
    },
    "You must select an event to copy, or go back and start from scratch": {
        pt: "É necessário selecionar um evento para copiar, ou voltar e criar do zero",
        es: "Debes seleccionar un evento para copiarlo; o bien, vuelve al inicio y comienza de cero",
        ja: "コピーするイベントを選択する必要があります。もしくは、戻ってゼロからイベントを作成します",
    },
    "Select event...": {
        pt: "Selecione evento...",
        es: "Seleccionar evento…",
        ja: "イベントを選択...",
    },
    "Create via import": {
        pt: "Importar dados",
        es: "Crear por importación",
        ja: "インポートから作成する",
    },
    "Recommended if you use another entry system. Simply import event divisions and entries via a spreadsheet.": {
        pt: "Recomendado se você utiliza um outro sistema de inscrições. Simplesmente importe as categorias e competidores usando uma tabela.",
        es: "Opción recomendada si usas otro sistema de inscripción. Solo tienes que importar las categorías del evento y las inscripciones usando una hoja de cálculo.",
        ja: "他のエントリーシステムを使用している場合にお勧めです。スプレッドシートでイベント部門とエントリーをインポートするだけです。",
    },
    "Upload data": {
        pt: "Importar dados",
        es: "Cargar datos",
        ja: "データをアップロードする",
    },
    "Event name": {
        pt: "Nome do evento",
        ja: "イベント名",
        es: "Nombre del evento",
    },
    "Start date": {
        pt: "Data de início",
        ja: "開始日",
        es: "Fecha de inicio",
    },
    "End date": {
        pt: "Data do fim",
        ja: "終了日",
        es: "Fecha de finalización",
    },
    "Location": {
        pt: "Local",
        ja: "場所",
        es: "Ubicación",
    },
    "Location:": {
        pt: "Local:",
        ja: "場所：",
        es: "Ubicación:",
    },
    "Search for a place or address, and choose from the list.": {
        pt: "Procure por um lugar ou endereço, e escolha da lista.",
        es: "Busca un lugar o dirección y elige de la lista.",
        ja: "場所や住所を検索し、リストから選択してください。",
    },
    "location_tooltip_text": {
        en: <>Enter an address, place or area e.g. "Bondi Beach". We use your event's location to set your event's timezone. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/31689254159764-Setting-the-event-location" variant="inherit" target="_blank" rel="noreferrer">Learn more</MuiLink></>,
        pt: <>Coloque um endereço, lugar ou área, por exemplo "Praia de Copacabana". Utilizamos a localização do seu evento para definir o fuso horário do evento. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/31689254159764-Setting-the-event-location" variant="inherit" target="_blank" rel="noreferrer">Saiba mais</MuiLink></>,
        es: <>Introduce una dirección, lugar o área, por ejemplo "Playa de Copacabana". Utilizamos la ubicación de tu evento para establecer la zona horaria del evento. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/31689254159764-Setting-the-event-location" variant="inherit" target="_blank" rel="noreferrer">Más información</MuiLink></>,
        ja: <>住所、場所、またはエリアを入力してください。たとえば、「コパカバーナビーチ」。イベントの場所を使用して、イベントのタイムゾーンを設定します。<MuiLink href="https://support.liveheats.com/hc/en-us/articles/31689254159764-Setting-the-event-location" variant="inherit" target="_blank" rel="noreferrer">詳細</MuiLink></>,
    },
    "Event type": {
        pt: "Tipo de evento",
        es: "Tipo de evento",
        ja: "イベントタイプ",
    },
    "Standalone": {
        pt: "Autônomo",
        es: "Independiente",
        ja: "単独",
    },
    "Part of a series": {
        pt: "Parte de temporada",
        es: "Parte de un grupo",
        ja: "シリーズの一部",
    },
    "Select series...": {
        pt: "Selecionar temporada...",
        ja: "シリーズを選択",
        es: "Seleccionar grupo…",
    },
    "Surely the event has a name 🤔": {
        pt: "Certamente o evento tem um nome 🤔",
        es: "El evento debe tener un nombre 🤔",
        ja: "イベントには名前があるはずです🤔",
    },
    "When does the event start?": {
        pt: "Quando o evento começa?",
        es: "¿Cuándo comienza el evento?",
        ja: "イベントはいつ始まりますか？",
    },
    "When does the event end?": {
        pt: "Quando o evento termina?",
        es: "¿Cuándo finaliza el evento?",
        ja: "イベントはいつ終わりますか？",
    },
    "Divisions in this event": {
        pt: "Categorias do evento",
        ja: "このイベントの各部門",
        es: "Categorías de este evento",
    },
    "You must add at least one division": {
        pt: "É necessário adicionar pelo menos uma categoria",
        es: "Debes agregar al menos una categoría",
        ja: "部門を1つ以上追加してください",
    },
    "create_new_division:value": {
        en: ({ value }) => `Create new division: ${value}`,
        pt: ({ value }) => `Criar nova categoria: ${value}`,
        es: ({ value }) => `Crear una categoría nueva: ${value}`,
        ja: ({ value }) => `新しい部門を作成する：${value}`,
    },
    "Clear all": {
        pt: "Remover todas",
        es: "Borrar todo",
        ja: "すべてクリア",
    },
    "Unable to create the event, the organisation is out of credits": {
        pt: "Não foi possível criar o evento, você não possui mais créditos",
        es: "No se pudo crear el evento porque la organización no tiene más créditos",
        ja: "イベントを作成できません。団体のクレジットが足りません",
    },
    "Looks like there was a problem importing your entries. Please check the entries and re-import if needed": {
        pt: "Houve um problema criando as inscrições. Por favor verifique os dados e importe novamente se necessário",
        es: "Parece que hubo un problema al importar las inscripciones. Si es necesario, revísalas y vuelve a importarlas",
        ja: "エントリーのインポート中に問題が発生したようです。エントリーを確認して、必要に応じて再度インポートしてください",
    },
    "Launch the importer wizard": {
        pt: "Utilizar o importador",
        es: "Iniciar el asistente de importaciones",
        ja: "インポーターウィザードを起動する",
    },
    "ready_to_import": {
        en: ({ entries, divisions }) => `Event will be created with ${entries} entries across ${divisions} division${plural(divisions)}`,
        pt: ({ entries, divisions }) => `O evento será criado com ${entries} inscrições em ${divisions} categoria${plural(divisions)}`,
        es: ({ entries, divisions }) => `El evento se creará con ${entries} inscripciones en ${divisions} categoría${plural(divisions)}`,
        ja: ({ entries, divisions }) => `${divisions}部門の${entries}エントリーを使用してイベントが作成されます`,
    },
    "event_upload_progress": {
        en: ({ progress }) => `Getting your event ready for showtime... ${progress}% complete`,
        pt: ({ progress }) => `Preparando o seu evento pro horário nobre... ${progress}% completo`,
        es: ({ progress }) => `Estamos preparando el evento… ${progress} % completado`,
        ja: ({ progress }) => `公開するためにイベントを準備しています... ${progress}%完了`,
    },
    "Upload event results?": {
        pt: "Importar resultados do evento?",
        es: "¿Importar los resultados del evento?",
        ja: "イベントの結果をインポートしますか？",
    },
    "Upload results": {
        pt: "Importar resultados",
        es: "Importar resultados",
        ja: "結果をインポートする",
    },
    "ready_to_upload_result": {
        en: ({ divisions, invalidEventDivisions }) => <>Heads up - results will be imported into {divisions} division{plural(divisions)} and override any existing draws. {invalidEventDivisions ? `There’s ${invalidEventDivisions} division${plural(invalidEventDivisions)} in the file not in this event (spelling must be identical), so these results won’t be imported.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-or-uploading-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: ({ divisions, invalidEventDivisions }) => <>Atenção - os resultados serão importados em {divisions} categoria{plural(divisions)} e vão sobrescrever qualquer chaves já criadas. {invalidEventDivisions ? `Há ${invalidEventDivisions} categoria${plural(invalidEventDivisions)} no arquivo que não estão nesse evento (a ortografia precisa ser idêntica), então esses resultados não serão importados.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-or-uploading-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: ({ divisions, invalidEventDivisions }) => <>Atención: los resultados se importarán a {divisions} categoría{plural(divisions)} y reemplazarán cualquier sorteo existente. {invalidEventDivisions ? `Hay ${invalidEventDivisions} categoría${plural(invalidEventDivisions)} en el archivo que no están en este evento (la ortografía debe ser idéntica), así que estos resultados no se importarán.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-or-uploading-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: ({ divisions, invalidEventDivisions }) => <>注意：結果は{divisions}部門にインポートされ、既存のドローを上書きします。{invalidEventDivisions ? `このイベントにはない${invalidEventDivisions}部門のファイルがあります（スペルは同じである必要がある）。したがって、これらの結果はインポートされません。` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-or-uploading-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">詳細を見る</MuiLink>.</>,
    },
    "ready_to_import_entries": {
        en: ({ divisions, invalidEventDivisions }) => <>Heads up - entries will be added or updated in {divisions} division{plural(divisions)}. {invalidEventDivisions ? `There's ${invalidEventDivisions} division${plural(invalidEventDivisions)} in the file not in this event (spelling must be identical), so those entries will be skipped.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: ({ divisions, invalidEventDivisions }) => <>Atenção - as inscrições serão adicionadas ou atualizadas em {divisions} categoria{plural(divisions)}. {invalidEventDivisions ? `Há ${invalidEventDivisions} categoria${plural(invalidEventDivisions)} no arquivo que não estão nesse evento (a ortografia precisa ser idêntica), então essas inscrições serão ignoradas.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: ({ divisions, invalidEventDivisions }) => <>Atención: las inscripciones se agregarán o actualizarán en {divisions} categoría{plural(divisions)}. {invalidEventDivisions ? `Hay ${invalidEventDivisions} categoría${plural(invalidEventDivisions)} en el archivo que no están en este evento (la ortografía debe ser idéntica), por lo que esas inscripciones se omitirán.` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: ({ divisions, invalidEventDivisions }) => <>注意：エントリーは{divisions}部門に追加または更新されます。{invalidEventDivisions ? `このイベントにはない${invalidEventDivisions}部門のファイルがあります（スペルは同じである必要がある）。したがって、これらのエントリーはスキップされます。` : ""} <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">詳細を見る</MuiLink>.</>,
    },
    "cannot_import_entries_bad_divisions": {
        en: <>The divisions in the file are not in this event (spelling must be identical), so all those entries will be skipped. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>As categorias no arquivo não estão nesse evento (a ortografia precisa ser idêntica), então todas essas inscrições serão ignoradas. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Las categorías en el archivo no están en este evento (la ortografía debe ser idéntica), por lo que todas esas inscripciones se omitirán. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>ファイルの部門がこのイベントにないため（スペルは同じである必要がある）、これらのエントリーはすべてスキップされます。<MuiLink href="https://support.liveheats.com/hc/en-us/articles/360036472211-Importing-your-athlete-list" target="_blank" rel="noreferrer">詳細を見る</MuiLink>.</>,
    },
    "cannot_upload_results_bad_divisions": {
        en: <>The divisions in the file are not in this event (spelling must be identical), so all those results will be skipped. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-importing-uploading-event-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>As categorias no arquivo não estão nesse evento (a ortografia precisa ser idêntica), então todos esses resultados serão ignorados. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-importing-uploading-event-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Las categorías en el archivo no están en este evento (la ortografía debe ser idéntica), por lo que todos esos resultados se omitirán. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-importing-uploading-event-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>ファイルの部門がこのイベントにないため（スペルは同じである必要がある）、これらの結果はすべてスキップされます。<MuiLink href="https://support.liveheats.com/hc/en-us/articles/360027895172-Adding-importing-uploading-event-results-into-your-rankings-that-didn-t-run-using-LiveHeats" target="_blank" rel="noreferrer">詳細を見る</MuiLink>.</>,
    },
    "Import entries": {
        pt: "Importar inscrições",
        es: "Importar inscripciones",
        ja: "エントリーをインポートする",
    },
    "Import entries?": {
        pt: "Importar inscrições?",
        es: "¿Importar inscripciones?",
        ja: "エントリーをインポートしますか？",
    },
    "Change file": {
        pt: "Usar outro arquivo",
        es: "Cambiar archivo",
        ja: "ファイルを変更する",
    },
    "Which series does this event belong to?": {
        pt: "Este evento pertence a uma temporada?",
        ja: "イベントが属しているシリーズ",
        es: "¿A qué grupo pertenece este evento?",
    },
    "Standalone event": {
        pt: "Evento autônomo",
        ja: "独立イベント",
        es: "Evento independiente",
    },
    "Event name:": {
        pt: "Nome do evento:",
        ja: "イベント名：",
        es: "Nombre del evento:",
    },
    "Start date:": {
        pt: "Data de início:",
        ja: "開始日:",
        es: "Fecha de inicio:",
    },
    "End date:": {
        pt: "Data do fim:",
        ja: "終了日:",
        es: "Fecha de finalización:",
    },
    "Default heat duration:": {
        pt: "Duração das baterias:",
        ja: "デフォルトのヒート時間：",
        es: "Duración predeterminada de la serie:",
    },
    "Default run duration:": {
        pt: "Duração dos voltas:",
        ja: "デフォルトのラン時間:",
        es: "Duración predeterminada de la tanda:",
    },
    "Divisions in this event:": {
        pt: "Categorias do evento:",
        ja: "このイベントの各部門：",
        es: "Categorías en este evento:",
    },
    "Division name:": {
        pt: "Nome da categoria:",
        ja: "部門名：",
        es: "Nombre de la categoría:",
    },
    "Add division": {
        pt: "Adicionar categoria",
        ja: "部門の追加",
        es: "Agregar categoría",
    },
    "Event layout type:": {
        pt: "Tipo de evento:",
        es: "Tipo de diseño del evento:",
        ja: "イベントのレイアウトタイプ：",
    },
    "Single course schedule": {
        pt: "Agenda única",
        es: "Cronograma de un solo ciclo",
        ja: "シングルコーススケジュール",
    },
    "Multi course schedule": {
        pt: "Multiplas agendas",
        es: "Cronograma de varios ciclos",
        ja: "マルチコーススケジュール",
    },
    "event_layout_type": {
        en: <>This determines how officials can start and stop heats and the public event display. If the races will be run on one course or area only, select Single course schedule, otherwise use Multi course schedule. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/9033119802260" target="_blank">Learn more.</MuiLink></>,
        pt: <>Esta configuração determina como árbitros iniciam e finalizam baterias. Se as baterias ocorrem numa agenda única, seleciona Agenda única, senão selecione Multiplas agendas. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/9033119802260" target="_blank">Saiba mais.</MuiLink></>,
        es: <>Esto determina cómo los dirigentes pueden iniciar y detener las series y la exhibición pública del evento. Si las competencias se llevarán a cabo solo en un ciclo o una zona, selecciona “Cronograma de un solo ciclo”, de lo contrario, selecciona “Cronograma de varios ciclos”. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/9033119802260" target="_blank">Obtén más información.</MuiLink></>,
        ja: <>これにより、オフィシャルがどのようにヒートや公開イベント表示を開始・停止できるかが決まります。レースが1つのコースまたは1つのエリアのみで行われる場合は、シングルコーススケジュールを、そうでない場合はマルチコーススケジュールを選択してください。<MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/9033119802260" target="_blank">詳細を見る。</MuiLink></>,
    },
    "Jersey order:": {
        pt: "Ordem das lycras:",
        ja: "ジャージ順：",
        es: "Orden de camisetas:",
    },
    "Hide results for finals": {
        pt: "Ocultar os resultados das finais",
        ja: "決勝結果を表示しない",
        es: "Ocultar resultados de las finales",
    },
    "Hide schedule from public event page": {
        pt: "Ocultar a agenda na página pública do evento",
        es: "Ocultar cronograma de la página de eventos públicos",
        ja: "公開イベントページにスケジュールを表示しない",
    },
    "Hide entry list and seeds from public": {
        pt: "Ocultar a lista de inscritos e os seeds do público",
        es: "Ocultar lista de inscripciones y cabezas de serie del público",
        ja: "公開からエントリーリストとシードを隠す",
    },
    "Hide 'What's on' area from public event page": {
        pt: "Ocultar 'Ao vivo' na página pública do evento",
        es: "Ocultar sección “Eventos actuales” de la página de eventos públicos",
        ja: "公開イベントページに「開催中」エリアを表示しない",
    },
    "Ranking points multiplier:": {
        pt: "Multiplicador de pontos do ranking:",
        ja: "リーダーボードマルチプライヤー",
        es: "Multiplicador de puntos de clasificación:",
    },
    "Event uses a club leaderboard": {
        pt: "O evento tem um ranking dos clubes",
        es: "El evento usa una tabla de posiciones por clubes",
        ja: "イベントはクラブのリーダーボードを使用します",
    },
    "cut_line_x": {
        en: ({ x }) => `Cut line ${x}`,
        pt: ({ x }) => `Linha de corte ${x}`,
        es: ({ x }) => `Línea de corte ${x}`,
        ja: ({ x }) => `カットライン${x}`,
    },
    "Position:": {
        pt: "Posição:",
        es: "Posición:",
        ja: "ポジション：",
    },
    "Description:": {
        pt: "Descrição:",
        es: "Descripción:",
        ja: "説明：",
    },
    "cut_line_position_help_text":{
        en: <>The position of the cut line on your ranking, e.g. enter “8” to make the cutline appear below the 8th ranked athlete.</>,
        pt: <>A posição da linha de corte no seu ranking, por exemplo, insira “8” para fazer a linha de corte aparecer abaixo do atleta classificado em 8º.</>,
        es: <>La posición de la línea de corte en tu ranking, por ejemplo, ingresa “8” para hacer que la línea de corte aparezca debajo del atleta clasificado en 8º lugar.</>,
        ja: <>あなたのランキングにおけるカットラインの位置、例えば、「8」を入力して8位のアスリートの下にカットラインを表示します。</>,
    },
    "cut_line_description_help_text":{
        en: <>Provide an informative and succinct description for your cutline, such as “TOP 8 SURFERS” or “QUALIFIER CUT OFF”.</>,
        pt: <>Forneça uma descrição informativa e sucinta para a sua linha de corte, como “TOP 8 SURFERS” ou “QUALIFIER CUT OFF”.</>,
        es: <>Proporcione una descripción informativa y sucinta para su línea de corte, como “TOP 8 SURFERS” o “QUALIFIER CUT OFF”.</>,
        ja: <>あなたのカットラインに対して、情報的で簡潔な説明を提供してください。例えば、「TOP 8 SURFERS」または「QUALIFIER CUT OFF」など。</>,
    },
    "club_leaderboard_help_text": {
        en: <>The club leaderboard awards points to each competitor's event team (like their club, country, or state) based on their overall division result. <a href="https://support.liveheats.com/hc/en-us/articles/9126933843860" target="_blank" rel="noreferrer">Learn more.</a></>,
        pt: <>O ranking dos clubes concede pontos pro time de cada competidor no evento (por exemplo o clube, estado ou país) dependendo do seu resultado geral em cada categoria. <a href="https://support.liveheats.com/hc/en-us/articles/9126933843860" target="_blank" rel="noreferrer">Saiba mais.</a></>,
        es: <>La tabla de posiciones por clubes le otorga puntos al equipo de cada competidor que participa en el evento (como su club, país o estado) en base al resultado general de la categoría. <a href="https://support.liveheats.com/hc/en-us/articles/9126933843860" target="_blank" rel="noreferrer">Obtén más información.</a></>,
        ja: <>クラブのリーダーボードは、部門の全体の結果に基づいて各競技者のイベントチーム（クラブ、国、州など）にポイントを付与します。<a href="https://support.liveheats.com/hc/en-us/articles/9126933843860" target="_blank" rel="noreferrer">詳しくはこちら。</a></>,
    },
    "Seeding comes from": {
        pt: "Usar a temporada pro seed",
        es: "La asignación de cabezas de serie viene de",
        ja: "シードは次のシリーズから行う",
    },
    "Registration notes:": {
        pt: "Notas na inscrição:",
        ja: "登録に関する注意事項:",
        es: "Notas sobre la inscripción:",
    },
    "Judging": {
        pt: "Arbitragem",
        ja: "ジャッジング",
        es: "Evaluación",
    },
    "Drop lowest and highest scores": {
        pt: "Remover nota mais alta e mais baixa",
        ja: "最高および最低スコアのドロップ",
        es: "Excluir los puntajes más bajos y más altos",
    },
    "Hide athlete names from judges": {
        pt: "Ocultar nomes dos atletas",
        ja: "選手名をジャッジに非表示",
        es: "Ocultar los nombres de los atletas a los jueces",
    },
    "Head judge must approve scores": {
        pt: "Árbitro principal precisa aprovar as notas",
        es: "El juez principal debe aprobar los puntajes",
        ja: "ヘッドジャッジはスコアを承認する必要があります",
    },
    "Number of judges on panel (optional):": {
        pt: "Número de juízes no painel (opcional):",
        es: "Número de jueces en el panel (opcional):",
        ja: "パネルの審査員数（オプション）：",
    },
    "minimum_judge_scores_explainer": {
        en: <>Enter the number of scoring judges on the panel. If using double banks/panels, use the smallest panel size, not the total. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/8410445420180-Setting-the-minimum-number-of-judges-panel-size-" target="_blank">Learn more.</MuiLink></>,
        pt: <>Insira o número de juízes que vão avaliar a bateria. Se você usar dois painéis, use o menor número de juízes, não o total. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/8410445420180-Setting-the-minimum-number-of-judges-panel-size-" target="_blank">Saiba mais.</MuiLink></>,
        es: <>Ingresa el número de jueces que calificarán la serie. Si usas dos paneles, usa el número más pequeño, no el total. <MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/8410445420180-Setting-the-minimum-number-of-judges-panel-size-" target="_blank">Obtén más información.</MuiLink></>,
        ja: <>パネルでスコアリングする審査員の数を入力します。ダブルバンク/パネルを使用する場合は、合計ではなく最小のパネルサイズを使用してください。<MuiLink variant="caption" href="https://support.liveheats.com/hc/en-us/articles/8410445420180-Setting-the-minimum-number-of-judges-panel-size-" target="_blank">詳細を見る。</MuiLink></>,
    },
    "Maximum possible score per ride:": {
        pt: "Nota máxima possível:",
        es: "Puntaje máximo por vuelta:",
        ja: "1回のライドの最高スコア：",
    },
    "Defines the highest possible score that an athlete can receive in a single ride, most commonly 10 or 100.": {
        pt: "Define a nota máxima que um atleta pode receber por cada avaliação, normalmente 10 ou 100.",
        es: "Define el puntaje más alto que un atleta puede recibir en una sola vuelta, normalmente 10 o 100.",
        ja: "選手が1回のライドで獲得できる最高得点を示すもので、一般的には10点または100点となります。",
    },
    "EventForm_judgeScale": {
        en: ({ value }) => `Set to ${value}`,
        pt: ({ value }) => `Configurar para ${value}`,
        ja: ({ value }) => `${value}選手に設定`,
        es: ({ value }) => `Establecer en ${value}`,
    },
    "Payments": {
        pt: "Pagamentos",
        ja: "支払い",
        es: "Pagos",
    },
    "Connect to Stripe": {
        pt: "Conectar com Stripe",
        es: "Conectar con Stripe",
        ja: "Stripeに接続",
    },
    "Fees and FAQ": {
        pt: "Taxas e FAQ",
        es: "Tarifas y preguntas frecuentes",
        ja: "料金とFAQ",
    },
    "You're all set! Your bank account is connected and you're ready to accept paid registrations for this event.": {
        pt: "Pronto! A sua conta bancária está connectada e você já pode receber pagamentos para este evento.",
        ja: "準備完了です！銀行口座が連携され、このイベントの有料登録を受け付ける準備が整いました。",
        es: "¡Todo listo! Tu cuenta bancaria está conectada y ya puedes aceptar inscripciones de pago para este evento.",
    },
    "Learn more": {
        pt: "Saiba mais",
        ja: "もっと詳しく",
        es: "Obtén más información",
    },
    "Activate paid registration": {
        pt: "Ativar pagamentos",
        ja: "有料登録をアクティベート",
        es: "Activar la inscripción de pago",
    },
    "open": {
        pt: "aberta",
        es: "abierta",
        ja: "open",
    },
    "closed": {
        pt: "fechada",
        es: "cerrada",
        ja: "クローズ",
    },
    "draft": {
        pt: "rascunho",
        es: "en curso",
        ja: "下書き",
    },
    "Remove payment": {
        pt: "Remover pagamento",
        ja: "支払の削除",
        es: "Eliminar pago",
    },
    "Price per division:": {
        pt: "Preço por categoria:",
        ja: "各部門ごとの価格：",
        es: "Precio por categoría:",
    },
    "Price per division": {
        pt: "Preço por categoria",
        ja: "各部門ごとの価格",
        es: "Precio por categoría",
    },
    "price_per_division_waitlisted_help_text": {
        en: <>Registration is set to waitlisted, so athletes will see prices but will only be charged if and when confirmed. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>A inscrição está configurada para lista de espera, então os atletas verão os preços mas só serão cobrados quando confirmados. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>La inscripción está configurada para lista de espera, por lo que los atletas verán los precios, pero solo se les cobrará cuando se confirmen. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>登録がウェイティングリスト制に設定されています。そのため、価格はアスリートに表示されますが、登録が確定した場合にのみ請求されます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">詳しくはこちら</MuiLink>。</>,
    },
    "price_per_division_x": {
        en: ({ price, currency }) => `Price per division: ${formatPrice(price, currency)}`,
        pt: ({ price, currency }) => `Preço por categoria: ${formatPrice(price, currency)}`,
        es: ({ price, currency }) => `Precio por categoría: ${formatPrice(price, currency)}`,
        ja: ({ price, currency }) => `部門ごとの価格：${formatPrice(price, currency)}`,
    },
    "athletes_discount": {
        en: ({ athletes, price, currency }) => `${athletes}+ athletes: ${formatPrice(price, currency)}`,
        pt: ({ athletes, price, currency }) => `${athletes} ou mais atletas: ${formatPrice(price, currency)}`,
        es: ({ athletes, price, currency }) => `Más de ${athletes} atletas: ${formatPrice(price, currency)}`,
        ja: ({ athletes, price, currency }) => `${athletes}+ 選手：${formatPrice(price, currency)}`,
    },
    "You've activated paid registration for this event.": {
        pt: "Você ativou pagamentos neste evento.",
        ja: "このイベントの有料登録をアクティベートしました。",
        es: "Activaste una inscripción de pago para este evento.",
    },
    "waitlist_payments_notification": {
        en: <>As this event has a waitlist, athletes will not be charged until their entry is confirmed by an event director. <a target="_blank" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" rel="noreferrer"><b>Learn more.</b></a></>,
        pt: <>Como este evento possui uma lista de espera, os atletas não serão cobrados até que sua inscrição seja confirmada. <a target="_blank" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" rel="noreferrer"><b>Saber mais.</b></a></>,
        es: <>Como este evento tiene una lista de espera, los atletas no serán cobrados hasta que su inscripción sea confirmada. <a target="_blank" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" rel="noreferrer"><b>Obtén más información.</b></a></>,
        ja: <>本イベントはウェイティングリストが設定されているため、イベントディレクターによってエントリーが確定するまでアスリートは料金を請求されません。<a target="_blank" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" rel="noreferrer"><b>詳しくはこちら。</b></a></>,
    },
    "event_terms_and_conditions": {
        en: <>Your Terms and Conditions must be read and accepted by athletes (or their parents) as they register, and usually includes a liability waiver in the case of athlete injury. See an <a href="https://support.liveheats.com/hc/en-us/articles/360039186652" target="_blank" rel="noopener noreferrer">example here</a>.</>,
        pt: <>Os Termos de Uso devem ser lidos e aceitos pelos atletas (ou seus responsáveis) quando se increverem, e geralmente incluem um termo de responsabilidade em caso de acidentes. Veja um <a href="https://support.liveheats.com/hc/en-us/articles/360039186652" target="_blank" rel="noopener noreferrer">exemplo aqui</a>.</>,
        es: <>Cada atleta (o su padre o madre) debe leer y aceptar los Términos y condiciones durante la inscripción. Por lo general, se incluye una cláusula de exención de responsabilidad en caso de que sufran una lesión. Consulta un <a href="https://support.liveheats.com/hc/en-us/articles/360039186652" target="_blank" rel="noopener noreferrer">ejemplo aquí</a>.</>,
        ja: <>選手（または選手の保護者）は登録時に利用規約を読み、同意する必要があり、通常、規約には選手が怪我をした場合の免責条項が含まれている必要があります。<a href="https://support.liveheats.com/hc/en-us/articles/360039186652" target="_blank" rel="noopener noreferrer">こちらで例</a>をご覧ください。</>,
    },
    "Event terms and conditions:": {
        pt: "Termos de uso do evento:",
        es: "Términos y condiciones del evento:",
        ja: "イベントの利用規約：",
    },
    "Terms and conditions": {
        pt: "Termos de uso",
        es: "Términos y condiciones",
        ja: "利用規約",
    },
    "Upload terms and conditions PDF": {
        pt: "Fazer upload do PDF dos termos de uso",
        es: "Cargar los términos y condiciones en PDF",
        ja: "利用規約のPDFをアップロードする",
    },
    "Upload another document to sign": {
        pt: "Fazer upload de outro documento para assinar",
        es: "Cargar otro documento para firmar",
        ja: "署名する別のドキュメントをアップロードする",
    },
    "event_docs_to_sign": {
        en: <>Collect e-signatures for waivers and agreements. Every registering athlete or their parent/guardian will be required to sign. Signatory details will be appended to your PDF. <a href="https://support.liveheats.com/hc/en-us/articles/16123061844628" target="_blank" rel="noopener noreferrer">Learn more</a></>,
        pt: <>Colete assinaturas eletrônicas para termos de responsabilidade e acordos. Cada atleta ou seu responsável precisará assinar. Os detalhes do signatário serão adicionados ao seu PDF. <a href="https://support.liveheats.com/hc/en-us/articles/16123061844628" target="_blank" rel="noopener noreferrer">Saiba mais</a></>,
        es: <>Obtén firmas electrónicas para las exenciones y los acuerdos. Cada atleta que se inscribe (o su madre, padre o tutor legal) deberá firmar los documentos. La información detallada de los firmantes se adjuntará al PDF. <a href="https://support.liveheats.com/hc/en-us/articles/16123061844628" target="_blank" rel="noopener noreferrer">Obtén más información</a></>,
        ja: <>権利放棄と同意書の電子署名を集めます。登録選手またはその親/保護者は署名をする必要があります。署名者の詳細はPDFに追加されます。<a href="https://support.liveheats.com/hc/en-us/articles/16123061844628" target="_blank" rel="noopener noreferrer">詳しくはこちら</a></>,
    },
    "Document uploaded successfully!": {
        pt: "Upload finalizado com sucesso!",
        es: "El documento se cargó correctamente.",
        ja: "ドキュメントがアップロードされました！",
    },
    "Upload PDF": {
        pt: "Escolher arquivo PDF",
        ja: "PDF のアップロード",
        es: "Cargar PDF",
    },
    "price_x": {
        en: ({ x }) => `Custom price ${x}`,
        pt: ({ x }) => `Preço ${x}`,
        ja: ({ x }) => `カスタム価格${x}`,
        es: ({ x }) => `Precio personalizado ${x}`,
    },
    "Division:": {
        pt: "Categoria:",
        ja: "部門：",
        es: "Categoría:",
    },
    "Price:": {
        pt: "Preço",
        ja: "価格：",
        es: "Precio:",
    },
    "Set custom pricing by division": {
        pt: "Criar preço específico por categoria",
        ja: "部門別のカスタム価格を設定",
        es: "Fijar precios personalizados por categoría",
    },
    "Add division price": {
        pt: "Adicionar preço de categoria",
        ja: "部門の価格を追加",
        es: "Agregar precio de la categoría",
    },
    "Registration": {
        pt: "Inscrições",
        es: "Inscripción",
        ja: "登録",
    },
    "Entry dates": {
        pt: "Datas de inscrição",
        es: "Fechas de inscripción",
        ja: "エントリー日",
    },
    "Automatically open and close registration based on specified dates and times. Learn more": {
        pt: "Abrir e fechar inscrições automaticamente com base em datas e horários específicos. Saiba mais",
        es: "Abrir y cerrar la inscripción automáticamente en función de fechas y horarios específicos. Obtén más información",
        ja: "指定された日時に基づいて自動的にエントリーを開始および終了します。詳細はこちら",
    },
    "Entry opens": {
        pt: "Inscrições abrem",
        es: "Inscripción se abre",
        ja: "エントリー開始",
    },
    "Entry closes": {
        pt: "Inscrições fecham",
        es: "Inscripción se cierra",
        ja: "エントリー終了",
    },
    "Waitlisting": {
        pt: "Lista de espera",
        es: "En lista de espera",
        ja: "ウェイティングリスト",
    },
    "waitlist_setting_help_text": {
        en: <>All athletes will enter as waitlisted and must be manually confirmed by a director to each division. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>Todos os atletas entrarão na lista de espera e precisarão ser confirmados manualmente pelo diretor em cada categoria. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Todos los atletas se inscribirán en lista de espera y el director deberá confirmarlos manualmente en cada categoría. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>すべての選手はウェイティングリストに登録し、各部門のディレクターが手動でエントリーを確認する必要があります。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032224171-Setting-event-registrations-to-be-waitlisted" target="_blank" rel="noreferrer">詳しくはこちら</MuiLink>。</>,
    },
    "As payment is required for this event, athletes will need to provide payment details during registration. However, they will only be charged if and when their entry gets confirmed by an event director.": {
        pt: "Como o pagamento é obrigatório para este evento, os atletas precisarão fornecer os dados de pagamento durante a inscrição. No entanto, eles só serão cobrados quando sua inscrição for confirmada.",
        es: "Como el pago es obligatorio para este evento, los atletas deberán proporcionar los datos de pago durante la inscripción. Sin embargo, solo se les cobrará cuando su inscripción sea confirmada.",
        ja: "このイベントには支払いが必要であるため、選手は登録時に支払いに関する詳細情報を提供する必要があります。ただし、イベントディレクターによってエントリーが確定した場合のみ、参加料が請求されます。",
    },
    "Once an athlete is registered or imported into a division, they are waitlisted until confirmed or removed": {
        pt: "Quando atletas se inscrevem ou são importados numa categoria, ficam na lista de espera até serem confirmados ou removidos",
        es: "Cuando se inscriben atletas o se importa a una categoría, quedan en lista de espera hasta que se confirma o se elimina.",
        ja: "選手が部門に登録するか、インポートされると、確定または削除されるまでウェイティングリストに登録されます",
    },
    "Waitlist entries": {
        pt: "Usar lista de espera",
        es: "Inscripciones en lista de espera",
        ja: "ウェイティングリストのエントリー",
    },
    "Limit number of divisions per athlete": {
        pt: "Restringir o número de categorias por atleta",
        es: "Limitar la cantidad de categorías por atleta",
        ja: "選手1人あたりの部門数を制限する",
    },
    "limit_athlete_division": {
        en: ({ divisionName, minAge, maxAge, by }) => `${divisionName} : ${minAge != null ? `Min. ${minAge}` : ""}${minAge != null && maxAge != null ? " and " : ""}${maxAge != null ? `Max. ${maxAge}` : ""} years by ${by}`,
        pt: ({ divisionName, minAge, maxAge, by }) => `${divisionName} : ${minAge != null ? `Mín. ${minAge}` : ""}${minAge != null && maxAge != null ? " e " : ""}${maxAge != null ? `Máx. ${maxAge}` : ""} anos em ${by}`,
        es: ({ divisionName, minAge, maxAge, by }) => `${divisionName}: ${minAge != null ? `mín. ${minAge}` : ""}${minAge != null && maxAge != null ? " y " : ""}${maxAge != null ? `máx. ${maxAge}` : ""} años antes del ${by}`,
        ja: ({ divisionName, minAge, maxAge, by }) => `${divisionName} : ${japaneseFormatDate(by)}${minAge != null ? `までに${minAge}歳以上` : "まで"}${minAge != null && maxAge != null ? "かつ" : ""}${maxAge != null ? `${maxAge}歳以下` : ""}`,
    },
    "Set discounted price for additional divisions": {
        pt: "Preço com desconto para categoria adicional",
        es: "Fijar precio con descuento a las categorías adicionales",
        ja: "追加の部門に割引価格を設定する",
    },
    "Price per additional division:": {
        pt: "Preço por categoria adicional:",
        es: "Precio por cada categoría adicional:",
        ja: "追加1部門あたりの価格：",
    },
    "Price per additional division": {
        pt: "Preço por categoria adicional",
        es: "Precio por cada categoría adicional",
        ja: "追加1部門あたりの価格",
    },
    "Set discounted total price for multiple athletes (family discount)": {
        pt: "Definir preço total com desconto para multiplos atletas (desconto de família)",
        es: "Fijar precio total con descuento para varios atletas (descuento familiar)",
        ja: "複数の選手に対して割引された合計価格を設定する（ファミリー割引）",
    },
    "family_discount_heading": {
        en: ({ x }) => `Discount ${x}`,
        pt: ({ x }) => `${x}˚ desconto`,
        es: ({ x }) => `Descuento de ${x}`,
        ja: ({ x }) => `${x}割引`,
    },
    "Number of athletes:": {
        pt: "Número de atletas:",
        es: "Cantidad de atletas:",
        ja: "選手の数：",
    },
    "Total price:": {
        pt: "Preço total:",
        es: "Precio total:",
        ja: "合計金額：",
    },
    "Maximum divisions per athlete:": {
        pt: "Número máximo de categorias por atleta:",
        es: "Máximo de categorías por atleta:",
        ja: "選手1人あたりの最大部門数：",
    },
    "Maximum divisions per athlete": {
        pt: "Número máximo de categorias por atleta",
        es: "Máximo de categorías por atleta",
        ja: "選手1人あたりの最大部門数",
    },
    "Add paid extras to registration": {
        pt: "Incluir adicionais pagos na inscrição",
        es: "Agregar complementos de pago a la inscripción",
        ja: "登録に有料エクストラを追加する",
    },
    "Description (e.g. T-shirt):": {
        pt: "Descrição (e.g. Camiseta)",
        es: "Descripción (p.ej., playera):",
        ja: "説明（Tシャツなど）：",
    },
    "Description": {
        pt: "Descrição",
        es: "Descripción",
        ja: "説明",
    },
    "Options (e.g. S, M, L):": {
        pt: "Opções (e.g. P, M, G):",
        es: "Opciones (p.ej., S, M, L):",
        ja: "オプション（S、M、Lなど）：",
    },
    "Add_new_option": {
        en: ({ value }) => `Create new option: ${value}`,
        pt: ({ value }) => `Criar nova opção: ${value}`,
        es: ({ value }) => `Crear opción nueva: ${value}`,
        ja: ({ value }) => `新しいオプションの作成：${value}`,
    },
    "No options": {
        pt: "Nenhuma opção",
        es: "No hay opciones",
        ja: "オプションなし",
    },
    "Loading…": {
        pt: "Carregando…",
        es: "Cargando…",
        ja: "読み込み中...",
    },
    "Add another": {
        pt: "Includir outro",
        es: "Agregar otro",
        ja: "さらに追加する",
    },
    "free_extra_count": {
        en: ({ x }) => `Free extra ${x}`,
        pt: ({ x }) => `${x}˚ adicional`,
        es: ({ x }) => `Complemento gratuito ${x}`,
        ja: ({ x }) => `無料エクストラ${x}`,
    },
    "paid_extra_count": {
        en: ({ x }) => `Paid extra ${x}`,
        pt: ({ x }) => `${x}˚ adicional pago`,
        es: ({ x }) => `Complemento de pago ${x}`,
        ja: ({ x }) => `有料エクストラ${x}`,
    },
    "Set age limits for registration": {
        pt: "Escolher limites de idade pra inscrição",
        es: "Establecer límites de edad para la inscripción",
        ja: "登録に年齢制限を設ける",
    },
    "age_limits_heading": {
        en: ({ x }) => `Restriction ${x}`,
        pt: ({ x }) => `Restrição ${x}`,
        es: ({ x }) => `Restricción de ${x}`,
        ja: ({ x }) => `制限${x}`,
    },
    "Minimum age:": {
        pt: "Idade mínima",
        es: "Edad máxima:",
        ja: "最低年齢：",
    },
    "Maximum age:": {
        pt: "Idade máxima",
        es: "Edad mínima:",
        ja: "最高年齢：",
    },
    "Must be older than min": {
        pt: "Precisa ser mais velho que o mínimo",
        es: "Debe ser mayor que la edad mínima",
        ja: "歳以上であること",
    },
    "Must be younger than max": {
        pt: "Precisa ser mais jovem que o máximo",
        es: "Debe ser menor que la edad máxima",
        ja: "歳以下であること",
    },
    "by": {
        pt: "em",
        es: "antes del",
        ja: "by",
    },
    "By the date:": {
        pt: "Na data",
        es: "Antes de la fecha:",
        ja: "以下の日付まで：",
    },
    "Update event": {
        pt: "Salvar evento",
        ja: "イベントの更新",
        es: "Actualizar evento",
    },
    "Delete event": {
        pt: "Deletar evento",
        ja: "イベントの削除",
        es: "Eliminar evento",
    },
    "Are you sure you want to delete this event?": {
        pt: "Tem certeza que quer deletar este evento?",
        ja: "このイベントを削除してもよろしいですか?",
        es: "¿Confirmas que deseas eliminar este evento?",
    },
    "All of the event data will be lost, this action cannot be undone!": {
        pt: "Todos os dados do evento serão perdidos e não da pra voltar atrás!",
        ja: "すべてのイベントデータが失われます。この操作を元に戻すことはできません。",
        es: "Se perderán todos los datos del evento y no podrás revertirlo.",
    },
    "Yes, delete it": {
        pt: "Sim, deletar",
        ja: "はい、削除します",
        es: "Sí, eliminar",
    },
    "Delete series": {
        pt: "Deletar temporada",
        es: "Eliminar grupo",
        ja: "シリーズを削除する",
    },
    "delete_series_name": {
        en: ({ name }) => `Delete ${name}?`,
        pt: ({ name }) => `Deletar ${name}?`,
        es: ({ name }) => `¿Deseas eliminar la serie ${name}?`,
        ja: ({ name }) => `${name}を削除しますか？`,
    },
    "We got you. Series deleted 🙅": {
        pt: "Deletamos a temporada 🙅",
        es: "Entendido. El grupo se eliminó 🙅",
        ja: "わかりました。シリーズは削除されました🙅",
    },
    "This will PERMANENTLY DELETE the rankings and any memberships for this series and cannot be undone.": {
        pt: "Isto vai DELETAR PERMANENTEMENTE o ranking e qualquer associação para esta temporada e não pode ser desfeito.",
        es: "Esto ELIMINARÁ PERMANENTEMENTE las clasificaciones y las membresías de este grupo y no puede revertirse.",
        ja: "これにより、このシリーズのランキングと会員は永久に削除され、元に戻すことはできません。",
    },
    "red": {
        pt: "vermelho",
        ja: "赤",
        es: "rojo",
    },
    "white": {
        pt: "branco",
        ja: "白",
        es: "blanco",
    },
    "yellow": {
        pt: "amarelo",
        ja: "黄色",
        es: "amarillo",
    },
    "blue": {
        pt: "azul",
        ja: "青",
        es: "azul",
    },
    "green": {
        pt: "verde",
        ja: "緑",
        es: "verde",
    },
    "pink": {
        pt: "rosa",
        ja: "ピンク",
        es: "rosa",
    },
    "black": {
        pt: "preto",
        ja: "黒",
        es: "negro",
    },
    "orange": {
        pt: "laranja",
        ja: "オレンジ",
        es: "naranja",
    },
    "purple": {
        pt: "roxo",
        ja: "紫",
        es: "morado",
    },
    "Quarter": {
        pt: "Quartas",
        ja: "クォーター",
        es: "Cuartos de final",
    },
    "Semi": {
        ja: "セミ",
        es: "Semifinal",
    },
    "Final": {
        ja: "決勝",
        es: "Final",
    },
    "Ht": {
        pt: "Bt",
        ja: "Ht",
        es: "El",
    },
    "Unable to render dashboard because this user doesn't have any athletes!": {
        pt: "Você ainda não se inscreveu em nenhum evento!",
        ja: "このユーザーには選手がいないため、ダッシュボードを表示できません!",
        es: "No se puede mostrar el panel porque este usuario no tiene atletas.",
    },
    "Heat Schedule": {
        pt: "Próximas Baterias",
        ja: "ヒートスケジュール",
        es: "Cronograma de la serie",
    },
    "You don't have any events yet! Once you've entered or completed an event, it'll show up here.": {
        pt: "Você ainda não tem baterias, mas assim que você se inscrever ou completar um evento elas aparecerão aqui!",
        ja: "まだイベントがありません！イベントを入力または完了すると、ここに表示されます。",
        es: "Aún no tienes eventos. Una vez que ingreses o completes un evento, aparecerá aquí.",
    },
    "No memberships to show yet. When you join an organisation, your membership will appear here.": {
        pt: "Você ainda não tem associações, mas assim que você se associar a uma organização elas aparecerão aqui!",
        ja: "まだ表示する会員がありません。組織に参加すると、会員がここに表示されます。",
        es: "Aún no tienes membresías. Cuando te unas a una organización, aparecerá aquí.",
    },
    "Search organisations to join": {
        pt: "Procurar organizações para se associar",
        ja: "参加する組織を検索する",
        es: "Buscar organizaciones para unirse",
    },
    "Search events and organisations":{
        pt: "Pesquisar eventos e organizações",
        ja: "イベントと組織を検索する",
        es: "Buscar eventos y organizaciones",
    },
    "Life member": {
        pt: "Sócio vitalício",
        ja: "生涯会員",
        es: "Miembro vitalicio",
    },
    "organisation_membership": {
        en: ({ organisationName }) => `${organisationName} Membership`,
        pt: ({ organisationName }) => `Associação com ${organisationName}`,
        ja: ({ organisationName }) => `${organisationName}の会員`,
        es: ({ organisationName }) => `Membresía de ${organisationName}`,
    },
    "One-off": {
        pt: "Única vez",
        ja: "ワンオフ",
        es: "Única vez",
    },
    "exp_date": {
        en: ({ expiryDate }) => `Exp. ${expiryDate}`,
        pt: ({ expiryDate }) => `Venc. ${expiryDate}`,
        es: ({ expiryDate }) => `Venc. ${expiryDate}`,
        ja: ({ expiryDate }) => `有効期限 ${japaneseFormatDate(expiryDate)}`,
    },
    "Used": {
        pt: "Usado",
        ja: "使用済み",
        es: "Usado",
    },
    "UpcomingEvent_message_waitlisted": {
        en: ({ event, divisions }) => <>You are on the waitlist for the {joinBy(", ", " and ", divisions)} division{plural(divisions.length)} of <strong>{event.name}</strong> which is on <EventDate date={event.date} window={event.daysWindow}/>. We'll drop you a line when your position is confirmed or declined by the organiser. Good luck 🙂!</>,
        pt: ({ event, divisions }) => <>Você está na lista de espera da{plural(divisions.length)} categoria{plural(divisions.length)} {joinBy(", ", " e ", divisions)} no <strong>{event.name}</strong> que vai acontecer em <EventDate date={event.date} window={event.daysWindow}/>. Nós te notificaremos quando os organizadores decidirem sobre a sua vaga. Boa sorte 🙂!</>,
        es: ({ event, divisions }) => <>Estás en la lista de espera de {joinBy(", ", " y ", divisions)} division{plural(divisions.length)} de <strong>{event.name}</strong>, que tendrá lugar el <EventDate date={event.date} window={event.daysWindow}/>. Te avisaremos cuando la organización confirme o rechace tu posición. ¡Suerte! 🙂</>,
        ja: ({ event, divisions }) => <><EventDate date={event.date} window={event.daysWindow}/>に開催される<strong>{event.name}</strong>の{joinBy("、", "および", divisions)}部門のウェイティングリストに登録されています。主催者によってあなたのエントリーが確定または却下されたら、ご連絡いたします。参加できますように🙂！</>,
    },
    "UpcomingEvent_message_confirmed": {
        en: ({ event, divisions }) => <>Start preparing 💪! You are confirmed to compete in the {joinBy(", ", " and ", divisions)} division{plural(divisions.length)} of <strong>{event.name}</strong> which is on <EventDate date={event.date} window={event.daysWindow}/>. The heat draw has not been published by the organiser yet, but when it is you'll find your heat information here.</>,
        pt: ({ event, divisions }) => <>Comece a preparar 💪! Sua vaga está confirmada na{plural(divisions.length)} categorias{plural(divisions.length)} {joinBy(", ", " e ", divisions)} do <strong>{event.name}</strong> que vai acontecer em <EventDate date={event.date} window={event.daysWindow}/>. As chaves ainda não foram criadas, mas você poderá ver as informações aqui assim que elas forem publicadas.</>,
        es: ({ event, divisions }) => <>¡Prepárate! 💪 Competirás en {joinBy(", ", " y ", divisions)} division{plural(divisions.length)} de <strong>{event.name}</strong>, que tendrá lugar el <EventDate date={event.date} window={event.daysWindow}/>. La organización aún no publicó el sorteo de las baterías, pero cuando lo haga verás la información de tu serie aquí.</>,
        ja: ({ event, divisions }) => <>準備を始めましょう💪！<EventDate date={event.date} window={event.daysWindow}/>に開催される<strong>{event.name}</strong>の{joinBy("、", "および", divisions)}部門へのエントリーが確定しました。ヒートの抽選はまだ主催者によって発表されていませんが、発表されたら、ここでヒートの情報を確認できます。</>,
    },
    "UpcomingEvent_message_waitlisted_and_confirmed": {
        en: ({ event, waitlisted, confirmed }) => <>Start preparing 💪! You're confirmed for the {joinBy(", ", " and ", confirmed)} division{plural(confirmed.length)} of <strong>{event.name}</strong> which is on <EventDate date={event.date} window={event.daysWindow}/>. However, you are still on the waitlist for the {joinBy(", ", " and ", waitlisted)} division{plural(waitlisted.length)}. We'll drop you a line when your position is confirmed or declined by the organiser.</>,
        pt: ({ event, waitlisted, confirmed }) => <>Comece a preparar 💪! Sua vaga está confirmada na{plural(confirmed.length)} categorias{plural(confirmed.length)} {joinBy(", ", " e ", confirmed)} do <strong>{event.name}</strong> que vai acontecer em <EventDate date={event.date} window={event.daysWindow}/>. Entretando, você ainda está na lista de espera da{plural(waitlisted.length)} categoria{plural(waitlisted.length)} {joinBy(", ", " e ", waitlisted)}. Nós te notificaremos quando os organizadores decidirem sobre a sua vaga.</>,
        es: ({ event, waitlisted, confirmed }) => <>¡Prepárate! 💪 Se confirmó tu participación en {joinBy(", ", " y ", confirmed)} division{plural(confirmed.length)} de <strong>{event.name}</strong>, que tendrá lugar el <EventDate date={event.date} window={event.daysWindow}/>. Sin embargo, sigues en la lista de espera de {joinBy(", ", " y ", waitlisted)} division{plural(waitlisted.length)}. Te avisaremos cuando la organización confirme o rechace tu posición.</>,
        ja: ({ event, waitlisted, confirmed }) => <>準備を始めましょう💪！<EventDate date={event.date} window={event.daysWindow}/>に開催される<strong>{event.name}</strong>の{joinBy("、", "および", confirmed)}部門へのエントリーが確定しました。ただし、{joinBy("、", "および", waitlisted)}部門については、まだウェイティングリストに登録されています。主催者によってあなたのエントリーが確定または却下されたら、ご連絡いたします。</>,
    },
    "Heat draw is not available yet": {
        pt: "As chaves do evento ainda não estão disponíveis",
        ja: "ヒート抽選はまだご利用いただけません",
        es: "El sorteo de las series aún no está disponible",
    },
    "Heat draw and Results": {
        pt: "Baterias e Resultados",
        ja: "ヒート抽選と結果",
        es: "Sorteo de las series y resultados",
    },
    "EventBox_eventHeading": {
        en: ({ name, status }) => `${name} is ${status}`,
        pt: ({ name, status }) => <>{name} está <T>{status}</T></>,
        ja: ({ name, status }) => `${name}は${status}です`,
        es: ({ name, status }) => `${name} está ${status}`,
    },
    "Join now": {
        pt: "Associe-se",
        es: "Registrarse ahora",
        ja: "今すぐ参加する",
    },
    "Log in and join": {
        pt: "Faça login e associe-se",
        es: "Iniciar sesión y registrarse",
        ja: "ログインして参加する",
    },
    "Options": {
        pt: "Opções",
        es: "Opciones",
        ja: "オプション",
    },
    "Register": {
        pt: "Inscreva-se",
        es: "Inscribirse",
        ja: "登録する",
    },
    "LIVE": {
        pt: "AO VIVO",
        ja: "ライブ",
        es: "EN DIRECTO",
    },
    "complete": {
        pt: "finalizado",
        ja: "完了",
        es: "completada",
    },
    "Bueno. Event successfully saved!": {
        pt: "Boa, evento atualizado!",
        ja: "素晴らしい。イベントが正常に保存されました！",
        es: "¡Bien! El evento se guardó correctamente.",
    },
    "Event successfully deleted!": {
        pt: "Evento deletado!",
        ja: "イベントが正常に削除されました！",
        es: "El evento se eliminó correctamente.",
    },
    "Nicely done! You've built a heat draw and closed athlete registration. You can re-open registration in the event status at any time.": {
        pt: "Muito bem! As chaves de baterias foram criadas com sucesso e as inscrições foram fechadas. Você pode re-abrir as incrição mudando o status do evento.",
        ja: "素晴らしい！ヒート抽選を組み立て選手登録を締め切りました。登録はイベントステータスからいつでも再開できます。",
        es: "¡Muy bien! Creaste un sorteo de series y cerraste la inscripción de atletas. Puedes volver a abrir la inscripción desde el estado del evento cuando gustes.",
    },
    "randomize_successful": {
        en: <>Nice! Entries are closed and the competitor lists have been randomised, ready for marshalling 🎙️. You can re-open entries <MuiLink variant="inherit" underline="none" href="https://support.liveheats.com/hc/en-us/articles/360035610252-Publishing-your-draw-and-managing-the-event-status" target="_blank">in the event status</MuiLink>.</>,
        pt: <>Gênio! Inscrições estão fechadas e as listas de competidores foram randomizadas️. Você pode abrir as inscrições de volta <MuiLink variant="inherit" underline="none" href="https://support.liveheats.com/hc/en-us/articles/360035610252-Publishing-your-draw-and-managing-the-event-status" target="_blank">mudando o status do evento</MuiLink>.</>,
        es: <>¡Qué bien! Las inscripciones se cerraron y las listas de competidores se aleatorizaron. Todo está listo para comenzar 🎙️ Puedes volver a abrir las inscripciones <MuiLink variant="inherit" underline="none" href="https://support.liveheats.com/hc/en-us/articles/360035610252-Publishing-your-draw-and-managing-the-event-status" target="_blank">desde el estado del evento</MuiLink>.</>,
        ja: <>素晴らしい！エントリーが締め切られ、競技者リストがランダム化されて、集まる準備ができました🎙️。<MuiLink variant="inherit" underline="none" href="https://support.liveheats.com/hc/en-us/articles/360035610252-Publishing-your-draw-and-managing-the-event-status" target="_blank">イベントステータス</MuiLink>からエントリーを再開できます。</>,
    },
    "Sad face. Athlete removed from the heat.": {
        pt: "Atleta removido da bateria.",
        ja: "残念です。選手がヒートから削除されました。",
        es: "¡Qué triste! Se suprimió el atleta de la serie.",
    },
    "competitor_removed_from_heat": {
        en: ({ name }) => `Sad face. ${name} removed from the heat.`,
        pt: ({ name }) => `Que tristeza. ${name} não está mais na bateria.`,
        es: ({ name }) => `¡Qué triste! ${name} se suprimió de la serie.`,
        ja: ({ name }) => `残念。${name}がヒートから削除されました。`,
    },
    "Sad face. Team removed from the heat.": {
        pt: "Time removide da bateria.",
        ja: "残念。チームがヒートから削除されました。",
        es: "¡Qué triste! Se suprimió el equipo de la serie.",
    },
    "Delete successful. Draw updated.": {
        pt: "Deletado com sucesso. Chave atualizada.",
        ja: "削除が成功しました。抽選が更新されました。",
        es: "Eliminado con éxito. Sorteo actualizado.",
    },
    "Voila! Round deleted.": {
        pt: "Pronto! Round deletado.",
        ja: "素晴らしい！ラウンドが削除されました。",
        es: "¡Tarán! Se eliminó la ronda.",
    },
    "Successfully added. Draw updated.": {
        pt: "Adicionado com sucesso. Chave atualizada.",
        ja: "追加されました。抽選が更新されました。",
        es: "Agregado con éxito. Sorteo actualizado.",
    },
    "Magic! New round added to end of the group.": {
        pt: "Excelente, novo round adicionado ao fim do grupo.",
        es: "¡Magnífico! Se agregó una nueva ronda al final del grupo.",
        ja: "素晴らしい！グループの最後に新しいラウンドが追加されました。",
    },
    "Success! Round settings have been saved.": {
        pt: "Configurações do round salvas!",
        ja: "成功！ラウンド設定が保存されました。",
        es: "¡Todo actualizado! Se guardó la configuración de la ronda.",
    },
    "Aren't you nice! Athlete added to the heat.": {
        pt: "Atleta adicionado à bateria.",
        ja: "素晴らしい！選手がヒートに追加されました。",
        es: "¡Qué bien! Se agregó al atleta a la serie.",
    },
    "Aren't you nice! Team added to the heat.": {
        pt: "Time adicionado à bateria.",
        ja: "素晴らしい！チームがヒートに追加されました。",
        es: "¡Qué bien! Se agregó al equipo a la serie.",
    },
    "Aren't you nice! Athlete added to the team.": {
        pt: "Atleta adicionado ao time.",
        es: "¡Qué bien! Se agregó al atleta al equipo.",
        ja: "素晴らしい！選手がチームに追加されました。",
    },
    "Entry has been moved to the cancellation list 💃": {
        pt: "Inscrição movida para a lista de cancelamentos 💃",
        es: "La inscripción se movió a la lista de cancelaciones 💃",
        ja: "エントリーがキャンセルリストに移動されました💃",
    },
    "Round settings": {
        pt: "Configurações do round",
        es: "Configuración de la ronda",
        ja: "ラウンド設定",
    },
    "Nice update! Round settings saved.": {
        pt: "Configurações do round salvas!",
        ja: "素晴らしい！ラウンド設定が保存されました。",
        es: "¡Todo actualizado! Se guardó la configuración de la ronda.",
    },
    "Division settings saved!": {
        pt: "Configurações da categoria salvas!",
        ja: "部門設定が保存されました！",
        es: "Se guardó la configuración de la categoría.",
    },
    "Magic! New round added to end of draw.": {
        pt: "Excelente, novo round adicionado ao fim da chave.",
        ja: "素晴らしい！抽選の最後に新しいラウンドが追加されました。",
        es: "¡Magnífico! Se agregó una nueva ronda al final del sorteo.",
    },
    "Your draw is looking good! Redraw successful.": {
        pt: "Chave recriada com sucesso!",
        ja: "抽選は正しく組み立てられているようです！再抽選に成功しました。",
        es: "El sorteo se ve bien. La actualización se completó correctamente.",
    },
    "Ride successfully removed!": {
        pt: "Notas deletadas.",
        ja: "ライドが正常に削除されました！",
        es: "La vuelta se eliminó correctamente.",
    },
    "Ride approved.": {
        pt: "Notas aprovadas.",
        es: "La vuelta está aprobada.",
        ja: "ライドが承認されました。",
    },
    "Ride updated.": {
        pt: "Notas atualizadas.",
        ja: "ライドが更新されました。",
        es: "La vuelta está actualizada.",
    },
    "It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?": {
        pt: "Não conseguimos encontrar o que você procura! Talvez você queria encontrar uma de nossas organizações?",
        ja: "お探しの情報が見つかりません！団体をお探しですか？",
        es: "No podemos encontrar lo que buscas. ¿Quizás estás buscando alguna de nuestras organizaciones?",
    },
    "It looks like we can't find the heat you are looking for! Are you sure it's here?": {
        pt: "A bateria que você está procurando não existe! Tem certeza que ela está aqui?",
        ja: "お探しのヒートが見つかりません！検索場所を間違えていませんか？",
        es: "No podemos encontrar la serie que buscas. ¿Seguro que está aquí?",
    },
    "Oops, we can't find what you were looking for!": {
        pt: "Ops, não podemos encontrar o que você estava procurando!",
        es: "Lo sentimos. No podemos encontrar lo que buscas.",
        ja: "おっと、お探しのものが見つかりません！",
    },
    "Copied to clipboard!": {
        pt: "Copiado!",
        ja: "クリップボードにコピーしました！",
        es: "Se copió al portapapeles.",
    },
    "You've successfully added a break to the schedule. You can now drag it into position.": {
        pt: "Pausa adicionada com sucesso, você pode arrastar ela para a posição correta.",
        ja: "スケジュールに休暇を追加しました。お好きな位置までドラグできます。",
        es: "Agregaste correctamente una pausa en el cronograma. Ahora puedes arrastrarla a su posición.",
    },
    "You're a schedule making superstar. Break successfully updated.": {
        pt: "Horário da pausa atualizado com sucesso.",
        ja: "スケジュールを組む天才ですね。休暇が更新されました。",
        es: "¡Sin duda dominas los cronogramas! La pausa se actualizó correctamente.",
    },
    "Who needed that break anyway? Break successfully removed from the schedule.": {
        pt: "Quem precisava dessa pausa mesmo? Pausa deletada.",
        ja: "休暇日など必要ありませんか？休暇日がスケジュールから削除されました。",
        es: "Al fin y al cabo, nadie necesitaba esa pausa. Se eliminó correctamente del cronograma.",
    },
    "You need to login before continuing to that page!": {
        pt: "Você precisa fazer login antes de acessar essa página!",
        ja: "そのページに進む前にログインする必要があります。",
        es: "Tienes que iniciar sesión antes de continuar a esa página.",
    },
    "You don't have enough permissions to view that page!": {
        pt: "Você não tem permissões suficientes para ver essa página!",
        ja: "このページを表示するためのアクセス許可がありません！",
        es: "No tienes suficientes permisos para ver esa página.",
    },
    "Connecting your device...": {
        pt: "Conectando seu dispositivo...",
        ja: "デバイスを接続しています...",
        es: "Conectando el dispositivo…",
    },
    "Connected!": {
        pt: "Conectado!",
        ja: "接続されました！",
        es: "¡Conectado!",
    },
    "You're offline. You can still enter scores.": {
        pt: "Você está disconectado, mas ainda é possível adicionar notas.",
        ja: "オフラインです。スコアは引き続き入力できます。",
        es: "No estás en línea, pero igualmente puedes ingresar puntajes.",
    },
    "Go to event page": {
        pt: "Acessar página do evento",
        ja: "イベントページへ移動",
        es: "Ir a la página de eventos",
    },
    "Go to Liveheats": {
        pt: "Ir para o Liveheats",
        es: "Ir a Liveheats",
        ja: "Liveheatsへ移動",
    },
    "Go to dashboard": {
        pt: "Ir para o painel",
        es: "Ir al panel",
        ja: "ダッシュボードへ移動",
    },
    "Live Heat": {
        pt: "Bateria ao Vivo",
        ja: "ライブヒート",
        es: "Serie en directo",
    },
    "NEXT": {
        pt: "PRÓXIMA",
        ja: "次",
        es: "SIGUIENTE",
    },
    "HeatHeader_title": {
        en: (props) => heatHeaderTitle(props),
        pt: (props) => heatHeaderTitle(props),
        es: (props) => heatHeaderTitle(props),
        ja: (props) => heatHeaderTitle(props),
    },
    "HeatHeader_empty_title": {
        en: "Division : Round : Heat",
        pt: "Categoria : Round : Bateria",
        ja: "部門：ラウンド：ヒート",
        es: "Categoría : Ronda : Serie",
    },
    "INT": {
        pt: "INT",
        ja: "INT",
        es: "INT",
    },
    "No athlete": {
        pt: "Sem atleta",
        ja: "選手未登録",
        es: "Sin atletas",
    },
    "No team": {
        pt: "Sem time",
        ja: "チームなし",
        es: "Sin equipo",
    },
    "Remove add athlete form": {
        pt: "Esconder campo de adicionar atleta",
        ja: "選手追加フォームの削除",
        es: "Eliminar el formulario para agregar atletas",
    },
    "Press and drag to move this progression to a new position": {
        pt: "Clique e segure para mover esta progressão para outra posição",
        ja: "この進行状況を新しい位置に移動するにはクリックしてドラグします",
        es: "Presiona y arrastra para mover esta progresión a una nueva posición",
    },
    "Live heats": {
        pt: "Baterias ao vivo",
        ja: "ライブヒート",
        es: "Series en directo",
    },
    "Recent results": {
        pt: "Resultados recentes",
        ja: "最新結果",
        es: "Resultados recientes",
    },
    "Upcoming heats": {
        pt: "Próximas baterias",
        ja: "間もなく開始するヒート",
        es: "Próximas series",
    },
    "Rotate your phone for detailed view": {
        pt: "Vire o seu dispositivo para ver mais detalhes",
        ja: "詳細を表示するためにはお使いの携帯電話を回転させます",
        es: "Gira el teléfono para tener una vista detallada",
    },
    "Edit Event": {
        pt: "Editar Evento",
        ja: "イベントの編集",
        es: "Editar evento",
    },
    "athlete_and_team_count": {
        en: ({ count }) => `${count.athlete} athletes${count.team !== null ? ` and ${count.team} teams` : ""}`,
        pt: ({ count }) => `${count.athlete} atletas${count.team !== null ? ` e ${count.team} times` : ""}`,
        es: ({ count }) => `${count.athlete} atletas${count.team !== null ? ` y ${count.team} equipos` : ""}`,
        ja: ({ count }) => `${count.athlete}選手${count.team !== null ? `と${count.team}チーム` : ""}`,
    },
    "athlete_count": {
        en: ({ count }) => `${count} athlete${plural(count)}`,
        pt: ({ count }) => `${count} atleta${plural(count)}`,
        es: ({ count }) => `${count} atleta${plural(count)}`,
        ja: ({ count }) => `${count}選手`,
    },
    "team_count": {
        en: ({ count }) => `${count} team${plural(count)}`,
        pt: ({ count }) => `${count} time${plural(count)}`,
        es: ({ count }) => `${count} equipo${plural(count)}`,
        ja: ({ count }) => `${count}チーム`,
    },
    "Athlete registration": {
        pt: "Inscrições de atletas",
        ja: "選手登録",
        es: "inscripción de atletas",
    },
    "TV scoreboard": {
        pt: "Placar para TV",
        ja: "TVスコアボード",
        es: "Página de la pizarra interactiva",
    },
    "Public event link": {
        pt: "Link público do evento",
        ja: "公開イベントリンク",
        es: "Enlace público del evento",
    },
    "Public page": {
        pt: "Página pública",
        es: "Página pública",
        ja: "パブリックページ",
    },
    "Embed URL": {
        pt: "URL para embutir",
        ja: "URL埋め込み",
        es: "Insertar URL",
    },
    "LED Priority Display": {
        pt: "Tela de prioridade para LED",
        es: "Indicador LED de prioridad",
        ja: "LED優先表示",
    },
    "Broadcast graphics URL (Transparent)": {
        pt: "URL para transmissão (transparente)",
        es: "URL de gráficos para transmisión (Transparente)",
        ja: "ブロードキャスト用グラフィックスURL（透明）",
    },
    "Broadcast graphics URL (Chromakey)": {
        pt: "URL para transmissão (chromakey)",
        es: "URL de gráficos para transmisión (Chromakey)",
        ja: "ブロードキャスト用グラフィックスURL（クロマキー）",
    },
    "Event divisions": {
        pt: "Categorias",
        ja: "イベント部門",
        es: "Categorías del evento",
    },
    "division_price": {
        en: ({ price, currency }) => `Entry ${formatPrice(price, currency)}`,
        pt: ({ price, currency }) => `Inscrição ${formatPrice(price, currency)}`,
        es: ({ price, currency }) => `Inscripción de ${formatPrice(price, currency)}`,
        ja: ({ price, currency }) => `エントリー${formatPrice(price, currency)}`,
    },
    "x_entered": {
        en: ({ x }) => `${x} entered`,
        pt: ({ x }) => `${x} inscrito${plural(x)}`,
        es: ({ x }) => `${x} ${x !== 1 ? "inscripciones" : "inscripción"}`,
        ja: ({ x }) => `${x}名がエントリーしました`,
    },
    "schedule_blocks": {
        en: ({ size, plural }) => `${size} block${plural ? "s" : ""}`,
        pt: ({ size, plural }) => `${size} bloco${plural ? "s" : ""}`,
        ja: ({ size }) => `${size}ブロック`,
        es: ({ size, plural }) => `${size} bloque${plural ? "s" : ""}`,
    },
    "schedule_days": {
        en: ({ size, plural }) => `${size} day${plural ? "s" : ""}`,
        pt: ({ size, plural }) => `${size} dia${plural ? "s" : ""}`,
        ja: ({ size }) => `${size}日間`,
        es: ({ size, plural }) => `${size} día${plural ? "s" : ""}`,
    },
    "Judge status": {
        pt: "Status dos árbitros",
        ja: "ジャッジステータス",
        es: "Estado del juez",
    },
    "Judge logins": {
        pt: "Logins dos árbitros",
        es: "Inicios de sesión del juez",
        ja: "ジャッジログイン",
    },
    "Unavailable": {
        pt: "Indisponível",
        ja: "利用不可",
        es: "No disponible",
    },
    "Connected": {
        pt: "Conectado",
        ja: "接続されました",
        es: "Conectado",
    },
    "It looks like there are connection issues, maybe there's a problem with the internet? Retrying...": {
        pt: "Parece que você não tem conexão à internet? Reconectando...",
        ja: "接続に問題があるようです。インターネット接続をお確かめください。再試行中...",
        es: "Parece que la conexión es defectuosa. Puede que haya un problema con Internet. Reintentando…",
    },
    "We're connecting you to the judges, sit tight!": {
        pt: "Estamos conectando todos os árbitros, um segundo!",
        ja: "ジャッジに接続しています。お待ちください！",
        es: "Te estamos conectando con los jueces, ¡no te vayas!",
    },
    "You're connected, but all judges are currently offline. Judge accounts will appear here when they're online.": {
        pt: "Estamos conectados mas ainda não tem nenhum árbitro. Os árbitros aparecerão aqui assim que conectarem.",
        ja: "接続されましたが、ジャッジが全員オフラインです。ジャッジがオンラインになるとここにジャッジアカウントが表示されます。",
        es: "Estás en línea, pero los jueces no lo están. Las cuentas de los jueces aparecerán aquí cuando se conecten.",
    },
    "online": {
        pt: "conectado",
        ja: "オンライン",
        es: "en línea",
    },
    "offline": {
        pt: "desconectado",
        ja: "オフライン",
        es: "sin conexión",
    },
    "Download": {
        pt: "Baixar",
        ja: "ダウンロード",
        es: "Descargar",
    },
    "Copy link": {
        pt: "Copiar link",
        ja: "リンクをコピー",
        es: "Copiar enlace",
    },
    "Share link": {
        pt: "Compartilhar link",
        ja: "リンクの共有",
        es: "Compartir enlace",
    },
    "Edit heat": {
        pt: "Editar bateria",
        ja: "ヒートの編集",
        es: "Editar serie",
    },
    "Toggle edit names mode": {
        pt: "Ligar/desligar edição de atletas",
        ja: "名前の編集モードを切り替える",
        es: "Activar el modo de edición de nombres",
    },
    "Add empty spot": {
        pt: "Adicionar posição na bateria",
        ja: "空白のスポットを追加する",
        es: "Agregar plaza vacía",
    },
    "Delete heat": {
        pt: "Deletear bateria",
        ja: "ヒートの削除",
        es: "Suprimir serie",
    },
    "Edit athletes": {
        pt: "Editar atletas",
        ja: "選手の編集",
        es: "Editar atletas",
    },
    "create_new_athlete:value": {
        en: ({ value }) => `Create new athlete: ${value}`,
        pt: ({ value }) => `Criar novo atleta: ${value}`,
        ja: ({ value }) => `新しい選手の作成: ${value}`,
        es: ({ value }) => `Crear atleta nuevo: ${value}`,
    },
    "AddAthleteForm_team_name": {
        en: ({ value }) => `Create new team: ${value}`,
        pt: ({ value }) => `Criar novo time: ${value}`,
        es: ({ value }) => `Crear equipo nuevo: ${value}`,
        ja: ({ value }) => `新しいチームの作成: ${value}`,
    },
    "Create new team:": {
        pt: "Criar novo time:",
        es: "Crear equipo nuevo:",
        ja: "新しいチームの作成：",
    },
    "EmptySpot_already_in_heat": {
        en: ({ athleteName }) => `${athleteName} is already in the heat`,
        pt: ({ athleteName }) => `${athleteName} já está nesta bateria`,
        es: ({ athleteName }) => `${athleteName} ya participa en la serie`,
        ja: ({ athleteName }) => `${athleteName}はすでにヒートに参加しています`,
    },
    "Enter athlete name...": {
        pt: "Nome do atleta...",
        es: "Ingresar nombre del atleta…",
        ja: "選手名を入力...",
    },
    "Enter team name...": {
        pt: "Nome do time...",
        es: "Ingresar nombre del equipo…",
        ja: "チーム名を入力...",
    },
    "Enter team": {
        pt: "Digite equipe",
        es: "Ingrese equipo",
        ja: "チームを入力",
    },
    "Enter division...": {
        pt: "Categoria...",
        es: "Ingresar categoría…",
        ja: "部門を入力...",
    },
    "Already added": {
        pt: "Já foi adicionado",
        es: "Ya se agregó",
        ja: "すでに追加済み",
    },
    "Create new team": {
        pt: "Criar novo time",
        es: "Crear equipo nuevo",
        ja: "新しいチームの作成",
    },
    "Create new athlete": {
        pt: "Criar novo atleta",
        ja: "新しい選手の作成",
        es: "Crear atleta nuevo",
    },
    "AddEntryWidget_add_athletes": {
        en: ({ value }) => `Add ${value} athlete${plural(value)}`,
        pt: ({ value }) => `Adicionar ${value} atleta${plural(value)}`,
        es: ({ value }) => `Agregar ${value} atleta${plural(value)}`,
        ja: ({ value }) => `${value}選手を追加する`,
    },
    "AddEntryWidget_add_teams": {
        en: ({ value }) => `Add ${value} team${plural(value)}`,
        pt: ({ value }) => `Adicionar ${value} time${plural(value)}`,
        es: ({ value }) => `Agregar ${value} equipo${plural(value)}`,
        ja: ({ value }) => `${value}チームを追加する`,
    },
    "Start typing a profile name to search for existing profiles or create a new one.": {
        pt: "Digite o nome para procurar por um perfil existente ou criar um novo.",
        es: "Comienza a escribir el nombre de un perfil para buscar perfiles en el sistema o crear uno nuevo.",
        ja: "プロフィール名を入力して、既存のプロフィールを検索するか、新しいプロフィールを作成します。",
    },
    "EditHeatPage_title": {
        en: ({ heat }) => <span>Edit {heatHeaderTitle({ heat })}</span>,
        pt: ({ heat }) => <span>Editar {heatHeaderTitle({ heat })}</span>,
        es: ({ heat }) => <span>Editar {heatHeaderTitle({ heat })}</span>,
        ja: ({ heat }) => <span>Edit {heatHeaderTitle({ heat })}</span>,
    },
    "HeadJudgePage_title": {
        en: ({ heat }) => <span>Head judge / {heatHeaderTitle({ heat })}</span>,
        pt: ({ heat }) => <span>Árbitro principal / {heatHeaderTitle({ heat })}</span>,
        es: ({ heat }) => <span>Juez principal/{heatHeaderTitle({ heat })}</span>,
        ja: ({ heat }) => <span>ヒートジャッジ / {heatHeaderTitle({ heat })}</span>,
    },
    "HeatViewPage_title": {
        en: ({ heat }) => <span>Heat view / {heatHeaderTitle({ heat })}</span>,
        pt: ({ heat }) => <span>Bateria / {heatHeaderTitle({ heat })}</span>,
        es: ({ heat }) => <span>Vista de la serie/{heatHeaderTitle({ heat })}</span>,
        ja: ({ heat }) => <span>ヒートビュー / {heatHeaderTitle({ heat })}</span>,
    },
    "Print": {
        pt: "Imprimir",
        es: "Imprimir",
        ja: "印刷",
    },
    "Remove this athlete from the heat": {
        pt: "Remover atleta da bateria",
        ja: "この選手をヒートから取り除く",
        es: "Suprimir atleta de la serie",
    },
    "All the scores for this athlete will be permanently removed.": {
        pt: "Todas suas notas serão permanentemente removidas.",
        ja: "この選手のすべてのスコアは永久に削除されます。",
        es: "Se eliminarán permanentemente todos los puntajes de este atleta.",
    },
    "Yes, remove": {
        pt: "Sim, remover",
        ja: "はい、削除します",
        es: "Sí, eliminar",
    },
    "Edit Schedule": {
        pt: "Editar Agenda",
        ja: "スケジュールの編集",
        es: "Editar cronograma",
    },
    "Add Break": {
        pt: "Adicionar Pausa",
        ja: "休暇日を追加",
        es: "Agregar pausa",
    },
    "Edit Break": {
        pt: "Editar Pausa",
        ja: "休暇日の編集",
        es: "Editar pausa",
    },
    "Date & restart time:": {
        pt: "Data e hora de recomeçar:",
        ja: "日付＆再スタート時刻:",
        es: "Fecha y hora de reinicio:",
    },
    "heat_short_title": {
        en: ({ number }) => ` : Ht ${number}`,
        pt: ({ number }) => ` : Bt ${number}`,
        ja: ({ number }) => ` : ヒート ${number}`,
        es: ({ number }) => ` : Serie ${number}`,
    },
    "run_short_title": {
        en: ({ number }) => ` : Run ${number}`,
        pt: ({ number }) => ` : Vt ${number}`,
        ja: ({ number }) => ` : ラン${number}`,
        es: ({ number }) => ` : Tanda ${number}`,
    },
    "Main bank": {
        pt: "Bancada principal",
        ja: "メインバンク",
        es: "Podio principal",
    },
    "Secondary bank": {
        pt: "Bancada secundária",
        ja: "第2バンク",
        es: "Podio secundario",
    },
    "Edit break": {
        pt: "Editar pausa",
        ja: "休暇日の編集",
        es: "Editar pausa",
    },
    "Round": {
        ja: "ラウンド",
        es: "Ronda",
    },
    " : Ht": {
        pt: " : Bt",
        es: " : Serie",
    },
    "Delete": {
        pt: "Deletar",
        ja: "削除",
        es: "Eliminar",
    },
    "Add": {
        pt: "Adicionar",
        ja: "追加",
        es: "Agregar",
    },
    "Update": {
        pt: "Atualizar",
        ja: "更新",
        es: "Actualizar",
    },
    "Event registration": {
        pt: "Inscrições ao evento",
        ja: "イベント登録",
        es: "Inscripción en el evento",
    },
    "You're a little late! Registration has been closed by the organiser.": {
        pt: "Você está um pouco atrasado! As inscrições foram encerrados pelos organizadores.",
        ja: "ちょっと遅かったようです！登録は運営者によって締め切られました。",
        es: "¡Llegas tarde! El organizador cerró la inscripción.",
    },
    "You're a little early! Registration is not open yet - check back soon.": {
        pt: "Você está um pouco na frente! As inscrições para este evento ainda não estão abertas - volte para ver em breve.",
        ja: "早すぎたようです！登録はまだ始まっていません。また後程お試しください。",
        es: "¡Es muy temprano! La inscripción aún no abrió. Vuelve pronto.",
    },
    "You're a little late! Membership registration has been closed by the organiser.": {
        pt: "Você está um pouco atrasado! A associção foi encerrada pelos organizadores.",
        ja: "ちょっと遅かったようです！会員登録は運営者によって締め切られました。",
        es: "¡Llegas tarde! El organizador cerró la inscripción en la membresía.",
    },
    "registration_unavailable": {
        en: ({ organisation }) => <>Heads up, registration is temporarily unavailable as the {organisation.name} payment facility is offline pending verification. <MuiLink href={`mailto:${organisation.contactEmail}?subject=Registration%20offline%20pending%20verification&body=I%20just%20tried%20to%20register%20and%20it%20says%20that%20registration%20is%20temporarily%20offline%20pending%20further%20verification%20of%20the%20organisers%20payments%20account%20on%20Liveheats.%20Here's%20how%20to%20fix%3A%20https%3A%2F%2Fsupport.liveheats.com%2Fhc%2Fen-us%2Farticles%2F14174976410260`}>Let them know.</MuiLink></>,
        pt: ({ organisation }) => <>Atenção, inscrições estão temporariamente indisponíveis porque os pagamentos para {organisation.name} necessitam de verificações adicionais. <MuiLink href={`mailto:${organisation.contactEmail}?subject=Registration%20offline%20pending%20verification&body=I%20just%20tried%20to%20register%20and%20it%20says%20that%20registration%20is%20temporarily%20offline%20pending%20further%20verification%20of%20the%20organisers%20payments%20account%20on%20Liveheats.%20Here's%20how%20to%20fix%3A%20https%3A%2F%2Fsupport.liveheats.com%2Fhc%2Fen-us%2Farticles%2F14174976410260`}>Informe os organizadores.</MuiLink></>,
        es: ({ organisation }) => <>¡Atención! La inscripción se desactivó temporalmente porque el servicio de pago de {organisation.name} está sin conexión y pendiente de verificación. <MuiLink href={`mailto:${organisation.contactEmail}?subject=Registration%20offline%20pending%20verification&body=I%20just%20tried%20to%20register%20and%20it%20says%20that%20registration%20is%20temporarily%20offline%20pending%20further%20verification%20of%20the%20organisers%20payments%20account%20on%20Liveheats.%20Here's%20how%20to%20fix%3A%20https%3A%2F%2Fsupport.liveheats.com%2Fhc%2Fen-us%2Farticles%2F14174976410260`}>Infórmales.</MuiLink></>,
        ja: ({ organisation }) => <>注意！{organisation.name}の支払い機能がオフラインで検証保留中のため、登録が一時的に利用できません。<MuiLink href={`mailto:${organisation.contactEmail}?subject=Registration%20offline%20pending%20verification&body=I%20just%20tried%20to%20register%20and%20it%20says%20that%20registration%20is%20temporarily%20offline%20pending%20further%20verification%20of%20the%20organisers%20payments%20account%20on%20Liveheats.%20Here's%20how%20to%20fix%3A%20https%3A%2F%2Fsupport.liveheats.com%2Fhc%2Fen-us%2Farticles%2F14174976410260`}>皆さんに知らせてください。</MuiLink></>,
    },
    "registered_for_x_divisions": {
        en: ({ x }) => `Registered for ${x} division${plural(x)}`,
        pt: ({ x }) => `Inscrito em ${x} categoria${plural(x)}`,
        es: ({ x }) => `Se inscribió en ${x} categoría${plural(x)}`,
        ja: ({ x }) => `${x}部門に登録済み`,
    },
    "Already a member": {
        pt: "Já é associado",
        es: "Ya es miembro",
        ja: "すでに会員です",
    },
    "membership_is_expired": {
        en: ({ expiryDate }) => `Membership expired ${expiryDate}`,
        pt: ({ expiryDate }) => `Associação venceu no dia ${expiryDate}`,
        es: ({ expiryDate }) => `La membresía venció el ${expiryDate}`,
        ja: ({ expiryDate }) => `${japaneseFormatDate(expiryDate)}に会員の期限が切れました`,
    },
    "membership_expires": {
        en: ({ expiryDate }) => `Membership expires ${expiryDate}`,
        pt: ({ expiryDate }) => `Associação vence dia ${expiryDate}`,
        es: ({ expiryDate }) => `La membresía vence el ${expiryDate}`,
        ja: ({ expiryDate }) => `${japaneseFormatDate(expiryDate)}に会員の期限が切れます`,
    },
    "already_registered_to_x": {
        en: ({ x }) => <>This athlete has existing entries in {`${x} division${plural(x)}`}. Depending on the event rules, you can enter more divisions below. To view entry status or cancel entries visit the <Link to="/user/athletes" target="_blank" rel="noreferrer">athlete dashboard</Link><OpenInNew fontSize="inherit"/>.</>,
        pt: ({ x }) => <>Este atleta possui inscrições em {`${x} categoria${plural(x)}`}. Dependendo das regras do evento, você pode adicionar mais categorias abaixo. Para cancelar ou ver o status da inscrição, visite o <Link to="/user/athletes" target="_blank" rel="noreferrer">painel do atleta</Link><OpenInNew fontSize="inherit"/>.</>,
        es: ({ x }) => <>Este atleta está inscrito actualmente en {`${x} categoría${plural(x)}`}. Según las reglas del evento, puedes agregar más categorías a continuación. Para consultar el estado de las inscripciones o cancelarlas, ingresa en el <Link to="/user/athletes" target="_blank" rel="noreferrer">panel de atleta</Link><OpenInNew fontSize="inherit"/>.</>,
        ja: ({ x }) => <>この選手は{`${x}`}つのディビジョンに既存のエントリーがあります。イベントルールによって、以下のディビジョンに参加できます。エントリーステータスを確認したり、エントリーをキャンセルしたりするには、<Link to="/user/athletes" target="_blank" rel="noreferrer">選手のダッシュボード</Link>を開いてください<OpenInNew fontSize="inherit"/>。</>,
    },
    "already_a_member": {
        en: ({ contactEmail }) => <>This athlete already has an active membership. Depending on the membership rules, you can add more divisions or options to their membership below. To request a change to existing divisions or options, please <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">contact the organiser</a><OpenInNew fontSize="inherit"/>.</>,
        pt: ({ contactEmail }) => <>Este atleta já possiu uma associação em dia. Dependendo das regras da associação, você pode adicionar mais categorias ou opções abaixo. Para pedir uma alteração à associação entre em contato com o <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">organizador</a><OpenInNew fontSize="inherit"/>.</>,
        es: ({ contactEmail }) => <>Este atleta ya tiene una membresía activa. Según las reglas de la membresía, puedes agregar más categorías u opciones a su membresía a continuación. Para solicitar un cambio en las categorías u opciones actuales, <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">comunícate con el organizador</a><OpenInNew fontSize="inherit"/>.</>,
        ja: ({ contactEmail }) => <>この選手はすでにアクティブな会員権を持っています。会員ルールによっては、以下で他の部門またはオプションを会員権に追加できます。既存の部門またはオプションの変更をリクエストするには、<a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">主催者にお問い合わせください</a><OpenInNew fontSize="inherit"/>。</>,
    },
    "membership_will_expire_at": {
        en: ({ expiryDate, length, unit }) => <>This athlete has an active membership that expires on {expiryDate}. To renew, just add divisions or options below. The new membership will expire {length} <T>{`${unit}${plural(length)}`}</T> from your current expiry date.</>,
        pt: ({ expiryDate, length, unit }) => <>Este atleta possui uma associação que vai expirar no dia {expiryDate}. Para renovar, adicione categorias ou opções abaixo. A nova associação vencerá {length} <T>{`${unit}${plural(length)}`}</T> depois da data de vencimento atual.</>,
        es: ({ expiryDate, length, unit }) => <>Este atleta tiene una membresía activa que vence el {expiryDate}. Para renovarla, simplemente agrega categorías u opciones a continuación. La nueva membresía vencerá {length} <T>{`${unit}${plural(length)}`}</T> días después de la fecha de vencimiento actual.</>,
        ja: ({ expiryDate, length, unit }) => <>この選手は、{japaneseFormatDate(expiryDate)}に有効期限が切れるアクティブな会員権を持っています。更新するには、以下で部門またはオプションを追加します。新しい会員権の有効期限は、現在の有効期限から {length} <T>{`${unit}`}</T> 後です。</>,
    },
    "membership_expired_at": {
        en: ({ expiryDate }) => <>This athlete’s membership expired on {expiryDate}. To renew, just add divisions or options below.</>,
        pt: ({ expiryDate }) => <>A associação deste atleta venceu no dia {expiryDate}. Para renovar, adicione categorias ou opções abaixo.</>,
        es: ({ expiryDate }) => <>La membresía de este atleta venció el {expiryDate}. Para renovarla, simplemente agrega categorías u opciones a continuación.</>,
        ja: ({ expiryDate }) => <>この選手の会員権は{japaneseFormatDate(expiryDate)}に有効期限が切れました。更新するには、以下で部門またはオプションを追加してください。</>,
    },
    "exclusive_registration_notification": {
        en: ({ links, contactEmail }) => <>Competing in this event requires <Typography variant="label1">{links.length}</Typography> active membership{plural(links.length)}: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Purchase membership</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Purchase these memberships to create your athlete profile and enter the event.</Typography><br /><Typography>Still need help? Double check you're using the correct login details, and if you're still having trouble, contact your organiser: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        pt: ({ links, contactEmail }) => <>Competir neste evento requer <Typography variant="label1">{links.length}</Typography> associação{plural(links.length)} ativa: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Comprar associação</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Compre essas associações para criar seu perfil de atleta e entrar no evento.</Typography><br /><Typography>Ainda precisa de ajuda? Verifique se está usando os detalhes de login corretos e, se ainda tiver problemas, entre em contato com o organizador: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        es: ({ links, contactEmail }) => <>Competir en este evento requiere <Typography variant="label1">{links.length}</Typography> membresía{plural(links.length)} activa: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Comprar membresía</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Compra estas membresías para crear tu perfil de atleta e inscribirte en el evento.</Typography><br /><Typography>¿Todavía necesitas ayuda? Asegúrate de que estás utilizando los detalles de inicio de sesión correctos y, si sigues teniendo problemas, contacta a tu organizador: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        ja: ({ links, contactEmail }) => <>このイベントへの参加には、<Typography variant="label1">{links.length}</Typography>つのアクティブな会員権が必要です：<br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">会員権を購入する</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>これらの会員権を購入して、選手プロフィールを作成し、イベントに参加してください。</Typography><br /><Typography>まだお困りですか？正しいログイン情報を使用しているかどうかを再確認し、問題が解決しない場合は、主催者に連絡してください：<MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
    },
    "exclusive_registration_notification_dialog": {
        en: ({ links, contactEmail }) => <>Competing in this event requires <Typography variant="label1">{links.length}</Typography> active membership{plural(links.length)}: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Purchase membership</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Check below to see whether you have the required memberships.</Typography><br /><Typography>Still need help? Double check you're using the correct login details, and if you're still having trouble, contact your organiser: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        pt: ({ links, contactEmail }) => <>Competir neste evento requer <Typography variant="label1">{links.length}</Typography> associação{plural(links.length)} ativa: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Comprar associação</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Verifique abaixo se você possui as associações necessárias.</Typography><br /><Typography>Ainda precisa de ajuda? Verifique se está usando os detalhes de login corretos e, se ainda tiver problemas, entre em contato com o organizador: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        es: ({ links, contactEmail }) => <>Competir en este evento requiere <Typography variant="label1">{links.length}</Typography> membresía{plural(links.length)} activa: <br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">Comprar membresía</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>Verifica a continuación si tienes las membresías necesarias.</Typography><br /><Typography>¿Todavía necesitas ayuda? Asegúrate de que estás utilizando los detalles de inicio de sesión correctos y, si sigues teniendo problemas, contacta a tu organizador: <MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
        ja: ({ links, contactEmail }) => <>このイベントへの参加には、<Typography variant="label1">{links.length}</Typography>つのアクティブな会員権が必要です：<br /><br />{links.map((link, i) => <Stack key={i}><Typography variant="label1">{link.name}</Typography><Stack direction="row" alignItems="center"><Link to={link.link} target="_blank" rel="noreferrer">会員権を購入する</Link><ArrowOutward fontSize="inherit" /></Stack><br /></Stack>)}<Typography>以下を確認して、必要な会員権を持っているかどうかを確認してください。</Typography><br /><Typography>まだお困りですか？正しいログイン情報を使用しているかどうかを再確認し、問題が解決しない場合は、主催者に連絡してください：<MuiLink underline="always" href={`mailto:${contactEmail}`}>{contactEmail}</MuiLink></Typography></>,
    },
    "Looking to enter a different athlete? This event requires membership to enter, so you'll need to purchase those first.": {
        pt: "Quer inscrever um atleta diferente? Este evento requer uma associação para inscrição, então você precisará comprá-las primeiro.",
        es: "¿Quieres inscribir a un atleta diferente? Este evento requiere membresía para inscribirse, por lo que primero deberás comprarlas.",
        ja: "別の選手をエントリーしたいですか？このイベントには会員登録が必要なので、まずそれを購入する必要があります。",
    },
    "One-off membership used": {
        pt: "Associação única vez",
        es: "Membresía única vez",
        ja: "ワンオフ会員権を使用しました",
    },
    "select_your_home_federated_term": {
        en: ({ federatedOrganisationTerm }) => `Select your home ${federatedOrganisationTerm || "organisation"}`,
        pt: ({ federatedOrganisationTerm }) => `Selecione sua ${federatedOrganisationTerm || "organização"}`,
        es: ({ federatedOrganisationTerm }) => `Selecciona la ${federatedOrganisationTerm || "organización"} local`,
        ja: ({ federatedOrganisationTerm }) => `ホーム${federatedOrganisationTerm || "団体"}を選択してください`,
    },
    "Log in to Liveheats": {
        pt: "Faça o login no Liveheats",
        ja: "ライブヒートにログイン",
        es: "Iniciar sesión en Liveheats",
    },
    "Create Liveheats account": {
        pt: "Crie uma conta Liveheats",
        ja: "ライブヒートアカウントを作成する",
        es: "Crear cuenta de Liveheats",
    },
    "Our apologies. There's been a slight problem reaching our secure payment gateway. Please reload": {
        pt: "Houve um problema carregando o nosso sistem de pagamentos seguros. Por favor recarrague a página.",
        ja: "申し訳ありません。セキュアペイメントゲートウェイへの接続に問題が生じています。ページを再読み込みしてください",
        es: "Lo sentimos. Hubo un pequeño problema de conexión con la plataforma de pago segura. Vuelve a cargar la página.",
    },
    "Reload": {
        pt: "Recarragar",
        ja: "再読み込み",
        es: "Volver a cargar",
    },
    "Add athlete": {
        pt: "Adicionar atleta",
        ja: "選手追加",
        es: "Agregar atleta",
    },
    "Add team": {
        pt: "Adicionar time",
        ja: "チームを追加",
        es: "Agregar equipo",
    },
    "Add athletes": {
        pt: "Adicionar atletas",
        es: "Agregar atletas",
        ja: "選手を追加",
    },
    "Add teams": {
        pt: "Adicionar times",
        es: "Agregar equipos",
        ja: "チームを追加",
    },
    "Done": {
        pt: "Pronto",
        ja: "完了",
        es: "Listo",
    },
    "confirm": {
        pt: "confirmar",
        ja: "確認",
        es: "confirmar",
    },
    "remove": {
        pt: "remover",
        ja: "削除",
        es: "eliminar",
    },
    "refund_price": {
        en: ({ amount, currency }) => `Refund entry (${formatPrice(amount, currency)}, minus transaction fees)`,
        pt: ({ amount, currency }) => `Reembolsar inscrição (${formatPrice(amount, currency)}, menos taxas)`,
        es: ({ amount, currency }) => `Reembolsar inscripción de (${formatPrice(amount, currency)}, menos los gastos de transacción)`,
        ja: ({ amount, currency }) => `エントリー料の払い戻し (${formatPrice(amount, currency)} から取引手数料を引く)`,
    },
    "Remove": {
        pt: "Remover",
        ja: "削除",
        es: "Eliminar",
    },
    "Nationality": {
        pt: "Nacionalidade",
        ja: "国籍",
        es: "Nacionalidad",
    },
    "Select a nationality...": {
        pt: "Selecione uma nacionalidade...",
        es: "Selecciona una nacionalidad...",
        ja: "国籍を選択...",
    },
    "Athlete name": {
        pt: "Nome do atleta",
        es: "Nombre del atleta",
        ja: "選手名",
    },
    "Date of birth": {
        pt: "Data de nascimento",
        ja: "生年月日",
        es: "Fecha de nacimiento",
    },
    "Contact phone number": {
        pt: "Número para contato",
        ja: "連絡先電話番号",
        es: "Número de teléfono de contacto",
    },
    "We'll need your phone number for emergencies.": {
        pt: "Precisamos do seu número de telefone em caso de emergências.",
        es: "Necesitaremos tu número de teléfono para casos de emergencia.",
        ja: "緊急時のために電話番号が必要です。",
    },
    "Entry date": {
        pt: "Data da inscrição",
        ja: "エントリー日",
        es: "Fecha de la inscripción",
    },
    "Status": {
        ja: "ステータス",
        es: "Estado",
    },
    "Confirm": {
        pt: "Confirmar",
        ja: "確認",
        es: "Confirmar",
    },
    "Confirmed": {
        pt: "Confirmado",
        ja: "確定",
        es: "Confirmada",
    },
    "Awaiting payment": {
        pt: "Aguardando pagamento",
        es: "Esperando el pago",
        ja: "支払い待ち",
    },
    "Unpaid": {
        pt: "Não pago",
        es: "No pagado",
        ja: "未払い",
    },
    "Confirm as paid": {
        pt: "Confirmar como pago",
        es: "Confirmar como pagado",
        ja: "支払済みとして確定",
    },
    "Mark as paid": {
        pt: "Marcar como pago",
        es: "Marcar como pagado",
        ja: "支払い済みとしてマーク",
    },
    "Confirm and request payment": {
        pt: "Confirmar e solicitar pagamento",
        es: "Confirmar y solicitar pago",
        ja: "確認と支払いのリクエスト",
    },
    "athlete_payment_failed": {
        en: ({ name }) => `Payment failed. ${name} has been notified to complete payment in order to confirm entry.`,
        pt: ({ name }) => `Pagamento falhou. ${name} foi notificado para completar o pagamento para confirmar a inscrição.`,
        es: ({ name }) => `Pago fallido. ${name} ha sido notificado para completar el pago para confirmar la inscripción.`,
        ja: ({ name }) => `支払いに失敗しました。エントリーを確定するために支払いを完了するよう${name}に通知されました。`,
    },
    "Seed": {
        pt: "Ranking",
        ja: "シード",
        es: "Cabeza de serie",
    },
    "Position": {
        pt: "Posição",
        es: "Posición",
        ja: "ポジション",
    },
    "Bib": {
        es: "Pechera",
        ja: "ビブス",
    },
    "Pos.": {
        es: "Pos.",
        ja: "ポジション",
    },
    "Close": {
        pt: "Fechar",
        ja: "締め切る",
        es: "Cerrar",
    },
    "Aren't you nice! Athlete added to the seed list.": {
        pt: "Atleta adicionado à lista.",
        ja: "素晴らしい！選手がシードリストに追加されました。",
        es: "¡Qué bien! Se agregó el atleta a la lista de cabezas de serie.",
    },
    "Aren't you nice! Team added to the seed list.": {
        pt: "Time adicionado à lista.",
        ja: "素晴らしい！チームがシードリストに追加されました。",
        es: "¡Qué bien! Se agregó el equipo a la lista de cabezas de serie.",
    },
    "Available spots:": {
        pt: "Vagas para inscrições:",
        es: "Plazas disponibles:",
        ja: "利用可能なスポット：",
    },
    "Heads up, this division has exceeded its limit. You might like to remove some entries from the list or edit the limit.": {
        pt: "Atenção, o número de atletas excedeu o limite de inscrições. Talvez você queira remover algumas inscrições da lista, ou modificar o limite.",
        es: "¡Atención! Esta categoría superó su límite. Te recomendamos eliminar algunas inscripciones de la lista o editar el límite.",
        ja: "注意！この部門は定員を超えました。リストからエントリーをいくつか削除するか、定員を編集してください。",
    },
    "Heads up, this division has reached its limit and registration has been closed.": {
        pt: "Atenção, está categoria atingiu o limite e as inscrições foram fechadas.",
        es: "¡Atención! Esta categoría alcanzó su límite y la inscripción se cerró.",
        ja: "注意！この部門は定員に達したため、登録受付を締め切りました。",
    },
    "no_competitors_in_seedlist": {
        en: "There are no competitors in this division yet. They'll appear here once registered or added by the director.",
        pt: "Ainda não tem atletas nesta categoria. Os atletas aparecerão aqui depois que se inscreverem ou forem adicionados pelos organizadores.",
        es: "Todavía no hay nadie compitiendo en esta categoría. Aparecerán aquí una vez que se inscriban o el director los agregue.",
        ja: "この部門にまだ選手はいません。選手が登録するか、ディレクターが追加すると、ここに表示されます。",
    },
    "no_athletes_in_seedlist": {
        en: <>There are no athletes in this division yet. Athletes will appear here once they register. Alternatively you can add athletes, or import athletes <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">using our template</a>.</>,
        pt: <>Ainda não tem atletas nesta categoria. Os atletas aparecerão aqui assim que se inscreverem, ou então você pode adicioná-los ou importar os atletas <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">utilizando nosso formato</a>.</>,
        ja: <>この部門にはまだ選手がいません。選手は参加登録が完了するとここに表示されます。また選手を手動で追加や<a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">テンプレートを使用</a>して選手をインポートすることもできます。</>,
        es: <>Todavía no hay atletas en esta categoría. Aparecerán aquí una vez que se inscriban. O bien, puedes agregarlos o importarlos <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">usando nuestra plantilla</a>.</>,
    },
    "no_teams_in_seedlist": {
        en: <>There are no teams in this division yet. You can add manually or import teams <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">using our template</a>.</>,
        pt: <>Ainda não tem times nesta categoria. Os times aparecerão aqui assim que se inscreverem, ou então você pode adicioná-los ou importar os times <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">utilizando nosso formato</a>.</>,
        ja: <>この部門にはまだチームが追加されていません。手動で追加するか <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">テンプレートを使用して</a>インポートすることができます。</>,
        es: <>Todavía no hay equipos en esta categoría. Puedes agregar equipos de forma manual o importarlos <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">usando nuestra plantilla</a>.</>,
    },
    "Import athletes": {
        pt: "Importar atletas",
        ja: "選手をインポート",
        es: "Importar atletas",
    },
    "Import teams": {
        pt: "Importar times",
        ja: "チームのインポート",
        es: "Importar equipos",
    },
    "Excellent! Athletes successfully added to this division.": {
        pt: "Excelente! Atletas importados para a lista.",
        ja: "素晴らしい！選手はこの部門に正しく追加されました。",
        es: "¡Excelente! Los atletas se agregaron correctamente a esta categoría.",
    },
    "Excellent! Teams successfully added to this division.": {
        pt: "Excelente! Times importados para a lista.",
        ja: "素晴らしい！チームがこの部門に正しく追加されました。",
        es: "¡Excelente! Los equipos se agregaron correctamente a esta categoría.",
    },
    "Epic! You've successfully imported athletes to your event. You'll find them on the seed list.": {
        pt: "Perfeito! Os atletas foram adicionados ao evento. Você encontra eles na list de athletes de cada categoria.",
        ja: "素晴らしい！イベントに選手が正しくインポートされました。シードリストからご確認ください。",
        es: "¡Espectacular! Importaste los atletas al evento correctamente. Podrás verlos en la lista de cabezas de serie.",
    },
    "invalid_entries_file_data": {
        en: <>Bummer, we could not import that file. Make sure you are <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">using our template</a>.</>,
        pt: <>Treta, não deu pra importar o seu arquivo. Verifique que você está usando <a href="https://support.liveheats.com/hc/en-us/articles/360029356271">o nosso formato</a>.</>,
        ja: <>ファイルをインポートすることができませんでした。正しい<a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">テンプレートを使用している</a>ことをお確かめください。</>,
        es: <>Lo sentimos. No pudimos importar el archivo. Asegúrate de estar <a href="https://support.liveheats.com/hc/en-us/articles/360029356271" target="_blank" rel="noopener noreferrer">usando nuestra plantilla</a>.</>,
    },
    "invalid_entries_to_event_file_data": {
        en: <>Bummer, we could not import that file. Make sure you are <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">using our template</a>.</>,
        pt: <>Treta, não deu pra importar o seu arquivo. Verifique que você está usando <a href="https://support.liveheats.com/hc/en-us/articles/360036472211">o nosso formato</a>.</>,
        ja: <>おっと。このファイルはインポートできません。正しい <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">テンプレートを使用していることを</a>お試しください。</>,
        es: <>Lo sentimos. No pudimos importar el archivo. Asegúrate de estar <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">usando nuestra plantilla</a>.</>,
    },
    "invalid_entries_to_event_file_data_bad_division": {
        en: <>Uh oh. It looks like that file includes a division that isn't in this event. Please check your file and try again. <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">See more</a>.</>,
        pt: <>Ops. Seu arquivo possui uma categoria que não está neste evento. Por favor verifique o arquivo e tente novamente. <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">Veja main</a>.</>,
        ja: <>おっと。ファイルにこのイベントにない部門が含まれているようです。ファイルをお確かめの上再度お試しください。<a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">もっと詳しく</a>。</>,
        es: <>¡Uy! Al parecer, el archivo incluye una categoría que no existe en este evento. Revisa el archivo y vuelve a intentarlo. <a href="https://support.liveheats.com/hc/en-us/articles/360036472211" target="_blank" rel="noopener noreferrer">Más información</a>.</>,
    },
    "Add to the existing table?": {
        pt: "Adicionar à lista de atletas?",
        ja: "既存のテーブルに追加しますか?",
        es: "¿Agregar a la tabla existente?",
    },
    "Add to the existing athletes?": {
        pt: "Adicionar à lista de atletas?",
        ja: "既存選手に追加しますか？",
        es: "¿Agregar a los atletas existentes?",
    },
    "import_dialog_description": {
        en: <>Overwrite will <strong>DELETE ALL</strong> existing athletes in this division and replace them with the athletes in the file.</>,
        pt: <>Sobrescrever irá <strong>APAGAR TODOS</strong> os atletas nesta categoria e substituí-los pelos atletas no arquivo.</>,
        ja: <>上書きはこの部門の<strong>全ての選手を削除</strong>し、ファイルに記載された選手と入れ替えます。</>,
        es: <>Si sobrescribes, se <strong>ELIMINARÁN TODOS</strong> los atletas actuales de esta categoría y se reemplazarán por los atletas del archivo.</>,
    },
    "No, overwrite existing": {
        pt: "Não, sobrescrever atletas",
        ja: "いいえ、上書きします",
        es: "No, sobrescribir",
    },
    "Yes, add to existing": {
        pt: "Sim, adicionar à lista",
        ja: "はい、既存に追加します",
        es: "Sí, agregar",
    },
    "Selected file:": {
        pt: "Arquivo selecionado:",
        ja: "選択されたファイル：",
        es: "Archivo seleccionado:",
    },
    "Athlete details": {
        pt: "Dados do atleta",
        ja: "選手情報",
        es: "Detalles del atleta",
    },
    "Team details": {
        pt: "Dados do time",
        ja: "チームの詳細",
        es: "Detalles del equipo",
    },
    "athlete_x": {
        en: ({ x }) => `Athlete ${x}`,
        pt: ({ x }) => `Atleta ${x}`,
        es: ({ x }) => `Atleta ${x}`,
        ja: ({ x }) => `選手${x}`,
    },
    "Registration details": {
        pt: "Dados da inscrição",
        ja: "登録詳細",
        es: "Detalles de la inscripción",
    },
    "View transaction in Stripe": {
        pt: "Ver transação no Stripe",
        es: "Ver transacción en Stripe",
        ja: "Stripeでトランザクションを表示",
    },
    "Refunds": {
        pt: "Reembolsos",
        ja: "返金",
        es: "Reembolsos",
    },
    "waitlisted": {
        pt: "lista de espera",
        ja: "キャンセル待ち",
        es: "en lista de espera",
    },
    "confirmed": {
        pt: "confirmado",
        ja: "確定",
        es: "confirmada",
    },
    "denied": {
        pt: "negado",
        ja: "却下",
        es: "rechazada",
    },
    "cancelled": {
        pt: "cancelado",
        es: "cancelada",
        ja: "キャンセル済み",
    },
    "Remove athlete": {
        pt: "Remover atleta",
        ja: "選手を削除",
        es: "Eliminar atleta",
    },
    "Cancel entry": {
        pt: "Cancelar inscrição",
        es: "Cancelar inscripción",
        ja: "エントリーをキャンセルする",
    },
    "Cancel entry?": {
        pt: "Cancelar inscrição?",
        es: "¿Deseas cancelar la inscripción?",
        ja: "エントリーをキャンセルしますか？",
    },
    "cancel_entry_message": {
        en: ({ name, orgName }) => <><Typography>Remove {name} from the division, and notify the organiser.</Typography><br /><Typography>Any cancellations are subject to {orgName}'s cancellation policy.</Typography></>,
        pt: ({ name, orgName }) => <><Typography>Remover {name} da categoria e notificar o organizador.</Typography><br /><Typography>Todos os cancelamentos estão sujeitos as regras de cancelamento de {orgName}.</Typography></>,
        es: ({ name, orgName }) => <><Typography>Elimina a {name} de la categoría y avisa al organizador.</Typography><br /><Typography>Las cancelaciones están sujetas a la política de cancelaciones de {orgName}.</Typography></>,
        ja: ({ name, orgName }) => <><Typography>部門から{name}を削除し、主催者に知らせてください。</Typography><br /><Typography>いかなるキャンセルも {orgName} のキャンセルポリシーの対象となります。</Typography></>,
    },
    "Add seeds to later rounds:": {
        pt: "Posicionar atletas em rounds futuros:",
        ja: "後のラウンドにシードを追加します：",
        es: "Agregar cabezas de serie a rondas posteriores:",
    },
    "Add seeds to another round": {
        pt: "Posicionar atletas em outro round",
        ja: "別のラウンドにシードを追加する",
        es: "Agregar cabezas de serie a otra ronda",
    },
    "Advanced": {
        pt: "Avançado",
        ja: "詳細",
        es: "Opciones avanzadas",
    },
    "Save": {
        pt: "Salvar",
        ja: "保存",
        es: "Guardar",
    },
    "Default heat size:": {
        pt: "Número de atletas na bateria:",
        ja: "デフォルトのヒートサイズ:",
        es: "Tamaño predeterminado de la serie:",
    },
    "Number of rounds:": {
        pt: "Número de rounds:",
        ja: "ラウンド数:",
        es: "Cantidad de rondas:",
    },
    "Default progression between rounds:": {
        pt: "Progressão padrão entre rounds:",
        ja: "ラウンド間のデフォルト進行:",
        es: "Progresión predeterminada entre rondas:",
    },
    "Default progression between runs in a round:": {
        pt: "Progressão padrão entre voltas num round:",
        ja: "ラウンド内のラン間のデフォルト進行状況:",
        es: "Progresión predeterminada entre tandas en una ronda:",
    },
    "Athletes per team:": {
        pt: "Número de atletas por time:",
        ja: "1チームあたりの選手数：",
        es: "Atletas por equipo:",
    },
    "Counting rides:": {
        pt: "Número de notas que somam ao total:",
        ja: "カウントされたライド数:",
        es: "Vueltas computadas:",
    },
    "Counting rides per athlete:": {
        pt: "Número de notas por atleta que somam ao total:",
        ja: "選手1名あたりのカウントライド数：",
        es: "Vueltas computadas por atleta:",
    },
    "All rides": {
        pt: "Todas as notas",
        ja: "全てのライド",
        es: "Todas las vueltas",
    },
    "Max rides:": {
        pt: "Número máximo de notas:",
        ja: "最大ライド数:",
        es: "Máx. de vueltas:",
    },
    "Max rides per athlete:": {
        pt: "Número máximo de notas por atleta:",
        ja: "選手1名あたりの最大ライド数：",
        es: "Máx. de vueltas por atleta:",
    },
    "Unlimited": {
        pt: "Ilimitado",
        ja: "無制限",
        es: "Ilimitadas",
    },
    "EditDivisionForm_ridesLabel": {
        en: ({ value }) => `Set to ${value} rides`,
        pt: ({ value }) => `Configurar para ${value} notas`,
        ja: ({ value }) => `${value}ライドに設定`,
        es: ({ value }) => `Establecer en ${value} vueltas`,
    },
    "Seeds:": {
        pt: "Atletas:",
        es: "Cabezas de serie:",
        ja: "シード：",
    },
    "Round:": {
        ja: "ラウンド：",
        es: "Ronda:",
    },
    "maps_to_org_division_help": {
        en: ({ org, series }) => `You've set this event as part of the ${series} series belonging to ${org}. Once published, the results from this division will be included in ${org}'s rankings.`,
        pt: ({ org, series }) => `Você configurou este evento como parte da temporada ${series} que pertence a ${org}. Uma vez publicados, os resultados desta categoria serão incluídos no ranking da ${org}`,
        es: ({ org, series }) => `Configuraste este evento como parte del grupo ${series} de ${org}. Una vez que se publiquen los resultados de esta categoría, se incluirán en las clasificaciones de ${org}.`,
        ja: ({ org, series }) => `あなたは、このイベントを${org}の${series}シリーズの一部として設定しています。公開されると、この部門の結果は${org}のランキングに反映されます。`,
    },
    "No related division": {
        pt: "Categoria não mapeada",
        es: "Sin categorías relacionadas",
        ja: "関連部門なし",
    },
    "Mapped division for rankings:": {
        pt: "Categoria mapeada para ranking:",
        es: "Categoría asignada para las clasificaciones:",
        ja: "ランキングのためにマップされる部門：",
    },
    "Round 1 will be automatically seeded": {
        pt: "O primeiro round vai ser automaticamente rankeado",
        ja: "第1ラウンドは自動的にシードされます。",
        es: "Las cabezas de serie se asignarán automáticamente a la ronda 1",
    },
    "EventDivisionForm_seedsAdded": {
        en: ({ value }) => `Already added seeds for round ${value}`,
        pt: ({ value }) => `Atletas já adicionados ao round ${value}`,
        ja: ({ value }) => `ラウンド${value}にシードを追加しました`,
        es: ({ value }) => `Ya se asignaron las cabezas de serie para la ronda ${value}`,
    },
    "Results are calculated using:": {
        pt: "Resultados são calculatos usando:",
        es: "Los resultados se calculan con:",
        ja: "結果は以下を使用して算出されます。",
    },
    "Average total score (recommended)": {
        pt: "Média das notas (recomendado)",
        es: "Puntaje total promedio (recomendado)",
        ja: "平均合計スコア（推奨）",
    },
    "Individual judge's placing": {
        pt: "Posicionamento por árbitro",
        es: "Posición otorgada por el juez",
        ja: "各ジャッジがつけた順位",
    },
    "Progression determined by athlete's place in:": {
        pt: "Progressão determinada pela colocação do atleta:",
        es: "Progresión determinada por la posición del atleta en:",
        ja: "以下の選手の順位によって進出が決まります。",
    },
    "Heat results": {
        pt: "Na bateria",
        es: "Resultados de la serie",
        ja: "ヒート結果",
    },
    "Round results": {
        pt: "No round",
        es: "Resultados de la ronda",
        ja: "ラウンド結果",
    },
    "Since you're using Round results for progression, you must use the Round settings to set the progression between rounds. You can access the Round settings once you've drawn heats.": {
        pt: "Por ter escolhido colocação no round, só é possível selectionar a progressão entre rounds. Você pode acessar as configurações do round depois que as chaves forem criadas.",
        es: "Como estás usando los resultados de la ronda para calcular la progresión, debes usar la Configuración de la ronda para establecer la progresión entre las mismas. Puedes acceder a la Configuración de la ronda después de sortear las series.",
        ja: "ラウンド結果を進出に使用しているので、ラウンド設定を使用してラウンド間の進出を設定する必要があります。ヒートの抽選が終わった後、ラウンド設定にアクセスできます。",
    },
    "Must be equal to or greater than twice the default progression": {
        pt: "Deve ser igual ou maior que o dobro da progressão padrão",
        es: "Debe ser igual o mayor que el doble de la progresión predeterminada",
        ja: "デフォルトの進行の2倍以上である必要があります",
    },
    "Must be equal to or smaller than half the default heat size": {
        pt: "Deve ser igual ou menor que metade do tamanho da bateria",
        ja: "デフォルトのヒートサイズの半分以下である必要があります",
        es: "Debe ser igual o menor que la mitad del tamaño predeterminado de la serie",
    },
    "Actions": {
        pt: "Ações",
        ja: "アクション",
        es: "Acciones",
    },
    "Add round": {
        pt: "Adicionar round",
        ja: "ラウンドの追加",
        es: "Agregar ronda",
    },
    "Redraw heats": {
        pt: "Recriar chave",
        ja: "ヒート再抽選",
        es: "Volver a sortear series",
    },
    "Draw heats": {
        pt: "Criar chave",
        ja: "ヒート抽選",
        es: "Sortear series",
    },
    "Redraw all divisions": {
        pt: "Recriar todas as chaves",
        es: "Volver a sortear todas las categorías",
        ja: "すべての部門を再抽選する",
    },
    "Draw heats and close entries?": {
        pt: "Criar chaves e fechar inscrições?",
        es: "¿Deseas sortear las series y cerrar las inscripciones?",
        ja: "ヒートの抽選を行って、エントリーを締め切りますか？",
    },
    "Generate draw": {
        pt: "Gerar chaves",
        es: "Generar sorteo",
        ja: "抽選を生成する",
    },
    "This will draw the heats and close entries for this event. It's no biggie if you do, you can always reopen entries and redraw heats later.": {
        pt: "Esta ação irá criar todas as chaves e fechar as incrições pro evento. Não tem problema nenhum, você pode sempre re-abrir as inscrições e recriar as chaves quando quiser.",
        es: "Se sortearán las series y se cerrarán las inscripciones para este evento. No desesperes si esto sucede, siempre puedes volver a abrir las inscripciones y sortear las series más tarde.",
        ja: "これにより、ヒートの抽選が行われ、このイベントのエントリーが締め切られます。締め切っても、いつでもエントリーを再開し、後からヒートを再抽選することができます。",
    },
    "Are you sure you want to redraw?": {
        pt: "Tem certeza que quer recriar a chave?",
        ja: "再抽選してもいいですか？",
        es: "¿Confirmas que deseas volver a sortear?",
    },
    "Changes previously made to rounds that have not started will be lost.": {
        pt: "Configurações feitas em rounds que ainda não começaram serão perdidas.",
        ja: "開始されていないラウンドに対してこれまでに行った変更が失われます。",
        es: "Se perderán los cambios realizados previamente en las rondas que no comenzaron.",
    },
    "Are you sure you want to draw the heats?": {
        pt: "Tem certeza que quer criar a chave?",
        ja: "ヒート抽選してもいいですか？",
        es: "¿Confirmas que deseas sortear las series?",
    },
    "The heat draw will be created but entries will still be open until you close them for the event.": {
        pt: "Inscrições continuarão abertas até que você as feche pro evento.",
        ja: "ヒート抽選は作成されますが、エントリーはイベントを閉じるまで開かれたままとなります。",
        es: "Se creará el sorteo de las series, pero las inscripciones en este evento seguirán abiertas hasta que las cierres.",
    },
    "Reseed the heats": {
        pt: "Reposicionar atletas",
        ja: "ヒートを再シードする",
        es: "Volver a asignar cabezas de serie para las series",
    },
    "Yes, redraw": {
        pt: "Sim, recriar",
        ja: "はい、再抽選します",
        es: "Sí, volver a hacer el sorteo",
    },
    "Yes, draw": {
        pt: "Sim, criar",
        ja: "はい、抽選します",
        es: "Sí, hacer el sorteo",
    },
    "Randomise entries": {
        pt: "Randomizar inscrições",
        es: "Aleatorizar inscripciones",
        ja: "エントリーをランダム化する",
    },
    "Re-randomise entries": {
        pt: "Re-randomizar inscrições",
        es: "Volver a aleatorizar inscripciones",
        ja: "エントリーを再度ランダム化する",
    },
    "Close and randomise entries?": {
        pt: "Fechar e randomizar inscrições?",
        es: "¿Deseas cerrar y aleatorizar las inscripciones?",
        ja: "エントリーを締め切って、ランダム化しますか？",
    },
    "This will re-randomise entries and close entries for this event. Any previous competitor positions will be lost.": {
        pt: "Esta ação irá fechar e re-randomizar as incrições neste evento. As posições anteriores dos competitors serão sobrescritas.",
        es: "Esto volverá a aleatorizar las inscripciones y las cerrará para este evento. Se perderán las posiciones anteriores de los competidores.",
        ja: "これにより、エントリーが再ランダム化され、このイベントのエントリーは締め切られます。競技者の以前のポジションはすべて失われます。",
    },
    "This will randomise entries and close entries for this event. It's no biggie if you do, you can always reopen entries and re-randomise later.": {
        pt: "Esta ação irá randomizar e fechar as inscrições neste evento. Não tem problema nenhum, você pode sempre re-abrir as inscrições e re-randomizar quando quiser.",
        es: "Esto aleatorizará las inscripciones y las cerrará para este evento. No desesperes si esto sucede, siempre puedes volver a abrir las inscripciones y aleatorizarlas más tarde.",
        ja: "これにより、このイベントのエントリーがランダム化され、締め切られます。締め切っても、エントリーはいつでも再開でき、後から再ランダム化できます。",
    },
    "progression": {
        en: ({ progression }) => <>{progression.get("position") + 1}<sup>{ordinal(progression.get("position") + 1)}</sup> from {progression.get("round")}{!progression.get("round_only") &&<> {progression.get("run") ? "Run" : "Heat"} {progression.get("heat") + 1}</>}</>,
        pt: ({ progression }) => <>{progression.get("position") + 1}<sup>o</sup> - {progression.get("round")}{!progression.get("round_only") &&<> {progression.get("run") ? "Volta" : "Bateria"} {progression.get("heat") + 1}</>}</>,
        es: ({ progression }) => <>{progression.get("position") + 1}<sup>o</sup> de {progression.get("round")}{!progression.get("round_only") &&<> {progression.get("run") ? "Tanda" : "Serie"} {progression.get("heat") + 1}</>}</>,
        ja: ({ progression }) => <>{progression.get("position") + 1}<sup>{ordinal(progression.get("position") + 1)}</sup> from {progression.get("round")}{!progression.get("round_only") &&<> {progression.get("run") ? "ラン" : "ヒート"} {progression.get("heat") + 1}</>}</>,
    },
    "progression_obscure": {
        en: ({ progression, isTeams }) => `${isTeams ? "Team" : "Athlete"} from ${progression.get("round")}`,
        pt: ({ progression, isTeams }) => `${isTeams ? "Time" : "Atleta"} de ${progression.get("round")}`,
        es: ({ progression, isTeams }) => `${isTeams ? "Team" : "Athlete"} de ${progression.get("round")}`,
        ja: ({ progression, isTeams }) => `${progression.get("round")}の${isTeams ? "チーム" : "選手"}`,
    },
    "close settings": {
        pt: "fechar configurações",
        ja: "設定を閉じる",
        es: "cerrar la configuración",
    },
    "Add heat": {
        pt: "Adicionar bateria",
        ja: "ヒートの追加",
        es: "Agregar serie",
    },
    "Add run": {
        pt: "Adicionar volta",
        ja: "ランの追加",
        es: "Agregar tanda",
    },
    "Delete round": {
        pt: "Deletar round",
        ja: "ラウンドの削除",
        es: "Eliminar ronda",
    },
    "Round name:": {
        pt: "Nome do round:",
        ja: "ラウンド名:",
        es: "Nombre de la ronda:",
    },
    "Heat size:": {
        pt: "Número de atletas na bateria:",
        ja: "ヒートサイズ:",
        es: "Tamaño de la serie:",
    },
    "Progression to next rounds:": {
        pt: "Progressão para os próximos rounds:",
        ja: "次のラウンドへの進行:",
        es: "Progresión a las próximas rondas:",
    },
    "Progression to runs in this round:": {
        pt: "Progressão para as voltas neste round:",
        ja: "このラウンドのランへの進行：",
        es: "Progresión a las tandas de esta ronda:",
    },
    "2nd chance": {
        pt: "Segunda chance",
        ja: "セカンドチャンス",
        es: "2ª oportunidad",
    },
    "Heat duration:": {
        pt: "Duração das baterias:",
        ja: "ヒート時間：",
        es: "Duración de la serie:",
    },
    "Run duration:": {
        pt: "Duração da volta:",
        ja: "ラン時間:",
        es: "Duración de la tanda:",
    },
    "Athletes seeded into this round:": {
        pt: "Número de atletas que iniciam neste round:",
        ja: "このラウンドでシードされた選手：",
        es: "Atletas clasificados en esta ronda:",
    },
    "Disable auto-progression": {
        pt: "Desabilitar progressão automatica",
        ja: "自動進行を無効にする",
        es: "Desactivar la progresión automática",
    },
    "divisionDefault": {
        en: ({ defaultSize }) => `${defaultSize} (division default)`,
        pt: ({ defaultSize }) => `${defaultSize} (padrão da categoria)`,
        es: ({ defaultSize }) => `${defaultSize} (categoría por defecto)`,
        ja: ({ defaultSize }) => `${defaultSize}(部門デフォルト)`,
    },
    "heatDurationDefault": {
        en: ({ type, defaultDuration }) => `${defaultDuration} minutes (${type} default)`,
        pt: ({ type, defaultDuration }) => `${defaultDuration} minutos (padrão ${type === "event" ? "do evento" : "da categoria"})`,
        es: ({ type, defaultDuration }) => `${defaultDuration} minutos (${type} por defecto)`,
        ja: ({ type, defaultDuration }) => `${defaultDuration}分 (${type}デフォルト)`,
    },
    "No progression: all runs use same start list": {
        pt: "Nenhuma progressão: todas as voltas usam a mesma lista de saída",
        ja: "進行なし: すべてのランで同じ開始リストが使用される",
        es: "Sin progresión: todas las tandas usan la misma lista de participantes",
    },
    "x_progress": {
        en: ({ x }) => `${x} progress`,
        pt: ({ x }) => `${x} avançam`,
        ja: ({ x }) => `${x}進行状況`,
        es: ({ x }) => `${x} de progreso`,
    },
    "This heat draw is looking a bit lonely. You can add teams and draw heats, or start building your draw manually by adding a round": {
        pt: "Esta chave está meio solitária. Você pode adicionar times e criar a chave, ou construir sua chave manualmente adicionando um round",
        es: "Este sorteo se ve un poco solitario. Puede agregar equipos y sortear las series, o comenzar a construir su sorteo manualmente agregando una ronda",
        ja: "このヒートの抽選は少し寂しく見えます。チームを追加してヒートを抽選したり、ラウンドを追加して手動で抽選の作成できます",
    },
    "This heat draw is looking a bit lonely. You can add athletes and draw heats, or start building your draw manually by adding a round": {
        pt: "Esta chave está meio solitária. Você pode adicionar atletas e criar a chave, ou construir sua chave manualmente adicionando um round",
        es: "Este sorteo se ve un poco solitario. Puede agregar atletas y sortear las series, o comenzar a construir su sorteo manualmente agregando una ronda",
        ja: "このヒートドローは少し寂しく見えます。選手を追加してヒートを抽選したり、ラウンドを追加して手動で抽選を作成したりできます",
    },
    "Go to athletes list": {
        pt: "Ir pra lista de atletas",
        es: "Ir a la lista de atletas",
        ja: "選手一覧へ",
    },
    "Go to teams list": {
        pt: "Ir pra lista de times",
        es: "Ir a la lista de equipos",
        ja: "チーム一覧へ",
    },
    "rule_x": {
        en: ({ x }) => `Rule ${x}`,
        pt: ({ x }) => `Regra ${x}`,
        es: ({ x }) => `Regla ${x}`,
        ja: ({ x }) => `ルール${x}`,
    },
    "round_x_settings": {
        en: ({ x }) => `Round ${x} settings`,
        pt: ({ x }) => `Configurar round ${x}`,
        es: ({ x }) => `Configuración de la ronda ${x}`,
        ja: ({ x }) => `ラウンド${x}設定`,
    },
    "contest_settings": {
        en: ({ name }) => `${name} settings`,
        pt: ({ name }) => `Configurar ${name.toLowerCase()}`,
        es: ({ name }) => `Configuración de ${name.toLowerCase()}`,
        ja: ({ name }) => `${name}の設定`,
    },
    "contest_leaderboard": {
        en: ({ name }) => `${name} leaderboard`,
        pt: ({ name }) => `Ranking ${name.toLowerCase()}`,
        es: ({ name }) => `Clasificación de ${name.toLowerCase()}`,
        ja: ({ name }) => `${name}のリーダーボード`,
    },
    "All remaining": {
        pt: "Restante",
        ja: "残りすべて",
        es: "Todas las demás opciones",
    },
    "x_progression_already_set": {
        en: ({ x }) => `Progression for ${x}${ordinal(x)} is already set`,
        pt: ({ x }) => `Progressão pro ${x}˚ lugar já foi configurada`,
        es: ({ x }) => `La progresión para ${x}˚ ya se estableció`,
        ja: ({ x }) => `${x}${ordinal(x)}の進出はすでに設定されています`,
    },
    "Remove custom progression": {
        pt: "Remover progressão customizada",
        ja: "カスタム進行を削除する",
        es: "Eliminar progresión predeterminada",
    },
    "Create custom progression": {
        pt: "Customizar progressão",
        ja: "カスタム進行を作成する",
        es: "Crear progresión predeterminada",
    },
    "Progress places:": {
        pt: "Avançar colocados:",
        ja: "次の順位を進行させる：",
        es: "Posiciones con progreso:",
    },
    "To round:": {
        pt: "Ao round:",
        ja: "次のラウンドに進行させる：",
        es: "A la ronda:",
    },
    "Add another rule": {
        pt: "Adicionar regra",
        ja: "別のルールを追加する",
        es: "Agregar otra regla",
    },
    "Round already selected": {
        pt: "Este round já foi utilizado",
        ja: "ラウンドは既に選択されています",
        es: "La ronda ya se seleccionó",
    },
    "Must be after current round": {
        pt: "Deve ser maior que o round atual",
        ja: "現在のラウンドの後である必要があります",
        es: "Debe ser posterior a la ronda actual",
    },
    "50%": {
        ja: "50%",
        es: "50%",
    },
    "x_progression_label_from": {
        en: ({ x }) => `${x}${ordinal(x)}`,
        pt: ({ x }) => `${x}˚`,
        ja: ({ x }) => `${x}${ordinal(x)}`,
        es: ({ x }) => `${x}˚`,
    },
    "progression_label": {
        en: ({ from, to }) => `${from}${ordinal(from)} - ${to}${ordinal(to)}`,
        pt: ({ from, to }) => `${from}˚ - ${to}˚`,
        ja: ({ from, to }) => `${from}${ordinal(from)} - ${to}${ordinal(to)}`,
        es: ({ from, to }) => `De ${from}˚ a ${to}˚`,
    },
    "Option unavailable": {
        pt: "Opção indisponível",
        ja: "オプション利用不可",
        es: "Opción no disponible",
    },
    "Remove athlete?": {
        pt: "Remover atleta?",
        es: "¿Deseas eliminar el atleta?",
        ja: "選手を削除しますか？",
    },
    "Remove team?": {
        pt: "Remover time?",
        ja: "チームを削除?",
        es: "¿Deseas eliminar el equipo?",
    },
    "The entry will be removed from the draw and sent to the cancellation list": {
        pt: "A inscrição será removida da chave e enviada para a lista de cancelamentos",
        es: "La inscripción se eliminará del sorteo y se enviará a la lista de cancelaciones.",
        ja: "そのエントリーは抽選から削除され、キャンセルリストに送られます",
    },
    "Delete heat?": {
        pt: "Deletar bateria?",
        es: "¿Deseas suprimir la serie?",
        ja: "ヒートを削除しますか？",
    },
    "This cannot be undone.": {
        pt: "Esta ação não pode ser desfeita.",
        es: "Esta acción no se puede deshacer.",
        ja: "この操作は元に戻すことはできません。",
    },
    "Delete round?": {
        pt: "Deletar round?",
        ja: "ラウンドの削除?",
        es: "¿Deseas eliminar la ronda?",
    },
    "Remove athletes without any scores?": {
        pt: "Remover atletas sem notas?",
        ja: "得点の無い選手を削除しますか？",
        es: "¿Deseas eliminar a los atletas que no tienen puntajes?",
    },
    "Remove athletes without places?": {
        pt: "Remover atletas sem colocação?",
        es: "¿Deseas eliminar a los atletas que no obtuvieron posiciones?",
        ja: "順位のない選手を削除しますか？",
    },
    "End heats": {
        pt: "Finalizar baterias",
        ja: "ヒートの終了",
        es: "Finalizar series",
    },
    "Remove and end heats": {
        pt: "Remover e finalizar baterias",
        ja: "ヒートの削除と完了",
        es: "Suprimir y finalizar series",
    },
    "End runs": {
        pt: "Finalizar voltas",
        ja: "ランの終了",
        es: "Finalizar tandas",
    },
    "Remove and end runs": {
        pt: "Remover e finalizar voltas",
        ja: "ランの削除と終了",
        es: "Eliminar y finalizar tandas",
    },
    "Review and approve scores?": {
        pt: "Revisar e aprovar notas?",
        es: "¿Deseas revisar y aprobar los puntajes?",
        ja: "スコアを確認して承認しますか？",
    },
    "There are some unapproved scores. You might like to review and approve them before ending the heat.": {
        pt: "Algumas notas não foram aprovadas. Você pode revisar e aprová-las antes de finalizar a bateria.",
        es: "Algunos puntajes no están aprobados. Quizás debas revisarlos y aprobarlos antes de finalizar la serie.",
        ja: "未承認のスコアがいくつかあります。ヒートを終了する前に、それらを確認し、承認することをお勧めします。",
    },
    "There are some unapproved scores. You might like to review and approve them before ending the run.": {
        pt: "Algumas notas não foram aprovadas. Você pode revisar e aprová-las antes de finalizar a volta.",
        es: "Algunos puntajes no se aprobaron. Te recomendamos revisarlos y aprobarlos antes de finalizar la tanda.",
        ja: "未承認のスコアがいくつかあります。ランを終了する前にそれらを確認し、承認することをお勧めします。",
    },
    "End without approving": {
        pt: "Finalizar sem aprovar",
        es: "Finalizar sin aprobarlos",
        ja: "承認せず終了",
    },
    "Review & approve": {
        pt: "Revisar & aprovar",
        es: "Revisar y aprobar",
        ja: "確認して承認",
    },
    "athlete_dashboard": {
        en: ({ name }) => `${name}'s dashboard`,
        pt: ({ name }) => `Perfil de ${name}`,
        ja: ({ name }) => `${name}のダッシュボード`,
        es: ({ name }) => `Panel de ${name}`,
    },
    "Find organisation": {
        pt: "Encontre sua organização",
        ja: "団体を検索する",
        es: "Buscar organización",
    },
    "Explore events and organisations": {
        pt: "Explore eventos e organizações",
        ja: "イベントと団体を探す",
        es: "Explorar eventos y organizaciones",
    },
    "Members": {
        pt: "Sócios",
        ja: "会員",
        es: "Miembros",
    },
    "Rankings": {
        pt: "Ranking",
        ja: "ランキング",
        es: "Clasificaciones",
    },
    "pts": {
    },
    "#": {
    },
    "#rank": {
        en: ({ rank }) => `#${rank}`,
        pt: ({ rank }) => `#${rank}`,
        es: ({ rank }) => `#${rank}`,
        ja: ({ rank }) => `#${rank}`,
    },
    "events_(x)": {
        en: ({ x }) => `Events (${x})`,
        pt: ({ x }) => `Eventos (${x})`,
        es: ({ x }) => `Eventos (${x})`,
        ja: ({ x }) => `イベント (${x})`,
    },
    "counted_results_note": {
        en: ({ count, eventsToCount }) => <>Top {count} results are counted{eventsToCount.length ? <>, plus {joinBy(", ", " and ", eventsToCount)} results</> : ""}. Dropped results have a <s>strikethrough</s>.</>,
        pt: ({ count, eventsToCount }) => <>Apenas os {count} melhores resultados são contados{eventsToCount.length ? <>, mais resultados {joinBy(", ", " e ", eventsToCount)}</>: ""}. Resultados descartados estão <s>riscados</s>.</>,
        es: ({ count, eventsToCount }) => <>Se cuentan los mejores {count} resultados{eventsToCount.length ? <>, más los resultados {joinBy(", ", " y ", eventsToCount)}</> : ""}. Los resultados descartados tienen una <s>línea</s>.</>,
        ja: ({ count, eventsToCount }) => <>トップ {count} の結果がカウントされます{eventsToCount.length ? <>、さらに {joinBy("、", "および", eventsToCount)} の結果がカウントされます</> : ""}。ドロップされる結果は<s>取り消し線</s>がつきます。</>,
    },
    "eligible_results_note": {
        en: ({ eligible, eventsToCount }) => <>Results of the athlete's first {eligible} events are counted{eventsToCount.length ? <>, plus {joinBy(", ", " and ", eventsToCount)} results</> : ""}. Dropped results have a <s>strikethrough</s>.</>,
        pt: ({ eligible, eventsToCount }) => <>Apenas os {eligible} primeiros resultados são contados{eventsToCount.length ? <>, mais resultados {joinBy(", ", " e ", eventsToCount)}</>: ""}. Resultados descartados estão <s>riscados</s>.</>,
        es: ({ eligible, eventsToCount }) => <>Se cuentan los resultados de los primeros {eligible} eventos del atleta{eventsToCount.length ? <>, más los resultados {joinBy(", ", " y ", eventsToCount)}</> : ""}. Los resultados descartados tienen una <s>línea</s>.</>,
        ja: ({ eligible, eventsToCount }) => <>選手の最初の {eligible} イベントの結果がカウントされ、さらに {joinBy("、", "および", eventsToCount)} の結果がカウントされます。ドロップされる結果は<s>取り消し線</s>がつきます。</>,
    },
    "eligible_and_counted_results_note": {
        en: ({ count, eligible, eventsToCount }) => <>Top {count} results of athlete's first {eligible} events are counted{eventsToCount.length ? <>, plus {joinBy(", ", " and ", eventsToCount)} results</> : ""}. Dropped results have a <s>strikethrough</s>.</>,
        pt: ({ count, eligible, eventsToCount }) => <>Apenas os {count} melhores resultados dos primeiros {eligible} eventos são contados{eventsToCount.length ? <>, mais resultados {joinBy(", ", " e ", eventsToCount)}</>: ""}. Resultados descartados estão <s>riscados</s>.</>,
        es: ({ count, eligible, eventsToCount }) => <>Se cuentan los mejores {count} resultados de los primeros {eligible} eventos del atleta{eventsToCount.length ? <>, más los resultados {joinBy(", ", " y ", eventsToCount)}</> : ""}. Los resultados descartados tienen una <s>línea</s>.</>,
        ja: ({ count, eligible, eventsToCount }) => <>選手の最初の {eligible} イベントのトップ {count} の結果がカウントされます{eventsToCount.length ? <>、さらに {joinBy("、", "および", eventsToCount)} の結果がカウントされます</> : ""}。ドロップされる結果は<s>取り消し線</s>がつきます。</>,
    },
    "Series": {
        pt: "Temporadas",
        ja: "シリーズ",
        es: "Grupo",
    },
    "Athletes & teams": {
        pt: "Atletas e times",
        es: "Atletas y equipos",
        ja: "選手＆チーム",
    },
    "Manager": {
        pt: "Gerente",
        es: "Gerente",
        ja: "マネージャー",
    },
    "Managers": {
        pt: "Gerentes",
        es: "Gerentes",
        ja: "マネージャー",
    },
    "View and manage all teams that compete within your organisation. If a manager is assigned, they can edit team rosters for their team.": {
        pt: "Veja e gerencie todos os times que competem dentro da sua organização. Se um gerente for atribuído, poderá editar a composição dos seus times.",
        es: "Ver y administrar todos los equipos que compiten dentro de tu organización. Si se asigna un administrador, puede editar las listas de los equipos a su cargo.",
        ja: "組織内で競技するすべてのチームを表示して管理します。マネージャーが割り当てられている場合、そのチームのロスターを編集できます。",
    },
    "no_teams_in_teams_page": {
        en: <>Teams will be listed here once <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040418251-Displaying-additional-information-with-each-athlete-on-an-event-by-using-the-team-field" target="_blank" rel="noreferrer">linked to entries</MuiLink>.</>,
        pt: <>Os times serão listados aqui assim que forem <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040418251-Displaying-additional-information-with-each-athlete-on-an-event-by-using-the-team-field" target="_blank" rel="noreferrer">vinculados às inscrições</MuiLink>.</>,
        es: <>Los equipos se enumerarán aquí una vez <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040418251-Displaying-additional-information-with-each-athlete-on-an-event-by-using-the-team-field" target="_blank" rel="noreferrer">vinculados a las inscripciones</MuiLink>.</>,
        ja: <>エントリーに<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040418251-Displaying-additional-information-with-each-athlete-on-an-event-by-using-the-team-field" target="_blank" rel="noreferrer">リンクされた</MuiLink>後、ここにチームが表示されます。</>,
    },
    "People who can manage this team's entries and rosters.": {
        pt: "Pessoas que podem gerenciar as inscrições e composições deste time.",
        es: "Personas que pueden administrar las inscripciones y listas de este equipo.",
        ja: "このチームのエントリーとロスターを管理できる人。",
    },
    "Add manager": {
        pt: "Adicionar gerente",
        es: "Agregar gerente",
        ja: "マネージャーを追加",
    },
    "Invite someone to manage the entries for this team.": {
        pt: "Convide alguém para gerenciar as inscrições deste time.",
        es: "Invita a alguien para que administre las inscripciones de este equipo.",
        ja: "このチームのエントリーを管理するように誰かを招待します。",
    },
    "Nice one. Manager has been added and emailed ✅": {
        pt: "Muito bem. Gerente adicionado e notificado por email ✅",
        es: "Excelente. Se ha agregado un gerente y se le ha enviado un correo electrónico ✅",
        ja: "素晴らしい。マネージャーが追加され、電子メールが送信されました ✅",
    },
    "merge_x": {
        en: ({ x, disabled }) => `Merge ${!disabled ? `(${x})` : ""}`,
        pt: ({ x, disabled }) => `Amalgamar ${!disabled ? `(${x})` : ""}`,
        es: ({ x, disabled }) => `Fusionar ${!disabled ? `(${x})` : ""}`,
        ja: ({ x, disabled }) => `${!disabled ? `(${x})` : ""} を結合`,
    },
    "merge_x_profiles": {
        en: ({ x }) => `Merge ${x} profiles`,
        pt: ({ x }) => `Amalgamar ${x} perfis`,
        es: ({ x }) => `Fusionar ${x} perfiles`,
        ja: ({ x }) => `${x}プロフィールを結合`,
    },
    "merge_profiles_description": {
        en: <>This will combine the profiles in the database and combine any results, memberships, and linked users. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360028213671" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>Esta ação irá fundir os perfis no banco de dados e combinar todos os resultados, associações e usuários conectados. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360028213671" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Esto combinará los perfiles en la base de datos y, a su vez, los resultados, las membresías y los usuarios vinculados. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360028213671" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>これにより、データベースのプロフィールは結合され、結果、会員、リンクされたユーザーも結合されます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360028213671" target="_blank" rel="noreferrer">詳しくはこちら</MuiLink>。</>,
    },
    "merge_profiles_appears_in": {
        en: ({ eventDivisionsByOrganisation }) => <>Appears in {joinBy(", ", " and ", Object.keys(eventDivisionsByOrganisation).map(organisation => <Fragment key={organisation}>{joinBy(", ", " and ", eventDivisionsByOrganisation[organisation].map(eventDivision => <Typography variant="button">{eventDivision.division.name}</Typography>))} ({organisation})</Fragment>))}</>,
        pt: ({ eventDivisionsByOrganisation }) => <>Aparecem em {joinBy(", ", " e ", Object.keys(eventDivisionsByOrganisation).map(organisation => <Fragment key={organisation}>{joinBy(", ", " e ", eventDivisionsByOrganisation[organisation].map(eventDivision => <Typography variant="button">{eventDivision.division.name}</Typography>))} ({organisation})</Fragment>))}</>,
        es: ({ eventDivisionsByOrganisation }) => <>Aparece en {joinBy(", ", " y ", Object.keys(eventDivisionsByOrganisation).map(organisation => <Fragment key={organisation}>{joinBy(", ", " y ", eventDivisionsByOrganisation[organisation].map(eventDivision => <Typography variant="button">{eventDivision.division.name}</Typography>))} ({organisation})</Fragment>))}</>,
        ja: ({ eventDivisionsByOrganisation }) => <>{joinBy("、", "および", Object.keys(eventDivisionsByOrganisation).map(organisation => <Fragment key={organisation}>{joinBy("、", "および", eventDivisionsByOrganisation[organisation].map(eventDivision => <Typography variant="button">{eventDivision.division.name}</Typography>))} ({organisation})</Fragment>))}に出場</>,
    },
    "Profiles cannot be merged": {
        pt: "Perfis não podem ser amalgamados",
        es: "Los perfiles no se pueden fusionar",
        ja: "プロフィールを結合できません",
    },
    "Select primary profile": {
        pt: "Escolher perfil primário",
        es: "Seleccionar perfil primario",
        ja: "プライマリプロフィールを選択",
    },
    "Confirm merge?": {
        pt: "Confirmar almagamação?",
        es: "¿Confirmas la fusión?",
        ja: "結合しますか？",
    },
    "Merging profiles cannot be undone and will have impacts on historical rankings if you combine profiles that are different athletes.": {
        pt: "A amalgamação de perfis não pode ser desfeita, e terá impacto no ranking histórico se você amalgamar perfis que são atletas diferentes.",
        es: "La fusión de perfiles no puede revertirse. Si combinas perfiles de diferentes atletas, afectará las clasificaciones históricas.",
        ja: "プロフィールの統合は取り消すことができず、別の選手のプロフィールを統合した場合、過去のランキングに影響を与えます。",
    },
    "Profiles successfully merged ✅": {
        pt: "Perfis almagamados com sucesso ✅",
        es: "Los perfiles se fusionaron correctamente ✅",
        ja: "プロフィールが結合されました✅",
    },
    "Okay": {
        pt: "Ok",
        es: "Aceptar",
        ja: "OK",
    },
    "Profiles were not merged. Please resolve the result and try again": {
        pt: "Os perfis não foram amalgamados. Por favor resolva o problema e tente novamente",
        es: "Los perfiles no se fusionaron. Determina el resultado y vuelve a intentarlo.",
        ja: "プロフィールが結合されませんでした。結果を解決してもう一度お試しください",
    },
    "merge_athletes_same_heat_error": {
        en: ({ conflictingAthletes }) => <>{joinBy(", ", " and ", conflictingAthletes)} appear in the same heat, so they cannot be merged until this is resolved. To fix this, you should <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360028410192-Adding-removing-athletes-or-changing-jersey-colours-for-live-or-completed-heats" target="_blank" rel="noreferrer" underline="always">remove or correct</MuiLink> the competitors in the heat. <br/><br/>The affected heats are:</>,
        pt: ({ conflictingAthletes }) => <>{joinBy(", ", " e ", conflictingAthletes)} aparecem na mesma bateria, então não podem ser amalgamados até que isso seja resolvido. Para concertar o problema você deve <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360028410192-Adding-removing-athletes-or-changing-jersey-colours-for-live-or-completed-heats" target="_blank" rel="noreferrer" underline="always">remover ou corrigir</MuiLink> os atletas na bateria. <br/><br/>As baterias afetadas são:</>,
        es: ({ conflictingAthletes }) => <>{joinBy(", ", " y ", conflictingAthletes)} participan en la misma serie y no pueden fusionarse hasta que esto se resuelva. Para ello, debes <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360028410192-Adding-removing-athletes-or-changing-jersey-colours-for-live-or-completed-heats" target="_blank" rel="noreferrer" underline="always">eliminar o corregir</MuiLink> los competidores de la serie. <br/><br/>Las series afectadas son las siguientes:</>,
        ja: ({ conflictingAthletes }) => <>{joinBy("、", "および", conflictingAthletes)}は同じヒートに出場するため、これを解決するまで結合できません。これを修正するには、ヒートの該当選手を<MuiLink href="https://support.liveheats.com/hc/en-us/articles/360028410192-Adding-removing-athletes-or-changing-jersey-colours-for-live-or-completed-heats" target="_blank" rel="noreferrer" underline="always">削除または修正</MuiLink>してください。<br/><br/>次のヒートが影響を受けます。</>,
    },
    "merge_athlete_error_other_organisation": {
        en: ({ heat }) => <><Typography variant="button">{heat}{" "}</Typography>(belongs to another organiser. Please contact support <MuiLink underline="always" href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>)</>,
        pt: ({ heat }) => <><Typography variant="button">{heat}{" "}</Typography>(pertence a outra organização. Por favor entre em contato com nosso suporte através do e-mail <MuiLink underline="always" href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>)</>,
        es: ({ heat }) => <><Typography variant="button">{heat}{" "}</Typography>(es de otro patrocinador; envía un correo electrónico al equipo de soporte a <MuiLink underline="always" href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>)</>,
        ja: ({ heat }) => <><Typography variant="button">{heat}{" "}</Typography>(別の主催者に属しています。サポート<MuiLink underline="always" href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>までお問い合わせください)</>,
    },
    "Help centre": {
        pt: "Ajuda",
        ja: "ヘルプセンター",
        es: "Centro de ayuda",
    },
    "MENU": {
        ja: "メニュー",
        es: "MENÚ",
    },
    "Supported By": {
        pt: "Patrocinado Por",
        ja: "Supported By",
        es: "Patrocinado por",
    },
    "Enter organisation name": {
        pt: "Nome da organização",
        ja: "団体名を入力",
        es: "Ingresar nombre de la organización",
    },
    "Organisations": {
        pt: "Organizações",
        ja: "団体",
        es: "Organizaciones",
    },
    "Select your team": {
        pt: "Selecione seu time",
        es: "Selecciona tu equipo",
        ja: "チームを選択",
    },
    "Type your team name...": {
        pt: "Digite o nome do seu time...",
        es: "Escribe el nombre de tu equipo...",
        ja: "チーム名を入力してください...",
    },
    "You must select a team": {
        pt: "Você precisa selecionar um time",
        es: "Debes seleccionar un equipo",
        ja: "チームを選択する必要があります",
    },
    "no_team_found_contact_organisation": {
        en: ({ contact }) => `Sorry, we couldn't find that team. If your team details need updating or for any queries, contact the organiser at ${contact}.`,
        pt: ({ contact }) => `Desculpe, não encontramos esse time. Se os detalhes do seu time precisam ser atualizados ou para qualquer dúvida, entre em contato com o organizador em ${contact}.`,
        es: ({ contact }) => `Lo sentimos, no pudimos encontrar ese equipo. Si los detalles de tu equipo necesitan actualizarse o si tienes alguna consulta, contacta al organizador en ${contact}.`,
        ja: ({ contact }) => `申し訳ありませんが、そのチームが見つかりませんでした。チームの詳細を更新する必要がある場合や、その他の疑問がある場合は、${contact}で主催者に連絡してください。`,
    },
    "Register athletes": {
        pt: "Inscrever atletas",
        es: "Inscribir atletas",
        ja: "選手を登録する",
    },
    "entries_for_team": {
        en: ({ team }) => `Entries for ${team}`,
        pt: ({ team }) => `Inscrições para ${team}`,
        es: ({ team }) => `Inscripciones para ${team}`,
        ja: ({ team }) => `${team}のエントリー`,
    },
    "Add another athlete": {
        pt: "Adicionar mais um atleta",
        es: "Agregar otro atleta",
        ja: "別の選手を追加する",
    },
    "Select athlete": {
        pt: "Selecione um atleta",
        es: "Seleccionar atleta",
        ja: "選手を選択する",
    },
    "New entry": {
        pt: "Nova inscrição",
        es: "Nueva inscripción",
        ja: "新規エントリー",
    },
    "Enter an individual or team?": {
        pt: "Inscrever um atleta ou time?",
        es: "¿Inscribir un atleta o equipo?",
        ja: "個人またはチームを登録しますか？",
    },
    "team_entered_x": {
        en: ({ x }) => `Teams entered: ${x}`,
        pt: ({ x }) => `Times inscritos: ${x}`,
        es: ({ x }) => `Equipos inscritos: ${x}`,
        ja: ({ x }) => `登録されたチーム：${x}`,
    },
    "Select division": {
        pt: "Selecione a categoria",
        es: "Seleccionar categoría",
        ja: "部門を選択する",
    },
    "Individual": {
        pt: "Individual",
        es: "Individual",
        ja: "個人",
    },
    "team_already_entered": {
        en: ({ team }) => `${team} (already entered)`,
        pt: ({ team }) => `${team} (já inscrito)`,
        es: ({ team }) => `${team} (ya inscrito)`,
        ja: ({ team }) => `${team} (既に登録済み)`,
    },
    "View in dashboard": {
        pt: "Ver no perfil",
        es: "Ver en el panel",
        ja: "ダッシュボードで表示",
    },
    "Team roster": {
        pt: "Composição do time",
        es: "Lista de equipo",
        ja: "チームロスター",
    },
    "Athletes (required)": {
        pt: "Atletas (obrigatório)",
        es: "Atletas (obligatorio)",
        ja: "選手（必須）",
    },
    "You'll need to register at least one athlete.": {
        pt: "Você precisa inscrever pelo menos um atleta.",
        es: "Tendrás que inscribir al menos un atleta.",
        ja: "1人以上の選手を登録する必要があります。",
    },
    "You'll need to add at least one entry.": {
        pt: "Você precisa adicionar pelo menos uma inscrição.",
        es: "Necesitas agregar al menos una inscripción.",
        ja: "少なくとも一つのエントリーを追加する必要があります。",
    },
    "Payment": {
        pt: "Pagamento",
        es: "Pago",
        ja: "支払い",
    },
    "Confirmation": {
        pt: "Confirmação",
        es: "Confirmación",
        ja: "確認",
    },
    "Select divisions": {
        pt: "Selecione as categorias",
        ja: "部門の選択",
        es: "Seleccionar categorías",
    },
    "Select membership options": {
        pt: "Selecione as opções de associação",
        es: "Seleccionar alternativas de membresía",
        ja: "会員オプションを選択する",
    },
    "Add divisions": {
        pt: "Adicionar categorias",
        es: "Agregar categorías",
        ja: "部門を追加する",
    },
    "Add membership options": {
        pt: "Adicionar opções de associação",
        es: "Agregar alternativas de membresía",
        ja: "会員オプションを追加する",
    },
    "They'll need to enter at least one division.": {
        pt: "O atleta precisa se inscrever em pelo menos uma categoria.",
        es: "Tendrán que ingresar al menos una categoría.",
        ja: "選手は1つ以上の部門にエントリーする必要があります。",
    },
    "They'll need to enter at least one membership option.": {
        pt: "O atleta precisa escolher pelo menos uma opção de associação.",
        es: "Tendrán que ingresar al menos una alternativa de membresía.",
        ja: "選手は1つ以上の会員オプションにエントリーする必要があります。",
    },
    "Already registered. Add a new division or remove athlete.": {
        pt: "Já está inscrito. Adicione mais categorias ou remova o atleta.",
        es: "Ya se inscribió. Agrega una categoría nueva o elimina al atleta.",
        ja: "登録済みです。新しい部門を追加するか、選手を削除してください。",
    },
    "Already registered. Add a new membership option or remove athlete.": {
        pt: "Já está inscrito. Adicione mais opções de associação ou remova o atleta.",
        es: "Ya se inscribió. Agrega una alternativa de membresía nueva o elimina al atleta.",
        ja: "登録済みです。新しい会員オプションを追加するか、選手を削除してください。",
    },
    "The athlete needs a name.": {
        pt: "O atleta precisa de um nome.",
        es: "El nombre del atleta es obligatorio.",
        ja: "選手には名前が必要です。",
    },
    "Surely they were born?": {
        pt: "Certamente o atleta nasceu?",
        es: "¿Debe hacer nacido ya?",
        ja: "確かに彼らは生まれていますか？",
    },
    "Please select a nationality.": {
        pt: "Por favor selecione uma nacionalidade.",
        es: "Selecciona una nacionalidad.",
        ja: "国籍を選択してください。",
    },
    "Select divisions:": {
        pt: "Selecione as categorias:",
        ja: "部門の選択",
        es: "Seleccionar categorías:",
    },
    "You must select at least one division": {
        pt: "Selecione ao menos uma categoria",
        ja: "最低でも一つの部門を選択しなくてはなりません",
        es: "Tienes que seleccionar al menos una categoría",
    },
    "You need to select an option": {
        pt: "Você precisa selecionar uma opção",
        es: "Tienes que seleccionar una opción",
        ja: "オプションを選択する必要があります",
    },
    "max_divisions_per_athlete": {
        en: ({ max }) => `Maximum ${max} division${plural(max)} per competitor. `,
        pt: ({ max }) => `No máximo ${max} categoria${plural(max)} por competidor. `,
        es: ({ max }) => `Máximo de ${max} categorías${plural(max)} por competidor. `,
        ja: ({ max }) => `選手1人あたり最大${max}部門。`,
    },
    "max_membership_options_per_athlete": {
        en: ({ max }) => `Maximum ${max} membership option${plural(max)} per athlete. `,
        pt: ({ max }) => `No máximo ${max} ${max !== 1 ? "opções" : "opção"} por atleta. `,
        es: ({ max }) => `Máximo de ${max} alternativa${plural(max)} de membresía por atleta. `,
        ja: ({ max }) => `選手1人あたり最大${max}会員オプション。`,
    },
    "single_division_price": {
        en: ({ price, currency }) => `Price is ${formatPrice(price, currency)} per division. `,
        pt: ({ price, currency }) => `O preço é ${formatPrice(price, currency)} por categoria. `,
        es: ({ price, currency }) => `El precio es de ${formatPrice(price, currency)} por categoría. `,
        ja: ({ price, currency }) => `価格は1部門あたり${formatPrice(price, currency)}です。`,
    },
    "single_membership_option_price": {
        en: ({ price, currency }) => `Price is ${formatPrice(price, currency)} per membership option. `,
        pt: ({ price, currency }) => `O preço é ${formatPrice(price, currency)} per opção. `,
        es: ({ price, currency }) => `El precio es de ${formatPrice(price, currency)} por alternativa de membresía. `,
        ja: ({ price, currency }) => `価格は1会員オプションあたり${formatPrice(price, currency)}です。`,
    },
    "single_division_price_with_extra": {
        en: ({ price, extra, currency }) => `Price is ${formatPrice(price, currency)} for the first division and ${formatPrice(extra, currency)} per additional division. `,
        pt: ({ price, extra, currency }) => `O preço é ${formatPrice(price, currency)} pra primeira categoria e ${formatPrice(extra, currency)} pra cada categoria adicional. `,
        es: ({ price, extra, currency }) => `El precio es de ${formatPrice(price, currency)} por la primera categoría y de ${formatPrice(extra, currency)} por cada categoría adicional. `,
        ja: ({ price, extra, currency }) => `価格は1つ目の部門は${formatPrice(price, currency)}で、追加1部門あたり ${formatPrice(extra, currency)}です。`,
    },
    "single_membership_option_price_with_extra": {
        en: ({ price, extra, currency }) => `Price is ${formatPrice(price, currency)} for the first membership option and ${formatPrice(extra, currency)} per additional membership option. `,
        pt: ({ price, extra, currency }) => `O preço é ${formatPrice(price, currency)} pra primeira opção e ${formatPrice(extra, currency)} pra cada opção adicional. `,
        es: ({ price, extra, currency }) => `El precio es de ${formatPrice(price, currency)} por la primera alternativa de membresía y de ${formatPrice(extra, currency)} por cada alternativa de membresía adicional. `,
        ja: ({ price, extra, currency }) => `価格は1つ目の会員オプションは${formatPrice(price, currency)}で、追加の1会員オプションあたり${formatPrice(extra, currency)}です。`,
    },
    "division_price_range": {
        en: ({ min, max, currency }) => `Prices are ${formatPrice(min, currency)} - ${formatPrice(max, currency)} per division. `,
        pt: ({ min, max, currency }) => `Os preços são entre ${formatPrice(min, currency)} - ${formatPrice(max, currency)} por categoria. `,
        es: ({ min, max, currency }) => `Los precios van de ${formatPrice(min, currency)} a ${formatPrice(max, currency)} por categoría. `,
        ja: ({ min, max, currency }) => `価格は1部門あたり${formatPrice(min, currency)} - ${formatPrice(max, currency)}です。`,
    },
    "membership_option_price_range": {
        en: ({ min, max, currency }) => `Prices are ${formatPrice(min, currency)} - ${formatPrice(max, currency)} per membership option. `,
        pt: ({ min, max, currency }) => `Os preços são entre ${formatPrice(min, currency)} - ${formatPrice(max, currency)} por opção. `,
        es: ({ min, max, currency }) => `Los precios van de ${formatPrice(min, currency)} a ${formatPrice(max, currency)} por alternativa de membresía. `,
        ja: ({ min, max, currency }) => `価格は1会員オプションあたり${formatPrice(min, currency)} - ${formatPrice(max, currency)}です。`,
    },
    "division_price_range_with_extra": {
        en: ({ min, max, extra, currency }) => `Prices are ${formatPrice(min, currency)} - ${formatPrice(max, currency)} for the first division and ${formatPrice(extra, currency)} per additional division. `,
        pt: ({ min, max, extra, currency }) => `Os preços são entre ${formatPrice(min, currency)} - ${formatPrice(max, currency)} pra primeira categoria e ${formatPrice(extra, currency)} pra cada categoria adicional. `,
        es: ({ min, max, extra, currency }) => `Los precios van de ${formatPrice(min, currency)} a ${formatPrice(max, currency)} por la primera categoría y cada categoría adicional tiene un costo de ${formatPrice(extra, currency)}. `,
        ja: ({ min, max, extra, currency }) => `価格は1つ目の部門は${formatPrice(min, currency)} - ${formatPrice(max, currency)}で、追加1部門あたり${formatPrice(extra, currency)}です。`,
    },
    "membership_option_price_range_with_extra": {
        en: ({ min, max, extra, currency }) => `Prices are ${formatPrice(min, currency)} - ${formatPrice(max, currency)} for the first membership option and ${formatPrice(extra, currency)} per additional membership option. `,
        pt: ({ min, max, extra, currency }) => `Os preços são entre ${formatPrice(min, currency)} - ${formatPrice(max, currency)} pra primeira opção e ${formatPrice(extra, currency)} pra cada opção adicional. `,
        es: ({ min, max, extra, currency }) => `Los precios van de ${formatPrice(min, currency)} a ${formatPrice(max, currency)} por la primera alternativa de membresía y cada alternativa de membresía adicional tiene un costo de ${formatPrice(extra, currency)}. `,
        ja: ({ min, max, extra, currency }) => `価格は1つ目の会員オプションは${formatPrice(min, currency)} - ${formatPrice(max, currency)}で、追加の1会員オプションあたり${formatPrice(extra, currency)}です。`,
    },
    "No entry fees": {
        pt: "Inscrições gratis",
        es: "Sin costos de inscripción",
        ja: "エントリー料無料",
    },
    "spots_left": {
        en: ({ spots }) => `${spots} spot${plural(spots)} left`,
        pt: ({ spots }) => `${spots} vaga${plural(spots)}`,
        es: ({ spots }) => `${spots} plaza${plural(spots)} restante${plural(spots)}`,
        ja: ({ spots }) => `残り${spots}スポット`,
    },
    "Already registered": {
        pt: "Já está inscrito",
        ja: "登録済み",
        es: "Ya se inscribió",
    },
    "Unlimited spots": {
        pt: "Vagas ilimitadas",
        es: "Plazas ilimitadas",
        ja: "スポット数無制限",
    },
    "No spots left": {
        pt: "Não têm mais vagas",
        es: "No quedan más plazas",
        ja: "残りスポットなし",
    },
    "Athlete outside age limit": {
        pt: "Atleta fora do limite de idade",
        es: "El atleta supera el límite de edad",
        ja: "年齢制限外の選手",
    },
    "spots_over": {
        en: ({ spots }) => `${spots} over the limit`,
        pt: ({ spots }) => `${spots} acima do limite`,
        es: ({ spots }) => `${spots} por encima del límite`,
        ja: ({ spots }) => `${spots}が上限を超えています`,
    },
    "Division full 😥": {
        pt: "Categoria cheia 😥",
        es: "La categoría está completa 😥",
        ja: "部門はいっぱいです😥",
    },
    "Purchase extras": {
        pt: "Comprar extras",
        ja: "その他を購入",
        es: "Comprar complementos",
    },
    "Documents to sign": {
        pt: "Documentos para assinar",
        es: "Documentos para firmar",
        ja: "署名するドキュメント",
    },
    "View and sign": {
        pt: "Ver e assinar",
        es: "Ver y firmar",
        ja: "確認して署名",
    },
    "Signed": {
        pt: "Assinado",
        es: "Firmado",
        ja: "署名済み",
    },
    "Pay now": {
        pt: "Pagar inscrição",
        ja: "今すぐ支払う",
        es: "Pagar ahora",
    },
    "UserForm_agreement": {
        en: ({ registrationOptions }) => <span>By completing this registration, you acknowledge that you (or for athletes under the age of 18, a parent or legal guardian) have read and accept the Liveheats <a target="_blank" rel="noopener noreferrer" href="/terms_and_conditions">Terms and Conditions</a> and <a target="_blank" rel="noopener noreferrer" href="/privacy_policy">Privacy Policy</a>{registrationOptions?.termsAndConditionsLink ? <span>, and the <a target="_blank" rel="noopener noreferrer" href={registrationOptions.termsAndConditionsLink}>{registrationOptions.termsOwner} Terms and Conditions</a>.</span>: "."}</span>,
        pt: ({ registrationOptions }) => <span>Ao completar este formalário, você (ou para atletas menores de 18 anos um pai ou guardião legal) afirma que leu e aceita os <a target="_blank" rel="noopener noreferrer" href="/terms_and_conditions">Termos de Uso</a> e a <a target="_blank" rel="noopener noreferrer" href="/privacy_policy">Política de Privacidade</a> do Liveheats{registrationOptions?.termsAndConditionsLink ? <span>, e os <a target="_blank" rel="noopener noreferrer" href={registrationOptions.termsAndConditionsLink}>Termos de Uso de {registrationOptions.termsOwner}</a>.</span>: "."}</span>,
        ja: ({ registrationOptions }) => <span>この登録を完了することであなた（あるいは選手が18歳未満の場合は両親または保護者）はライブヒート <a target="_blank" rel="noopener noreferrer" href="/terms_and_conditions">利用規約</a> および <a target="_blank" rel="noopener noreferrer" href="/privacy_policy">プライバシーポリシー</a>{registrationOptions?.termsAndConditionsLink ? <span>、そして <a target="_blank" rel="noopener noreferrer" href={registrationOptions.termsAndConditionsLink}>{registrationOptions.termsOwner} 利用規約</a>.</span>: "."}を読んで理解したものとみなします</span>,
        es: ({ registrationOptions }) => <span>Al completar esta inscripción, reconoces que leíste y aceptas (en el caso de atletas menores de 18 años, esto debe hacerlo su padre, madre o tutor legal) <a target="_blank" rel="noopener noreferrer" href="/terms_and_conditions">los Términos y condiciones</a> y <a target="_blank" rel="noopener noreferrer" href="/privacy_policy">la Política de privacidad</a> de Liveheats{registrationOptions?.termsAndConditionsLink ? <span>, así como los <a target="_blank" rel="noopener noreferrer" href={registrationOptions.termsAndConditionsLink}>Términos y condiciones de {registrationOptions.termsOwner}</a>.</span>: "."}</span>,
    },
    "divisions_entries_summary": {
        en: ({ divisions, athletes }) => `Divisions x ${divisions} (${athletes} ${pluralEntries(athletes, "en")})`,
        pt: ({ divisions, athletes }) => `Categorias x ${divisions} (${athletes} ${pluralEntries(athletes, "pt")})`,
        es: ({ divisions, athletes }) => `${divisions} categorías (${athletes} ${pluralEntries(athletes, "es")})`,
        ja: ({ divisions, athletes }) => `部門 x ${divisions}(${athletes}エントリー)`,
    },
    "membership_options_athletes_summary": {
        en: ({ divisions, athletes }) => `Membership options x ${divisions} (${athletes} athlete${plural(athletes)})`,
        pt: ({ divisions, athletes }) => `Opções de associação x ${divisions} (${athletes} atleta${plural(athletes)})`,
        es: ({ divisions, athletes }) => `${divisions} alternativas de membresía (${athletes} atleta${plural(athletes)})`,
        ja: ({ divisions, athletes }) => `会員オプション x ${divisions}(${athletes}選手)`,
    },
    "extras_athletes_summary": {
        en: ({ extras, athletes }) => `Extras x ${extras} (${athletes} athlete${plural(athletes)})`,
        pt: ({ extras, athletes }) => `Extras x ${extras} (${athletes} atleta${plural(athletes)})`,
        es: ({ extras, athletes }) => `${extras} complementos (${athletes} atleta${plural(athletes)})`,
        ja: ({ extras, athletes }) => `エクストラ x ${extras}(${athletes}選手)`,
    },
    "Waitlisted event": {
        pt: "Evento em lista de espera",
        es: "Evento en lista de espera",
        ja: "ウェイティングリスト制のイベント",
    },
    "You won’t be charged yet.": {
        pt: "Por enquanto não te cobraremos.",
        es: "Aún no te cobraremos.",
        ja: "まだ請求はされません。",
    },
    "More details.": {
        pt: "Mais detalhes.",
        ja: "さらに詳しく。",
        es: "Más detalles.",
    },
    "As payment is required for this event, you will need to provide payment details during registration. However, you will only be charged if and when your entry gets confirmed by the event organiser.": {
        pt: "Como o pagamento é necessário para este evento, você precisará fornecer os detalhes de pagamento durante a inscrição. No entanto, só te cobraremos quando sua inscrição for confirmada pelo organizador do evento.",
        es: "Como el pago es necesario para este evento, deberás proporcionar los detalles de pago durante la inscripción. Sin embargo, solo te cobraremos cuando tu inscripción sea confirmada por el organizador del evento.",
        ja: "このイベントはお支払いが必要なため、登録時に支払いの詳細をお知らせいただく必要があります。ただし、エントリー料が請求されるのは、イベント主催者によってエントリーが確定してからです。",
    },
    "subject_to_organiser_discretion": {
        en: ({ organisationName }) => `Acceptance into this event is subject to the event rules and at the discretion of ${organisationName}. By registering, you agree to pay the amount indicated in the registration summary if your entry is accepted by ${organisationName}.`,
        pt: ({ organisationName }) => `Admissão neste evento está sujeita às regras do evento e à decisão de ${organisationName}. Ao se inscrever, voce aceita pagar o valor descrito no resumo da inscrição se ${organisationName} aceitar a sua participação.`,
        es: ({ organisationName }) => `La aceptación para participar en este evento está sujeta a las reglas del evento y a la decisión de ${organisationName}. Al inscribirte, aceptas pagar el monto que figura en el resumen de inscripción si  ${organisationName} acepta tu participación.`,
        ja: ({ organisationName }) => `このイベントへの参加許可はイベントルールに従い、${organisationName} の裁量に委ねられます。登録することで、${organisationName} があなたのエントリーを許可した場合、登録の概要に記載されている金額の支払いに同意したものとみなされます。`,
    },
    "Your login details are incorrect, please try again, or click below to recover your password. First time using Liveheats? Create an account below.": {
        pt: "As informação fornecidas não estão corretas, por favor tente novamente ou clique abaixo para recuperar sua senha. Primeira vez no Liveheats? Crie uma conta abaixo.",
        ja: "ログイン情報が正しくありません。もう一度お試しいただくか下をクリックしてパスワードをリセットしてください。ライブヒートのご使用は初めてですか？下記からアカウントを作成してください。",
        es: "La información de inicio de sesión es incorrecta. Vuelve a intentarlo o haz clic más abajo para recuperar tu contraseña. ¿Es la primera vez que usas Liveheats? Crea una cuenta a continuación.",
    },
    "Email:": {
        pt: "E-mail:",
        ja: "Eメール：",
        es: "Correo electrónico:",
    },
    "Reset password": {
        pt: "Alterar senha",
        ja: "パスワードのリセット",
        es: "Restablecer contraseña",
    },
    "Don't have an account?": {
        pt: "Não possiu usuário?",
        ja: "まだアカウントをお持ちではないですか？",
        es: "¿No tienes una cuenta?",
    },
    "Sign up": {
        pt: "Crie uma conta",
        ja: "登録",
        es: "Regístrate",
    },
    "Incorrect email or password": {
        pt: "E-mail ou senha incorretos",
        es: "Correo electrónico o contraseña incorrectos",
        ja: "メールアドレスまたはパスワードが間違っています",
    },
    "Your password has been successfully reset, you can now login with your new password.": {
        pt: "Sua senha for alterada, você já pode fazer o login com a nova senha.",
        ja: "パスワードが正常にリセットされました。新しいパスワードでログインしてください。",
        es: "Tu contraseña se restableció correctamente. Ya puedes iniciar sesión con la contraseña nueva.",
    },
    "failed_login_open_in_external_tab": {
        en: ({ url }) => <>We weren't able to log you in. <MuiLink href={url} underline="none" target="_blank">Try opening Liveheats in a different tab</MuiLink></>,
        pt: ({ url }) => <>Não conseguimos fazer o login. <MuiLink href={url} underline="none" target="_blank">Tente abrir o Liveheats em uma aba diferente</MuiLink></>,
        ja: ({ url }) => <>ログインできませんでした。<MuiLink href={url} underline="none" target="_blank">別のタブでLiveheatsを開いてみてください</MuiLink></>,
        es: ({ url }) => <>No pudimos iniciar sesión. <MuiLink href={url} underline="none" target="_blank">Intenta abrir Liveheats en una pestaña diferente</MuiLink></>,
    },
    "Back": {
        pt: "Voltar",
        ja: "戻る",
        es: "Atrás",
    },
    "Summary": {
        pt: "Detalhes",
        ja: "要約",
        es: "Resumen",
    },
    "Another user with that email address already exists.": {
        pt: "Um usuário já existe com este e-mail.",
        ja: "そのEメールアドレスは別のユーザーにより既に使用されています。",
        es: "Ya existe otro usuario con la misma dirección de correo electrónico.",
    },
    "Continue with Facebook": {
        pt: "Entrar com Facebook",
        ja: "Facebookで続ける",
        es: "Continuar con Facebook",
    },
    "or create an account with email": {
        pt: "ou crie um usuário com o seu e-mail",
        ja: "またはEメールでアカウントを作成",
        es: "o crear una cuenta con un correo electrónico",
    },
    "Already have an account?": {
        pt: "Já possiu um usuário?",
        ja: "既にアカウントをお持ちですか？",
        es: "¿Ya tienes una cuenta?",
    },
    "event status": {
        pt: "status do evento",
        ja: "イベントステータス",
        es: "estado del evento",
    },
    "Event settings": {
        pt: "Configurar evento",
        ja: "イベント設定",
        es: "Configuración del evento",
    },
    "Email athletes": {
        pt: "Notificar atletas",
        es: "Mensajear atletas",
        ja: "選手にメールを送信する",
    },
    "email_athletes_error_message": {
        en: () => <>You can email athletes once they have registered via Liveheats, or if importing entries, after <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360058880412-Linking-a-new-user-to-an-athlete-as-a-Director" underline="always" variant="inherit" target="_blank">linking emails to athlete profiles</MuiLink>.</>,
        pt: () => <>Você pode enviar e-mail para os atletas depois que eles se inscreverem pelo Liveheats, ou se importar inscrições, depois de <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360058880412-Linking-a-new-user-to-an-athlete-as-a-Director" underline="always" variant="inherit" target="_blank">vincular e-mails aos perfis dos atletas</MuiLink>.</>,
        es: () => <>Puedes enviar un correo electrónico a los atletas una vez que se hayan inscrito a través de Liveheats, o si importas inscripciones, después de <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360058880412-Linking-a-new-user-to-an-athlete-as-a-Director" underline="always" variant="inherit" target="_blank">vincular correos electrónicos a los perfiles de los atletas</MuiLink>.</>,
        ja: () => <>選手がLiveheats経由で登録した場合、またはエントリーをインポートした場合、<MuiLink href="https://support.liveheats.com/hc/en-us/articles/360058880412-Linking-a-new-user-to-an-athlete-as-a-Director" underline="always" variant="inherit" target="_blank">メールアドレスを選手のプロフィールにリンクした</MuiLink>後、選手にメールを送信することができます。</>,
    },
    "email_athletes_on_trial_notification": {
        en: () => <>Ready to message athletes? This feature is unlocked with paid event credits. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" underline="always" variant="inherit" target="_blank">Learn more</MuiLink>.</>,
        pt: () => <>Pronto para enviar mensagens aos atletas? Esta funcionalidade é desbloqueada com créditos de evento pagos. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" underline="always" variant="inherit" target="_blank">Saiba mais</MuiLink>.</>,
        es: () => <>¿Listo para enviar mensajes a los atletas? Esta función se desbloquea con créditos de evento pagados. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" underline="always" variant="inherit" target="_blank">Obtén más información</MuiLink>.</>,
        ja: () => <>選手にメッセージを送信する準備はできましたか？この機能は有料のイベントクレジットでロックを解除できます。<MuiLink href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" underline="always" variant="inherit" target="_blank">詳しくはこちら</MuiLink>。</>,
    },
    "Get event credits": {
        pt: "Obter créditos de evento",
        es: "Obtener créditos de evento",
        ja: "イベントクレジットを取得",
    },
    "To:": {
        pt: "Para:",
        es: "Para:",
        ja: "宛先：",
    },
    "From:": {
        pt: "De:",
        es: "De:",
        ja: "送信元：",
    },
    "Reply-to:": {
        pt: "Responder para:",
        es: "Responder a:",
        ja: "返信先：",
    },
    "x_recipients": {
        en: ({ x }) => `${x} recipient${plural(x)}`,
        pt: ({ x }) => `${x} destinatário${plural(x)}`,
        es: ({ x }) => `${x} destinatario${plural(x)}`,
        ja: ({ x }) => `${x} 名の受信者`,
    },
    "email_athletes_tooltip": {
        en: () => <>Email will be sent to all linked emails of athletes entered in this event, excluding cancelled entries. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" target="_blank">Learn more</MuiLink>.</>,
        pt: () => <>O e-mail será enviado para todos os e-mails vinculados aos atletas inscritos neste evento, excluindo as inscrições canceladas. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" target="_blank">Saiba mais</MuiLink>.</>,
        es: () => <>El correo electrónico se enviará a todos los correos electrónicos vinculados de los atletas inscritos en este evento, excluidas las inscripciones canceladas. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" target="_blank">Obtén más información</MuiLink>.</>,
        ja: () => <>メールは、このイベントにエントリーしている選手のリンクされたすべてのメールアドレスに送信されます。キャンセルされたエントリーも対象となります。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants" target="_blank">詳しくはこちら</MuiLink>。</>,
    },
    "Subject": {
        pt: "Assunto",
        es: "Asunto",
        ja: "件名",
    },
    "Message": {
        pt: "Mensagem",
        es: "Mensaje",
        ja: "メッセージ",
    },
    "Type your message...": {
        pt: "Digite sua mensagem...",
        es: "Escribe tu mensaje...",
        ja: "メッセージを入力...",
    },
    "Send message": {
        pt: "Enviar mensagem",
        es: "Enviar mensaje",
        ja: "メッセージを送る",
    },
    "Your message is on its way! ✉️": {
        pt: "Sua mensagem foi enviada! ✉️",
        es: "¡Tu mensaje está en camino! ✉️",
        ja: "メッセージを送信しました！✉️",
    },
    "What's the email about?": {
        pt: "Sobre o que é o e-mail?",
        es: "¿De qué se trata el correo electrónico?",
        ja: "何についてのメールですか？",
    },
    "All emails need some words!": {
        pt: "Todos os e-mails precisam de algumas palavras!",
        es: "¡Todos los correos electrónicos necesitan algunas palabras!",
        ja: "どんなメールにも言葉が必要です！",
    },
    "New message": {
        pt: "Nova mensagem",
        es: "Nuevo mensaje",
        ja: "新しいメッセージ",
    },
    "Sent": {
        pt: "Enviado",
        es: "Enviado",
        ja: "送信済み",
    },
    "email_athletes_sent_heading": {
        en: ({ value }) => `Sent (${value})`,
        pt: ({ value }) => `Enviado (${value})`,
        es: ({ value }) => `Enviado (${value})`,
        ja: ({ value }) => `送信済み (${value})`,
    },
    "Head judge": {
        pt: "Árbitro principal",
        ja: "ヘッドジャッジ",
        es: "Juez principal",
    },
    "Our carrier pigeons are sending your registrations to the organiser": {
        pt: "Nossos pombos correio estão enviando sua inscrição aos organizadores",
        es: "Nuestras palomas mensajeras están llevándole tus inscripciones al organizador",
        ja: "伝書鳩があなたの登録内容を主催者のもとに運んでいます",
    },
    "Woohoo!": {
        pt: "Genial!",
        ja: "最高!",
        es: "¡Genial!",
    },
    "registration_success_callout": {
        en: ({ name }) => `You're confirmed for ${name}.`,
        pt: ({ name }) => `Sua inscrição em ${name} está confirmada.`,
        ja: ({ name }) => `${name}への参加が確認されました。`,
        es: ({ name }) => `Tu inscripción en ${name} está confirmada.`,
    },
    "waitlisted_registration_callout": {
        en: ({ name }) => `You're on the waitlist for ${name}.`,
        pt: ({ name }) => `Você está na lista de espera para ${name}.`,
        es: ({ name }) => `Estás en la lista de espera para ${name}.`,
        ja: ({ name }) => `${name} のウェイティングリストに登録されました。`,
    },
    "You will receive an email shortly with your registration details.": {
        pt: "Você receberá em e-mail em breve com os detalhes da inscrição.",
        ja: "間もなく登録詳細が記載されたメールが送信されます。",
        es: "En breve recibirás un correo electrónico con los detalles de la inscripción.",
    },
    "If you are accepted, you will be notified by email.": {
        pt: "Se sua inscrição for aceita, te notificaremos por e-mail.",
        ja: "エントリーが承認された場合は、メールにてお知らせします。",
        es: "Si se acepta tu participación, te notificaremos por correo electrónico.",
    },
    "If you are accepted, your saved payment method will be charged and you will be notified by email.": {
        pt: "Se sua inscrição for aceita, seu cartão será cobrado e te notificaremos por e-mail.",
        ja: "エントリーが承認された場合は、登録済みの支払い方法で支払いが行われ、メールにてお知らせします。",
        es: "Si se acepta tu participación, se te cobrará el método de pago guardado y te notificaremos por correo electrónico.",
    },
    "View my dashboard": {
        pt: "Ver meu perfil",
        ja: "自分のダッシュボードを表示する",
        es: "Ver mi panel",
    },
    "View event": {
        pt: "Ver evento",
        ja: "イベントを表示する",
        es: "Ver evento",
    },
    "trouble_registering_contact_organiser": {
        en: ({ email }) => <>Need help with your registration? <a href={`mailto:${email}`}>Contact organiser</a></>,
        pt: ({ email }) => <>Precisa de ajuda com a inscrição? <a href={`mailto:${email}`}>Entre em contato</a></>,
        es: ({ email }) => <>¿Necesitas ayuda para inscribirte? <a href={`mailto:${email}`}>Comunícate con el organizador</a></>,
        ja: ({ email }) => <>登録でお困りですか？ <a href={`mailto:${email}`}>主催者に問い合わせる</a></>,
    },
    "sign_up_success_callout": {
        en: ({ name, shortName, series }) => <>Woohoo! You’re a member of <Link to={`/${shortName}`}>{name} {series}</Link>.</>,
        pt: ({ name, shortName, series }) => <>Parabéns! Você se associou à temporada {series} de <Link to={`/${shortName}`}>{name}</Link>.</>,
        ja: ({ name, shortName, series }) => <>最高！あなたは<Link to={`/${shortName}`}>{name} {series}</Link>の会員になりました。</>,
        es: ({ name, shortName, series }) => <>¡Genial! Eres miembro de <Link to={`/${shortName}`}>{series} de {name}</Link>.</>,
    },
    "Summary of your membership": {
        pt: "Resumo da sua associação",
        ja: "会員の概要",
        es: "Resumen de la membresía",
    },
    "You will receive an email shortly with your membership details.": {
        pt: "Você receberá em e-mail em breve com os detalhes da associação.",
        ja: "間もなく会員情報が記載されたメールが送信されます。",
        es: "En breve recibirás un correo electrónico con los detalles de la membresía.",
    },
    "TotalCell_winBy": {
        en: ({ winBy, heatFinished }) => winBy === 0 ? "Tie break win" : `${heatFinished ? "Won" : "Win"} by ${winBy}`,
        pt: ({ winBy, heatFinished }) => winBy === 0 ? "Desempate" : `${heatFinished ? "Venceu" : "Vencendo"} por ${winBy}`,
        es: ({ winBy, heatFinished }) => winBy === 0 ? "Victoria por desempate" : `${heatFinished ? "Gana" : "Ganando"} por ${winBy}`,
        ja: ({ winBy, heatFinished }) => winBy === 0 ? "タイブレークでの勝利" : `${winBy}${heatFinished ? "差で勝利" : "差で勝利"}`,
    },
    "TotalCell_needs": {
        en: ({ needs, heatFinished, maxRideScore }) => needs > maxRideScore ? `Comb. ${needs}` : `${heatFinished ? "Needed" : "Needs"} ${needs}`,
        pt: ({ needs, heatFinished, maxRideScore }) => needs > maxRideScore ? `Comb. ${needs}` : `${heatFinished ? "Precisava" : "Precisa"} de ${needs}`,
        es: ({ needs, heatFinished, maxRideScore }) => needs > maxRideScore ? `Comb. ${needs}` : `${heatFinished ? "Necesitaba" : "Necesita"} ${needs}`,
        ja: ({ needs, heatFinished, maxRideScore }) => needs > maxRideScore ? `コンボ${needs}` : `${needs}必要`,
    },
    "ride_title": {
        en: ({ number }) => `Ride ${number}`,
        pt: ({ number }) => `Nota ${number}`,
        es: ({ number }) => `Vuelta ${number}`,
        ja: ({ number }) => `ライド ${number}`,
    },
    "EditRideForm_title_pending": {
        en: ({ value }) => `Approve ride ${value}`,
        pt: ({ value }) => `Aprovar nota ${value}`,
        es: ({ value }) => `Aprobar vuelta ${value}`,
        ja: ({ value }) => `${value} ライドの承認`,
    },
    "EditRideForm_title_adding": {
        en: ({ value }) => `Add ride ${value}`,
        pt: ({ value }) => `Adicionar nota ${value}`,
        ja: ({ value }) => ` ${value}ライドの追加`,
        es: ({ value }) => `Agregar vuelta ${value}`,
    },
    "EditRideForm_title_editing": {
        en: ({ value }) => `Edit ride ${value}`,
        pt: ({ value }) => `Editar nota ${value}`,
        ja: ({ value }) => `${value}ライドの編集`,
        es: ({ value }) => `Editar vuelta ${value}`,
    },
    "Approve": {
        pt: "Aprovar",
        es: "Aprobar",
        ja: "承認",
    },
    "Score": {
        pt: "Nota",
        ja: "スコア",
        es: "Puntaje",
    },
    "Category": {
        pt: "Categoria",
        es: "Categoría",
        ja: "カテゴリー",
    },
    "Modifiers": {
        pt: "Modificadores",
        ja: "変更点",
        es: "Modificadores",
    },
    "None": {
        pt: "Nenhum",
        ja: "なし",
        es: "Ninguno",
    },
    "select series": {
        pt: "selecione a temporada",
        ja: "シリーズを選択",
        es: "seleccionar grupo",
    },
    "result_place": {
        en: ({ place }) => `${place}${ordinal(place)} place`,
        pt: ({ place }) => `${place}˚ lugar`,
        ja: ({ place }) => `${place} 位`,
        es: ({ place }) => `${place}˚ lugar`,
    },
    "Export rankings": {
        pt: "Exportar ranking",
        ja: "ランキングをエクスポート",
        es: "Exportar clasificaciones",
    },
    "Public rankings": {
        pt: "Ranking público",
        es: "Clasificaciones públicas",
        ja: "公開ランキング",
    },
    "Rank": {
        pt: "Lugar",
        ja: "ランク",
        es: "Clasif.",
    },
    "Total points": {
        pt: "Total de pontos",
        ja: "合計ポイント数",
        es: "Puntos totales",
    },
    "No rankings yet. You'll find the leaderboard here when event results are available.": {
        pt: "Ainda não existe ranking. Você verá os resultados aqui assim que disponíveis.",
        ja: "ランキングはまだありません。イベント結果が公開されるとここにリーダーボードが表示されます。",
        es: "Aún no hay clasificaciones. Aquí encontrarás la tabla de posiciones cuando estén disponibles los resultados del evento.",
    },
    "rankings_athelete_search_no_results": {
        en: ({ searchTerm, series, division }) => <>Sorry, we couldn't find <strong>{searchTerm}</strong> in {series} - {division}</>,
        pt: ({ searchTerm, series, division }) => <>Desculpe, não conseguimos encontrar <strong>{searchTerm}</strong> em {series} - {division}</>,
        es: ({ searchTerm, series, division }) => <>Lo sentimos, no pudimos encontrar <strong>{searchTerm}</strong> en {series} - {division}</>,
    },
    "Heads up, you have some unpublished results. Change the event status to \"results published\" to make them visible to the public.": {
        pt: "Atenção, você tem resultados não publicados. Modifique o status do evento para \"resultados publicados\" para que se tornem visíveis ao público.",
        ja: "未公開の結果があります。イベントステータスを「結果公開」に変更して、一般に公開されるようにします。",
        es: "¡Atención! No publicaste todos los resultados. Cambia el estado del evento a “Resultados publicados” para que el público pueda verlos.",
    },
    "Your search results": {
        pt: "Resultados da busca",
        ja: "検索結果",
        es: "Tus resultados de búsqueda",
    },
    "Sorry, we can't seem to find that organisation 😞": {
        pt: "Desculpe-nos, não conseguimos encontrar essa organização 😞",
        ja: "申し訳ありません。お探しの団体は見つかりませんでした 😞",
        es: "Lo sentimos. No podemos encontrar esa organización 😞",
    },
    "Clear your search to view all organisations": {
        pt: "Limpe o campo de busca pra ver todas as organizações",
        ja: "すべての団体を見るには検索をクリアします",
        es: "Borra la búsqueda para ver todas las organizaciones",
    },
    "Find by organisation": {
        pt: "Encontrar organização",
        ja: "団体で検索",
        es: "Buscar por organización",
    },
    "Add another score": {
        pt: "Adicionar outra nota",
        ja: "別のスコアを追加する",
        es: "Agregar otro puntaje",
    },
    "Required": {
        pt: "Obrigatório",
        ja: "必須",
        es: "Obligatorio",
    },
    "Not a number": {
        pt: "Não é um número",
        ja: "数値ではありません",
        es: "No es un número",
    },
    "Must be greater than 0": {
        pt: "Deve ser maior do que 0",
        es: "Debe ser mayor que 0",
        ja: "0より大きい必要があります",
    },
    "has already been taken": {
        en: "Sorry, this email address is already in use",
        pt: "Opa, este e-mail já foi utilizado",
        ja: "このEメール アドレスは既に使用されています",
        es: "Lo sentimos, la dirección de correo electrónico ya está en uso",
    },
    "Email is invalid": {
        pt: "E-mail inválido",
        ja: "Eメールが無効です",
        es: "El correo electrónico no es válido",
    },
    "Too short (minimum is 6 characters)": {
        pt: "é curta demais (pelo menos 6 caracteres)",
        ja: "短すぎます（最低必要文字数は6文字です）",
        es: "Demasiado corto (mínimo de 6 caracteres)",
    },
    "Max 30 character limit reached": {
        pt: "Limite máximo de 30 caracteres atingido",
        es: "Se alcanzó el límite máximo de 30 caracteres",
        ja: "最大30文字の制限に達しました",
    },
    "Is already in the heat": {
        pt: "Já está nesta bateria",
        ja: "すでにヒートに参加しています",
        es: "Ya está en la serie",
    },
    "Is already entered": {
        pt: "Já está na categoria",
        ja: "既に入力されています",
        es: "Ya se inscribió",
    },
    "Unable to save schedule, heats have changed before saving. The schedule has been reloaded.": {
        pt: "Não foi possível salvar a agenda, as baterias mudaram antes de salvar. A agenda foi recarregada.",
        ja: "保存前にヒートが変更されたためスケジュールの保存に失敗しました。スケジュールが再読み込みされました。",
        es: "No se pudo guardar el cronograma. Las series se modificaron antes de guardarse y se volvió a cargar el cronograma.",
    },
    "Unable to draw heats because it would create an infinite draw, please check each division settings.": {
        pt: "Não foi possível criar a chave porque seria criada uma chave infinita! Por favor verifique as configurações da categoria.",
        ja: "無限抽選が発生するためヒート抽選ができませんでした。各部門設定を確認してください。",
        es: "No se pudo sortear las series porque se crearía un sorteo infinito. Verifica la configuración de cada categoría.",
    },
    "Unable to add heat, next round already started.": {
        pt: "Não foi possível adicionar a bateria, o próximo round já foi iniciado.",
        ja: "次のラウンドが既に開始されているためヒートを追加できませんでした。",
        es: "No se pudo agregar la serie porque la próxima ronda ya comenzó.",
    },
    "Unable to delete round, the round has already started.": {
        pt: "Não foi possível deletar o round, ele já foi iniciado.",
        ja: "ラウンドは既に開始されているため削除できません。",
        es: "No se pudo eliminar la ronda porque ya comenzó.",
    },
    "Unable to create the event.": {
        pt: "Não foi possível criar o evento.",
        ja: "イベントを作成できませんでした。",
        es: "No se pudo crear el evento.",
    },
    "Unable to update the event.": {
        pt: "Não foi possível atualizar o evento.",
        ja: "イベントを更新できませんでした。",
        es: "No se pudo actualizar el evento.",
    },
    "Registrations for this event are already closed.": {
        pt: "Inscrições para este evento já estão fechadas",
        ja: "このイベントへの登録は既に終了しています。",
        es: "Las inscripciones en este evento ya cerraron.",
    },
    "The athlete is already registered for the division.": {
        pt: "O atleta já está inscrito na categoria.",
        ja: "この選手はすでにこの部門に登録されています。",
        es: "El atleta ya se registró en la categoría.",
    },
    "Please provide a name for the athlete.": {
        pt: "Por favor coloque o nome do atleta",
        ja: "選手名を入力してください。",
        es: "Ingresa el nombre del atleta.",
    },
    "The athlete is not allowed to enter this event.": {
        pt: "O atleta não pode se inscrever neste evento",
        ja: "選手はこのイベントに参加することはできません。",
        es: "El atleta no tiene permitido participar en el evento.",
    },
    "We're sorry! Since you started, one or more of your divisions has filled up. Please review your divisions and try again.": {
        pt: "Foi mal! Desde que você começou sua inscrição, algumas categorias ficaram cheias. Por favor revise suas categorias e tente novamente.",
        es: "Lo sentimos. Desde que comenzaste, una o más de las categorías se completaron. Revisa tus categorías y vuelve a intentarlo.",
        ja: "申し訳ございません！開始後に1つ以上の部門が埋まってしまいました。部門を見直して、もう一度やり直してください。",
    },
    "Payment authorisation failed. Please try again.": {
        pt: "A autorização do seu pagamento falhou. Por favor tente novamente.",
        es: "Se produjo un error al autorizar el pago. Vuelve a intentarlo.",
        ja: "お支払いの認証に失敗しました。もう一度やり直してください。",
    },
    "Membership sign up is closed.": {
        pt: "Associação está fechada.",
        ja: "会員登録は締め切られました。",
        es: "El registro en la membresía está cerrado.",
    },
    "The athlete is already a member.": {
        pt: "O atleta já é sócio.",
        ja: "この選手は既に会員登録されています。",
        es: "El atleta ya es miembro.",
    },
    "Please share your e-mail to login with Facebook.": {
        pt: "Por favor compartilhe seu e-mail para continuar com o Facebook.",
        ja: "Facebookでログインするには、Eメールアドレスを入力してください。",
        es: "Ingresa tu correo electrónico para iniciar sesión con Facebook.",
    },
    "Uh oh. We could not process your payment with that card, please try again.": {
        pt: "Não foi possível completar o seu pagamento com este cartão, por favor tente novamente.",
        ja: "残念。このカードではお支払いを処理できませんでした。再度お試しください。",
        es: "No pudimos procesar el pago con esa tarjeta. Vuelve a intentarlo.",
    },
    "Internal Server Error": {
        en: "Oops, something went wrong!",
        pt: "Ops, deu algo errado!",
        ja: "おっと。何かが間違っているようです！",
        es: "Ups. Se produjo un error.",
    },
    "Uh oh. Our wires got crossed and there was a problem redirecting you, please try again later.": {
        pt: "Ops, houve um problema no redirecionamento. Por favor tente novamente.",
        es: "Se produjo un cortocircuito y no pudimos redirigirte. Vuelve a intentarlo más tarde.",
        ja: "残念！リダイレクトに問題が発生しました。後ほど、もう一度お試しください。",
    },
    "TermsAndConditions_LocalizedComponent": {
        en: <TermsAndConditionsWordsEN/>,
        es: <TermsAndConditionsWordsEN/>,
        ja: <TermsAndConditionsWordsEN/>,
    },
    "PrivacyPolicy_LocalizedComponent": {
        en: <PrivacyPolicyWordsEN/>,
        es: <PrivacyPolicyWordsEN/>,
        ja: <PrivacyPolicyWordsEN/>,
    },
    "Welcome to the Series area. Create a series to manage your rankings and memberships for a series of events - for example \"2021 Pro Tour\"": {
        pt: "Esta é a área das temporadas. Crie uma nova temporada para gerenciar rankings e membros em uma temporada de eventos - por exemplo \"Temporada Pro 2021\"",
        es: "Te damos la bienvenida a la sección de los grupos. Crea un grupo para gestionar las clasificaciones y las membresías de un conjunto de eventos, por ejemplo, “2021 Pro Tour”.",
        ja: "「シリーズ」エリアへようこそ。シリーズ（例えば「2021年プロツアー」）を作成してランキングや会員を管理しましょう",
    },
    "Series settings": {
        pt: "Configurar temporada",
        ja: "シリーズ設定",
        es: "Configuración del grupo",
    },
    "Create new series": {
        pt: "Criar nova temporada",
        ja: "新しいシリーズの作成",
        es: "Crear grupo nuevo",
    },
    "Series name:": {
        pt: "Nome da temporada:",
        ja: "シリーズ名:",
        es: "Nombre del grupo:",
    },
    "Series type:": {
        pt: "Tipo de temporada:",
        ja: "シリーズタイプ:",
        es: "Tipo de grupo:",
    },
    "First event seeding comes from": {
        pt: "Posicionamento no primeiro evento vem de",
        ja: "最初のイベントシードは次から行われます",
        es: "La asignación de cabezas de serie del primer evento viene de",
    },
    "The first event of the series will be seeded using the rankings from this previous series.": {
        pt: "O primeiro evento da temporada posicionará os atletas utilizando o ranking desta temporada anterior.",
        ja: "シリーズの最初のイベントは、この前回シリーズのランキングを使用してシードされます。",
        es: "La asignación de cabezas de serie del primer evento del grupo se hará en base a las clasificaciones del grupo anterior.",
    },
    "None - first event unseeded": {
        pt: "Nenhuma - primeiro evento sem ranking",
        ja: "なし - 最初のイベントのシードは無し",
        es: "Ninguna; el primer evento no tiene asignada ninguna cabeza de serie",
    },
    "division_add_division": {
        en: ({ division }) => `Add '${division}' as a new division`,
        pt: ({ division }) => `Adicionar '${division}' como uma nova categoria`,
        es: ({ division }) => `Agregar '${division}' como una categoría nueva`,
        ja: ({ division }) => `新しい部門として '${division}' を追加する`,
    },
    "Number of counting results": {
        pt: "Número de resultados que contam",
        ja: "カウント結果数",
        es: "Cantidad de resultados computados",
    },
    "setToTopResultsLabel": {
        en: ({ value }) => `Set to counting ${value} results`,
        pt: ({ value }) => `Configurar para contar ${value} resultados`,
        es: ({ value }) => `Establecer el recuento de resultados en ${value}`,
        ja: ({ value }) => `${value} 結果をカウントするように設定する`,
    },
    "The number of top results that determine the rankings. For example, top 6 results of 8 events.": {
        pt: "O número de resultados que determinam o ranking. Por examplo, os melhores 6 resultados de 8 eventos.",
        ja: "ランキングを決定する上位結果数。例：8イベントの上位6結果。",
        es: "La media de resultados principales que determinan las clasificaciones. Por ejemplo, los mejores 6 resultados de 8 eventos.",
    },
    "Standardise for all divisions": {
        pt: "Mesmo pra todas as categorias",
        es: "Estandarizar para todas las categorías",
        ja: "すべての部門で標準化",
    },
    "Set per division": {
        pt: "Customizar por categoria",
        es: "Establecer por categoría",
        ja: "部門ごとに設定",
    },
    "All results": {
        pt: "Todos os resultados",
        ja: "すべての結果",
        es: "Todos los resultados",
    },
    "You're an icon! Your new series is now set up and ready for events💃": {
        pt: "Muito bem! Sua nova temporada está configurada e pronta para eventos💃",
        ja: "素晴らしい！新しいシリーズが正しく設定されイベントの準備が整いました💃",
        es: "¡Eres lo máximo! Tu grupo nuevo ya está configurado y listo para los eventos 💃",
    },
    "Love your work. Your series settings have been updated.": {
        pt: "Bom trabalho. As configurações da temporada foram salvas.",
        ja: "素晴らしい！シリーズ設定が更新されました。",
        es: "Excelente trabajo. Se actualizó la configuración del grupo.",
    },
    "Restrict event registration to members?": {
        pt: "Restringir inscrição de eventos somente para associados?",
        ja: "イベントへの参加登録を会員に限定しますか？",
        es: "¿Deseas restringir la inscripción en el evento a los miembros?",
    },
    "If yes, only members with a current membership will be able to register for events in this series.": {
        pt: "Se sim, somente associados atuais poderão inscrever-se nos eventos desta temporada.",
        ja: "[はい] の場合、有効な会員権を持つ会員のみがこのシリーズのイベントに登録できます。",
        es: "Si lo haces, solo los miembros que tienen una membresía actual podrán inscribirse en los eventos de este grupo.",
    },
    "Divisions in this series are managed by your parent organisation": {
        pt: "Categorias nesta temporada são controladas pela federação",
        es: "La organización matriz gestiona las categorías de este grupo",
        ja: "親団体がこのシリーズの部門を管理します",
    },
    "Add member": {
        pt: "Adicionar sócio",
        ja: "メンバーの追加",
        es: "Agregar miembro",
    },
    "Hooray! New member successfully added to this series.": {
        pt: "Boa! Novo sócio adicionado a esta temporada.",
        ja: "素晴らしい！このシリーズに新しいメンバーが追加されました。",
        es: "¡Hurra! El miembro nuevo se agregó correctamente a este grupo.",
    },
    "Nice edits! The member's details have been updated.": {
        pt: "Boa edição! Os detalhes do sócio foram alterados.",
        ja: "素晴らしい！メンバーの詳細が更新されました。",
        es: "¡Lindo trabajo! La información del miembro se actualizó correctamente.",
    },
    "Goodbyes are tough. Member has been removed from the series 😔": {
        pt: "É dificil dizer tchau. O sócio foi removido da temporada 😔",
        es: "Las despedidas son difíciles. Se eliminó al miembro del grupo 😔",
        ja: "お別れはつらいです。メンバーがこのシリーズから削除されました😔",
    },
    "Member details": {
        pt: "Detalhes do sócio",
        ja: "メンバー詳細",
        es: "Información del miembro",
    },
    "Member name:": {
        pt: "Nome do sócio",
        ja: "メンバー名：",
        es: "Nombre del miembro:",
    },
    "Date of birth (optional):": {
        pt: "Data da nascimento (opcional):",
        ja: "生年月日（任意）：",
        es: "Fecha de nacimiento (opcional):",
    },
    "Membership expiry:": {
        pt: "Data de vencimento:",
        es: "Vencimiento de la membresía:",
        ja: "会員期限：",
    },
    "After this date, the membership will expire and the member will need to renew to enter events. You can leave this blank for life members.": {
        pt: "Após esta data, a associação estará vencida e o sócio precisará renovar para se inscrever em eventos. Você pode deixar este campo vazio para sócios vitalícios.",
        es: "Después de esta fecha, la membresía vencerá y el miembro tendrá que renovarla para participar en los eventos. Puedes dejar este espacio en blanco para los miembros vitalicios.",
        ja: "この日を過ぎるとメンバーシップの期限が切れ、イベントに参加するには更新が必要となります。永久会員の場合は空欄のままでもかまいません。",
    },
    "Divisions:": {
        pt: "Categorias:",
        ja: "部門：",
        es: "Categorías:",
    },
    "Rankings and membership": {
        pt: "Rankings e associação",
        ja: "ランキングと会員",
        es: "Clasificaciones y membresía",
    },
    "Membership only": {
        pt: "Apenas associação",
        ja: "会員のみ",
        es: "Solo membresía",
    },
    "Rankings only": {
        pt: "Apenas rankings",
        ja: "ランキングのみ",
        es: "Solo clasificaciones",
    },
    "Select one membership option or change series type": {
        pt: "Selecione uma opção de associação ou mude o tipo de temporada",
        ja: "会員オプションを1つ選択するか、シリーズタイプを変更してください",
        es: "Selecciona una alternativa de membresía o cambia el tipo de grupo",
    },
    "Select one division or change series type": {
        pt: "Selecione uma categoria ou mude o tipo de temporada",
        ja: "部門を1つ選択するか、シリーズタイプを変更してください",
        es: "Selecciona una categoría o cambia el tipo de grupo",
    },
    "Membership options:": {
        pt: "Opções de associação:",
        es: "Alternativas de membresía:",
        ja: "会員オプション：",
    },
    "Divisions and membership options:": {
        pt: "Categorias e opções de associação:",
        es: "Categorías y alternativas de membresía:",
        ja: "部門と会員オプション：",
    },
    "Please select the series type": {
        pt: "Por favor selecione o tipo de temporada",
        es: "Selecciona el tipo de grupo",
        ja: "シリーズタイプを選択してください",
    },
    "Customise": {
        pt: "Customizar",
        es: "Personalizar",
    },
    "Used for": {
        pt: "Usado para",
        es: "Usado para",
    },
    "Member belongs to this series:": {
        pt: "Sócio pertence a esta temporada:",
        ja: "メンバーは次のシリーズに所属しています：",
        es: "El miembro pertenece a este grupo:",
    },
    "All - life member": {
        pt: "Todas - sócio vitalício",
        ja: "全て - 永久会員",
        es: "Todas (miembro vitalicio)",
    },
    "All - rolling member": {
        pt: "Todas - sócio periódico",
        es: "Todas (miembro renovable)",
        ja: "すべて - ローリング会員",
    },
    "Is this person a life member?": {
        pt: "Este sócio é vitalício?",
        ja: "この人は永久会員ですか？",
        es: "¿Esta persona es un miembro vitalicio?",
    },
    "If yes, they will be automatically added as a member in all series' in your organisation.": {
        pt: "Se sim, esta pessoa será automaticamente adicionada a todas suas temporadas.",
        ja: "「はい」の場合、あなたの団体の全てのシリーズにメンバーとして自動的に追加されます。",
        es: "De ser así, se agregará automáticamente como miembro en todos los grupos de tu organización.",
    },
    "Your details": {
        pt: "Seus dados",
        es: "Tu información",
        ja: "あなたの詳細情報",
    },
    "Email address:": {
        pt: "Endereço de email:",
        ja: "Eメール：",
        es: "Dirección de correo electrónico:",
    },
    "Contact number (optional):": {
        pt: "Número para contato (opcional):",
        ja: "連絡先電話番号（任意）：",
        es: "Número de contacto (opcional)",
    },
    "Contact name:": {
        pt: "Nome do contato:",
        ja: "連絡先氏名",
        es: "Nombre de contacto:",
    },
    "This is the contact name for the member. If an adult, you should use the member name. If a child, use the parent name.": {
        pt: "Este é o nome do contato do sócio. No caso de um adulto, utilize o nome do sócio. No caso de uma criança, o nome dos pais.",
        ja: "これは会員の連絡先名です。会員が成人の場合は本人の名前、未成年の場合は保護者の名前をお使いください。",
        es: "Se trata del nombre de contacto del miembro. Si es una persona adulta, debes usar el nombre del miembro. Si es un menor de edad, usa el nombre de su padre o madre.",
    },
    "Where the cash at 💸? Payments are not yet activated on your account. You can activate payments if you operate in a country supported by our payment gateway partner Stripe.": {
        pt: "Cadê a grana 💸? Pagamentos ainda não estão habilitados na sua conta. Você pode ativar pagamentos se você opera num país vinculado ao Stripe, nosso gateway de pagamentos.",
        es: "¿Dónde está el dinero? 💸 Aún no se activaron los pagos en tu cuenta. Puedes activarlos si desarrollas tus actividades comerciales en un país en el que se acepte Stripe, nuestra plataforma de pago asociada.",
        ja: "資金はどちらにありますか💸？あなたのアカウントでは、まだ支払いが有効になっていません。決済ゲートウェイパートナーであるStripeがサポートする国で活動している場合は、支払いを有効にすることができます。",
    },
    "Stripe settings": {
        pt: "Configurações do Stripe",
        es: "Configuración de Stripe",
        ja: "Stripe設定",
    },
    "Date": {
        pt: "Data",
        es: "Fecha",
        ja: "日付",
    },
    "Item": {
        es: "Elemento",
        ja: "項目",
    },
    "Amount": {
        pt: "Valor",
        es: "Monto",
        ja: "金額",
    },
    "connected": {
        pt: "conectado",
        es: "conectada",
        ja: "連携されました",
    },
    "rejected": {
        pt: "rejeitado",
        es: "rechazada",
        ja: "却下されました",
    },
    "restricted": {
        pt: "restrito",
        es: "restringida",
        ja: "制限されました",
    },
    "restricted_soon": {
        en: "restricted soon",
        pt: "restrito em breve",
        es: "restringida en breve",
        ja: "すぐに制限されます",
    },
    "pending": {
        pt: "pendente",
        es: "pendiente",
        ja: "保留中",
    },
    "Your bank account is connected and you're all set to accept payments!": {
        pt: "Sua conta bancária está conectada e você já pode recever pagamentos!",
        es: "Tu cuenta bancaria está conectada y ya puedes aceptar pagos.",
        ja: "あなたの銀行口座は連携され、支払いを受け取るための準備がすべて整いました！",
    },
    "Your account is connected, but payments are restricted until you provide some more information. You can do this in your Stripe settings.": {
        pt: "Sua conta está conectada, mas pagamentos estão desabilitados até você fornecer mais informações. Você pode fazer isso pelas Configurações do Stripe.",
        es: "Tu cuenta está conectada, pero los pagos están restringidos hasta que brindes algo más de información. Puedes hacerlo en la configuración de Stripe.",
        ja: "アカウントは連携されていますが、他の情報を提供するまで支払いは制限されます。情報の提供はStripeの設定で行うことができます。",
    },
    "Your account is connected, but you'll need to provide some more information soon or your account will become restricted. You can do this in your Stripe settings.": {
        pt: "Sua conta está conectada, mas você vai precisar fornecer mais informações em breve ou então pagamentos serão desabilitados. Você pode fazer isso pelas Configurações do Stripe.",
        es: "Tu cuenta está conectada, pero pronto tendrás que brindar algo más de información, de lo contrario, la cuenta quedará restringida. Puedes hacerlo en la configuración de Stripe.",
        ja: "アカウントは連携されていますが、すぐに他の情報を提供する必要があります。提供しなかった場合、アカウントは制限されます。情報の提供はStripeの設定で行うことができます。",
    },
    "We're sorry. Stripe rejected your account, so you'll need to activate payments again.": {
        pt: "Foi mal. O Stripe rejeitou a sua conta. Você vai precisar ativar pagamentos outra vez.",
        es: "Lo sentimos. Stripe rechazó tu cuenta. Tendrás que activar los pagos otra vez.",
        ja: "申し訳ございません。Stripeによってアカウントが却下されました。もう一度支払いを有効にする必要があります。",
    },
    "Hold tight. Stripe is just checking a few bits and pieces before they connect your account. Check back in a few minutes.": {
        pt: "Só mais um pouquinho. O stripe está verificando seus detalhes antes de finalizar a sua conta. Verifique novamente daqui alguns minutos.",
        es: "¡Espera! Stripe está comprobando algunos detalles antes de conectar tu cuenta. Vuelve a ingresar en unos minutos.",
        ja: "少々お待ちください。Stripeはあなたのアカウントを連携する前に、いくつかの点をチェックしています。数分後にもう一度ご確認ください。",
    },
    "Stripe connected, you can now collect payments.": {
        pt: "Stripe conectado, você já pode receber pagamentos.",
        es: "Stripe conectado, ahora puedes recibir pagos.",
        ja: "Stripeが接続されました。これで支払いを受け取ることができます。",
    },
    "paid": {
        pt: "pago",
        es: "pagado",
        ja: "支払い済み",
    },
    "refunded": {
        pt: "reembolsado",
        es: "reembolsado",
        ja: "払い戻し済み",
    },
    "partially refunded": {
        pt: "reembolsado parcialmente",
        es: "reembolsado parcialmente",
        ja: "一部払い戻し済み",
    },
    "broadcast_event_complete": {
        en: ({ eventName }) => `That's a wrap! ${eventName} is complete!`,
        pt: ({ eventName }) => `É isso! ${eventName} está finalizado!`,
        es: ({ eventName }) => `¡Listo! ${eventName} está completo.`,
        ja: ({ eventName }) => `以上です！${eventName} は完成です！`,
    },
    "broadcast_event_upcoming": {
        en: ({ eventName }) => `Hang tight! ${eventName} is coming soon!`,
        pt: ({ eventName }) => `Só um momento! ${eventName} vai começar em breve!`,
        es: ({ eventName }) => `¡Sé paciente! ${eventName} arranca pronto.`,
        ja: ({ eventName }) => `お待ちください！${eventName} はもうすぐです！`,
    },
    "You nailed it! This event is now complete.": {
        pt: "Muito bem! Este evento acabou.",
        es: "¡Lo lograste! Este evento ya está completo.",
        ja: "がんばりました！これでこのイベントは完成です。",
    },
    "Thanks for stopping by. There's no live heats running at the moment.": {
        pt: "Obrigado pela visita. Ainda não tem nenhuma bateria.",
        es: "Gracias por visitarnos. En este momento no hay series en directo.",
        ja: "お立ち寄りいただきありがとうございます。現在、開催中のライブヒートはありません。",
    },
    "Leaderboard": {
        pt: "Ranking do round",
        es: "Tabla de posiciones",
        ja: "リーダーボード",
    },
    "Runs": {
        pt: "Voltas",
        es: "Tandas",
        ja: "ラン",
    },
    "Rides": {
        pt: "Notas",
        es: "Vueltas",
        ja: "ライド",
    },
    "Laps": {
        pt: "Splits",
        es: "Splits",
        ja: "スプリット",
    },
    "scores_scores": {
        en: ({ scores }) => `Scores ${scores.join(" + ")}`,
        pt: ({ scores }) => `Notas ${scores.join(" + ")}`,
        es: ({ scores }) => `Puntuaciones ${scores.join(" + ")}`,
        ja: ({ scores }) => `スコア ${scores.join(" + ")}`,
    },
    "waves_scores": {
        en: ({ scores }) => `Waves ${scores.join(" + ")}`,
        pt: ({ scores }) => `Ondas ${scores.join(" + ")}`,
        es: ({ scores }) => `Olas ${scores.join(" + ")}`,
        ja: ({ scores }) => `ウェーブ ${scores.join(" + ")}`,
    },
    "Start list": {
        pt: "Lista de saída",
        es: "Lista de participantes",
        ja: "スタートリスト",
    },
    "round_leaderboard_title": {
        en: ({ round }) => `${round} leaderboard`,
        pt: ({ round }) =>  `Ranking: ${round}`,
        es: ({ round }) => `Tabla de posiciones de la ronda ${round}`,
        ja: ({ round }) => `${round} リーダーボード`,
    },
    "Commenced": {
        pt: "Iniciado",
        es: "Iniciada",
        ja: "開始済み",
    },
    "heat_number": {
        en: ({ number }) => `Heat ${number}`,
        pt: ({ number }) =>  `Bateria ${number}`,
        es: ({ number }) => `Serie ${number}`,
        ja: ({ number }) => `ヒート ${number}`,
    },
    "run_number": {
        en: ({ number }) => `Run ${number}`,
        pt: ({ number }) =>  `Volta ${number}`,
        es: ({ number }) => `Tanda ${number}`,
        ja: ({ number }) => `ラン ${number}`,
    },
    "Now": {
        pt: "Agora",
        es: "Ahora",
        ja: "今",
    },
    "live now": {
        pt: "ao vivo",
        es: "en directo ahora",
        ja: "ライブ中",
    },
    "heat_progression": {
        en: ({ progression: { position, round, roundOnly, heat, run } }) => <>{position + 1}<sup>{ordinal(position + 1)}</sup> from {round}{!roundOnly &&<> {run ? "Run" : "Heat"} {heat + 1}</>}</>,
        pt: ({ progression: { position, round, roundOnly, heat, run } }) => <>{position + 1}<sup>o</sup> - {round}{!roundOnly &&<> {run ? "Volta" : "Bateria"} {heat + 1}</>}</>,
        es: ({ progression: { position, round, roundOnly, heat, run } }) => <>{position + 1}<sup>o</sup> - {round}{!roundOnly &&<> {run ? "Vuelta" : "Serie"} {heat + 1}</>}</>,
        ja: ({ progression: { position, round, roundOnly, heat, run } }) => <>{round}{!roundOnly &&<> {run ? "Run" : "Heat"} {heat + 1}</>} の {position + 1}<sup>{ordinal(position + 1)}</sup></>,
    },
    "heat_progression_obscure": {
        en: ({ progression, isTeams }) => `${isTeams ? "Team" : "Athlete"} from ${progression.round}`,
        pt: ({ progression, isTeams }) => `${isTeams ? "Time" : "Atleta"} de ${progression.round}`,
        es: ({ progression, isTeams }) => `${isTeams ? "Equipo" : "Atleta"} de ${progression.round}`,
        ja: ({ progression, isTeams }) => `${progression.round}の${isTeams ? "チーム" : "選手"}`,
    },
    "There's no draw for this division yet. Check back soon.": {
        pt: "Ainda não há chaves para esta categoria. Volte em breve.",
        es: "Esta categoría aún no tiene series. Vuelve pronto.",
        ja: "この部門の抽選はまだ行われていません。後ほどもう一度チェックしてください。",
    },
    "View details": {
        pt: "Ver detalhes",
        es: "Ver detalles",
        ja: "詳細を見る",
    },
    "Heats": {
        pt: "Baterias",
        es: "Series",
        ja: "ヒート",
    },
    "start_order_header": {
        en: "Start",
        pt: "Pos.",
        es: "Iniciar",
        ja: "スタート",
    },
    "Lane": {
        pt: "Raia",
        es: "Carril",
        ja: "レーン",
    },
    "Athlete lineup is coming soon, stay tuned.": {
        pt: "Os atletas estarão aqui em breve, fique ligado.",
        es: "Pronto publicaremos la lista de atletas. ¡No te vayas!",
        ja: "選手のラインナップは近日公開予定。",
    },
    "You can view the leaderboard here once the round is underway.": {
        pt: "Você poderá ver o ranking do round aqui assim que o round começar.",
        es: "Aquí puedes ver la tabla de posiciones cuando la ronda haya comenzado.",
        ja: "ラウンドが始まると、ここでリーダーボードを見ることができます。",
    },
    "Part of ": {
        pt: "Parte de ",
        es: "Parte de ",
    },
    "Find competitors or divisions": {
        pt: "Buscar por competidores ou categorias",
        es: "Buscar competidores o categorías",
        ja: "選手または部門を探す",
    },
    "Up next": {
        pt: "Em breve",
        es: "A continuación",
        ja: "予定",
    },
    "What's on": {
        pt: "Ao vivo",
        es: "Eventos actuales",
        ja: "開催中",
    },
    "View schedule": {
        pt: "Ver agenda",
        es: "Ver cronograma",
        ja: "スケジュールを見る",
    },
    "Divisions": {
        pt: "Categorias",
        es: "Categorías",
        ja: "部門",
    },
    "We can tell you're excited for this event! The schedule will be available here once created by the Director.": {
        pt: "Da pra ver que você ta na pilha desse evento! A agenda vai aparecer aqui depois que for criada.",
        es: "¡Se nota tu entusiasmo! Aquí podrás ver el cronograma del evento después de que el director lo cree.",
        ja: "皆さんがこのイベントを楽しみにしていることが伝わってきます！スケジュールはディレクターが作成次第、こちらでご覧いただけます。",
    },
    "This schedule is top secret material. The Event Director has opted to keep the schedule hidden for now.": {
        pt: "Essa agenda é ultra secreta. Os organizadores do evento optaram por esconder a agenda por enquanto.",
        es: "El cronograma es confidencial. El director del evento decidió ocultarlo por el momento.",
        ja: "このスケジュールは極秘事項です。イベントディレクターは今のところ、スケジュールを非表示にすることを選択しています。",
    },
    "The entries are top secret material. The Event Director has opted to keep the event entries hidden for now.": {
        pt: "As inscrições são ultra secretas. Os organizadores do evento optaram por esconder a lista por enquanto.",
        es: "Las inscripciones son confidenciales. El director del evento decidió ocultarlas por el momento.",
        ja: "エントリーは極秘事項です。イベントディレクターは今のところ、イベントのエントリーを非表示にすることを選択しています。",
    },
    "heat_duration": {
        en: ({ duration }) => `${duration} mins`,
        pt: ({ duration }) => `${duration} mins`,
        es: ({ duration }) => `${duration} minutos`,
        ja: ({ duration }) => `${duration} 分`,
    },
    "timezone_message": {
        en: ({ timezone }) => `Times are displayed in your device's time zone (GMT ${timezone})`,
        pt: ({ timezone }) => `A agenda mostra as horas no seu horário local (GMT ${timezone})`,
        es: ({ timezone }) => `La hora se muestra en el huso horario de tu dispositivo (GMT ${timezone})`,
        ja: ({ timezone }) => `時刻はお使いのデバイスのタイムゾーン（GMT ${timezone}) で表示されます`,
    },
    "Edit profile": {
        pt: "Editar perfil",
        es: "Editar perfil",
        ja: "プロフィールを編集する",
    },
    "Profile info": {
        pt: "Detalhes do perfil",
        es: "Información del perfil",
        ja: "プロフィール情報",
    },
    "Linked users": {
        pt: "Usuários conectados",
        es: "Usuarios vinculados",
        ja: "リンクされたユーザー",
    },
    "No linked uses": {
        pt: "Não há usuários conectados a este atleta",
        es: "No hay usuarios vinculados",
        ja: "リンクされたユーザーがいません",
    },
    "Link another user": {
        pt: "Conectar usuário",
        es: "Vincular otro usuario",
        ja: "別のユーザーをリンクする",
    },
    "Add user": {
        pt: "Adicionar usuário",
        es: "Agregar usuario",
        ja: "ユーザーを追加する",
    },
    "This will allow the user to manage this athlete on Liveheats": {
        pt: "O novo usuário poderá gerenciar este atleta",
        es: "Esto le permitirá al usuario gestionar este atleta en Liveheats",
        ja: "これにより、ユーザーはLiveheats上でこの選手を管理できるようになります",
    },
    "Full name": {
        pt: "Nome completo",
        ja: "氏名",
        es: "Nombre completo",
    },
    "Full name (first and last name)": {
        pt: "Nome completo (nome e sobrenome)",
        ja: "氏名（名と姓）",
        es: "Nombre completo (nombre y apellido)",
    },
    "Contact phone number (optional)": {
        pt: "Número para contato (opcional)",
        es: "Número de teléfono de contacto (opcional)",
        ja: "連絡先電話番号（任意）",
    },
    "(you)": {
        pt: "(você)",
        es: "(tú)",
        ja: "（あなた）",
    },
    "Good onya! Athlete profile successfully updated.": {
        pt: "Muito bem! O atleta foi editado.",
        es: "¡Excelente! El perfil del atleta se actualizó correctamente.",
        ja: "お疲れ様です！選手のプロフィールの更新に成功しました。",
    },
    "Upload image": {
        pt: "Mudar imagem",
        es: "Cargar imagen",
        ja: "画像をアップロードする",
    },
    "Upload profile image": {
        pt: "Mudar imagem do perfil",
        es: "Cargar imagen de perfil",
        ja: "プロフィール画像をアップロードする",
    },
    "Use different image": {
        pt: "Selectionar outra imagem",
        es: "Usar otra imagen",
        ja: "別の画像を使用する",
    },
    "Upload": {
        pt: "Salvar",
        es: "Cargar",
        ja: "アップロード",
    },
    "Personal settings": {
        pt: "Configurações pessoais",
        es: "Configuración personal",
        ja: "個人設定",
    },
    "Account info": {
        pt: "Informações",
        es: "Información de la cuenta",
        ja: "アカウント情報",
    },
    "Surely you have a name?": {
        pt: "Você tem um nome, não?",
        es: "Debes ingresar tu nombre",
        ja: "お名前をお持ちですよね？",
    },
    "Athletes": {
        pt: "Atletas",
        es: "Atletas",
        ja: "選手",
    },
    "Entries": {
        pt: "Inscrições",
        es: "Inscripciones",
        ja: "エントリー'",
    },
    "Export Profiles": {
        pt: "Exportar perfis",
        es: "Exportar perfiles",
        ja: "プロファイルのエクスポート",
    },
    "Power to you. Personal settings updated 👊": {
        pt: "Força. Suas modificações foram salvas 👊",
        es: "Te pasamos el mando. La configuración personal está actualizada 👊",
        ja: "よくできました。個人設定が更新されました👊",
    },
    "Email required": {
        pt: "E-mail é obrigatório",
        es: "El correo electrónico es obligatorio",
        ja: "Eメールは必須です",
    },
    "Try a complete email 😉": {
        pt: "Tente com um e-mail completo 😉",
        es: "Prueba con una dirección de correo electrónico completa 😉",
        ja: "完全なメールアドレスをお試しください😉",
    },
    "Pricing": {
        pt: "Preços",
        es: "Precios",
        ja: "価格設定",
    },
    "Entry fees": {
        pt: "Taxas de inscrição",
        es: "Tarifas de inscripción",
        ja: "エントリー料金",
    },
    "For organisations": {
        pt: "Para organizações",
        es: "Para organizaciones",
        ja: "団体の場合",
    },
    "New organiser account": {
        pt: "Nova conta de organizador",
        es: "Nueva cuenta de organizador",
        ja: "新しい主催者アカウント",
    },
    "credit_maximum_entries": {
        en: ({ maximumEntries }) => `Up to ${maximumEntries} entries`,
        pt: ({ maximumEntries }) => `Até ${maximumEntries} inscrições`,
        es: ({ maximumEntries }) => `Hasta ${maximumEntries} inscripciones`,
        ja: ({ maximumEntries }) => `最大 ${maximumEntries} エントリー`,
    },
    "x_credits": {
        en: ({ credits }) => `${credits} credit${plural(credits)}`,
        pt: ({ credits }) => `${credits} crédito${plural(credits)}`,
        es: ({ credits }) => `${credits} crédito${plural(credits)}`,
        ja: ({ credits }) => `${credits} クレジット`,
    },
    "Select event credits": {
        pt: "Créditos para eventos",
        es: "Seleccionar créditos para eventos",
        ja: "イベントクレジットを選択する",
    },
    "event_credits_explainer": {
        en: () => <>Event credits provide all features you need to create, manage, score, and rank events on Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360049931631-Purchasing-event-credits-on-LiveHeats" target="_blank">Learn more</MuiLink>.</>,
        pt: () => <>Um crédito disponibiliza tudo que você precisa para criar, gerenciar, e adicionar resultados e rankings ao seus eventos no Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360049931631-Purchasing-event-credits-on-LiveHeats" target="_blank">Saiba mais</MuiLink>.</>,
        es: () => <>Los créditos para eventos te ofrecen todas las funcionalidades necesarias para que crees, gestiones, puntúes y clasifiques eventos en Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360049931631-Purchasing-event-credits-on-LiveHeats" target="_blank">Obtén más información</MuiLink>.</>,
        ja: () => <>イベントクレジットがあれば、Liveheats でのイベントの作成、管理、スコア、ランキングに必要な機能をすべて利用できます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360049931631-Purchasing-event-credits-on-LiveHeats" target="_blank">詳しくはこちら</MuiLink>。</>,
    },
    "xs_event_purchase_title": {
        en: "Extra small event",
        pt: "Evento extra pequeno",
        es: "Evento extrachico",
        ja: "追加の小イベント",
    },
    "s_event_purchase_title": {
        en: "Small event",
        pt: "Evento pequeno",
        es: "Evento chico",
        ja: "小イベント",
    },
    "m_event_purchase_title": {
        en: "Medium event",
        pt: "Evento médio",
        es: "Evento mediano",
        ja: "中イベント",
    },
    "l_event_purchase_title": {
        en: "Large event",
        pt: "Evento grande",
        es: "Evento grande",
        ja: "大イベント",
    },
    "xl_event_purchase_title": {
        en: "Extra large event",
        pt: "Evento extra grande",
        es: "Evento extragrande",
        ja: "特大イベント",
    },
    "Event credits": {
        pt: "Créditos para eventos",
        es: "Créditos para eventos",
        ja: "イベントクレジット",
    },
    "/event": {
        pt: "/evento",
        es: "/evento",
        ja: "/event",
    },
    "x_off": {
        en: ({ x }) => `${x} OFF`,
        pt: ({ x }) => `${x} a menos`,
        es: ({ x }) => `${x} de descuento`,
        ja: ({ x }) => `${x} OFF`,
    },
    "Add-ons": {
        pt: "Adicionais",
        es: "Complementos",
        ja: "アドオン",
    },
    "Select optional add-ons": {
        pt: "Selecione adicionais opcionais",
        es: "Seleccionar complementos opcionales",
        ja: "オプションのアドオンを選択",
    },
    "add_ons_explainer": {
        en: () => <>Add-ons are totally optional, and provide a superior experience using Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank">Learn more</MuiLink>.</>,
        pt: () => <>Adicionais são totalmente opcionais, e criam uma experiência superior utilizando o Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank">Saiba mais</MuiLink>.</>,
        es: () => <>Los complementos son totalmente opcionales y ofrecen la mejor experiencia de uso de Liveheats. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank">Obtén más información</MuiLink>.</>,
        ja: () => <>アドオンは完全にオプションであり、Liveheatsの使用体験が向上します。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank">詳しくはこちら</MuiLink>。</>,
    },
    "add_on_custom_templates_purchase_title": {
        en: "Draw templates",
        pt: "Chaves customizadas",
        es: "Diseñar plantillas",
        ja: "抽選テンプレート",
    },
    "add_on_fis_export_purchase_title": {
        en: "FIS XML exports",
        pt: "FIS XML download",
        es: "Archivos de la FIS exportados en XML",
        ja: "FIS XMLエクスポート",
    },
    "add_on_priority_purchase_title": {
        en: "Priority management",
        pt: "Gerenciamento de prioridade",
        es: "Gestión de prioridades",
        ja: "優先管理",
    },
    "add_on_broadcast_purchase_title": {
        en: "Streaming graphics",
        pt: "Gráficos pra streaming",
        es: "Gráficos para transmisión en directo",
        ja: "ストリーミンググラフィックス",
    },
    "add_on_embed_purchase_title": {
        en: "Website embedding",
        pt: "Embutir no seu site",
        es: "Inserción en el sitio web",
        ja: "ウェブサイトへの埋め込み",
    },
    "add_on_whats_app_support_purchase_title": {
        en: "WhatsApp support",
        pt: "Suporte via WhatsApp",
        es: "Soporte de WhatsApp",
        ja: "WhatsAppサポート",
    },
    "add_on_document_signing_purchase_title": {
        en: "e-Signatures",
        pt: "Assinaturas eletrônicas",
        es: "Firmas electrónicas",
        ja: "電子署名",
    },
    "add_on_custom_templates_purchase_description": {
        en: () => <>Allows you to save, store and reuse custom progression and seeding templates. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/11039486816404-Saving-a-draw-template" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Permite que você possa salvar e reutilizar suas regras de seed e progressão nas suas chaves. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/11039486816404-Saving-a-draw-template" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Te permite guardar, almacenar y reutilizar plantillas personalizadas de progresión y cabezas de serie. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/11039486816404-Saving-a-draw-template" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>カスタム進出とシードテンプレートの保存、保管、再利用が可能になります。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/11039486816404-Saving-a-draw-template" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_fis_export_purchase_description": {
        en: () => <>Provides an XML file containing results in the FIS required format for easy submission. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360050021092-Exporting-results-to-XML-for-submission-to-FIS" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Disponibiliza um arquivo XML com os resultador no formato necessário para o FIS. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360050021092-Exporting-results-to-XML-for-submission-to-FIS" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Crea un archivo XML con los resultados en el formato exigido por la FIS para facilitar su presentación. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360050021092-Exporting-results-to-XML-for-submission-to-FIS" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>提出しやすいように、FISが要求する形式の結果を含むXMLファイルを提供します。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360050021092-Exporting-results-to-XML-for-submission-to-FIS" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_priority_purchase_description": {
        en: () => <>Priority judge can manage live priority which displays on streaming, live scores, and large format screen. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/7907256236564-Priority-management-with-LiveHeats" target="_blank">Learn more</MuiLink></>,
        pt: () => <>O árbitro de prioridade pode gerenciar a prioridade ao vivo, e prioridade é visível nos seus resultados, em gráficos para streaming, e em telas pros atletas. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/7907256236564-Priority-management-with-LiveHeats" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>El juez de prioridad puede gestionar en directo la prioridad que se mostrará en la transmisión, en los puntajes en directo y en la pantalla de formato grande. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/7907256236564-Priority-management-with-LiveHeats" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>優先ジャッジは、ストリーミング、ライブスコア、大画面で表示されるライブの優先順位を管理できます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/7907256236564-Priority-management-with-LiveHeats" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_broadcast_purchase_description": {
        en: () => <>Live scores displayed in real-time graphics for insertion into live stream or broadcast. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040281292-Integrating-live-scores-into-a-broadcast-or-live-stream" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Notas e resultados em um gráfico em tempo real que pode ser inserido em streaming ao vivo. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040281292-Integrating-live-scores-into-a-broadcast-or-live-stream" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Puntajes en directo que se muestran en los gráficos en tiempo real y se integrarán en las transmisiones en directo. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040281292-Integrating-live-scores-into-a-broadcast-or-live-stream" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>ライブスコアはリアルタイムのグラフィックスで表示され、ライブストリーミングやブロードキャストに挿入することができます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040281292-Integrating-live-scores-into-a-broadcast-or-live-stream" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_embed_purchase_description": {
        en: () => <>Have all scoring, results, draws, rankings and registration live inside website via iFrame. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360027930792-Embedding-LiveHeats-in-your-website" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Disponibilize resultados, chaves, rankings e inscrições dentro do seu próprio site via um iFrame. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360027930792-Embedding-LiveHeats-in-your-website" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Muestra todos los puntajes, resultados, sorteos, clasificaciones e inscripciones en directo en el sitio web a través de iFrame. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360027930792-Embedding-LiveHeats-in-your-website" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>iFrameを通じてウェブサイト内でスコア、結果、抽選、ランキング、登録をライブで配信できます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360027930792-Embedding-LiveHeats-in-your-website" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_whats_app_support_purchase_description": {
        en: () => <>Priority real-time support during events via WhatsApp with a faster response. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/12638571285396" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Suporte prioritário e em tempo real durante os seus eventos pelo WhatsApp. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/12638571285396" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Asistencia prioritaria en tiempo real durante los eventos a través de WhatsApp con una respuesta más rápida. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/12638571285396" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>WhatsAppの迅速な応答で、イベント中に優先的にリアルタイムサポート。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/12638571285396" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "add_on_document_signing_purchase_description": {
        en: () => <>Allows you to submit PDF documents that are e-signed by the athlete or legal guardian. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/27443702057492-How-to-activate-e-Signatures" target="_blank">Learn more</MuiLink></>,
        pt: () => <>Permite enviar documentos PDF assinados pelo atleta ou responsável legal. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/27443702057492-How-to-activate-e-Signatures" target="_blank">Saiba mais</MuiLink></>,
        es: () => <>Le permite enviar documentos PDF firmados electrónicamente por el atleta o el tutor legal. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/27443702057492-How-to-activate-e-Signatures" target="_blank">Obtén más información</MuiLink></>,
        ja: () => <>選手または法的保護者が電子署名したPDFドキュメントを提出できます。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/27443702057492-How-to-activate-e-Signatures" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "Skip": {
        pt: "Pular",
        es: "Omitir",
        ja: "スキップ",
    },
    "x_event": {
        en: ({ x }) => `${x} event${plural(x)}`,
        pt: ({ x }) => `${x} evento${plural(x)}`,
        es: ({ x }) => `${x} evento${plural(x)}`,
        ja: ({ x }) => `${x} イベント`,
    },
    "all_x_events": {
        en: ({ x }) => x > 1 ? `(all ${x} events)` : `(${x} event)`,
        pt: ({ x }) => `(${x} evento${plural(x)})`,
        es: ({ x }) => x > 1 ? `(todos los ${x} eventos)` : `(${x} evento)`,
        ja: ({ x }) => x > 1 ? `(すべての ${x} イベント)` : `(${x} イベント)`,
    },
    "total_for_all_x_events": {
        en: ({ x }) => `total for ${x} event${plural(x)}`,
        pt: ({ x }) => `total para ${x} evento${plural(x)}`,
        es: ({ x }) => `total por ${x} evento${plural(x)}`,
        ja: ({ x }) => `${x} イベント の合計`,
    },
    "keep_add_on_warning": {
        en: ({ onClick }) => <>Currently active. To keep this add-on, <MuiLink underline="always" variant="inherit" component="span" onClick={onClick}>add it now</MuiLink></>,
        pt: ({ onClick }) => <>Ativo no momento. Para manter este adicional, <MuiLink underline="always" variant="inherit" component="span" onClick={onClick}>clique aqui</MuiLink></>,
        es: ({ onClick }) => <>Está activo en este momento. Para conservar este complemento, <MuiLink underline="always" variant="inherit" component="span" onClick={onClick}>agrégalo ahora</MuiLink></>,
        ja: ({ onClick }) => <>現在アクティブです。このアドオンを維持するには、<MuiLink underline="always" variant="inherit" component="span" onClick={onClick}>今すぐ追加</MuiLink>してください</>,
    },
    "🎉 Add 2+ credits for better rates": {
        pt: "🎉 Compre 2+ créditos e ganhe descontos",
        es: "🎉 Agrega 2+ créditos para obtener mejores precios",
        ja: "🎉 より良いレートのために2クレジット以上を追加する",
    },
    "bulk_discount": {
        en: ({ percentage }) => `🎉 Bulk discount ${percentage}%`,
        pt: ({ percentage }) => `🎉 Desconto aplicado ${percentage}%`,
        es: ({ percentage }) => `🎉 Descuento por cantidad del ${percentage} %`,
        ja: ({ percentage }) => `🎉 一括割引 ${percentage}%`,
    },
    "discount_savings": {
        en: ({ saved, currency }) => `Saving ${formatPrice(saved, currency)}`,
        pt: ({ saved, currency }) => `${formatPrice(saved, currency)} economizados`,
        es: ({ saved, currency }) => `Guardando ${formatPrice(saved, currency)}`,
        ja: ({ saved, currency }) => `${formatPrice(saved, currency)}割引`,
    },
    "contact_team_for_purchase": {
        en: () => <>Any questions or concerns? Contact <MuiLink underline="always" variant="inherit" href="mailto:sales@liveheats.com" target="_blank">sales@liveheats.com</MuiLink></>,
        pt: () => <>Tem alguma dúvida? Entre em contato com <MuiLink underline="always" variant="inherit" href="mailto:sales@liveheats.com" target="_blank">sales@liveheats.com</MuiLink></>,
        es: () => <>¿Tienes alguna pregunta o inquietud? Envía un correo electrónico a <MuiLink underline="always" variant="inherit" href="mailto:sales@liveheats.com" target="_blank">sales@liveheats.com</MuiLink></>,
        ja: () => <>質問や気になることはございますか？<MuiLink underline="always" variant="inherit" href="mailto:sales@liveheats.com" target="_blank">sales@liveheats.com</MuiLink>までお問い合わせください</>,
    },
    "Purchase successful - thank you for using Liveheats!": {
        pt: "Compra efetuada com sucesso - obrigado por utilizar o Liveheats!",
        es: "La compra se realizó correctamente. ¡Gracias por usar Liveheats!",
        ja: "購入に成功しました。Liveheatsをご利用いただき、ありがとうございます！",
    },
    "We’ll shoot you a payment confirmation on email. Get started creating events with Liveheats now.": {
        pt: "Te enviamos um e-mail de confirmação. Comece a criar seus eventos.",
        es: "Te enviaremos una confirmación de pago por correo electrónico. Comienza a crear eventos ahora mismo con Liveheats.",
        ja: "支払い確認メールをお送りします。今すぐLiveheatsでイベント作成を始めましょう。",
    },
    "Back to plan and billing": {
        pt: "Voltar pra plano e faturamento",
        es: "Volver a Planes y facturación",
        ja: "プランと請求に戻る",
    },
    "showing_prices_for_region": {
        en: ({ region }) => `Showing prices for ${region}`,
        pt: ({ region }) => `Mostrando preços para ${region}`,
        es: ({ region }) => `Se muestran los precios de ${region}`,
        ja: ({ region }) => `${region} の価格を表示`,
    },
    "Change": {
        pt: "Mudar",
        es: "Cambiar",
        ja: "変更",
    },
    "trial_active": {
        en: <>You’ve used your free event credit. To create a new event, you’ll need to purchase event credits&nbsp;<Link to="/director/purchase">here</Link>.</>,
        pt: <>Você usou seu crédito para evento gratuito. Para criar um novo evento, você precisará comprar créditos para eventos&nbsp;<Link to="/director/purchase">aqui</Link>.</>,
        es: <>Has utilizado tu crédito gratuito para eventos. Para crear un nuevo evento, tendrás que comprar créditos para eventos&nbsp;<Link to="/director/purchase">aquí</Link>.</>,
        ja: <>無料のイベントクレジットを使用しました。新しいイベントを作成するには、&nbsp;<Link to="/director/purchase">こちら</Link>でイベントクレジットを購入する必要があります。</>,
    },
    "trial_complete": {
        en: <>You've completed the trial! You can purchase event credits&nbsp;<Link to="/director/purchase">here</Link>.</>,
        pt: <>Você completou sua avaliação grátis! Você pode comprar créditos&nbsp;<Link to="/director/purchase">aqui</Link>.</>,
        es: <>¡Finalizaste la prueba! Puedes comprar créditos para eventos&nbsp;<Link to="/director/purchase">aquí</Link>.</>,
        ja: <>トライアルが終了しました！&nbsp;<Link to="/director/purchase">こちら</Link>でイベントクレジットを購入できます。</>,
    },
    "one_credit_left":{
        en: <>Heads up, you have one credit left, so you can only create one more event. You can top up event credits&nbsp;<Link to="/director/purchase">here</Link>.</>,
        pt: <>Aviso, você só tem mais 1 crédito, então você só pode criar mais um evento. Você pode adicionar créditos para eventos&nbsp;<Link to="/director/purchase">aqui</Link>.</>,
        es: <>¡Atención! Te queda un crédito, por lo que solo puedes crear un evento más. Puedes recargar créditos para eventos&nbsp;<Link to="/director/purchase">aqui</Link>.</>,
        ja: <>お知らせです。クレジットが残り1つですので、もう1つのイベントのみを作成できます。&nbsp;<Link to="/director/purchase">こちら</Link>でイベントクレジットを追加購入できます。</>,
    },
    "no_credits_left": {
        en: <>You’ve used up all your event credits. To create your next event, you’ll need to top up event credits&nbsp;<Link to="/director/purchase">here</Link>.</>,
        pt: <>Você usou todos os seus créditos para eventos. Para criar seu próximo evento, você vai precisar adicionar mais créditos&nbsp;<Link to="/director/purchase">aqui</Link>.</>,
        es: <>Has agotado todos tus créditos para eventos. Para crear tu próximo evento, tendrás que recargar créditos para eventos&nbsp;<Link to="/director/purchase">aquí</Link>.</>,
        ja: <>イベントクレジットをすべて使い切りました。次のイベントを作成するには、&nbsp;<Link to="/director/purchase">こちら</Link>でクレジットを追加する必要があります。</>,
    },
    "Common questions": {
        pt: "Perguntas frequentes",
        es: "Preguntas frecuentes",
        ja: "よくある質問",
    },
    "What’s included in the free trial event?": {
        pt: "O que está incluído no evento da avaliação gratuita?",
        es: "¿Qué incluye la prueba gratuita del evento?",
        ja: "無料トライアルのイベントには何が含まれていますか？",
    },
    "Our event athlete numbers hover right on the “border” of plans. Which one do we choose?": {
        pt: "A quantidade de atletas de nosso evento está um pouco acima do “limite“ dos planos. Qual devemos escolher?",
        es: "La cantidad de atletas de nuestro evento ronda el límite de los planes. ¿Cuál elegimos?",
        ja: "イベントの選手数が、プランの「ボーダーライン」あたりをさまよっています。どちらを選ぶべきでしょうか？",
    },
    "If you’re expecting to hover on the border of plans, you should choose the lower tiered plan. We’re happy to have you go over the athlete limit from time to time by a small amount. If you find yourself going well over, you can upgrade your plan.": {
        pt: "Se você imagina que vai ficar apenas um pouco acima de algum dos planos, escolha esse plano sem problemas. Não há problema em exceder um pouco, de tempos em tempos, o limite de atletas de um plano. Se o número de atletas acabar aumentando muito, você pode fazer um upgrade do seu plano.",
        es: "Si prevés rondar el límite de los planes, deberías elegir el plan de menor nivel. De vez en cuando, te permitiremos superar el límite de atletas por una pequeña cantidad. Si lo superas ampliamente, puedes renovar el plan.",
        ja: "プランのボーダーライン前後であることが予想される場合は、下位のプランを選ぶことをお勧めします。時間の経過とともに選手数が上限を少し超えても問題ありません。イベントの評判が良い場合は、プランをアップグレードできます。",
    },
    "What happens if we go over the athlete limit during an event?": {
        pt: "O que acontece se passarmos do limite de atletas durante um evento?",
        es: "¿Qué sucede si superamos el límite de atletas durante un evento?",
        ja: "イベント中に選手の上限を超えた場合はどうなりますか？",
    },
    "It’s not a big deal if you exceed your limit. We understand your athlete numbers fluctuate, so we won’t restrict anything, we’ll just give you a nudge that it might be time to upgrade your plan if you regularly find yourself going over.": {
        pt: "Não se preocupe se você exceder o seu limite. Nós entendemos que o número de atletas pode flutuar, portanto não fazemos restrições, mas se você exceder os números com frequência lhe faremos um lembrete de que pode estar na hora de fazer um upgrade do seu plano.",
        es: "No te preocupes si superas el límite. Sabemos que la cantidad de atletas varía, así que no aplicaremos ninguna restricción. Si superas el límite con regularidad, te recordaremos que quizás sea momento de renovar tu plan.",
        ja: "上限を超えたとしても、大きな問題はありません。選手数が変動することは理解しているので、こちらから制限することはありません。定期的に上限を超えるようであれば、プランのアップグレードをお勧めすることがあります。",
    },
    "We’re a club with over 100 members. Do we have to purchase Pro Am?": {
        pt: "Somos um clube com mais de 100 membros. Temos que comprar Pro Am?",
        es: "Nuestro club tiene más de 100 miembros. ¿Deberíamos comprar Pro Am?",
        ja: "私たちのクラブには100人以上の会員がいます。Pro Amを購入する必要がありますか？",
    },
    "club_over_100_answer": {
        en: <>The athlete limit is based on unique athletes in your actual events. If you have over 100 members, they probably don’t all compete in every event, so the number of athletes per event will be lower. Reach out to us (<a href="mailto:community@liveheats.com">community@liveheats.com</a>) if you’re not sure how many athletes you usually attract into your events.</>,
        pt: <>O limite de atletas é baseado no número de atletas únicos nos seus eventos em si. Se o clube tem mais de 100 membros, eles provavelmente não competem em todos os eventos, portanto o número de atletas por evento será menor. Envie um email para (<a href="mailto:community@liveheats.com">community@liveheats.com</a>) se você não tem certeza de quantos atletas você normalmente atrai para seus eventos.</>,
        es: <>El límite de atletas se basa en los atletas individuales que participan en tus eventos concretos. Si cuentas con más de 100 miembros, es probable que no todos compitan en todos los eventos, por lo que la cantidad de atletas por evento sería menor. Envíanos un correo electrónico (<a href="mailto:community@liveheats.com">community@liveheats.com</a>) si no tienes certeza de cuántos atletas suelen participar en tus eventos.</>,
        ja: <>選手の上限は、実際のイベントに参加する選手に基づいています。会員が100人以上いる場合、おそらく全員がすべてのイベントに出場するわけではないので、イベントごとの選手数は100人より少なくなるでしょう。ご自身のイベントに参加する選手数が予想できない場合は、<a href="mailto:community@liveheats.com">community@liveheats.com</a>までお問い合わせください。</>,
    },
    "How does billing work?": {
        pt: "Como funciona o faturamento?",
        es: "¿Cómo funciona la facturación?",
        ja: "請求はどのように行われますか？",
    },
    "As an organizer, you’ll purchase event credits to cover the number of events in your calendar. As you create events, your credits will be consumed, and you can top up when you’re ready.": {
        pt: "Como organizador você terá que comprar créditos de evento para cobrir o número de eventos em seu calendário. Conforme você cria eventos seus créditos são consumidos, mas você pode recarregar quando for necessário.",
        es: "Como organizador, comprarás créditos para usar en los eventos de tu calendario. A medida que crees eventos, tus créditos se irán consumiendo y podrás recargarlos cuando gustes.",
        ja: "主催者は、カレンダーのイベント数に応じたイベントクレジットを購入します。イベントを作成すると、クレジットが消費されます。クレジットは必要な時に追加購入できます。",
    },
    "What happens to our event credits when we cancel or postpone an event?": {
        pt: "O que acontece com os créditos de eventos quando cancelamos ou adiamos um evento?",
        es: "¿Qué les sucede a nuestros créditos para eventos cuando cancelamos o posponemos un evento?",
        ja: "イベントをキャンセルまたは延期した場合、クレジットはどうなりますか？",
    },
    "You’ll keep any unused event credits and use them whenever you’re ready (like next season). If an event is cancelled, just delete the event from Liveheats and the credit will be refilled.": {
        pt: "Você pode manter todos os créditos de eventos não utilizados e utilizá-los quando estiver pronto (como na próxima temporada). Se um evento for cancelado, basta apagar o evento do Liveheats e o crédito será devolvido.",
        es: "Podrás conservar los créditos para eventos que no uses y guardarlos para cuando quieras (por ejemplo, la próxima temporada). Si se cancela un evento, bórralo de Liveheats y el crédito se volverá a cargar.",
        ja: "未使用のイベントクレジットはとっておいて、いつでも（来シーズンなど）使用することができます。イベントがキャンセルされた場合は、Liveheatsからイベントを削除すれば、クレジットは回復します。",
    },
    "What payment methods do you accept?": {
        pt: "Quais métodos de pagamento vocês aceitam?",
        es: "¿Qué métodos de pago se aceptan?",
        ja: "どのような支払い方法がありますか？",
    },
    "You can pay within Liveheats using any major credit or debit card.": {
        pt: "Você pode pagar o Liveheats usando quaisquer cartões de crédito ou débito das principais marcas.",
        es: "Puedes hacer pagos en Liveheats con cualquiera de las principales tarjetas de crédito o débito.",
        ja: "Liveheatsでは、主要なクレジットカードまたはデビットカードでお支払いいただけます。",
    },
    "Our club doesn’t have a credit or debit card, how can we pay?": {
        pt: "Nosso clube não tem cartões de crédito nem débito, como podemos pagar?",
        es: "Nuestro club no tiene ninguna tarjeta de crédito ni de débito. ¿Cómo podemos pagar?",
        ja: "私たちのクラブはクレジットカードやデビットカードを持っていません。どのように支払ったらよいでしょうか？",
    },
    "no_card_answer": {
        en: <>If you are a small club without a credit or debit card, contact support (<a href="mailto:sales@liveheats.com">sales@liveheats.com</a>) and we can issue a manual invoice for electronic funds transfer.</>,
        pt: <>Se vocês são um clube pequeno sem cartão de crédito ou débito, contate-nos via (<a href="mailto:sales@liveheats.com">sales@liveheats.com</a>) e podemos emitir uma fatura manual para transferência eletrônica de fundos.</>,
        es: <>Si tu club es pequeño y no cuenta con una tarjeta de crédito o de débito, ponte en contacto con el equipo de soporte (<a href="mailto:sales@liveheats.com">sales@liveheats.com</a>) y podremos emitir una factura manual por la transferencia electrónica de fondos.</>,
        ja: <>クレジットカードやデビットカードを持っていない小規模クラブの場合は、サポート（<a href="mailto:sales@liveheats.com">sales@liveheats.com</a>）までお問い合わせください。電子送金用の請求書を発行いたします。</>,
    },
    "Can we pay as we go?": {
        pt: "Podemos pagar conforme usamos?",
        es: "¿Podemos hacer pagos periódicos?",
        ja: "都度払いは可能ですか？",
    },
    "you_can_buy_one_off_events": {
        en: <>Yes you can buy one-off event credits, but rates are <em>significantly</em> better if you buy in bulk. We encourage organizers to buy enough credits for their whole calendar upfront to get the best rates.</>,
        pt: <>Sim, é possível comprar créditos para eventos isolados, mas as taxas são <em>significativamente</em> melhores se eles forem comprados em maior volume. Nós incentivamos os organizadores a comprarem adiantado os créditos suficientes para os seus calendários completos, para assim terem as melhores taxas.</>,
        es: <>Sí, puedes comprar créditos para un solo evento, pero las tarifas serán <em>mucho</em> mejores si los compras en cantidad. Les recomendamos a los organizadores comprar con anticipación una cantidad suficiente de créditos para todo el calendario a fin de obtener las mejores tarifas.</>,
        ja: <>1回限りのイベントクレジットを購入することもできますが、まとめて購入した方が<em>かなり</em>お得です。私たちは、主催者がベストなレートで利用できるよう、1年分のクレジットを前もって購入することをお勧めしています。</>,
    },
    "We have one annual event that is larger than our standard events. What should we do?": {
        pt: "Nos temos um evento anual que é maior que os nossos eventos normais. O que devemos fazer?",
        es: "Una vez al año, organizamos un evento que es más grande que nuestros eventos habituales. ¿Qué deberíamos hacer?",
        ja: "年に一度、通常のイベントよりも大きなイベントがあります。どうすればいいでしょうか？",
    },
    "You should choose the plan that meets the needs of the majority of your events. If you have some outliers, you can upgrade events individually.": {
        pt: "Escolha o plano que melhor atende às necessidades da maioria dos seus eventos. Se houver poucos eventos com características muito diferentes, você pode fazer upgrade deles individualmente.",
        es: "Elige el plan que se adapte a las necesidades de la mayoría de tus eventos. Si algunos de tus eventos son atípicos, puedes renovarlos individualmente.",
        ja: "大半のイベントのニーズを満たすプランをお選びください。例外がある場合は、イベントを個別にアップグレードすることができます。",
    },
    "Why isn’t Liveheats free?": {
        pt: "Por que o Liveheats não é grátis?",
        es: "¿Por qué Liveheats no es gratuito?",
        ja: "なぜLiveheatsは無料ではないのですか？",
    },
    "As we’ve all seen with the issues around data privacy with Facebook and Google, “free” web software doesn’t really exist. It just means that instead of you paying for Liveheats as the “product”, your athletes and spectators become the “product” that is sold to advertisers. Some scoring platforms have attempted the Advertisement-based model, but haven’t been able to make it work long term. We’re focussed on building a sustainable business that offers customers a tonne of value, and awesome support, and hope that in return you’ll support our cause.": {
        pt: "Como todos vimos com as questões em torno da privacidade de dados com o Facebook e o Google, o software “gratuito“ na internet na verdade não existe. Significa apenas que em vez de pagar pelo Liveheats como o “produto“, os seus atletas e espectadores tornam-se o “produto“ que é vendido aos anunciantes. Algumas plataformas de gestão de competições esportivas tentaram esse modelo baseado na publicidade, mas não foram capazes de fazê-lo funcionar a longo prazo. Estamos focados em construir um negócio sustentável e oferecer aos nossos clientes um serviço útil e com um suporte técnico incrível, e esperamos que em troca a nossa causa seja apoiada.",
        es: "Los problemas relacionados con la privacidad de datos en Facebook y Google nos han demostrado que, en realidad, el software web “gratuito” no existe. Solo significa que, en lugar de que pagues por Liveheats como si fuera un “producto”, tus atletas y espectadores se convierten en el “producto” que se vende a los anunciantes. Algunas plataformas de puntajes han intentado implementar el modelo basado en la publicidad, pero no han logrado que funcione a largo plazo. En Liveheats, nuestro objetivo es construir un negocio sostenible que les ofrezca a los clientes un servicio de gran valor y asistencia de primera categoría, y esperamos que, a cambio, apoyes nuestra causa.",
        ja: "FacebookやGoogleのデータプライバシーの問題に見られるように、「無料」のウェブソフトウェアは実際には存在しません。Liveheatsという「商品」にお金を払う代わりに、選手や観客が広告主に販売される「商品」になるということです。一部の採点プラットフォームは広告ベースのモデルを試みましたが、長期的に成功させることができていません。私たちは、お客様に多くの価値と素晴らしいサポートを提供する持続可能なビジネスを構築することに力を入れており、代わりにお客様が私たちの理念を支えてくれることを願っています。",
    },
    "I’d like to speak to someone first, can I see a demo?": {
        pt: "Gostaria de falar com alguém primeiro, posso ver uma demonstração?",
        es: "Primero me gustaría hablar con alguien. ¿Podría ver una demostración?",
        ja: "まず誰かと話したいのですが、デモを見ることはできますか？",
    },
    "stoked_to_demo": {
        en: <>Yes, we’d be stoked to walk you through our platform!<br/><br/>Click <a href="https://calendly.com/chrisfriendy/liveheats-discovery-meeting" target="_blank" rel="noreferrer">here</a> to schedule a demo.</>,
        pt: <>Sim, seria um prazer lhe mostrar a nossa plataforma!<br/><br/>Clique <a href="https://calendly.com/chrisfriendy/liveheats-discovery-meeting" target="_blank" rel="noreferrer">aqui</a> para agendar uma demonstração.</>,
        es: <>Sí, con gusto te mostraremos cómo funciona la plataforma.<br/><br/>Haz clic<a href="https://calendly.com/chrisfriendy/liveheats-discovery-meeting" target="_blank" rel="noreferrer">aquí</a> para programar una demostración.</>,
        ja: <>はい、喜んでプラットフォームをご案内いたします！<br/><br/>デモのご予約は、<a href="https://calendly.com/chrisfriendy/liveheats-discovery-meeting" target="_blank" rel="noreferrer">こちら</a>をクリックしてください。</>,
    },
    "Get the competitive edge with Fletch - the new premium athlete experience on Liveheats": {
        pt: "Ganhe a vantagem competitiva com Fletch - a nova experiência premium para atletas do Liveheats",
        es: "Obtén una ventaja competitiva con Fletch, la nueva experiencia prémium para atletas en Liveheats",
        ja: "Fletchで競争力を手に入れよう - Liveheatsの新しいプレミアムアスリート体験",
    },
    "Fletch improves your competitions with premium features including:": {
        pt: "Fletch melhora seu desempenho com funcionalidades premium incluíndo:",
        es: "Fletch perfecciona tus competencias con características prémium como las siguientes:",
        ja: "Fletchは、以下のプレミアム機能であなたの競争力を向上させます。",
    },
    "athletes_premium_price_month": {
        en: ({ price, currency }) => `${formatPriceSmall(price, currency)} / month`,
        pt: ({ price, currency }) => `${formatPriceSmall(price, currency)} / mês`,
        es: ({ price, currency }) => `${formatPriceSmall(price, currency)} por mes`,
        ja: ({ price, currency }) => `${formatPriceSmall(price, currency)} /月`,
    },
    "(cancel anytime)": {
        pt: "(cancele quando quiser)",
        es: "(cancela en cualquier momento)",
        ja: "（いつでもキャンセル可）",
    },
    "Sign me up": {
        pt: "Quero me inscrever",
        es: "Registrarme",
        ja: "登録する",
    },
    "athletes_premium_price_year": {
        en: ({ price, currency }) => `or ${formatPriceSmall(price, currency)} for annual pass`,
        pt: ({ price, currency }) => `ou ${formatPriceSmall(price, currency)} por ano`,
        es: ({ price, currency }) => `o ${formatPriceSmall(price, currency)} por el pase anual`,
        ja: ({ price, currency }) => `または ${formatPriceSmall(price, currency)} /年`,
    },
    "Personalised event updates": {
        pt: "Atualizações personalizadas das competições",
        es: "Novedades personalizadas de eventos",
        ja: "個人に合わせたイベント最新情報",
    },
    "Know when you’re on, who you’re against, and get notified if schedule changes": {
        pt: "Saiba quando você vai competir, contra quem, e seja notificado se a agenda mudar",
        es: "Descubre cuándo es tu turno y quién es tu contrincante, y recibe notificaciones con los cambios del cronograma",
        ja: "出番や対戦相手を把握し、スケジュールが変更になった場合は通知を受けることができます",
    },
    "In-depth performance stats": {
        pt: "Estatísticas do seu desempenho",
        es: "Estadísticas detalladas de rendimiento",
        ja: "詳細なパフォーマンス統計",
    },
    "Explore your data, characteristics of your best performances, and how you can improve": {
        pt: "Explore seus dados, as características dos seus melhores resultados, e saiba o que melhorar",
        es: "Analiza tus datos, las características de tus mejores actuaciones y cómo puedes mejorar",
        ja: "あなたのデータ、ベストパフォーマンスの特徴、改善方法を調べることができます",
    },
    "Keep your team informed": {
        pt: "Mantenha o seu time informado",
        es: "Mantén a tu equipo informado",
        ja: "チームへの情報提供",
    },
    "Add your coach, friends and family so they can follow your events and get performance reports": {
        pt: "Adicione seu técnico, amigos e família para que possam seguir suas competições e receber relatórios sobre o seu desempenho",
        es: "Agrega entrenadores, amigos y familiares para que puedan hacer un seguimiento de tus eventos y recibir informes de rendimiento",
        ja: "コーチ、友人、家族を追加することで、彼らはあなたのイベントの情報を追いかけたり、パフォーマンスレポートを入手したりできるようになります",
    },
    "Who is Fletch for?": {
        pt: "O Fletch é pra mim?",
        es: "¿A quién está destinado Fletch?",
        ja: "Fletchはどのような人向けですか？",
    },
    "Fletch is built for any athlete that participates in any events that run on the Liveheats platform. We created Fletch to help you get more from your competitions and better understand your performances.": {
        pt: "O Fletch foi construído para qualquer atleta que participa em competições que usam a plataforma Liveheats. Nós criamos o Fletch para te ajudar a compreender melhor o seu desempenho.",
        es: "Fletch está diseñado para cualquier atleta que participe en un evento organizado en la plataforma Liveheats. Creamos Fletch para ayudarte a sacarle más partido a tus competencias y comprender mejor tu rendimiento.",
        ja: "Fletchは、Liveheatsプラットフォーム上で運営されるイベントに参加するすべての選手に向けて作られています。私たちは、皆さんが試合からより多くのものを得て、パフォーマンスをより深く理解できるよう、Fletch を作りました。",
    },
    "Which sports and events are supported?": {
        pt: "Quais esportes e eventos são suportados?",
        es: "¿Qué deportes y eventos se incluyen?",
        ja: "どのようなスポーツやイベントがサポートされていますか？",
    },
    "All events that run on Liveheats will be included in your Fletch package.": {
        pt: "Quaisquer competições que usam a plataforma Liveheats serão inclusas no seu pacote Fletch",
        es: "Todos los eventos que se organicen en Liveheats estarán incluidos en tu paquete de Fletch.",
        ja: "Liveheatsで行われるすべてのイベントが、Fletchパッケージに含まれます。",
    },
    "I don’t have a Liveheats login, how will that work?": {
        pt: "Eu não possuo uma conta Liveheats, como funciona?",
        es: "¿Qué sucede si no tengo una cuenta de inicio de sesión en Liveheats?",
        ja: "Liveheatsにログインしていないと、どうなりますか？",
    },
    "No problem. When you sign up, you’ll get an opportunity to claim any existing profile that belongs to you on any Liveheats event.": {
        pt: "Quando você se inscrever, você poderá importar quaisquer perfils em competições Liveheats que pertecem a você.",
        es: "No hay problema. Al registrarte, podrás reclamar los perfiles existentes que te pertenezcan en cualquier evento de Liveheats.",
        ja: "問題ありません。登録いただくと、Liveheatsのイベントであなたに属するプロフィールを取得する機会があります。",
    },
    "I’m a coach or parent managing multiple athletes, how will that work?": {
        pt: "Eu sou um técnico ou um pai gerenciando vários atletas, como isso vai funcionar?",
        es: "¿Qué sucede si soy entrenador o el padre o la madre de un atleta?",
        ja: "複数の選手を管理しているコーチ（または保護者）ですが、どのように役立ちますか？",
    },
    "Each athlete profile can upgrade to Fletch. If you have multiple athletes, you (or they) can upgrade. For coaches, individuals can upgrade and add you to follow their progress.": {
        pt: "Cada perfil de atleta pode fazer o upgrade para usar Fletch. Se você gerencia multiplos atletas, você (ou o atleta) pode fazer o upgrade. Para técnicos, os atletas fazem o upgrade e você pode segui-los",
        es: "Los perfiles de cada atleta pueden actualizarse a Fletch. Si tienes varios atletas, podéis completar la actualización tanto tú como tus atletas. Si eres entrenador, las personas pueden completar la actualización y agregarte para que sigas su progreso.",
        ja: "各選手のプロフィールはFletchにアップグレードできます。複数の選手がいる場合は、あなた（または彼ら）がアップグレードできます。コーチの場合は、選手個人でアップグレードし、あなたを追加してもらうことで、進捗状況を追跡することができます。",
    },
    "How can I learn more?": {
        pt: "Como faço para saber mais?",
        es: "¿Cómo puedo obtener más información?",
        ja: "もっと詳しく知りたいです。",
    },
    "contact_us": {
        en: <>If you have any questions, contact us at <MuiLink href="mailto:athletes@liveheats.com">athletes@liveheats.com</MuiLink></>,
        pt: <>Para quaisquer dúvidas, entre em contato em <MuiLink href="mailto:athletes@liveheats.com">athletes@liveheats.com</MuiLink></>,
        es: <>Si tienes alguna pregunta, envíanos un correo electrónico a <MuiLink href="mailto:athletes@liveheats.com">athletes@liveheats.com</MuiLink></>,
        ja: <>ご質問がございましたら、<MuiLink href="mailto:athletes@liveheats.com">athletes@liveheats.com</MuiLink>までご連絡ください</>,
    },
    "Hello! You're here early.": {
        pt: "Olá! Você chegou um pouco cedo.",
        es: "¡Hola! Llegas temprano.",
        ja: "こんにちは！早い到着ですね。",
    },
    "We're still putting the finishing touches to Fletch, and he'll be ready to help you soon. We'd love to notify you when it's ready, and also get your feedback on which features you're most excited about. Leave your email and we'll be in touch.": {
        pt: "Nosso time está finalizando Fletch, e em breve ele estará pronto pra te ajudar. Seria um prazer poder te notificar quando ele estiver pronto, e saber quais funcionalidades você mais gostou. Deixe o seu email e entraremos em contato.",
        es: "Todavía estamos dando los últimos retoques a Fletch; pronto estará listo para ayudarte. Nos gustaría avisarte cuando esté listo y que nos cuentes cuáles son las funcionalidades que más te llaman la atención. Déjanos tu correo electrónico y nos pondremos en contacto contigo.",
        ja: "まだFletchの最後の仕上げをしているところです。もうすぐ準備が整います。準備ができ次第、お知らせしたいと思います。また、最も期待している機能について、ぜひご意見をお聞かせください。メールをいただければ、こちらからご連絡いたします。",
    },
    "You're a superstar. We appreciate your interest and we'll keep you posted.": {
        pt: "Gênio. Nós agredecemos pelo interesse e entraremos em contato.",
        es: "¡Eres lo mejor! Gracias por tu interés; te enviaremos novedades.",
        ja: "あなたはスーパースターです。関心をお寄せいただき、ありがとうございます。また最新の情報をお伝えします。",
    },
    "Submit": {
        pt: "Enviar",
        es: "Enviar",
        ja: "送信",
    },
    "Head back to home page": {
        pt: "Voltar para a página inicial",
        es: "Volver a la página de inicio",
        ja: "ホームページに戻る",
    },
    "Access your personal performance report and stats": {
        pt: "Acesse relatórios e estatísticas personalizadas",
        es: "Accede a tus estadísticas e informes de rendimiento personales",
        ja: "パーソナルパフォーマンスレポートと統計にアクセスする",
    },
    "Receive personal updates and stats for this event": {
        pt: "Receba atualização e estatísticas desta competição",
        es: "Recibe estadísticas y novedades personales para este evento",
        ja: "このイベントの個人に合わせた最新情報と統計情報を受け取る",
    },
    "Upgrade your competition experience with Fletch": {
        pt: "Faça um upgrade da sua experiência de competições com Fletch",
        es: "Mejora tu paso por las competencias con Fletch",
        ja: "Fletchで競技体験をアップグレード",
    },
    "Welcome back 👋": {
        pt: "Bem vindo de volta 👋",
        es: "¡Hola otra vez! 👋",
        ja: "お帰りなさい👋",
    },
    "account_already_director": {
        en: ({ org, email }) => <>This account ({email}) is a Director for an existing Liveheats organiser account - {org}. To create a new organiser account for a different organisation, use another email or contact support at <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>.</>,
        pt: ({ org, email }) => <>Este login ({email}) já é diretor de uma organização do Liveheats - {org}. Para criar uma conta nova para uma outra organização, use um outro e-mail ou entre em contato com o suporte via <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>.</>,
        es: ({ org, email }) => <>La cuenta ({email}) es del director de una cuenta de organizador actual de Liveheats, {org}. Para crear una cuenta de organizador nueva para una organización diferente, usa otro correo electrónico o ponte en contacto con el equipo de soporte en <MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>.</>,
        ja: ({ org, email }) => <>このアカウント（{email}）は既存のLiveheats主催者アカウントのディレクターです。別の団体用の新しい主催者アカウントを作成するには、別のメールアドレスを使用するか、サポート（<MuiLink href="mailto:community@liveheats.com">community@liveheats.com</MuiLink>）までお問い合わせください。</>,
    },
    "continue_to_org": {
        en: ({ org }) => `Continue to ${org}`,
        pt: ({ org }) => `Ir para ${org}`,
        es: ({ org }) => `Continuar a ${org}`,
        ja: ({ org }) => `${org} に移動`,
    },
    "Organisation settings": {
        pt: "Configurar organização",
        es: "Configuración de la organización",
        ja: "団体の設定",
    },
    "Profile": {
        pt: "Perfil",
        es: "Perfil",
        ja: "プロフィール",
    },
    "Users": {
        pt: "Usuários",
        es: "Usuarios",
        ja: "ユーザー",
    },
    "User details": {
        pt: "Detalhes do usuário",
        es: "Información de usuario",
        ja: "ユーザー詳細",
    },
    "New password:": {
        pt: "Nova senha:",
        es: "Contraseña nueva:",
        ja: "新しいパスワード：",
    },
    "Confirm new password:": {
        pt: "Confirmar nova senha:",
        es: "Confirmar contraseña nueva:",
        ja: "新しいパスワードを確認する：",
    },
    "User's details have been updated ✅": {
        pt: "Os detalhes do usuário foram atualizados ✅",
        es: "La información del usuario se actualizó correctamente ✅",
        ja: "ユーザーの詳細が更新されました✅",
    },
    "Upload logo": {
        pt: "Mudar logo",
        es: "Cargar logotipo",
        ja: "ロゴをアップロード",
    },
    "Facebook URL": {
        pt: "Página do Facebook",
        es: "URL de Facebook",
        ja: "Facebook URL",
    },
    "Please enter a valid Facebook URL (e.g. https://www.facebook.com/live_heats)": {
        pt: "Por favor coloque um link válido pro Facebook (e.g. https://www.facebook.com/live_heats)",
        es: "Ingresa una URL de Facebook válida (p.ej., https://www.facebook.com/live_heats)",
        ja: "有効なFacebookのURLを入力してください（例：https://www.facebook.com/live_heats）",
    },
    "Instagram URL": {
        pt: "Link pro instagram",
        es: "URL de Instagram",
        ja: "Instagram URL",
    },
    "Please enter a valid Instagram URL (e.g. https://www.instagram.com/live_heats)": {
        pt: "Por favor coloque um link válido pro Instagram (e.g. https://www.instagram.com/live_heats)",
        es: "Ingresa una URL de Instagram válida (p.ej., https://www.instagram.com/live_heats)",
        ja: "有効なInstagramのURL（例：https://www.instagram.com/live_heats）を入力してください",
    },
    "Wohoo! Organisation info successfully updated.": {
        pt: "Wohoo! As informações foram modificadas.",
        es: "¡Sí! La información de la organización se actualizó correctamente.",
        ja: "やった！団体の情報が更新されました。",
    },
    "Oops! Something went wrong, please try again.": {
        pt: "Ops, deu algo errado, por favor tente novamente.",
        es: "¡Uy! Algo salió mal, vuelve a intentarlo.",
        ja: "おっと！問題が起こったようです。もう一度お試しください。",
    },
    "View public page": {
        pt: "Ver página pública",
        es: "Ver página pública",
        ja: "公開ページを見る",
    },
    "Organisation info": {
        pt: "Informações da organização",
        es: "Información de la organización",
        ja: "団体情報",
    },
    "Use different email": {
        pt: "Usar outro email",
        es: "Usar otro correo electrónico",
        ja: "別のメールアドレスを使用する",
    },
    "Create your organisation account": {
        pt: "Crie a conta da sua organização",
        es: "Crea la cuenta de tu organización",
        ja: "団体アカウントを作成する",
    },
    "Organisation name": {
        pt: "Nome da organização",
        es: "Nombre de la organización",
        ja: "団体名",
    },
    "Sport type": {
        pt: "Esporte",
        es: "Tipo de deporte",
        ja: "スポーツタイプ",
    },
    "Choose a sport": {
        pt: "Escolha um esporte",
        es: "Elige un deporte",
        ja: "スポーツを選ぶ",
    },
    "Choose a Liveheats URL": {
        pt: "Escolha um URL do Liveheats:",
        es: "Elige un URL de Liveheats:",
        ja: "LiveheatsのURLを選択:",
    },
    "This will be your organisation's home on Liveheats, where athletes will be able to find and sign up for events, see results, and more.": {
        pt: "Este será o endereço da sua organização no Liveheats, onde os atletas poderão encontrar e se inscrever em competições, ver resultados e muito mais.",
        es: "Este será el espacio de tu organización en Liveheats, donde los atletas podrán encontrar y registrarse en eventos, ver resultados y mucho más.",
        ja: "これは、Liveheats上のあなたの団体のホームになります。ここでは、選手がイベントを見つけて登録し、結果を見ることができます。",
    },
    "Contact email": {
        pt: "E-mail para contato",
        es: "Correo electrónico de contacto",
        ja: "連絡先Eメール",
    },
    "We'll need an organisation name 👆": {
        pt: "Precisamos de um nome pra organização 👆",
        es: "Debes ingresar el nombre de la organización 👆",
        ja: "団体名が必要です👆",
    },
    "We need to know what presets to use 👆": {
        pt: "Precisamos saber quais configurações usar",
        es: "Debes decirnos qué valores predeterminados quieres usar 👆",
        ja: "どのプリセットを使うかを教えてください👆",
    },
    "We need a link to give your users 👆": {
        pt: "Precisamos de um link pros seus usuários 👆",
        es: "Debes ingresar un enlace para compartirles a tus usuarios 👆",
        ja: "ユーザーに提供するリンクが必要です👆",
    },
    "Can only include letters, numbers, (_) and (.)": {
        pt: "Só pode ter letras, números, (_) e (.)",
        es: "Solo puede incluir letras, números, (_) y (.)",
        ja: "使用できるのは、アルファベット、数字、（_）、（.）のみです",
    },
    "Someone beat you to it 😔. URL taken": {
        pt: "Alguém já pegou esta URL 😔",
        es: "Te ganaron de mano😔. La URL no está disponible",
        ja: "誰かに先を越されましたようです😔。そのURLは取得されています",
    },
    "Checking if that’s free 🧐": {
        pt: "Verificando se está disponível 🧐",
        es: "Verificando si está disponible 🧐",
        ja: "使用できるか確認しています🧐",
    },
    "Available. The public will access your events at this link.": {
        pt: "Disponível. O público acessará seus eventos por este link.",
        es: "Disponible. El público accederá a tus eventos a través de este enlace.",
        ja: "利用可能です。一般の方はこのリンクでイベントにアクセスします。",
    },
    "liveheats.com/": {
        pt: "liveheats.com.br/",
        ja: "liveheats.jp/",
        es: "liveheats.com/",
    },
    "Create profile": {
        pt: "Criar perfil",
        es: "Crear perfil",
        ja: "プロフィールを作成する",
    },
    "Plan and billing": {
        pt: "Plano e faturamento",
        es: "Planes y facturación",
        ja: "プランと請求",
    },
    "Start": {
        pt: "Iniciar",
        es: "Iniciar",
        ja: "スタート",
    },
    "Undo start": {
        pt: "Desfazer começar",
        es: "Revertir inicio",
        ja: "スタートの取り消し",
    },
    "End and confirm": {
        pt: "Finalizar e confirmar",
        es: "Finalizar y confirmar",
        ja: "終了して確認",
    },
    "Undo end": {
        pt: "Desfazer finalização",
        es: "Revertir finalización",
        ja: "終了の取り消し",
    },
    "Undo end?": {
        pt: "Desfazer finalização?",
        es: "¿Quieres revertir la finalización?",
        ja: "終了を取り消しますか？",
    },
    "Caution - this will remove progressed athletes from later rounds, which might impact results if those rounds have been started. Alternatively, you can make edits on the previous screen without changing progression.": {
        pt: "Cuidado - esta ação vai remover atletas que já progrediram pros rounds futuros, isso pode modificar os resultados caso esses rounds já tenham começado. Como alternativa você pode simplesmente editar os resultados sem modificar a progressão.",
        es: "Advertencia: Esto eliminará a los atletas avanzados de rondas posteriores y podría afectar los resultados si dichas rondas ya comenzaron. Como alternativa, puedes hacer modificaciones en la pantalla anterior sin cambiar la progresión.",
        ja: "注意 - この操作により、進出した選手が後半のラウンドから削除され、そのラウンドが開始されている場合、結果に影響を与える可能性があります。また、進出を変更せずに、前の画面で編集することもできます。",
    },
    "Proceed with undo": {
        pt: "Prosseguir com desfazer",
        es: "Confirmar la reversión",
        ja: "取り消して進める",
    },
    "Filter and place": {
        pt: "Buscar e posicionar",
        es: "Filtrar y posicionar",
        ja: "フィルターと順位",
    },
    "ordinal": {
        en: ({ place }) => `${place}${ordinal(place)}`,
        pt: ({ place }) => `${place}˚`,
        es: ({ place }) => `${place}˚`,
        ja: ({ place }) => `${place}${ordinal(place)}`,
    },
    "x_confirmed_teams": {
        en: ({ x }) => `${x} confirmed team${plural(x)}`,
        pt: ({ x }) => `${x} time${plural(x)} confirmado${plural(x)}`,
        es: ({ x }) => `${x} equipo${plural(x)} con confirmación`,
        ja: ({ x }) => `${x} 確定チーム`,
    },
    "x_confirmed_athletes": {
        en: ({ x }) => `${x} confirmed athlete${plural(x)}`,
        pt: ({ x }) => `${x} atleta${plural(x)} confirmado${plural(x)}`,
        es: ({ x }) => `${x} atleta${plural(x)} con confirmación`,
        ja: ({ x }) => `${x} 確定選手`,
    },
    "x_registered_teams": {
        en: ({ x }) => `${x} total on team list`,
        pt: ({ x }) => `${x} em total na lista de times`,
        es: ({ x }) => `Total de ${x} en la lista de equipos`,
        ja: ({ x }) => `チームリストの ${x} 合計`,
    },
    "x_registered_athletes": {
        en: ({ x }) => `${x} total on athlete list`,
        pt: ({ x }) => `${x} em total na lista de atletas`,
        es: ({ x }) => `Total de ${x} en la lista de atletas`,
        ja: ({ x }) => `選手リストの ${x} 合計`,
    },
    "x_teams": {
        en: ({ x }) => `${x} team${plural(x)}`,
        pt: ({ x }) => `${x} time${plural(x)}`,
        es: ({ x }) => `${x} equipo${plural(x)}`,
        ja: ({ x }) => `${x} チーム`,
    },
    "x_athletes": {
        en: ({ x }) => `${x} athlete${plural(x)}`,
        pt: ({ x }) => `${x} atleta${plural(x)}`,
        es: ({ x }) => `${x} atleta${plural(x)}`,
        ja: ({ x }) => `${x} アスリート`,
    },
    "x_heats": {
        en: ({ x }) => `${x} heat${plural(x)}`,
        pt: ({ x }) => `${x} bateria${plural(x)}`,
        es: ({ x }) => `${x} serie${plural(x)}`,
        ja: ({ x }) => `${x} ヒート`,
    },
    "x_divisions": {
        en: ({ x }) => `${x} division${plural(x)}`,
        pt: ({ x }) => `${x} categoria${plural(x)}`,
        es: ({ x }) => `${x} categoría${plural(x)}`,
        ja: ({ x }) => `${x} 部門`,
    },
    "Cancellations": {
        pt: "Cancelamentos",
        es: "Cancelaciones",
        ja: "キャンセル",
    },
    "Reinstate": {
        pt: "Reativar",
        es: "Reanudar",
        ja: "復元",
    },
    "Remove from division": {
        pt: "Remover da categoria",
        es: "Eliminar de la categoría",
        ja: "部門から削除する",
    },
    "Registration form": {
        pt: "Formulário de inscrição",
        es: "Formulario de inscripción",
        ja: "登録フォーム",
    },
    "Manage the fields captured in registration and included in data exports": {
        pt: "Gerencie os atributos capturados nas inscrições e inclusos nos downloads",
        es: "Gestionar los campos registrados en la inscripción e incluidos en las exportaciones de datos",
        ja: "登録時に取得されるフィールドとデータエクスポートに含まれるフィールドを管理します",
    },
    "Create field": {
        pt: "Criar atributo",
        es: "Crear campo",
        ja: "フィールドを作成する",
    },
    "Create custom field": {
        pt: "Criar atributo",
        es: "Crear campo personalizado",
        ja: "カスタムフィールドを作成する",
    },
    "View custom field": {
        pt: "Ver atributo",
        es: "Ver campo personalizado",
        ja: "カスタムフィールドを表示する",
    },
    "Archive field?": {
        pt: "Remover atributo?",
        es: "¿Deseas archivar el campo?",
        ja: "フィールドをアーカイブしますか？",
    },
    "Archiving this field will remove it from your organisation. You can always just deactivate.": {
        pt: "Esta ação permanentemente removerá o atributo da sua organização. Ao invés, é sempre possivel desativar o atributo.",
        es: "Si archivas este campo, se eliminará de tu organización. También puedes sencillamente desactivarlo.",
        ja: "アーカイブすると、このフィールドは団体から削除されます。これはいつでも無効化することができます。",
    },
    "Archive field": {
        pt: "Remover atributo",
        es: "Archivar campo",
        ja: "フィールドをアーカイブする",
    },
    "Archive": {
        pt: "Remover",
        es: "Archivar",
        ja: "アーカイブ",
    },
    "step_x_of_max": {
        en: ({ step, max }) => `Step ${step} of ${max}`,
        pt: ({ step, max }) => `Passo ${step} de ${max}`,
        es: ({ step, max }) => `paso ${step} de ${max}`,
        ja: ({ step, max }) => `ステップ ${step} / ${max}`,
    },
    "Next": {
        pt: "Próximo",
        es: "Siguiente",
        ja: "次へ",
    },
    "Select field type": {
        pt: "Selecionar o tipo do attributo",
        es: "Seleccionar tipo de campo",
        ja: "フィールドタイプを選択する",
    },
    "Free text or number": {
        pt: "Texto livre ou número",
        es: "Números o texto sin formato",
        ja: "フリーテキストまたは数字",
    },
    "User can enter any text or number. Best for items such as “Address”, “Sponsors”, “Emergency contact number”, or “Member ID”": {
        pt: "Usuários podem colocar qualquer texto ou número. Bom para atributos como “Endereço” ou “Patrocinadores”",
        es: "Los usuarios pueden ingresar la cantidad de texto o números que deseen. Se recomienda para elementos como “Dirección”, “Patrocinadores”, “Número de contacto de emergencia” o “Identificación de miembro”.",
        ja: "ユーザーは任意のテキストや数字を入力することができます。「住所」、「スポンサー」、「緊急連絡先」、「会員ID」などの項目に最適です",
    },
    "Drop down": {
        pt: "Lista",
        es: "Lista desplegable",
        ja: "ドロップダウン",
    },
    "Users can select from a set of options. Best for items such as “T-shirt size” or “Gender”": {
        pt: "Usuários selecionam de um lista de opções. Bom para atributos tipo “Tamanho da camiseta”",
        es: "Los usuarios pueden seleccionar de un conjunto de opciones. Se recomienda para elementos como “Talla de playera” o “Sexo”.",
        ja: "ユーザーは一連のオプションから選択することができます。「Tシャツのサイズ 」や「性別」などの項目に最適です",
    },
    "Check box": {
        es: "Casilla de verificación",
        ja: "チェックボックス",
    },
    "Users can check the box. Best for items such as “COVID Declaration” or other important terms that must be accepted": {
        pt: "Usuários podem selecionar a caixa. Bom para atributos tipo “Declaração de COVID” ou outros ítems importantes que devem ser aceitos",
        es: "Los usuarios pueden marcar la casilla. Se recomienda para elementos como “Declaración sobre la COVID” u otros términos importantes que deben aceptarse.",
        ja: "ユーザーはこのボックスにチェックを入れることができます。「新型コロナウイルスの宣言」など、必ず承諾が必要な重要事項に最適です",
    },
    "Date picker": {
        pt: "Data",
        es: "Selector de fecha",
        ja: "日付ピッカー",
    },
    "Users can select a date. Best for items such as “Arrival date” or “Departure date”": {
        pt: "Usuários selecionam uma data. Bom para atributos tipo “Data de chegada” or “Data de saída”",
        es: "Los usuarios pueden seleccionar una fecha. Se recomienda para elementos como “Fecha de llegada o “Fecha de partida”.",
        ja: "ユーザーは日付を選択できます。「到着日」や「出発日」などの項目に最適です",
    },
    "You must select a field type": {
        pt: "É necessário selection o tipo do atributo",
        es: "Debes seleccionar un tipo de campo",
        ja: "フィールドタイプを選択する必要があります",
    },
    "Name of field": {
        pt: "Nome do atributo",
        es: "Nombre del campo",
        ja: "フィールド名",
    },
    "This is how the field will display on the registration form and data exports, for example “T-shirt size” or “Membership ID”": {
        pt: "Como o atributo vai aparecer no formulário de inscrição e nos downloads, por exemplo “Tamanho da camiseta” ou “Declaração de COVID”",
        es: "Con este nombre se verá el campo en el formulario de inscripción y las exportaciones de datos, por ejemplo, “Talla de playera” o “Identificación de miembro”.",
        ja: "例えば、「Tシャツのサイズ」や「会員ID」のように、登録フォームやデータエクスポートでフィールドがどのように表示されるかを示します",
    },
    "You must name the field": {
        pt: "É necessário dar um nome ao atributo",
        es: "Debes asignarle un nombre al campo",
        ja: "フィールドに名前を付ける必要があります",
    },
    "Is the answer required?": {
        pt: "A resposta é obrigatória?",
        es: "¿Es obligatoria la respuesta?",
        ja: "回答は必須ですか？",
    },
    "Options users can select from": {
        pt: "Opções que os usuários podem selecionar",
        es: "Opciones entre las que los usuarios pueden elegir",
        ja: "ユーザーが選択できるオプション",
    },
    "Type to create an option": {
        pt: "Digite para criar uma opção",
        es: "Escribe para crear una opción",
        ja: "入力してオプションを作成する",
    },
    "create_option:value": {
        en: ({ value }) => `Create option: ${value}`,
        pt: ({ value }) => `Criar opção: ${value}`,
        es: ({ value }) => `Crear opción: ${value}`,
        ja: ({ value }) => `オプションの作成：${value}`,
    },
    "You must add at least one option": {
        pt: "É necessário adicinar pelo menos uma opção",
        es: "Debes agregar al menos una opción",
        ja: "1つ以上のオプションを追加する必要があります",
    },
    "Field category": {
        pt: "Categoria do atributo",
        es: "Categoría del campo",
        ja: "フィールドカテゴリー",
    },
    "You must select a category": {
        pt: "É necessário escolher a categoria",
        es: "Debes seleccionar una categoría",
        ja: "カテゴリーを選択する必要があります",
    },
    "Field will be linked to the athlete in this event or series only. Best for items that might change in the future, such as “T-shirt size” or “COVID Declaration”": {
        pt: "O atributo será vinculado somente a inscrição do atleta no evento ou temporada. Bom para ítems que vão mudar no futuro, como “Tamanho da camiseta” ou “Declaração de COVID”",
        es: "El campo solo se vinculará al atleta en este evento o este grupo. Se recomienda para elementos que pueden cambiar en el futuro, como “Talla de playera” o “Declaración sobre la COVID”.",
        ja: "フィールドは、このイベントまたはシリーズの選手のみにリンクされます。「Tシャツのサイズ」や「新型コロナウイルスの宣言」など、将来変更される可能性のある項目に最適です",
    },
    "Field will be permanently linked to the athlete’s Liveheats profile. Best for items such as “Gender”, “Sponsors” and “Member ID”": {
        pt: "O atributo será permanentemente vinculado ao perfil do atleta no Liveheats. Bom para ítems como “Patrocinadores”",
        es: "El campo se vinculará automáticamente al perfil de Liveheats del atleta. Se recomienda para elementos como “Sexo”, “Patrocinadores” e “Identificación de miembro”.",
        ja: "フィールドは選手のLiveheatsプロフィールに永久にリンクされます。「性別」、「スポンサー」、「会員ID」などの項目に最適です",
    },
    "User": {
        pt: "Usuário",
        es: "Usuario",
        ja: "ユーザー",
    },
    "Information will be permanently linked to the user who manages the athlete profile (usually the athlete themselves or parent) now and in the future. Best for items such as “Address” or “Emergency contact”": {
        pt: "O atributo será permanentemente vinculado ao usuário que gerencia o perfil do atleta (em geral o próprio atleta ou um responsável). Bom para ítems como “Contato de emergência”",
        es: "La información quedará permanentemente vinculada al usuario que gestione el perfil del atleta (generalmente el propio atleta, su padre o su madre) ahora y en el futuro. Se recomienda para elementos como “Dirección” o “Contacto para casos de emergencia”.",
        ja: "情報は、現在および将来にわたって、選手のプロフィールを管理するユーザー（通常は選手本人または保護者）に永久にリンクされます。「住所」や「緊急連絡先」などの項目に最適です",
    },
    "Epic! Custom field successfully created 👊": {
        pt: "Gênio! O Atributo foi criado 👊",
        es: "¡Increíble! El campo personalizado se creó correctamente 👊",
        ja: "素晴らしい！カスタムフィールドの作成に成功しました👊",
    },
    "Approve scores": {
        pt: "Aprovar notas",
        es: "Aprobar puntajes",
        ja: "スコアを承認する",
    },
    "Score locked": {
        pt: "Nota finalizada",
        es: "Puntaje bloqueado",
        ja: "スコアがロックされました",
    },
    "Sweet! Scores published ✔": {
        pt: "Excelente! Notas aprovadas ✔",
        es: "¡Qué bien! Se publicaron los puntajes ✔",
        ja: "良いですね！スコアが公開されました✔",
    },
    "Default points allocation": {
        pt: "Pontuação padrão",
        es: "Asignación de puntos por defecto",
        ja: "デフォルトのポイント配分",
    },
    "This determines the baseline rankings points each event place receives in this series.": {
        pt: "Determina a pontuação padrão que cada colocado recebe na temporada.",
        es: "Esto determina los puntos clasificatorios iniciales que recibe cada posición en un evento de este grupo",
        ja: "これにより、このシリーズの各イベントの順位で獲得できるランキングポイントの基準値が決まります。",
    },
    "Liveheats default": {
        pt: "Padrão do Liveheats",
        es: "Liveheats por defecto",
        ja: "Liveheatsのデフォルト",
    },
    "Display field on ranking": {
        pt: "Mostrar atributo no ranking",
        es: "Mostrar campo en las clasificaciones",
        ja: "ランキングの表示フィールド",
    },
    "Show cut line": {
        pt: "Mostrar linha de corte",
        es: "Mostrar línea de corte",
        ja: "カットラインを表示する",
    },
    "Add another cut line": {
        pt:"Adicione outra linha de corte",
        es: "Añadir otra línea de corte",
        ja:"別のカットラインを追加する",
    },
    "Show a line on your ranking to indicate which athletes will progress or 'make the cut'.":{
        pt:"Mostre uma linha em seu ranking para indicar quais atletas irão progredir ou 'passar o corte'.",
        es:"Muestra una línea en tu ranking para indicar qué atletas progresarán o 'superarán el corte'.",
        ja:"ランキングにラインを表示して、どのアスリートが進行または'カットを通過'するかを示します。",
    },
    "Select a field to display on the public ranking with each athlete or team, for example nationality": {
        pt: "Selecine um atributo para aparecer com cada atleta no ranking público, como por exemplo nacionalidade",
        es: "Selecciona un campo en el que se mostrarán las clasificaciones públicas con cada atleta o equipo (p.ej., nacionalidad)",
        ja: "公開ランキングに表示するフィールド（例：国籍）を選手やチームごとに選択します",
    },
    "None - display name only": {
        pt: "Nenhum - mostrar apenas o nome",
        es: "Ninguno (solo mostrar el nombre)",
        ja: "なし - 名前のみ表示",
    },
    "Team leaderboard": {
        pt: "Ranking dos times",
        es: "Tabla de posiciones del equipo",
        ja: "チームのリーダーボード",
    },
    "The team leaderboard will appear here once there's some scores": {
        pt: "O ranking dos times estará disponível aqui assim que houverem notas",
        es: "Aquí aparecerá la tabla de posiciones del equipo una vez que haya algunos puntajes",
        ja: "チームのリーダーボードは、スコアが出るとここに表示されます",
    },
    "Round leaderboard": {
        pt: "Ranking do round",
        es: "Tabla de posiciones de la ronda",
        ja: "ラウンドのリーダーボード",
    },
    "The round leaderboard will appear here once there's some scores": {
        pt: "O ranking do round estará disponível aqui assim que houverem notas",
        es: "Aquí aparecerá la tabla de posiciones de la ronda una vez que haya algunos puntajes",
        ja: "ラウンドのリーダーボードは、スコアが出るとここに表示されます",
    },
    "We need the score category 😉": {
        pt: "É necessário escolher a categoria da nota 😉",
        es: "Debes ingresar la categoría de puntaje 😉",
        ja: "スコアカテゴリーが必要です😉",
    },
    "Assign tag": {
        pt: "Atribuir pulseiras NFC",
        es: "Asignar etiqueta",
        ja: "タグを割り当てる",
    },
    "Go back": {
        pt: "Voltar",
        es: "Volver",
        ja: "戻る",
    },
    "Overwrite": {
        pt: "Sobreescrever",
        es: "Sobrescribir",
        ja: "上書き",
    },
    "Scan different tag": {
        pt: "Escanear outra pulseira",
        es: "Escanear una etiqueta diferente",
        ja: "別のタグをスキャンする",
    },
    "Tag successfully linked": {
        pt: "Pulseira vinculada",
        es: "La etiqueta se vinculó correctamente",
        ja: "タグのリンクに成功しました",
    },
    "tag_already_linked_can_overwrite": {
        en: ({ athleteName }) => `This tag is linked to ${athleteName}. You can overwrite or scan another tag.`,
        pt: ({ athleteName }) => `Esta pulseira está vinculada a ${athleteName}. Você pode sobreescrever ou usar outra pulseira.`,
        es: ({ athleteName }) => `Esta etiqueta está vinculada a ${athleteName}. Puedes sobrescribirla o escanear una etiqueta nueva.`,
        ja: ({ athleteName }) => `このタグは ${athleteName} にリンクされています。これを上書きできます。また別のタグをスキャンすることもできます。`,
    },
    "tag_already_linked_cannot_overwrite": {
        en: ({ athleteName }) => `This tag is linked to ${athleteName}. Please try another.`,
        pt: ({ athleteName }) => `Esta pulseira está vinculada a ${athleteName}. Por favor use outra pulseira.`,
        es: ({ athleteName }) => `Esta etiqueta está vinculada a ${athleteName}. Prueba con otra.`,
        ja: ({ athleteName }) => `このタグは ${athleteName} にリンクされています。別のタグをお試しください。`,
    },
    "Find athlete": {
        pt: "Encontrar atleta",
        es: "Buscar atleta",
        ja: "選手を探す",
    },
    "Scan to confirm": {
        pt: "Escanear pulseiras",
        es: "Escanear para confirmar",
        ja: "スキャンして確認する",
    },
    "x_confirmed_of_registered_athletes": {
        en: ({ confirmed, registered }) => `${confirmed}/${registered} athletes confirmed`,
        pt: ({ confirmed, registered }) => `${confirmed}/${registered} atletas confirmados`,
        es: ({ confirmed, registered }) => `${confirmed}/${registered} atletas confirmados`,
        ja: ({ confirmed, registered }) => `${confirmed}/${registered} 選手が確定しました`,
    },
    "x_confirmed_of_registered_teams": {
        en: ({ confirmed, registered }) => `${confirmed}/${registered} teams confirmed`,
        pt: ({ confirmed, registered }) => `${confirmed}/${registered} times confirmados`,
        es: ({ confirmed, registered }) => `${confirmed}/${registered} equipos confirmados`,
        ja: ({ confirmed, registered }) => `${confirmed}/${registered} チームが確定しました`,
    },
    "Hold device near tag": {
        pt: "Colocar dispositivo perto da pulseira",
        es: "Sujeta el dispositivo cerca de la etiqueta",
        ja: "タグにデバイスを近づける",
    },
    "Scanning failed, try again": {
        pt: "Leitura falhou, tente novamente",
        es: "Error de escaneado, inténtalo de nuevo",
        ja: "スキャンに失敗しました。もう一度やり直してください",
    },
    "Overwrite failed, try again": {
        pt: "Sobreescrever falhou, tente novamente",
        es: "Error de sobrescritura, inténtalo de nuevo",
        ja: "上書きに失敗しました。もう一度やり直してください",
    },
    "Athlete confirmed": {
        pt: "Atleta confirmado",
        es: "Atleta confirmado",
        ja: "選手が確定しました",
    },
    "No athlete linked to this tag": {
        pt: "Não tem nenhum atleta vinculado a esta pulseira",
        es: "La etiqueta no tiene ningún atleta vinculado",
        ja: "このタグにリンクされている選手はいません",
    },
    "no_athlete_linked_to_tag_or_bad_scan": {
        en: <>We're having issues reading this tag - please scan again. If that doesn't work, <Link to="/director/athletes-teams" component={MuiLink}>assign it to the athlete again</Link>, or supply them with a new tag.</>,
        pt: <>Não estamos conseguindo escanear esta pulseira - por favor escaneie novamente. Se isso não funcionar <Link to="/director/athletes-teams" component={MuiLink}>vincule ao atleta novamente</Link>, ou troque a pulseira.</>,
        es: <>No podemos leer esta etiqueta, vuelve a escanearla. Si eso no funciona, <Link to="/director/athletes-teams" component={MuiLink}>vuelve a asignársela al atleta</Link> o asigna una etiqueta nueva.</>,
        ja: <>このタグの読み込みで問題が発生しました。もう一度スキャンしてください。うまくいかない場合は、<Link to="/director/athletes-teams" component={MuiLink}>もう一度選手に割り当てる</Link>か、新しいタグを提供してください。</>,
    },
    "Faulty tag. Please try another": {
        pt: "Pulseira com defeito, por favor use outra",
        es: "La etiqueta es defectuosa, prueba con otra",
        ja: "タグに欠陥があります。別のタグをお試しください",
    },
    "scanned_athlete_not_in_division": {
        en: ({ name }) => `${name} isn't in this division`,
        pt: ({ name }) => `${name} não esta nesta categoria`,
        es: ({ name }) => `${name} no participa en esta categoría`,
        ja: ({ name }) => `${name} はこの部門にいません`,
    },
    "scanned_athlete_add_to_division": {
        en: ({ name }) => `What division do you want to add ${name} to?`,
        pt: ({ name }) => `Em qual categoria você quer adicionar ${name}?`,
        es: ({ name }) => `¿A qué categoría quieres agregar a ${name}?`,
        ja: ({ name }) => `${name} をどの部門に追加しますか？`,
    },
    "scanned_athlete_not_in_team": {
        en: ({ name }) => `${name} isn't in a team in this division`,
        pt: ({ name }) => `${name} não esta num time nesta categoria`,
        es: ({ name }) => `${name} no participa en un equipo de esta categoría`,
        ja: ({ name }) => `${name} はこの部門のチームにいません`,
    },
    "Select team": {
        pt: "Selecione um time",
        es: "Seleccionar equipo",
        ja: "チームを選択する",
    },
    "Add to team": {
        pt: "Adicionar ao time",
        es: "Agregar al equipo",
        ja: "チームに追加する",
    },
    "Done scanning": {
        pt: "Finalizar leitura",
        es: "Escaneo finalizado",
        ja: "スキャン完了",
    },
    "scanning_not_supported": {
        en: <>Bummer, your device or browser does not support scanning. Learn more about compatibility <MuiLink variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/4406002935700" target="_blank">here</MuiLink>.</>,
        pt: <>Eita, seu dispositivo ou navegador não suportam NFC. Leia mais sobre compatibilidade <MuiLink variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/4406002935700" target="_blank">aqui</MuiLink>.</>,
        es: <>¡Qué pena! El dispositivo o navegador no es compatible con el escaneo. Obtén más información sobre la compatibilidad <MuiLink variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/4406002935700" target="_blank">aquí</MuiLink>.</>,
        ja: <>残念ですが、お使いのデバイスまたはブラウザがスキャンに対応していません。互換性の詳細については、<MuiLink variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/4406002935700" target="_blank">こちら</MuiLink>をご覧ください。</>,
    },
    "Scan another tag": {
        pt: "Escanear outra pulseira",
        es: "Escanear otra etiqueta",
        ja: "別のタグをスキャンする",
    },
    "Confirm manually": {
        pt: "Confirmar manualmente",
        es: "Confirmar de forma manual",
        ja: "手動で確認する",
    },
    "Scan again": {
        pt: "Escanear novamente",
        es: "Volver a escanear",
        ja: "もう一度スキャンする",
    },
    "Scan to record": {
        pt: "Escanear pulseiras",
        es: "Escanear para registrar",
        ja: "スキャンして記録する",
    },
    "Tag read successfully": {
        pt: "Pulseira escaneada com sucesso",
        es: "La etiqueta se escaneó correctamente",
        ja: "タグの読み取りに成功しました",
    },
    "Scanning": {
        pt: "Escaneando",
        es: "Escaneando",
        ja: "スキャン中",
    },
    "Error": {
        pt: "Erro",
        es: "Error",
        ja: "エラー",
    },
    "Warning": {
        pt: "Alerta",
        es: "Advertencia",
        ja: "警告",
    },
    "Ready to read tag": {
        pt: "Pronto para escanear pulseira",
        es: "Ya se puede escanear la etiqueta",
        ja: "タグを読み取る準備ができました",
    },
    "Hello!": {
        pt: "Olá!",
        es: "¡Hola!",
        ja: "こんにちは！",
    },
    "This tag belongs to:": {
        pt: "Essa pulseira pertence a:",
        es: "Esta etiqueta pertenece a:",
        ja: "このタグは次のカテゴリに属しています。",
    },
    "This tag is empty": {
        pt: "Essa pulseira está vazia",
        es: "La etiqueta está vacía",
        ja: "このタグは空です",
    },
    "Athlete recorded": {
        pt: "Atleta posicionado",
        es: "Atleta registrado",
        ja: "選手が記録されました",
    },
    "Team recorded": {
        pt: "Time posicionado",
        es: "Equipo registrado",
        ja: "チームが記録されました",
    },
    "scanned_athlete_not_in_heat": {
        en: ({ name }) => `${name} isn't in this heat`,
        pt: ({ name }) => `${name} não esta nesta bateria`,
        es: ({ name }) => `${name} no participa en esta serie`,
        ja: ({ name }) => `${name} はこのヒートにいません`,
    },
    "scanned_athlete_not_in_team_in_heat": {
        en: ({ name }) => `${name} isn't in a team in this heat`,
        pt: ({ name }) => `${name} não esta num time nesta bateria`,
        es: ({ name }) => `${name} no participa en un equipo de esta serie`,
        ja: ({ name }) => `${name} はこのヒートのチームにいません`,
    },
    "x_recorded_of_total_athletes": {
        en: ({ recorded, total }) => `${recorded}/${total} athletes recorded`,
        pt: ({ recorded, total }) => `${recorded}/${total} atletas posicionados`,
        es: ({ recorded, total }) => `${recorded}/${total} atletas registrados`,
        ja: ({ recorded, total }) => `${recorded}/${total} 選手が記録されました`,
    },
    "x_recorded_of_total_teams": {
        en: ({ recorded, total }) => `${recorded}/${total} teams recorded`,
        pt: ({ recorded, total }) => `${recorded}/${total} times posicionados`,
        es: ({ recorded, total }) => `${recorded}/${total} equipos registrados`,
        ja: ({ recorded, total }) => `${recorded}/${total} チームが記録されました`,
    },
    "Record manually": {
        pt: "Posicionar manualmente",
        es: "Registrar de forma manual",
        ja: "手動で記録する",
    },
    "Our apologies. There's been a slight problem reaching our secure payment gateway. Please reload.": {
        pt: "Desculpa, parece que houve um pequeno problema com nosso provedor de pagamentos. Por favor recarregue a página.",
        es: "Lo sentimos. Hubo un pequeño problema de conexión con la plataforma de pago segura. Vuelve a cargar la página.",
        ja: "申し訳ございません。安全な決済ゲートウェイへのアクセスで問題が発生しました。リロードしてください。",
    },
    "Snow park & pipe (Interschools)": {
        es: "Snowpark y tubería (interescolares)",
        ja: "スノーパーク & パイプ（学校対抗）",
    },
    "URBN Surf Format": {
        es: "Formato URBN Surf",
        ja: "URBNサーフフォーマット",
    },
    "Big air snowboard (startlist)": {
        es: "Snowboard en big air (lista de participantes)",
        ja: "ビッグエアスノーボード（スタートリスト）",
    },
    "Big air freeski (startlist)": {
        es: "Esquí acrobático en big air (lista de participantes)",
        ja: "ビッグエアフリースキー（スタートリスト）",
    },
    "Big air basic (startlist)": {
        es: "Competencia básica en big air (lista de participantes)",
        ja: "ビッグエアベーシック（スタートリスト）",
    },
    "Big air snowboard (heats)": {
        es: "Snowboard en big air (series)",
        ja: "ビッグエアスノーボード（ヒート）",
    },
    "Big air freeski (heats)": {
        es: "Esquí acrobático en big air (series)",
        ja: "ビッグエアフリースキー（ヒート）",
    },
    "Big air basic (heats)": {
        es: "Competencia básica en big air (series)",
        ja: "ビッグエアベーシック（ヒート）",
    },
    "Skimboarding": {
        es: "Skimboard",
        pt: "Skimboard",
        ja: "スキムボード",
    },
    "Wakesurfing": {
        es: "Wakesurf",
        pt: "Wakesurf",
        ja: "ウェイクサーフィン",
    },
    "Set age restrictions": {
        pt: "Definir restrições de idade",
        es: "Configurar límites de edad",
        ja: "年齢制限の設定",
    },
    "Result": {
        pt: "Resultado",
        es: "Resultado",
        ja: "結果",
    },
    "Sorry, we can't seem to find that athlete 😞": {
        pt: "Desculpe-nos, não conseguimos encontrar esse atleta 😞",
        es: "Lo sentimos. No podemos encontrar al atleta 😞",
        ja: "残念ながら、その選手は見つかりませんでした😞",
    },
    "Clear your search to view all athletes": {
        pt: "Limpe o campo de busca para ver todos os atletas",
        es: "Borra la búsqueda para ver a todos los atletas",
        ja: "すべての選手を表示するには検索をクリアしてください",
    },
    "Clear your search and try again": {
        pt: "Limpe o campo de busca e tente novamente",
        es: "Borra la búsqueda y vuelve a intentarlo",
        ja: "検索をクリアしてもう一度検索してください",
    },
    "Break": {
        pt: "Pausa",
        es: "Pausa",
        ja: "休憩",
    },
    "Assign another tag": {
        pt: "Definir outra pulseira",
        es: "Asignar otra etiqueta",
        ja: "別のタグを割り当てる",
    },
    "Connecting": {
        pt: "Conectando",
        es: "Conectando",
        ja: "接続中",
    },
    "Create": {
        pt: "Criar",
        es: "Crear",
        ja: "作成",
    },
    "Active": {
        pt: "Ativa",
        es: "Activar",
        ja: "アクティブ",
    },
    "Field": {
        pt: "Campo",
        es: "Campo",
        ja: "フィールド",
    },
    "Type": {
        pt: "Tipo",
        es: "Tipo",
        ja: "タイプ",
    },
    "No linked athletes": {
        pt: "Nenhum atleta atrelado",
        es: "No hay ningún atleta vinculado",
        ja: "リンクされている選手はいません",
    },
    "No linked users": {
        pt: "Nenhum usuário atrelado",
        es: "No hay ningún usuario vinculado",
        ja: "リンクされているユーザーはいません",
    },
    "Sorry, we can't seem to find any results for you search 😞": {
        pt: "Desculpe-nos, não encontramos nenhum resultado para sua busca 😞",
        es: "Lo sentimos. No podemos encontrar ningún resultado para tu búsqueda 😞",
        ja: "残念ながら、検索結果が見つかりません😞",
    },
    "Competitors": {
        pt: "Competidores",
        es: "Competidores",
        ja: "選手",
    },
    "in": {
        pt: "em",
        es: "en",
        ja: "in",
    },
    "entry_search_result_no_seed": {
        en: ({ athlete, division }) => `${athlete} is in ${division}`,
        pt: ({ athlete, division }) => `${athlete} está na categoria ${division}`,
        es: ({ athlete, division }) => `${athlete} participa en ${division}`,
        ja: ({ athlete, division }) => `${athlete} は ${division} にいます`,
    },
    "entry_search_result_with_position": {
        en: ({ athlete, division, position }) => `${athlete} is position ${position} in ${division}`,
        pt: ({ athlete, division, position }) => `${athlete} está na posição ${position} da categoria ${division}`,
        es: ({ athlete, division, position }) => `${athlete} está en la posición ${position} en ${division}`,
        ja: ({ athlete, division, position }) => `${athlete} は ${division} のポジション ${position} です`,
    },
    "entry_search_result_with_seed": {
        en: ({ athlete, division, seed }) => `${athlete} is seed ${seed} in ${division}`,
        pt: ({ athlete, division, position }) => `${athlete} está na posição ${position} da categoria ${division}`,
        es: ({ athlete, division, seed }) => `Se asignó a ${athlete} como cabeza de serie ${seed} en ${division}`,
        ja: ({ athlete, division, seed }) => `${athlete} は ${division} のシード ${seed} です`,
    },
    "Top scores": {
        pt: "Melhores pontuações",
        es: "Mejores puntajes",
        ja: "トップスコア",
    },
    "Are you sure you want to remove this athlete?": {
        pt: "Você tem certeza que deseja remover esse atleta?",
        es: "¿Confirmas que deseas eliminar a este atleta?",
        ja: "本当にこの選手を削除しますか？",
    },
    "Add anyway": {
        pt: "Adicionar mesmo assim",
        es: "Agregar de todos modos",
        ja: "とにかく追加する",
    },
    "Select a division": {
        pt: "Selecionar categoria",
        es: "Seleccionar una categoría",
        ja: "部門を選択する",
    },
    "Final results will appear here once in. Check back soon.": {
        pt: "Os resultados aparecerão aqui assim que disponíveis. Volte em breve.",
        es: "Los resultados finales aparecerán aquí una vez que se definan. Vuelve pronto.",
        ja: "最終結果はここに掲載されます。後ほどチェックしてください。",
    },
    "All divisions": {
        pt: "Todas as categorias",
        es: "Todas las categorías",
        ja: "全部門",
    },
    "Create groups": {
        pt: "Criar grupos",
        es: "Crear grupos",
        ja: "グループを作成する",
    },
    "Edit grouped division": {
        pt: "Editar grupo de categorias",
        es: "Editar categoría agrupada",
        ja: "グループ分けされた部門を編集する",
    },
    "Group name:": {
        pt: "Nome do grupo",
        es: "Nombre del grupo:",
        ja: "グループ名：",
    },
    "The group needs a name": {
        pt: "O grupo precisa de um nome",
        es: "El nombre del grupo es obligatorio",
        ja: "グループ名が必要です",
    },
    "Pick some divisions": {
        pt: "Escolha algumas categorias",
        es: "Elegir algunas categorías",
        ja: "いくつか部門を選んでください",
    },
    "Add divisions to group:": {
        pt: "Adicionar categorias ao grupo:",
        es: "Agregar categorías al grupo:",
        ja: "グループに部門を追加する：",
    },
    "Enter division": {
        pt: "Categoria",
        es: "Ingresar categoría",
        ja: "部門に参加する",
    },
    "Sorry, we can't seem to find that division 😞": {
        pt: "Desculpe-nos, não conseguimos encontrar essa categoria 😞",
        es: "Lo sentimos. No podemos encontrar esa categoría 😞",
        ja: "残念ながら、その部門は見つからないようです😞",
    },
    "Clear your search to view all divisions": {
        pt: "Limpe o campo de busca para ver todas as categorias",
        es: "Borra la búsqueda para ver todas las categorías",
        ja: "検索をクリアしてすべての部門を表示する",
    },
    "create_group_tooltip": {
        en: <>You can use groups to run divisions together in a combined draw, but with results being attributed to each division. <a href="https://support.liveheats.com/hc/en-us/articles/4403584796052" target="_blank" rel="noopener noreferrer">Learn more.</a></>,
        pt: <>Você pode usar grupos para juntar categorias numa só chave, com os resultados sendo atribuídos para cada categoria corretamente. <a href="https://support.liveheats.com/hc/en-us/articles/4403584796052" target="_blank" rel="noopener noreferrer">Saiba mais.</a></>,
        es: <>Puedes usar los grupos para organizar categorías en un sorteo combinado y asignar resultados a cada categoría. <a href="https://support.liveheats.com/hc/en-us/articles/4403584796052" target="_blank" rel="noopener noreferrer">Obtén más información.</a></>,
        ja: <>グループを使用して、結合した抽選で部門を一緒に運用できますが、結果は各部門に帰属します。<a href="https://support.liveheats.com/hc/en-us/articles/4403584796052" target="_blank" rel="noopener noreferrer">詳しくはこちら。</a></>,
    },
    "Group successfully created. Nicely done 🙂": {
        pt: "Grupo criado com sucesso 🙂",
        es: "El grupo se creó correctamente. ¡Bien hecho! 🙂",
        ja: "グループが作成されました。よくできました🙂",
    },
    "Group successfully updated. Nicely done 🙂": {
        pt: "Grupo modificado com sucesso 🙂",
        es: "El grupo se actualizó correctamente. ¡Bien hecho! 🙂",
        ja: "グループが更新されました。よくできました🙂",
    },
    "Divisions successfully ungrouped. Nicely done 🙂": {
        pt: "Categorias removidas do grupo 🙂",
        es: "Las categorías se desagruparon correctamente. ¡Bien hecho! 🙂",
        ja: "部門のグループが解除されました。よくできました🙂",
    },
    "Ungroup": {
        pt: "Desagrupar",
        es: "Desagrupar",
        ja: "グループ解除",
    },
    "Ungroup divisions?": {
        pt: "Desagrupar categorias?",
        es: "¿Deseas desagrupar las categorías?",
        ja: "部門のグループ分けを解除しますか？",
    },
    "Ungrouping a division will destroy any draws and results for all divisions in the group, and cannot be undone.": {
        pt: "Esta ação irá destruir quaisquer chaves ou resultados para todas as categorias no grupo, e não pode ser desfeita.",
        es: "Si desagrupas una categoría, se perderán todos los sorteos y los resultados de todas las categorías y no podrás revertirlo.",
        ja: "部門のグループ分けを解除すると、グループ内のすべての部門の抽選と結果が破棄されます。これを元に戻すことはできません。",
    },
    "division_group_add_division": {
        en: ({ divisionName }) => <>{divisionName} already has a draw. If you add it to a group, this draw and any results will be discarded.</>,
        pt: ({ divisionName }) => <>{divisionName} já tem uma chave. Se você adicioná-la a um grupo, essa chave e quaisquer resultados serão descartados.</>,
        es: ({ divisionName }) => <>La categoría {divisionName} ya tiene un sorteo. Si la agregas a un grupo, este sorteo y los resultados se descartarán.</>,
        ja: ({ divisionName }) => <>{divisionName} にはすでに抽選があります。それをグループに追加する場合、この抽選と結果は破棄されます。</>,
    },
    "division_group_remove_division": {
        en: ({ divisionName }) => <>Remove {divisionName} from group? This division is part of the group draw. If you decide to remove it, we recommend redrawing this group.</>,
        pt: ({ divisionName }) => <>Remover {divisionName} do grupo? Essa categoria é parte do grupo de chaves. Se você decidir removê-la recomenda-se recriar as chaves desse grupo.</>,
        es: ({ divisionName }) => <>¿Deseas eliminar a {divisionName} del grupo? Esta categoría forma parte del sorteo del grupo. Si la eliminas, te recomendamos volver a sortear el grupo.</>,
        ja: ({ divisionName }) => <>グループから{divisionName}を削除しますか？この部門はグループ抽選の一部です。これを削除する場合は、このグループの抽選をもう一度行うことをお勧めします。</>,
    },
    "Where the cash at 💸? Your payments will appear here once you've received a paid event or membership registration.": {
        pt: "Onde está a grana 💸? Seus pagamentos aparecerão aqui uma vez que você tiver recebido alguma inscrição paga em um evento ou associação.",
        es: "¿Dónde está el dinero? 💸 Aquí podrás ver tus pagos una vez que recibas una inscripción en una membresía o un evento de pago.",
        ja: "お金はどこに💸？有料イベントまたは有料のメンバーシップ登録が完了すると、支払いはここに表示されます。",
    },
    "Grand total:": {
        pt: "Total geral:",
        es: "Total general:",
        ja: "総計：",
    },
    "Total less fees:": {
        pt: "Total menos taxas:",
        es: "Total sin cargos:",
        ja: "手数料を差し引いた合計：",
    },
    "Transactions:": {
        pt: "Transações:",
        es: "Transacciones:",
        ja: "取引：",
    },
    "Club leaderboard": {
        pt: "Ranking dos clubes",
        es: "Tabla de posiciones por clubes",
        ja: "クラブのリーダーボード",
    },
    "Settings": {
        pt: "Configurações",
        es: "Configuración",
        ja: "設定",
    },
    "Club leaderboard settings": {
        pt: "Configurações do ranking dos clubes",
        es: "Configuración de la tabla de posiciones por clubes",
        ja: "クラブのリーダーボード設定",
    },
    "team_leaderboard_caption": {
        en: <>The points set in this table will be automatically awarded to each competitor's team based on their final position in the division. <MuiLink href="https://liveheats.zendesk.com/hc/en-us/articles/9126933843860" target="_blank">Learn more</MuiLink></>,
        pt: <>Os pontos nesta tabela serão automaticamente somados ao time de cada competidor dependendo de sua colocação final na categoria. <MuiLink href="https://liveheats.zendesk.com/hc/en-us/articles/9126933843860" target="_blank">Saiba mais</MuiLink></>,
        es: <>Los puntos que se indican en esta tabla se asignarán automáticamente al equipo de cada competidor en función de su posición final en la categoría. <MuiLink href="https://liveheats.zendesk.com/hc/en-us/articles/9126933843860" target="_blank">Obtén más información</MuiLink></>,
        ja: <>この表で設定されたポイントは、各部門の最終順位に応じて、各選手のチームに自動的に付与されます。 <MuiLink href="https://liveheats.zendesk.com/hc/en-us/articles/9126933843860" target="_blank">詳しくはこちら</MuiLink></>,
    },
    "Add place": {
        pt: "Adicionar lugar",
        es: "Agregar posición",
        ja: "場所を追加する",
    },
    "Club leaderboard has been set up.": {
        pt: "O ranking dos clubes for configurado.",
        es: "Se configuró la tabla de posiciones por clubes.",
        ja: "クラブのリーダーボードが設定されました。",
    },
    "Club": {
        pt: "Clube",
        es: "Club",
        ja: "クラブ",
    },
    "Points": {
        pt: "Pontos",
        es: "Puntos",
        ja: "ポイント",
    },
    "The live standings can be found here once when some results are locked in": {
        pt: "O ranking pode ser visto aqui assim que resultados forem confirmados",
        es: "Aquí podrás consultar las posiciones en directo una vez que se hayan confirmado algunos resultados",
        ja: "一部の結果が確定すると、ここでライブの順位を確認できます",
    },
    "View full leaderboard": {
        pt: "Ver ranking completo",
        es: "Ver tabla de posiciones completa",
        ja: "リーダーボード全体を見る",
    },
    "Membership for": {
        pt: "Associação para",
        es: "Membresía para",
        ja: "会員：",
    },
    "Registration to": {
        pt: "Inscrição para",
        es: "Inscripción en",
        ja: "登録：",
    },
    "Fee": {
        pt: "Taxa",
        es: "Cargo",
        ja: "料金",
    },
    "Net": {
        pt: "Líquido",
        es: "Neto",
        ja: "ネット",
    },
    "Reference": {
        pt: "Referência",
        es: "Referencia",
        ja: "参考",
    },
    "Payment details": {
        pt: "Detalhes do pagamento",
        es: "Detalles del pago",
        ja: "支払いの詳細",
    },
    "Purchase details": {
        pt: "Detalhes da compra",
        es: "Detalles de la compra",
        ja: "購入の詳細",
    },
    "Refund payment to": {
        pt: "Reembolsar pagamento para",
        es: "Reembolsar el pago a",
        ja: "払い戻し先",
    },
    "Refunds exclude any transaction fees and take 5-10 days to appear on a customer's statement.": {
        pt: "Reembolsos excluem a taxa de transação e levam 5-10 dias para serem efetuados.",
        es: "Los reembolsos no incluyen los cargos por transacción y demoran entre 5 y 10 días en aparecer en el estado de cuenta del cliente.",
        ja: "払い戻しは取引手数料を差し引いた額で、お客様の利用明細書に記載されるまでに5～10日かかります。",
    },
    "Continue": {
        pt: "Continuar",
        es: "Continuar",
        ja: "続ける",
    },
    "All done! The payment has been successfully refunded": {
        pt: "O reembolso foi feito com sucesso!",
        es: "¡Listo! El pago se reembolsó correctamente.",
        ja: "すべて完了しました！払い戻しが完了しました",
    },
    "Refund": {
        pt: "Reembolsar",
        es: "Reembolsar",
        ja: "払い戻し",
    },
    "Refunded": {
        pt: "Reembolsado",
        es: "Reembolsado",
        ja: "払い戻し済み",
    },
    "Ouch. We were unable to process the refund, please try again": {
        pt: "Não foi possível fazer o reembolse, por favor tente novamente",
        es: "No pudimos procesar el reembolso, vuelve a intentarlo.",
        ja: "残念ながら、払い戻しの手続きができませんでした。もう一度お試しください",
    },
    "refund_cannot_exceed": {
        en: ({ netAmount, currency }) => `Refund cannot exceed ${formatPrice(netAmount, currency)}`,
        pt: ({ netAmount, currency }) => `Reembolso não pode ser maior do que ${formatPrice(netAmount, currency)}`,
        es: ({ netAmount, currency }) => `El reembolso no puede ser mayor que ${formatPrice(netAmount, currency)}`,
        ja: ({ netAmount, currency }) => `払い戻し額は ${formatPrice(netAmount, currency)} 以下である必要があります`,
    },
    "Change the template in use for this division": {
        pt: "Mardar o gabarito para esta categoria",
        es: "Cambiar la plantilla actual para esta categoría",
        ja: "この部門に使用されているテンプレートを変更する",
    },
    "Kapow! We've changed the template and reset all settings.": {
        pt: "Kapow! Nós mudamos os gabaritos resetamos as configurações.",
        es: "¡Pum! Ya cambiamos la plantilla y restablecimos la configuración.",
        ja: "ばっちりです！テンプレートを変更し、すべての設定をリセットしました。",
    },
    "change_template_warning": {
        en: () => <>Changing the template will restore the division's settings to the template defaults, and can't be undone. <MuiLink underline="none" href="https://support.liveheats.com/hc/en-us/articles/4410719048724" target="_blank">Learn more.</MuiLink></>,
        pt: () => <>Mudando o gabarito todas as configurações da categoria serão apagadas e usarão as configurações do gabarito. Esta ação não pode ser desfeita. <MuiLink underline="none" href="https://support.liveheats.com/hc/en-us/articles/4410719048724" target="_blank">Saiba mais.</MuiLink></>,
        es: () => <>Si cambias la plantilla, se restablecerá la configuración de la categoría a los valores predeterminados de la plantilla y no podrás revertirlo. <MuiLink underline="none" href="https://support.liveheats.com/hc/en-us/articles/4410719048724" target="_blank">Obtén más información.</MuiLink></>,
        ja: () => <>テンプレートを変更すると、部門の設定がテンプレートのデフォルトに戻ります。これを元に戻すことはできません。<MuiLink underline="none" href="https://support.liveheats.com/hc/en-us/articles/4410719048724" target="_blank">詳しくはこちら。</MuiLink></>,
    },
    "Set rolling membership": {
        pt: "Configurar associação por tempo",
        es: "Configurar membresía renovable",
        ja: "ローリング会員の設定",
    },
    "Set membership duration": {
        pt: "Configure a duração",
        es: "Configurar duración de la membresía",
        ja: "会員期間の設定",
    },
    "Use this setting if your membership is not for a single series or season, and instead is valid for a set period of time after registration, for example 12 months.": {
        pt: "Use esta configuração se a sua associação não é específica a uma temporado, mas sim válida por um período determinado de tempo, por exemplo 12 meses.",
        es: "Usa esta configuración si la membresía no está destinada a un único grupo o temporada, sino que es válida por un tiempo determinado después de la inscripción, por ejemplo, 12 meses.",
        ja: "この設定は、会員が1シリーズまたは1シーズンではなく、登録後一定期間（例えば12ヶ月間）有効である場合に使用します。",
    },
    "Membership is valid for:": {
        pt: "Associação é valida por:",
        es: "La membresía tiene una validez de:",
        ja: "会員有効期間：",
    },
    "Duration unit:": {
        pt: "Unidade de duração:",
        es: "Unidad de duración:",
        ja: "期間の単位：",
    },
    "Select a unit": {
        pt: "Selecione uma unidade",
        es: "Seleccionar una unidad",
        ja: "単位を選択する",
    },
    "membership_valid_for": {
        en: ({ length, unit }) => `Membership is valid for: ${length} ${unit}${plural(length)}`,
        pt: ({ length, unit }) => <>Associação é valida por: {length} <T>{unit}${plural(length)}</T></>,
        es: ({ length, unit }) => `La membresía tiene una validez de ${length} ${unit}${plural(length)}`,
        ja: ({ length, unit }) => `会員有効期間：${length} ${unit}`,
    },
    "year": {
        pt: "ano",
        es: "año",
        ja: "年",
    },
    "years": {
        pt: "anos",
        es: "años",
        ja: "年",
    },
    "month": {
        pt: "mês",
        es: "mes",
        ja: "ヶ月",
    },
    "months": {
        pt: "meses",
        es: "meses",
        ja: "ヶ月",
    },
    "day": {
        pt: "dia",
        es: "día",
        ja: "日",
    },
    "days": {
        pt: "dias",
        es: "días",
        ja: "日",
    },
    "Memberships": {
        pt: "Associações",
        es: "Membresías",
        ja: "会員",
    },
    "Federated membership": {
        pt: "Associação federada",
        es: "Membresía federada",
        ja: "連盟会員",
    },
    "federated_help_text": {
        en: <>Members will join via a series that belongs to a child organisation in your federation. They will become federation members, as well as a member in the child organisation. <MuiLink href="mailto:community@liveheats.com">Contact us</MuiLink> to learn more.</>,
        pt: <>Atletas se associarão diretamente à temporada das organisações ligadas a sua federação. Atletas seão membros tanto da federação quanto da sub-organisação. <MuiLink href="mailto:community@liveheats.com">Entre em contato</MuiLink> para saber mais.</>,
        es: <>Los miembros se afiliarán por medio de un grupo que pertenezca a una organización subsidiaria de tu federación. Pasarán a ser miembros de la federación y, a su vez, miembros de la organización subsidiaria. <MuiLink href="mailto:community@liveheats.com">Contáctanos</MuiLink> para obtener más información.</>,
        ja: <>会員は、連盟内の子団体に属するシリーズを通じて参加します。連盟会員となり、同時に子団体のメンバーにもなります。詳しくは<MuiLink href="mailto:community@liveheats.com">お問い合わせ</MuiLink>ください。</>,
    },
    "Activate federated membership?": {
        pt: "Ativar associação federada?",
        es: "¿Deseas activar la membresía federada?",
        ja: "連盟会員を有効にしますか？",
    },
    "select_series_to_add": {
        en: ({ federated_organisation_term = "organisation" }) => `Select ${federated_organisation_term}s' series to add:`,
        pt: ({ federated_organisation_term = "organizaçõe" }) => `Selecione as temporadas de cada ${federated_organisation_term}s:`,
        es: ({ federated_organisation_term = "organisation" }) => `Selecciona el grupo de ${federated_organisation_term} que deseas agregar:`,
        ja: ({ federated_organisation_term = "organisation" }) => `追加する ${federated_organisation_term} のシリーズを選択します：`,
    },
    "Please select some series": {
        pt: "Por favor selecione algumas temporadas",
        es: "Selecciona algún grupo",
        ja: "シリーズをいくつか選択してください",
    },
    "This is a federated series": {
        pt: "Esta temporada é parte de uma federação",
        es: "Este es un grupo federado",
        ja: "これは連盟シリーズです",
    },
    "federated_series_belongs_to_parent": {
        en: ({ parentSeriesName, parentOrgName }) => `This is a federated series, belonging to ${parentSeriesName} by ${parentOrgName}. Only admins at ${parentOrgName} can edit the membership settings.`,
        pt: ({ parentSeriesName, parentOrgName }) => `Esta é uma temporada federada, que pertence a ${parentSeriesName} por ${parentOrgName}. Somente administradores de ${parentOrgName} podem configurar a associação.`,
        es: ({ parentSeriesName, parentOrgName }) => `Este es un grupo federado que pertenece a ${parentSeriesName} de ${parentOrgName}. Solo los administradores de ${parentOrgName} pueden editar la configuración de la membresía.`,
        ja: ({ parentSeriesName, parentOrgName }) => `これは連盟シリーズで、${parentOrgName} の ${parentSeriesName} に属しています。会員設定を編集できるのは、${parentOrgName} の管理者だけです。`,
    },
    "federated_series_belongs_to_parent_rankings": {
        en: ({ parentSeriesName, parentOrgName }) => `This is a federated series, belonging to ${parentSeriesName} by ${parentOrgName}. Only admins at ${parentOrgName} can edit the rankings settings.`,
        pt: ({ parentSeriesName, parentOrgName }) => `Esta é uma temporada federada, que pertence a ${parentSeriesName} por ${parentOrgName}. Somente administradores de ${parentOrgName} podem configurar o ranking.`,
        es: ({ parentSeriesName, parentOrgName }) => `Este es un grupo federado que pertenece a ${parentSeriesName} de ${parentOrgName}. Solo los administradores de ${parentOrgName} pueden editar la configuración de clasificaciones.`,
        ja: ({ parentSeriesName, parentOrgName }) => `これは連盟シリーズで、${parentOrgName} の ${parentSeriesName} に属しています。ランキング設定を編集できるのは、${parentOrgName} の管理者だけです。`,
    },
    "View all events": {
        pt: "Ver todos os eventos",
        es: "Ver todos los eventos",
        ja: "すべてのイベントを見る",
    },
    "organisation_does_not_have_events": {
        en: ({ organisationName }) => `${organisationName} doesn’t have any events on Liveheats yet, check back soon!`,
        pt: ({ organisationName }) => `${organisationName} ainda não tem nenhum evento no Liveheats, volte em breve.`,
        es: ({ organisationName }) => `${organisationName} aún no tiene eventos en Liveheats. ¡Vuelve pronto!`,
        ja: ({ organisationName }) => `${organisationName} はまだLiveheatsでイベントを開催していません。また後でチェックしてください！`,
    },
    "organisation_has_no_upcoming_events": {
        en: ({ organisationName }) => `${organisationName} doesn’t have any upcoming events on Liveheats right now, check back soon!`,
        pt: ({ organisationName }) => `${organisationName} ainda não tem nenhum evento futuro no Liveheats, volte em breve.`,
        es: ({ organisationName }) => `${organisationName} aún no tiene eventos futuros en Liveheats. ¡Vuelve pronto!`,
        ja: ({ organisationName }) => `${organisationName} はまだLiveheatsでイベントを開催していません。また後でチェックしてください！`,
    },
    "organisation_has_no_past_events": {
        en: ({ organisationName }) => `${organisationName} doesn’t have any past events on Liveheats yet, check back soon!`,
        pt: ({ organisationName }) => `${organisationName} ainda não tem nenhum evento passado no Liveheats, volte em breve.`,
        es: ({ organisationName }) => `${organisationName} aún no tiene eventos pasados en Liveheats. ¡Vuelve pronto!`,
        ja: ({ organisationName }) => `${organisationName} はまだLiveheatsでイベントを開催していません。また後でチェックしてください！`,
    },
    "Clubs": {
        pt: "Clubes",
        es: "Clubes",
        ja: "クラブ",
    },
    "find_federated_organisation": {
        en: ({ federatedOrganisationTerm }) => `Find ${federatedOrganisationTerm || "organisation"}`,
        pt: ({ federatedOrganisationTerm }) => `Buscar por ${federatedOrganisationTerm || "organização"}`,
        es: ({ federatedOrganisationTerm }) => `Buscar ${federatedOrganisationTerm || "organización"}`,
        ja: ({ federatedOrganisationTerm }) => `${federatedOrganisationTerm || "団体"}を検索する`,
    },
    "clear_search_to_view_all_federated_organisations": {
        en: ({ federatedOrganisationTerm }) => `Clear your search to view all ${federatedOrganisationTerm || "organisation"}s`,
        pt: ({ federatedOrganisationTerm }) => `Limpe a busca para ver todas ${federatedOrganisationTerm || "organizaçõe"}s`,
        es: ({ federatedOrganisationTerm }) => `Borra la búsqueda para ver todas las ${federatedOrganisationTerm || "organizacione"}s`,
        ja: ({ federatedOrganisationTerm }) => `すべての${federatedOrganisationTerm || "団体"}を表示するには検索をクリアしてください`,
    },
    "Home": {
        pt: "Início",
        es: "Inicio",
        ja: "ホーム",
    },
    "Highlights": {
        pt: "Destaques",
        es: "Aspectos destacados",
        ja: "ハイライト",
    },
    "Preview draw": {
        pt: "Visualizar chave",
        es: "Vista previa del sorteo",
        ja: "抽選のプレビュー",
    },
    "Starting teams": {
        pt: "Número de times",
        es: "Equipos iniciales",
        ja: "スターティングチーム",
    },
    "Starting athletes": {
        pt: "Número de atletas",
        es: "Atletas iniciales",
        ja: "スターティング選手",
    },
    "x_(confirmed_athletes)": {
        en: ({ confirmed, isTeams }) => `${confirmed} (confirmed ${isTeams ? "teams" : "athletes"})`,
        pt: ({ confirmed, isTeams }) => `${confirmed} (${isTeams ? "times" : "atletas"} confirmados)`,
        es: ({ confirmed, isTeams }) => `${confirmed} (${isTeams ? "equipos" : "atletas"} confirmados)`,
        ja: ({ confirmed, isTeams }) => `${confirmed} (確定した${isTeams ? "チーム" : "選手"})`,
    },
    "x_(available_spots)": {
        en: ({ spots }) => `${spots} (available spots)`,
        pt: ({ spots }) => `${spots} (número de vagas)`,
        es: ({ spots }) => `${spots} (plazas disponibles)`,
        ja: ({ spots }) => `${spots} (利用可能なスポット)`,
    },
    "lower_than_confirmed_athletes": {
        en: ({ confirmed, isTeams }) => `Cannot be less than confirmed ${isTeams ? "teams" : "athletes"} (${confirmed})`,
        pt: ({ confirmed, isTeams }) => `Não pode ser menos to que o número de ${isTeams ? "times" : "atletas"} confirmados (${confirmed})`,
        es: ({ confirmed, isTeams }) => `No puede ser inferior a los ${isTeams ? "equipos" : "atletas"} confirmados (${confirmed})`,
        ja: ({ confirmed, isTeams }) => `確定した${isTeams ? "チーム" : "選手"}より少なくすることはできません (${confirmed})`,
    },
    "exceeds_available_spots": {
        en: ({ spots }) => `Exceeds available spots in division settings (${spots})`,
        pt: ({ spots }) => `É maior do que o número de vagas nas configurações da categoria (${spots})`,
        es: ({ spots }) => `Supera las plazas disponibles indicadas en la configuración de la categoría (${spots})`,
        ja: ({ spots }) => `部門設定で利用可能なスポットを超えています (${spots})`,
    },
    "exceeds_confirmed_athletes": {
        en: ({ confirmed, isTeams }) => `Exceeds confirmed ${isTeams ? "teams" : "athletes"} (${confirmed}) which will leave empties`,
        pt: ({ confirmed, isTeams }) => `É maior do que o número confirmado de ${isTeams ? "times" : "atletas"} (${confirmed}) e cria lugares vazios`,
        es: ({ confirmed, isTeams }) => `Supera los ${isTeams ? "equipos" : "atletas"} confirmados (${confirmed}), por lo que quedarán plazas vacías`,
        ja: ({ confirmed, isTeams }) => `空きを残す確定した ${isTeams ? "teams" : "athletes"} (${confirmed}) を超えています`,
    },
    "Athletes per heat": {
        pt: "Atletas por bateria",
        es: "Atletas por serie",
        ja: "ヒートごとの選手",
    },
    "Teams per heat": {
        pt: "Times por bateria",
        es: "Equipos por serie",
        ja: "ヒートごとのチーム",
    },
    "Progression per heat": {
        pt: "Progressão por bateria",
        es: "Progresión por serie",
        ja: "ヒートごとの進出",
    },
    "Nothing to see here - there's no competitors in this draw. You can build a skeleton draw by adding a number of spots above, or build an empty draw.": {
        pt: "Nada pra ver aqui - esta chave está vazia. Você pode criar uma chave esqueleto adicionando um número de vagas acima, ou criar uma chave do zero.",
        es: "No hay nada para mostrar. Este sorteo no tiene competidores. Puedes crear un sorteo preliminar agregando un número de plazas arriba o crear un sorteo en blanco.",
        ja: "ここには何もありません - この抽選には選手がいません。上記でスポット数を追加してスケルトン抽選を作るか、空の抽選を作ることができます。",
    },
    "Additional division discount": {
        pt: "Preço com desconto para categoria adicional",
        es: "Descuento por categoría adicional",
        ja: "追加の部門割引",
    },
    "Family discount": {
        pt: "Desconto para famílias",
        es: "Descuento familiar",
        ja: "ファミリー割引",
    },
    "Group discount": {
        pt: "Desconto para grupos",
        es: "Descuento para grupos",
        ja: "グループ割引",
    },
    "family_discounts_for": {
        en: ({ familyDiscounts, currency }) => <>Family discounted total price will automatically apply: {joinBy(", ", " and ", Immutable.fromJS(familyDiscounts.map(familyDiscount => `${formatPrice(familyDiscount[1], currency)} for families of ${familyDiscount[0]}`)))} or more.</>,
        pt: ({ familyDiscounts, currency }) => <>Desconto no preço final para uma família será automaticamente aplicado: {joinBy(", ", " e ", Immutable.fromJS(familyDiscounts.map(familyDiscount => `${formatPrice(familyDiscount[1], currency)} para famílias de ${familyDiscount[0]}`)))} ou mais.</>,
        es: ({ familyDiscounts, currency }) => <>El importe total con descuento familiar se aplicará automáticamente: {joinBy(", ", " y ", Immutable.fromJS(familyDiscounts.map(familyDiscount => `${formatPrice(familyDiscount[1], currency)} para familias de ${familyDiscount[0]}`)))} o más integrantes.</>,
        ja: ({ familyDiscounts, currency }) => <>家族の合計割引価格が自動的に適用されます。{Immutable.fromJS(familyDiscounts.map((familyDiscount, index) => `家族${familyDiscount[0]}人${familyDiscounts.length === (index+1) ? "以上" : ""}は${formatPrice(familyDiscount[1], currency)}`)).join("、")}になります。</>,
    },
    "group_discounts_for": {
        en: ({ groupDiscounts, currency }) => <>Group discounted total price will automatically apply: {joinBy(", ", " and ", Immutable.fromJS(groupDiscounts.map(groupDiscount => `${formatPrice(groupDiscount[1], currency)} for groups of ${groupDiscount[0]}`)))} or more.</>,
        pt: ({ groupDiscounts, currency }) => <>Desconto no preço final para um grupo será automaticamente aplicado: {joinBy(", ", " e ", Immutable.fromJS(groupDiscounts.map(groupDiscount => `${formatPrice(groupDiscount[1], currency)} para grupos de ${groupDiscount[0]}`)))} ou mais.</>,
        es: ({ groupDiscounts, currency }) => <>El importe total con descuento grupal se aplicará automáticamente: {joinBy(", ", " y ", Immutable.fromJS(groupDiscounts.map(groupDiscount => `${formatPrice(groupDiscount[1], currency)} para grupos de ${groupDiscount[0]}`)))} o más integrantes.</>,
        ja: ({ groupDiscounts, currency }) => <>グループの合計割引価格が自動的に適用されます。{Immutable.fromJS(groupDiscounts.map((groupDiscount, index) => `グループ${groupDiscount[0]}人${groupDiscounts.length === (index+1) ? "以上" : ""}は${formatPrice(groupDiscount[1], currency)}`)).join("、")}になります。</>,
    },
    "additional_division_price": {
        en: ({ price }) => `After each athlete's first division, additional divisions are ${price}`,
        pt: ({ price }) => `Depois da primeira categoria de cada atleta, categorias adicionais são ${price}`,
        es: ({ price }) => `Después de la primera categoría de cada atleta, las categorías adicionales tienen un costo de ${price}`,
        ja: ({ price }) => `各選手の1つ目の部門以降、追加の部門は${price}になります`,
    },
    "x_users": {
        en: ({ x }) => `${x} user${plural(x)}`,
        pt: ({ x }) => `${x} usuário${plural(x)}`,
        es: ({ x }) => `${x} usuario${plural(x)}`,
        ja: ({ x }) => `${x}ユーザー`,
    },
    "Invite": {
        pt: "Convidar",
        es: "Invitar",
        ja: "招待",
    },
    "Once added, the user will have director or judge permissions in your Liveheats organiser account.": {
        pt: "Uma vez adicionada, a pessoa terá acesso as funções de diretor ou árbitro na sua organização.",
        es: "Después de que lo agregues, el usuario tendrá permisos de director o juez en tu cuenta de organizador de Liveheats.",
        ja: "一度追加されると、そのユーザーはLiveheatsの主催者アカウントでディレクターまたはジャッジの権限を持つことになります。",
    },
    "That user is already a director or judge at another organisation, so they cannot be invited to your account 🙁": {
        pt: "Esta pessoa já é diretor ou juiz de uma outra organização no Liveheats, portanto não podem ser adicionadas a sua conta 🙁",
        es: "El usuario ya es director o juez en otra organización, así que no puedes invitarlo a tu cuenta 🙁",
        ja: "そのユーザーはすでに他の団体のディレクターまたはジャッジであるため、あなたのアカウントに招待することはできません🙁",
    },
    "Nice one. User has been added and emailed ✅": {
        pt: "Feito. O usuário foi adicionado e enviamos um email ✅",
        es: "¡Muy bien! Ya se agregó al usuario y se le envió un correo electrónico ✅",
        ja: "いいですね。ユーザーが追加され、メールが送信されました✅",
    },
    "Nice one. User has been removed ✅": {
        pt: "Feito. O usuário foi removido ✅",
        es: "¡Muy bien! Ya se eliminó al usuario ✅",
        ja: "いいですね。ユーザーが削除されました✅",
    },
    "Contact": {
        pt: "Contato",
        es: "Contactar",
        ja: "連絡先",
    },
    "enquiry_for_organisation": {
        en: ({ name }) => `Enquiry for ${name}`,
        pt: ({ name }) => `Consulta por ${name}`,
        es: ({ name }) => `Consulta para ${name}`,
        ja: ({ name }) => `${name}に関する問い合わせ`,
    },
    "Priority judge": {
        pt: "Árbitro de prioridade",
        es: "Juez de prioridad",
        ja: "優先ジャッジ",
    },
    "NEW": {
        pt: "NOVO",
        es: "NOVEDAD",
        ja: "NEW",
    },
    "assign_priority": {
        en: ({ position }) => `Assign P${position}`,
        pt: ({ position }) => `Atribuir P${position}`,
        es: ({ position }) => `Asignar P${position}`,
        ja: ({ position }) => `P${position}を割り当てる`,
    },
    "PRIORITY": {
        pt: "PRIORIDADE",
        es: "PRIORIDAD",
        ja: "優先",
    },
    "ATHLETE": {
        pt: "ATLETA",
        es: "ATLETA",
        ja: "選手",
    },
    "Priority": {
        pt: "Prioridade",
        es: "Prioridad",
        ja: "優先",
    },
    "Priority management, reimagined by Liveheats": {
        pt: "Gerenciamento de prioridade, re-inventado pelo Liveheats",
        es: "Gestión de prioridades, reinventada por Liveheats",
        ja: "Liveheatsが再考した優先管理",
    },
    "Liveheats has built a user-friendly priority management tool, which can be accessed for an additional per-event fee. Your priority judge can quickly manage priority on a phone or tablet - and the real time priority order will be displayed on live scores, to the judges, in broadcast graphics, and a web-page optimised to be displayed on an event-site display screen for athletes in the water.": {
        pt: "Liveheats criou uma ferramenta incrivelmente simples para gerenciar prioridade, que pode ser acessada por uma taxa adicional por event. O juíz de prioridade pode rapidamente gerenciar as prioridades usando um tablet ou smartphone - e a prioridade será visualizada em tempo real nos resultados, para os árbitros, e numa página feita para ser vista pelos atletas dentro d'água.",
        es: "Liveheats creó una herramienta de gestión de prioridades fácil de usar, a la que se puede acceder por un costo adicional por evento. El juez de prioridad puede gestionar rápidamente las prioridades desde un teléfono o una tableta, y el orden de prioridad en tiempo real se mostrará en los puntajes en directo, a los jueces, en los gráficos para transmisión y en una página web optimizada que los atletas que están en el agua podrán ver en una pantalla en el lugar del evento.",
        ja: "Liveheatsは、ユーザーフレンドリーな優先管理ツールを作成しました。優先ジャッジは、スマホやタブレットで素早く優先順位を管理することができ、リアルタイムで優先順位がライブスコア、ジャッジ、ブロードキャスト用グラフィックス、イベント会場のディスプレイスクリーンに表示されるよう最適化されたウェブページに表示されます。",
    },
    "Activate for an event": {
        pt: "Usar em um evento",
        es: "Activar para un evento",
        ja: "イベントのために有効にする",
    },
    "Once you've created a draw, head back here for priority management.": {
        pt: "Depois de criadas as chaves, volte aqui para gerenciar prioridade.",
        es: "Una vez que hayas creado un sorteo, vuelve para gestionar las prioridades.",
        ja: "抽選を作成したら、ここに戻って優先管理を行います。",
    },
    "You're off the hook! This heat does not use priority.": {
        pt: "Ta liberado! Esta bateria não usa prioridade.",
        es: "¡Nada que hacer aquí! En esta serie no se usa el régimen de prioridades.",
        ja: "大丈夫です！このヒートは優先を使用していません。",
    },
    "Save format": {
        pt: "Salvar formato",
        es: "Guardar formato",
        ja: "フォーマットを保存する",
    },
    "Save format as new division template": {
        pt: "Salvar este formato como um gabarito pra categorias",
        es: "Guardar formato como nueva plantilla de la categoría",
        ja: "新しい部門テンプレートとしてフォーマットを保存します",
    },
    "Template created for future use, and set for this division ✅": {
        pt: "Gabarito criado com sucesso e configurado nesta categoria",
        es: "La plantilla se creó para usar más tarde y se configuró para era categoría ✅",
        ja: "将来使用するために作成され、この部門用に設定されたテンプレートです✅",
    },
    "Template": {
        pt: "Gabarito",
        es: "Plantilla",
        ja: "テンプレート",
    },
    "Template name": {
        pt: "Nome do gabarito",
        es: "Nombre de la plantilla",
        ja: "テンプレート名",
    },
    "custom_template_name": {
        en: ({ name }) => `e.g. (Custom) - ${name}`,
        pt: ({ name }) => `e.g. (Novo) - ${name}`,
        es: ({ name }) => `Por ejemplo (Personalizada) - ${name}`,
        ja: ({ name }) => `例：(カスタム) - ${name}`,
    },
    "The template needs a name": {
        pt: "O gabarito precisa de um nome",
        es: "El nombre de la plantilla es obligatorio",
        ja: "テンプレートには名前が必要です",
    },
    "Create template": {
        pt: "Criar gabarito",
        es: "Crear plantilla",
        ja: "テンプレートを作成する",
    },
    "all_saved_on_save_as_template": {
        en: <>All division, round and progression settings will be saved, so you can easily reuse this format in event divisions. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/10064874621332" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>Todas as configurações da categoria, rounds e progressão serão salvas para que você possa re-utilizar este formato no futuro. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/10064874621332" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Se guardará la configuración de todas las categorías, rondas y progresiones para que puedas volver a usar este formato en las categorías del evento. <MuiLink href="https://support.liveheats.com/hc/en-us/articles/10064874621332" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>すべての部門、ラウンド、進出設定が保存されるので、このフォーマットをどのイベント部門でも簡単に再利用できます。<MuiLink href="https://support.liveheats.com/hc/en-us/articles/10064874621332" target="_blank" rel="noreferrer">詳しくはこちら</MuiLink>。</>,
    },
    "Custom fields": {
        pt: "Atributos adicionais",
        es: "Campos personalizados",
        ja: "カスタムフィールド",
    },
    "Remove user": {
        pt: "Remover usuário",
        es: "Eliminar usuario",
        ja: "ユーザーを削除する",
    },
    "Remove user from organisation?": {
        pt: "Remover usuário da sua organização?",
        es: "¿Deseas eliminar al usuario de la organización?",
        ja: "団体からユーザーを削除しますか？",
    },
    "This will unlink the user from your organisation and they will no longer be able to manage or judge events for your organisation.": {
        pt: "Esta ação removerá a conexão entre este usuário e sua organização e ele não poderá mais gerenciar ou arbitrar eventos na sua organização.",
        es: "Si lo haces, se desvinculará al usuario de la organización y ya no podrá gestionar ni evaluar los eventos de tu organización.",
        ja: "これにより、そのユーザーはあなたの団体からリンクが解除され、あなたの団体のイベントを管理・審査することができなくなります。",
    },
    "Purchase credits and add-ons": {
        pt: "Comprar créditos e adicionais",
        es: "Comprar créditos y complementos",
        ja: "クレジットとアドオンを購入する",
    },
    "My credits": {
        pt: "Meus créditos",
        es: "Mis créditos",
        ja: "マイクレジット",
    },
    "My active add-ons": {
        pt: "Meus adicionais",
        es: "Mis complementos activos",
        ja: "アクティブなアドオン",
    },
    "credits_exhausted": {
        en: <>All your credits have been used (credits are consumed when events are created). To create more events, you can purchase more credits <Link to="/director/purchase">here</Link>.</>,
        pt: <>Todos os seus créditos já foram utilizados (créditos são debitados quando eventos são criados). Para criar mais eventos, você pode comprar mais créditos <Link to="/director/purchase">aqui</Link>.</>,
        es: <>Usaste todos tus créditos (los créditos se consumen cuando creas eventos). Para crear más eventos, puedes comprar más créditos <Link to="/director/purchase">aquí</Link>.</>,
        ja: <>すべてのクレジットが使用ｓあれました（イベントが作成されると、クレジットが消費されます）。さらにイベントを作成するには、<Link to="/director/purchase">こちら</Link>でクレジットを購入する必要があります。</>,
    },
    "add_ons_empty_state": {
        en: <>Add-ons provide additional features for events. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank" rel="noreferrer">Learn more</MuiLink>.</>,
        pt: <>Adicionais disponibilizam mais funcionalidades nos seus eventos. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank" rel="noreferrer">Saiba mais</MuiLink>.</>,
        es: <>Los complementos ofrecen características adicionales para los eventos. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank" rel="noreferrer">Obtén más información</MuiLink>.</>,
        ja: <>アドオンは、イベントの追加機能を提供します。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/13757714400148" target="_blank" rel="noreferrer">詳しくはこちら</MuiLink>。</>,
    },
    "for_x_event": {
        en: ({ x }) => `For ${x} event${plural(x)}`,
        pt: ({ x }) => `Para ${x} evento${plural(x)}`,
        es: ({ x }) => `Para ${x} evento${plural(x)}`,
        ja: ({ x }) => `${x}イベントの場合`,
    },
    "purchased_date": {
        en: ({ date }) => `Purchased ${date}`,
        pt: ({ date }) => `Comprado em ${date}`,
        es: ({ date }) => `La compra se efectuó el ${date}`,
        ja: ({ date }) => `購入済み${japaneseFormatDate(date)}`,
    },
    "Role": {
        pt: "Função",
        es: "Función",
        ja: "役割",
    },
    "Select a role...": {
        pt: "Selecione uma função...",
        es: "Seleccionar una función…",
        ja: "役割を選択...",
    },
    "Role required": {
        pt: "A função é obrigatória",
        es: "La función es obligatoria",
        ja: "役割が必要です",
    },
    "Director": {
        pt: "Diretor",
        es: "Director",
        ja: "ディレクター",
    },
    "Judge": {
        pt: "Árbitro",
        es: "Juez",
        ja: "ジャッジ",
    },
    "Team manager": {
        pt: "Gerente de times",
        es: "Gerente de equipos",
        ja: "チームマネージャー",
    },
    "director": {
        pt: "diretor",
        es: "director",
        ja: "ディレクター",
    },
    "judge": {
        pt: "árbitro",
        es: "juez",
        ja: "ジャッジ",
    },
    "Reports": {
        pt: "Relatórios",
        es: "Informes",
        ja: "レポート",
    },
    "Event media": {
        pt: "Mídia do evento",
        es: "Medios del evento",
        ja: "イベントメディア",
    },
    "Event media automation by Liveheats": {
        pt: "Automação de mídia do evento pelo Liveheats",
        es: "Automatización de medios del evento por Liveheats",
        ja: "Liveheatsによるイベントメディアの自動化",
    },
    "Bring your event to life with event media": {
        pt: "Deixe seu evento mais atrativo com mídia",
        es: "Dale vida a tu evento con medios",
        ja: "イベントメディアでイベントを活性化",
    },
    "event_media_empty_state": {
        en: <>Our new event media feature automates the sorting and distribution of photos in your Liveheats-powered event. Simply bulk upload all media files, and our systems will magically link them to the online heat results, so athletes and supporters can easily find, view, and share to social media.</>,
        pt: <>A nova funcionalidade de mídia do Liveheats automatiza a organização e distribuição de fotos no seu evento. Basta fazer o upload de todas as fotos, e nosso sistema irá automaticamente vincular as fotos aos resultados online, para que atletas e apoiadores possam facilmente encontrar, visualizar e compartilhar nas redes sociais.</>,
        es: <>Nuestra nueva función de medios para eventos automatiza la organización y distribución de fotos en tu evento. Simplemente sube todas las fotos, y nuestro sistema vinculará automáticamente las fotos a los resultados en línea, para que los atletas y sus seguidores puedan encontrar, ver y compartir fácilmente en las redes sociales.</>,
        ja: <>新しいイベントメディア機能は、Liveheatsを使用したイベントの写真の整理と配布を自動化します。すべてのメディアファイルを一括アップロードするだけで、当社のシステムがオンラインヒート結果にリンクし、アスリートやサポーターが簡単に見つけて表示し、ソーシャルメディアで共有できるようになります。</>,
    },
    "Upload event photos": {
        pt: "Enviar fotos do evento",
        es: "Subir fotos del evento",
        ja: "イベント写真をアップロードする",
    },
    "Error loading image": {
        pt: "Erro ao carregar imagem",
        es: "Error al cargar la imagen",
        ja: "画像の読み込みエラー",
    },
    "View gallery": {
        pt: "Ver galeria",
        es: "Ver galería",
        ja: "ギャラリーを見る",
    },
    "Gallery": {
        pt: "Galeria",
        es: "Galería",
        ja: "ギャラリー",
    },
    "View image": {
        pt: "Ver imagem",
        es: "Ver imagen",
        ja: "画像を見る",
    },
    "Export and share": {
        pt: "Exportar e compartilhar",
        es: "Exportar y compartir",
        ja: "エクスポートして共有",
    },
    "download_event_media": {
        en: <>Buy original for <Typography variant="inherit" component="s" display="inline">$3.99</Typography> $0</>,
        pt: <>Comprar original por <Typography variant="inherit" component="s" display="inline">€3.99</Typography> €0</>,
        es: <>Comprar original por <Typography variant="inherit" component="s" display="inline">€3.99</Typography> €0</>,
        ja: <>オリジナルを購入 <Typography variant="inherit" component="s" display="inline">¥600</Typography> ¥0</>,
    },
    "Event entries (csv)": {
        pt: "Planilha de inscrições(csv)",
        es: "Inscripciones del evento (CSV)",
        ja: "イベントエントリー（csv）",
    },
    "Event payments (csv)": {
        pt: "Planilha de pagamentos (csv)",
        es: "Pagos del evento (CSV)",
        ja: "イベント支払い（csv）",
    },
    "Detailed results and scores (csv)": {
        pt: "Planilha de resultados (csv)",
        es: "Detalle de resultados y puntajes (CSV)",
        ja: "詳細結果とスコア（csv）",
    },
    "NSW Interschools moguls results (csv)": {
        pt: "Resultados dos magnatas das escolas interescolares de NSW (csv)",
        es: "Resultados del campeonato interescolar de moguls de NSW (CSV)",
        ja: "NSW学校対抗モーグルの結果（csv）",
    },
    "Final division rankings (csv)": {
        pt: "Planilha de classificação final das categorias (csv)",
        es: "Clasificaciones finales de la categoría (CSV)",
        ja: "部門最終ランキング（csv）",
    },
    "Full event draws (pdf)": {
        pt: "Chaves completas (pdf)",
        es: "Sorteos completos del evento (PDF)",
        ja: "イベント抽選全体（pdf）",
    },
    "fis_results_report_name_for": {
        en: ({ division }) => `FIS results - ${division} (xml)`,
        pt: ({ division }) => `Resultados FIS - ${division} (xml)`,
        es: ({ division }) => `Resultados de la FIS - ${division} (XML)`,
        ja: ({ division }) => `FIS結果 - ${division} (xml)`,
    },
    "Athlete dashboard": {
        pt: "Painel do atleta",
        es: "Panel de atleta",
        ja: "選手のダッシュボード",
    },
    "Activity": {
        pt: "Atividade",
        es: "Actividad",
        ja: "アクティビティ",
    },
    "by_organisation": {
        en: ({ organisationName }) => `by ${organisationName}`,
        pt: ({ organisationName }) => `por ${organisationName}`,
        es: ({ organisationName }) => `por ${organisationName}`,
        ja: ({ organisationName }) => `${organisationName}によって`,
    },
    "Dashboard": {
        pt: "Painel",
        es: "Panel",
        ja: "ダッシュボード",
    },
    "Add registrations": {
        pt: "Adicionar inscrições",
        es: "Agregar inscripciones",
        ja: "登録の追加",
    },
    "Email organiser": {
        pt: "Falar com o organizador",
        es: "Enviar correo electrónico al organizador",
        ja: "主催者のメールを送信する",
    },
    "entered_in_x_divisions": {
        en: ({ x }) => <>Entered in <Typography variant="button3">{x} division{plural(x)}</Typography></>,
        pt: ({ x }) => <>Inscrito em <Typography variant="button3">{x} categoria{plural(x)}</Typography></>,
        es: ({ x }) => <>Ingresó en <Typography variant="button3">{x} categoría{plural(x)}</Typography></>,
        ja: ({ x }) => <><Typography variant="button3">{x}部門</Typography>にエントリー済み</>,
    },
    "Draw yet to be announced 📣": {
        pt: "Chave ainda não anunciada 📣",
        es: "El sorteo aún no se anunció 📣",
        ja: "抽選はまだ発表されていません📣",
    },
    "View entry list": {
        pt: "Ver lista de inscritos",
        es: "Ver lista de inscripciones",
        ja: "エントリーリストを見る",
    },
    "View draw & results": {
        pt: "Ver chave & resultados",
        es: "Ver sorteo y resultados",
        ja: "抽選と結果を見る",
    },
    "seed_x": {
        en: ({ x }) => `Seed ${x}`,
        pt: ({ x }) => `Seed ${x}`,
        es: ({ x }) => `Cabeza de serie ${x}`,
        ja: ({ x }) => `シード ${x}`,
    },
    "No upcoming events - it's time to find a new one!": {
        pt: "Nenhum evento aqui - esta na hora de encontrar outro!",
        es: "No hay eventos futuros. ¡Busca uno nuevo!",
        ja: "イベントの予定はありません - 新しいイベントを見つけましょう！",
    },
    "Get started by finding an event or organiser!": {
        pt: "Encontre um evento ou organização!",
        es: "Para comenzar, busca un evento u organizador",
        ja: "まずはイベントや主催者を探してみましょう！",
    },
    "Profiles": {
        pt: "Perfis",
        es: "Perfiles",
        ja: "プロフィール",
    },
    "Dashboards": {
        pt: "Painéis",
        es: "Tableros",
        ja: "ダッシュボード",
    },
    "Full draw & results": {
        pt: "Chave completa e resultados",
        es: "Sorteo y resultados completos",
        ja: "すべての抽選＆結果",
    },
    "team": {
        pt: "time",
        es: "equipo",
        ja: "チーム",
    },
    "teams": {
        pt: "times",
        es: "equipos",
        ja: "チーム",
    },
    "In this team": {
        pt: "Neste time",
        es: "En este equipo",
        ja: "すでにこのチームにいます",
    },
    "Search by division": {
        pt: "Buscar por categoria",
        es: "Buscar por categoría",
        ja: "部門で検索",
    },
    "Help docs and support": {
        pt: "Ajuda e suporte",
        es: "Documentos de ayuda y soporte",
        ja: "ヘルプドキュメントとサポート",
    },
    "payment_due_for_entries": {
        en: ({ athleteNames, eventName, divisionName }) => <>💳 Payment due for {joinBy(", ", " and ", athleteNames)} in {eventName} for {divisionName}.</>,
        pt: ({ athleteNames, eventName, divisionName }) => <>💳 Pagamento devido para {joinBy(", ", " e ", athleteNames)} em {eventName} para {divisionName}.</>,
        es: ({ athleteNames, eventName, divisionName }) => <>💳 Pago debido para {joinBy(", ", " y ", athleteNames)} en {eventName} para {divisionName}.</>,
        ja: ({ athleteNames, eventName, divisionName }) => <>💳 {eventName}の{divisionName}に対する{joinBy("、", "および", athleteNames)}の支払い期限。</>,
    },
    "payments_failed_for_entries": {
        en: () => "⚠︎ 💳 Payment was unsuccessful for:",
        pt: () => "⚠︎ 💳 O pagamento falhou para:",
        es: () => "⚠︎ 💳 El pago no se realizó correctamente para:",
        ja: () => "⚠︎ 💳 以下の支払いに失敗しました：",
    },
    "Request for payment has been sent. Once payment is received, the entry will be confirmed.": {
        pt: "Pedido de pagamento enviado. Assim que o pagamento for recebido, a inscrição será confirmada.",
        es: "Se ha enviado una solicitud de pago. Una vez recibido el pago, la inscripción se confirmará.",
        ja: "支払いリクエストが送信されました。支払いが受領されると、エントリーが確定します。",
    },
    "confirm_selected": {
        en: ({ size }) => `Confirm ${size}`,
        pt: ({ size }) => `Confirmar ${size}`,
        es: ({ size }) => `Confirmar ${size}`,
        ja: ({ size }) => `${size}を確認`,
    },
    "Entries successfully confirmed!": {
        pt: "Inscrições confirmadas com sucesso!",
        es: "¡Inscripciones confirmadas con éxito!",
        ja: "エントリーが正常に確認されました！",
    },
    "Entries are already confirmed.": {
        pt: "Inscrições já confirmadas.",
        es: "Las inscripciones ya están confirmadas.",
        ja: "エントリーはすでに確認されています。",
    },
    "Entries are already pending payment.": {
        pt: "Inscrições já estão pendentes de pagamento.",
        es: "Las inscripciones ya están pendientes de pago.",
        ja: "エントリーはすでに支払い待ちです。",
    },
    "Entries are already confirmed or pending payment.": {
        pt: "Inscrições já estão confirmadas ou pendentes de pagamento.",
        es: "Las inscripciones ya están confirmadas o pendientes de pago.",
        ja: "エントリーはすでに確認されているか、支払い待ちです。",
    },
    "Not eligible for ranking": {
        pt: "Não elegível para ranking",
        es: "No es elegible para clasificación",
        ja: "ランキング対象外",
    },
    "Nation": {
        pt: "País",
        es: "Nación",
        ja: "国",
    },
    "property_filter_label_all_values": {
        en: ({ label }) => <><T>{label}</T>:&nbsp;<strong>All</strong></>,
        pt: ({ label }) => <><T>{label}</T>:&nbsp;<strong>Todos</strong></>,
        es: ({ label }) => <><T>{label}</T>:&nbsp;<strong>Todos</strong></>,
        ja: ({ label }) => <><T>{label}</T>:<strong>すべて</strong></>,
    },
    "Select item...": {
        pt: "Selecione um item...",
        es: "Seleccionar un elemento...",
        ja: "アイテムを選択...",
    },
    "no_search_results_found": {
        en: ({ search }) =><>Oops! No matches for <strong>{search}</strong>.<br />Check the spelling and try again or <u><Link variant="inherit" to="/organisations">browse all organisations</Link></u>.</>,
        pt: ({ search }) =><>Ops! Nenhuma correspondência para <strong>{search}</strong>.<br />Verifique a ortografia e tente novamente ou <u><Link variant="inherit" to="/organisations">navegar por todas as organizações</Link></u>.</>,
        es: ({ search }) =><>¡Vaya! No hay coincidencias para <strong>{search}</strong>.<br />Verifica la ortografía e intenta de nuevo o <u><Link variant="inherit" to="/organisations">navegar por todas las organizaciones</Link></u>.</>,
        ja: ({ search }) =><>おっと！<strong>{search}</strong>に一致するものはありません。<br /> スペルを確認してもう一度試すか、または<u><Link variant="inherit" to="/organisations">すべての組織を閲覧する</Link></u></>,
    },
    "browse_all_organisations": {
        en: <>Can't find what you're looking for? <u><strong><Link variant="inherit" to="/organisations">Browse all organisations</Link></strong></u></>,
        pt: <>Não consegue encontrar o que está procurando? <u><strong><Link variant="inherit" to="/organisations">Navegar por todas as organizações</Link></strong></u></>,
        es: <>¿No puedes encontrar lo que estás buscando? <ul><strong><Link variant="inherit" to="/organisations">Navegar por todas las organizaciones</Link></strong></ul></>,
        ja: <>お探しのものが見つかりませんか？<u><strong><Link variant="inherit" to="/organisations">すべての組織を閲覧する</Link></strong></u></>,
    },
    "Set per athlete": {
        pt: "Customizar por atleta",
        es: "Establecer por atleta",
        ja: "選手ごとに設定",
    },
    "The number of top results per athlete and division that determine the rankings.": {
        pt: "O número de melhores resultados por atleta e categoria que determinam o ranking.",
        es: "El número de mejores resultados por atleta y categoría que determinan las clasificaciones.",
        ja: "ランキングを決定する選手と部門ごとのトップ結果の数。",
    },
    "Add athlete counting result": {
        pt: "Adicionar resultado de contagem do atleta",
        es: "Añadir resultado de conteo del atleta",
        ja: "選手のカウント結果を追加する",
    },
    "Update athlete counting result": {
        pt: "Atualizar resultado de contagem do atleta",
        es: "Actualizar resultado de conteo del atleta",
        ja: "選手のカウント結果を更新する",
    },
    "Alrighty then, athlete counting result removed!": {
        pt: "Ok, resultado de contagem do atleta removido!",
        es: "¡Listo, se eliminó el resultado de conteo del atleta!",
        ja: "よし、選手のカウント結果が削除されました！",
    },
    "Terrific, athlete counting result updated!": {
        pt: "Ótimo, resultado de contagem do atleta atualizado!",
        es: "¡Genial, se actualizó el resultado de conteo del atleta!",
        ja: "素晴らしい、選手のカウント結果が更新されました！",
    },
    "Terrific, athlete counting result added!": {
        pt: "Ótimo, resultado de contagem do atleta adicionado!",
        es: "¡Genial, se añadió el resultado de conteo del atleta!",
        ja: "素晴らしい、選手のカウント結果が追加されました！",
    },
    "Counting results:": {
        pt: "Resultados de contagem:",
        es: "Resultados de conteo:",
        ja: "カウント結果：",
    },
    "DSQ reason": {
        pt: "Motivo da desclassificação",
        es: "Motivo de la descalificación",
        ja: "DSQ理由",
    },
    "Reason for disqualification (optional)": {
        pt: "Motivo da desclassificação (opcional)",
        es: "Motivo de la descalificación (opcional)",
        ja: "失格の理由（オプション）",
    },
    "DSQ code and/or short description": {
        pt: "Código de DSQ e/ou descrição curta",
        es: "Código de DSQ y/o descripción corta",
        ja: "DSQコードおよび/または短い説明",
    },
    "more_divisions": {
        en: ({ division, count }) => <><Typography variant="body2" color="text.primary">{division}<Typography variant="body2" color="text.light" component="span"> and </Typography><Typography variant="body2" color="text.primary" component="span">{count} more division{plural(count)}</Typography></Typography></>,
        pt: ({ division, count }) => <><Typography variant="body2" color="text.primary">{division}<Typography variant="body2" color="text.light" component="span"> e </Typography><Typography variant="body2" color="text.primary" component="span">{count} categoria{plural(count)}</Typography></Typography></>,
        es: ({ division, count }) => <><Typography variant="body2" color="text.primary">{division}<Typography variant="body2" color="text.light" component="span"> y </Typography><Typography variant="body2" color="text.primary" component="span">{count} categoría{plural(count)}</Typography></Typography></>,
        ja: ({ division, count }) => <><Typography variant="body2" color="text.primary">{division}<Typography variant="body2" color="text.light" component="span"> と </Typography><Typography variant="body2" color="text.primary" component="span">{count}部門</Typography></Typography></>,
    },
    "one_series": {
        en: ({ series }) => <><Typography variant="body2" color="text.light">Part of<Typography variant="body2" color="text.primary" component="span"> {series}</Typography></Typography></>,
        pt: ({ series }) => <><Typography variant="body2" color="text.light">Parte de<Typography variant="body2" color="text.primary" component="span"> {series}</Typography></Typography></>,
        es: ({ series }) => <><Typography variant="body2" color="text.light">Parte de<Typography variant="body2" color="text.primary" component="span"> {series}</Typography></Typography></>,
        ja: ({ series }) => <><Typography variant="body2" color="text.light">の一部<Typography variant="body2" color="text.primary" component="span"> {series}</Typography></Typography></>,
    },
    "more_series": {
        en: ({ series, count }) => <><Typography variant="body2" color="text.light">Part of<Typography variant="body2" color="text.primary" component="span"> {series} </Typography><Typography variant="body2" color="text.light" component="span"> and </Typography><Typography variant="body2" color="text.primary" component="span">{count} more series</Typography></Typography></>,
        pt: ({ series, count }) => <><Typography variant="body2" color="text.light">Parte de<Typography variant="body2" color="text.primary" component="span"> {series} </Typography><Typography variant="body2" color="text.light" component="span"> e </Typography><Typography variant="body2" color="text.primary" component="span">{count} série{plural(count)}</Typography></Typography></>,
        es: ({ series, count }) => <><Typography variant="body2" color="text.light">Parte de<Typography variant="body2" color="text.primary" component="span"> {series} </Typography><Typography variant="body2" color="text.light" component="span"> y </Typography><Typography variant="body2" color="text.primary" component="span">{count} serie{plural(count)}</Typography></Typography></>,
        ja: ({ series, count }) => <><Typography variant="body2" color="text.light">の一部<Typography variant="body2" color="text.primary" component="span"> {series} </Typography><Typography variant="body2" color="text.light" component="span"> と </Typography><Typography variant="body2" color="text.primary" component="span">{count}シリーズ</Typography></Typography></>,
    },
    "By": {
        pt: "Por",
        es: "Por",
    },
    "Award winning software for surf, skate, snow, and ocean racing competitions - live scoring, heat draws, registrations, rankings and more.": {
        pt: "Software premiado para competições de surf, skate, snowboard e corridas oceânicas - pontuações ao vivo, chaves, inscrições, rankings e muito mais.",
        es: "Software galardonado para competiciones de surf, skate, snowboard y carreras oceánicas - puntuaciones en directo, sorteos, inscripciones, clasificaciones y mucho más.",
        ja: "サーフィン、スケート、スノーボード、オーシャンレースの競技用ソフトウェア - ライブスコアリング、抽選、登録、ランキングなど。",
    },
    "Search events": {
        pt: "Buscar eventos",
        es: "Buscar eventos",
        ja: "イベントを検索",
    },
    "Search organisations": {
        pt: "Buscar organizações",
        es: "Buscar organizaciones",
        ja: "組織を検索",
    },
    "Contact event organiser": {
        pt: "Falar com o organizador do evento",
        es: "Contactar con el organizador del evento",
        ja: "イベント主催者に連絡する",
    },
    "Event organisers": {
        pt: "Organizadores de eventos",
        es: "Organizadores de eventos",
        ja: "イベント主催者",
    },
    "Features": {
        pt: "Recursos",
        es: "Características",
        ja: "機能",
    },
    "Socials": {
        pt: "Redes sociais",
        es: "Redes sociales",
        ja: "ソーシャル",
    },
    "Privacy Policy": {
        pt: "Política de privacidade",
        es: "Política de privacidad",
        ja: "プライバシーポリシー",
    },
    "Find an action sports event": {
        pt: "Encontre um evento de esportes de ação",
        es: "Encuentra un evento de deportes de acción",
        ja: "アクションスポーツイベントを探す",
    },
    "org_promo_title": {
        en: <>Power up your club, association or tour with <Typography variant="inherit" color="brand.primary" component="span">Liveheats</Typography></>,
        pt: <>Potencialize seu clube, associação ou tour com <Typography variant="inherit" color="brand.primary" component="span">Liveheats</Typography></>,
        es: <>Potencia tu club, asociación o gira con <Typography variant="inherit" color="brand.primary" component="span">Liveheats</Typography></>,
        ja: <>クラブ、協会、ツアーを強化する<Typography variant="inherit" color="brand.primary" component="span">Liveheats</Typography></>,
    },
    "Liveheats provides an easy to use platform for registrations, draws, schedules, live scoring and rankings": {
        pt: "Liveheats fornece uma plataforma fácil de usar para inscrições, chaves, horários, pontuações ao vivo e rankings",
        es: "Liveheats proporciona una plataforma fácil de usar para inscripciones, sorteos, horarios, puntuaciones en directo y clasificaciones",
        ja: "Liveheatsは、登録、抽選、スケジュール、ライブスコアリング、ランキングのための使いやすいプラットフォームを提供します",
    },
    "live_now_heading": {
        en: <><Typography variant="brandSubheadingH1" textTransform="uppercase">Live now</Typography><Typography variant="brandSubheadingH3Light" textTransform="uppercase">and upcoming events</Typography></>,
        pt: <><Typography variant="brandSubheadingH1" textTransform="uppercase">Ao vivo</Typography><Typography variant="brandSubheadingH3Light" textTransform="uppercase">e próximos eventos</Typography></>,
        es: <><Typography variant="brandSubheadingH1" textTransform="uppercase">En vivo</Typography><Typography variant="brandSubheadingH3Light" textTransform="uppercase">y próximos eventos</Typography></>,
        ja: <><Typography variant="brandSubheadingH1" textTransform="uppercase">ライブ中</Typography><Typography variant="brandSubheadingH3Light" textTransform="uppercase">および今後のイベント</Typography></>,
    },
    "For event organisers": {
        pt: "Para organizadores de eventos",
        es: "Para organizadores de eventos",
        ja: "イベント主催者向け",
    },
    "Liveheats for organisers": {
        pt: "Liveheats para organizadores",
        es: "Liveheats para organizadores",
        ja: "主催者向けLiveheats",
    },
    "Organiser pricing": {
        pt: "Preços para organizadores",
        es: "Precios para organizadores",
        ja: "主催者の価格設定",
    },
    "New organiser enquiry": {
        pt: "Nova consulta de organizador",
        es: "Nueva consulta de organizador",
        ja: "新しい主催者のお問い合わせ",
    },
    "Add role": {
        pt: "Adicionar função",
        es: "Añadir función",
        ja: "役割を追加",
    },
    "Edit or remove role": {
        pt: "Editar ou remover função",
        es: "Editar o eliminar función",
        ja: "役割を編集または削除",
    },
    "Find events and organisations": {
        pt: "Encontre eventos e organizações",
        es: "Encuentra eventos y organizaciones",
        ja: "イベントと組織を探す",
    },
    "Registration fees apply": {
        pt: "Taxas de inscrição aplicáveis",
        es: "Se aplican tasas de registro",
        ja: "登録料が適用されます",
    },
    "Set prices, discounts, and paid extras.": {
        "pt": "Defina preços, descontos e extras pagos.",
        "es": "Establezca precios, descuentos y extras pagados.",
        "ja": "価格、割引、有料エクストラを設定してください。",
    },
    "set up required": {
        pt: "configuração necessária",
        es: "configuración requerida",
        ja: "設定が必要",
    },
    "Streamline payments and manage finances easily": {
        pt: "Facilite pagamentos e gerencie finanças com facilidade",
        es: "Simplifica los pagos y gestiona las finanzas fácilmente",
        ja: "支払いを効率化し、簡単に財務を管理",
    },
    "Accept online payments for registrations & memberships securely with Stripe. Analyse data and create reports for informed decisions.": {
        pt: "Aceite pagamentos online para inscrições e associações com segurança com Stripe. Analise dados e crie relatórios para decisões informadas.",
        es: "Acepta pagos en línea para inscripciones y membresías de forma segura con Stripe. Analiza datos y crea informes para tomar decisiones informadas.",
        ja: "Stripeを使用して登録と会員費のオンライン支払いを安全に受け付けます。データを分析し、情報に基づいた意思決定のためのレポートを作成します。",
    },
    "How to get started": {
        pt: "Como começar",
        es: "Cómo empezar",
        ja: "始め方",
    },
    "Click ‘Connect Stripe’ to link your existing account or create a new free Stripe account": {
        pt: "Clique em 'Conectar Stripe' para vincular sua conta existente ou criar uma nova conta gratuita no Stripe",
        es: "Haz clic en 'Conectar Stripe' para vincular tu cuenta existente o crear una nueva cuenta gratuita en Stripe",
        ja: "'Stripeに接続'をクリックして、既存のアカウントをリンクするか、新しい無料のStripeアカウントを作成します",
    },
    "Once complete, Payments will be activated!": {
        pt: "Uma vez concluído, os pagamentos serão ativados!",
        es: "¡Una vez completado, los pagos se activarán!",
        ja: "完了すると、支払いが有効になります！",
    },
    "Then set up registration fees in your event or membership settings": {
        pt: "Em seguida, configure as taxas de inscrição nas configurações do seu evento ou associação",
        es: "Luego configure las tarifas de inscripción en la configuración de tu evento o membresía",
        ja: "次に、イベントまたは会員設定で登録料を設定します",
    },
    "About Stripe": {
        pt: "Sobre o Stripe",
        es: "Acerca de Stripe",
        ja: "Stripeについて",
    },
    "Secure payments and powerful reporting": {
        pt: "Pagamentos seguros e relatórios poderosos",
        es: "Pagos seguros e informes potentes",
        ja: "安全な支払いと強力なレポーティング",
    },
    "Designed to reduce friction and optimised for all devices": {
        pt: "Projetado para reduzir o atrito e otimizado para todos os dispositivos",
        es: "Diseñado para reducir la fricción y optimizado para todos los dispositivos",
        ja: "摩擦を減らすために設計され、すべてのデバイスに最適化されています",
    },
    "Built for all organisation sizes, from grassroots to professional world leagues": {
        pt: "Construído para organizações de todos os tamanhos, desde a base até ligas profissionais",
        es: "Diseñado para organizaciones de todos los tamaños, desde la base hasta las ligas profesionales",
        ja: "すべての組織サイズに対応し、草の根からプロの世界リーグまで対応",
    },
    "available_in": {
        en: <>Available in <MuiLink underline="always" variant="inherit" href="https://stripe.com/global" target="_blank" rel="noreferrer">40+ countries</MuiLink>. For other countries, see our <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040423031-Accepting-paid-registrations-for-non-supported-countries" target="_blank" rel="noreferrer">help doc</MuiLink></>,
        pt: <>Disponível em <MuiLink underline="always" variant="inherit" href="https://stripe.com/global" target="_blank" rel="noreferrer">40+ países</MuiLink>. Para outros países, consulte nosso <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040423031-Accepting-paid-registrations-for-non-supported-countries" target="_blank" rel="noreferrer">documento de ajuda</MuiLink></>,
        es: <>Disponible en <MuiLink underline="always" variant="inherit" href="https://stripe.com/global" target="_blank" rel="noreferrer">40+ países</MuiLink>. Para otros países, consulte nuestro <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040423031-Accepting-paid-registrations-for-non-supported-countries" target="_blank" rel="noreferrer">documento de ayuda</MuiLink></>,
        ja: <>40以上の国で<MuiLink underline="always" variant="inherit" href="https://stripe.com/global" target="_blank" rel="noreferrer">利用可能</MuiLink>です。その他の国の場合は、<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360040423031-Accepting-paid-registrations-for-non-supported-countries" target="_blank" rel="noreferrer">ヘルプドキュメント</MuiLink>をご覧ください</>,
    },
    "Pricing & fees": {
        pt: "Preços e taxas",
        es: "Precios y tarifas",
        ja: "価格と手数料",
    },
    "Free to set up": {
        pt: "Grátis para configurar",
        es: "Gratis para configurar",
        ja: "設定は無料",
    },
    "Liveheats Payments Power Up: Included with all accounts, no additional cost to activate.": {
        pt: "Liveheats Payments Power Up: Incluído em todas as contas, sem custo adicional para ativar.",
        es: "Liveheats Payments Power Up: Incluido en todas las cuentas, sin costo adicional para activar.",
        ja: "Liveheats Payments Power Up: すべてのアカウントに含まれており、追加費用はかかりません。",
    },
    "Stripe Account: Creating a free Stripe account is required to process payments.": {
        pt: "Conta Stripe: A criação de uma conta gratuita no Stripe é necessária para processar pagamentos.",
        es: "Cuenta de Stripe: Se requiere crear una cuenta gratuita de Stripe para procesar pagos.",
        ja: "Stripeアカウント：支払いを処理するには、無料のStripeアカウントの作成が必要です。",
    },
    "Transaction fees": {
        pt: "Taxas de transação",
        es: "Tarifas de transacción",
        ja: "取引手数料",
    },
    "stripe_processing_fee": {
        en: <>Stripe processing fee: Rates vary by region. Check your local pricing at <MuiLink underline="always" variant="inherit" href="https://stripe.com/pricing" target="_blank" rel="noreferrer">Stripe pricing</MuiLink>.</>,
        pt: <>Taxa de processamento do Stripe: As taxas variam por região. Verifique os preços locais em <MuiLink underline="always" variant="inherit" href="https://stripe.com/pricing" target="_blank" rel="noreferrer">Preços do Stripe</MuiLink>.</>,
        es: <>Tarifa de procesamiento de Stripe: Las tarifas varían según la región. Consulta los precios locales en <MuiLink underline="always" variant="inherit" href="https://stripe.com/pricing" target="_blank" rel="noreferrer">Precios de Stripe</MuiLink>.</>,
        ja: <>Stripe手数料：地域によって料金が異なります。地元の価格は<MuiLink underline="always" variant="inherit" href="https://stripe.com/pricing" target="_blank" rel="noreferrer">Stripe価格</MuiLink>で確認してください。</>,
    },
    "application_fee": {
        en: ({ fee }) => `Liveheats application fee: ${fee}% per transaction.`,
        pt: ({ fee }) => `Taxa de aplicação Liveheats: ${fee}% por transação.`,
        es: ({ fee }) => `Tarifa de aplicación de Liveheats: ${fee}% por transacción.`,
        ja: ({ fee }) => `Liveheatsアプリケーション料金：トランザクションごとに${fee}%。`,
    },
    "Both transaction fees will be automatically deducted. You will see the breakdown of fees within your Stripe account.": {
        pt: "Ambas as taxas de transação serão deduzidas automaticamente. Você verá a divisão das taxas em sua conta do Stripe.",
        es: "Ambas las tarifas de transacción se deducirán automáticamente. Verás el desglose de las tarifas en tu cuenta de Stripe.",
        ja: "両方の取引手数料は自動的に差し引かれます。 Stripeアカウント内で手数料の内訳を確認できます。",
    },
    "Stripe dashboard": {
        pt: "Painel do Stripe",
        es: "Tablero de Stripe",
        ja: "Stripeダッシュボード",
    },
    "Want to accept payments?": {
        pt: "Quer aceitar pagamentos?",
        es: "¿Quieres aceptar pagos?",
        ja: "支払いを受け付けたいですか？",
    },
    "Go to payments settings": {
        pt: "Ir para configurações de pagamentos",
        es: "Ir a la configuración de pagos",
        ja: "支払い設定に移動",
    },
    "Connect Stripe to activate online payments.": {
        pt: "Conecte o Stripe para ativar pagamentos online.",
        es: "Conecta Stripe para activar pagos en línea.",
        ja: "Stripeを接続してオンライン支払いを有効にします。",
    },
    "Not applied as all divisions have a custom price.": {
        pt: "Não aplicado, pois todas as categorias têm um preço personalizado.",
        es: "No aplicado ya que todas las categorías tienen un precio personalizado.",
        ja: "すべての部門にカスタム価格が設定されているため、適用されません。",
    },
    "Payments (legacy)": {
        pt: "Pagamentos (legado)",
        es: "Pagos (heredado)",
        ja: "支払い（レガシー）",
    },
    "payments_changing": {
        en: <>Payments are changing. You need to connect a new version of Stripe to continue receiving payments. <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">Read about the changes</a></>,
        pt: <>Os pagamentos estão mudando. Você precisa conectar uma nova versão do Stripe para continuar recebendo pagamentos. <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">Leia sobre as mudanças</a></>,
        es: <>Los pagos están cambiando. Necesitas conectar una nueva versión de Stripe para seguir recibiendo pagos. <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">Leer sobre los cambios</a></>,
        ja: <>支払いが変わります。支払いを続けるには新しいバージョンのStripeを接続する必要があります。 <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">変更点について読む</a></>,
    },
    "Connect now": {
        pt: "Conectar agora",
        es: "Conectar ahora",
        ja: "今すぐ接続",
    },
    "Just a moment while we connect your Stripe account": {
        pt: "Aguarde um momento enquanto conectamos sua conta do Stripe",
        es: "Espere un momento mientras conectamos su cuenta de Stripe",
        ja: "Stripeアカウントを接続しています",
    },
    "Important changes": {
        pt: "Mudanças importantes",
        es: "Cambios importantes",
        ja: "重要な変更",
    },
    "transaction_fees": {
        en: <>Transaction fees will change to 4% plus a Stripe fee (check your local pricing at <a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">Stripe pricing</a>).</>,
        pt: <>As taxas de transação mudarão para 4% mais uma taxa do Stripe (verifique os preços locais em <a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">Preços do Stripe</a>).</>,
        es: <>Las tarifas de transacción cambiarán a 4% más una tarifa de Stripe (consulte los precios locales en <a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">Precios de Stripe</a>).</>,
        ja: <>取引手数料は4%に変更され、Stripe手数料が追加されます（地域の価格は<a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">Stripe価格</a>で確認してください）。</>,
    },
    "You'll now manage your payments directly through Stripe, which offers more advanced payment tools. This means the Payments menu will no longer appear within Liveheats.": {
        pt: "Agora você gerenciará seus pagamentos diretamente através do Stripe, que oferece ferramentas de pagamento mais avançadas. Isso significa que o menu Pagamentos não aparecerá mais no Liveheats.",
        es: "Ahora administrará sus pagos directamente a través de Stripe, que ofrece herramientas de pago más avanzadas. Esto significa que el menú Pagos ya no aparecerá en Liveheats.",
        ja: "これにより、より高度な支払いツールを提供するStripeを介して支払いを直接管理することになります。これは、Liveheats内に支払いメニューが表示されなくなることを意味します。",
    },
    "To connect a new Stripe account, you'll need your organisation details and information about key personnel of the organisation.": {
        pt: "Para conectar uma nova conta do Stripe, você precisará dos detalhes da sua organização e informações sobre os principais funcionários da organização.",
        es: "Para conectar una nueva cuenta de Stripe, necesitará los detalles de su organización e información sobre el personal clave de la organización.",
        ja: "新しいStripeアカウントを接続するには、組織の詳細と組織の主要な人員に関する情報が必要です。",
    },
    "continue_migration": {
        en: <>By continuing, you agree to these changes. For more information read our <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">migration article</a>.</>,
        pt: <>Ao continuar, você concorda com essas mudanças. Para mais informações, leia nosso <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">artigo de migração</a>.</>,
        es: <>Al continuar, aceptas estos cambios. Para obtener más información, lee nuestro <a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">artículo de migración</a>.</>,
        ja: <>続行すると、これらの変更に同意したことになります。詳細については、<a href="https://support.liveheats.com/hc/en-us/articles/27560957633428-Migration-guide-Stripe-Express-to-Stripe-Standard" target="_blank" rel="noopener noreferrer">移行記事</a>をお読みください。</>,
    },
    "ID_NOT_FOUND": {
        en: "ID number cannot be found.",
        pt: "Número de identificação não encontrado.",
        es: "No se puede encontrar el número de identificación.",
        ja: "ID番号が見つかりません。",
    },
    "NO_MATCH": {
        en: "Personal details do not match. Check the full name and date of birth entered above are the same as your USSS membership details.",
        pt: "Os detalhes pessoais não correspondem. Verifique se o nome completo e a data de nascimento inseridos acima são os mesmos que os detalhes da sua associação à USSS.",
        es: "Los detalles personales no coinciden. Comprueba que el nombre completo y la fecha de nacimiento introducidos arriba son los mismos que los detalles de tu membresía en la USSS.",
        ja: "個人情報が一致しません。上記に入力された氏名と生年月日がUSSSの会員情報と同じであることを確認してください。",
    },
    "MEMBERSHIP_INVALID": {
        en: "USSS membership is not valid. Check your licence with USSS.",
        pt: "A associação à USSS não é válida. Verifique sua licença com a USSS.",
        es: "La membresía en la USSS no es válida. Comprueba tu licencia con la USSS.",
        ja: "USSSの会員資格が無効です。USSSでライセンスを確認してください。",
    },
    "FIS_NO_MATCH": {
        en: "Personal details do not match. Check the full name, date of birth and nationality entered above are the same as your FIS licence details.",
        pt: "Os detalhes pessoais não correspondem. Verifique se o nome completo, a data de nascimento e a nacionalidade inseridos acima são os mesmos que os detalhes da sua licença FIS.",
        es: "Los detalles personales no coinciden. Comprueba que el nombre completo, la fecha de nacimiento y la nacionalidad introducidos arriba son los mismos que los detalles de tu licencia FIS.",
        ja: "個人情報が一致しません。上記に入力された氏名、生年月日、国籍がFISライセンスの詳細と同じであることを確認してください。",
    },
    "FIS_NOT_VALID": {
        en: "FIS licence is not valid. Check your licence with FIS.",
        pt: "Licença FIS não é válida. Verifique sua licença com a FIS.",
        es: "La licencia FIS no es válida. Comprueba tu licencia con la FIS.",
        ja: "FISライセンスが無効です。FISでライセンスを確認してください。",
    },
    "Checking...": {
        pt: "Verificando...",
        es: "Comprobando...",
        ja: "確認中...",
    },
    "Valid": {
        pt: "Válido",
        es: "Válido",
        ja: "有効",
    },
    "Missing": {
        pt: "Faltando",
        es: "Faltante",
        ja: "欠落",
    },
    "Member": {
        pt: "Membro",
        es: "Miembro",
        ja: "メンバー",
    },
    "restricted_field": {
        en: ({ fieldName, contactEmail }) => <>Your {fieldName} can only be updated by an organiser.<br/><a href={`mailto:${contactEmail}`}>Email organiser.</a></>,
        pt: ({ fieldName, contactEmail }) => <>Seu {fieldName} só pode ser atualizado por um organizador.<br/><a href={`mailto:${contactEmail}`}>Email organizador.</a></>,
        es: ({ fieldName, contactEmail }) => <>Tu {fieldName} solo puede ser actualizado por un organizador.<br/><a href={`mailto:${contactEmail}`}>Email organizador.</a></>,
        ja: ({ fieldName, contactEmail }) => <>{fieldName}は主催者のみが更新できます。<br/><a href={`mailto:${contactEmail}`}>主催者にメール</a></>,
    },
    "event_timezone": {
        en: ({ timezone }) => `Event timezone: ${timezone}`,
        pt: ({ timezone }) => `Fuso horário do evento: ${timezone}`,
        es: ({ timezone }) => `Zona horaria del evento: ${timezone}`,
        ja: ({ timezone }) => `イベントのタイムゾーン：${timezone}`,
    },
    "event_timezone_required_location": {
        en: ({ timezone }) => "Event timezone: Requires event location",
        pt: ({ timezone }) => "Fuso horário do evento: Requer local do evento",
        es: ({ timezone }) => "Zona horaria del evento: Requiere ubicación del evento",
        ja: ({ timezone }) => "イベントのタイムゾーン：イベントの場所が必要です",
    },
    "Add date": {
        pt: "Adicionar data",
        es: "Añadir fecha",
        ja: "日付を追加",
    },
    "Add date for entry open": {
        pt: "Adicionar data para inscrições abertas",
        es: "Añadir fecha para inscripciones abiertas",
        ja: "エントリー開始の日付を追加",
    },
    "Add date for entry close": {
        pt: "Adicionar data para inscrições fechadas",
        es: "Añadir fecha para inscripciones cerradas",
        ja: "エントリー終了の日付を追加",
    },
    "Edit date for entry open": {
        pt: "Editar data para inscrições abertas",
        es: "Editar fecha para inscripciones abiertas",
        ja: "エントリー開始の日付を編集",
    },
    "Edit date for entry close": {
        pt: "Editar data para inscrições fechadas",
        es: "Editar fecha para inscripciones cerradas",
        ja: "エントリー終了の日付を編集",
    },
    "missing_location_entry_dates_warning": {
        en: <>Go to <Typography variant="label1">General</Typography> settings to add an event location. This adds the timezone, which is required before adding entry dates.</>,
        pt: <>Vá para as configurações <Typography variant="label1">Gerais</Typography> para adicionar um local do evento. Isso adiciona o fuso horário, que é necessário antes de adicionar datas de inscrição.</>,
        es: <>Ve a la configuración <Typography variant="label1">General</Typography> para agregar una ubicación del evento. Esto agrega la zona horaria, que es necesaria antes de agregar fechas de inscripción.</>,
        ja: <>イベントの場所を追加するには、<Typography variant="label1">一般</Typography>設定に移動します。これにより、エントリー日付を追加する前に必要なタイムゾーンが追加されます。</>,
    },
    "entry_dates_subtitle": {
        en: <>Automatically open and close registration based on specified dates and times. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/32019274544276" target="_blank">Learn more</MuiLink></>,
        pt: <>Abra e feche automaticamente as inscrições com base em datas e horários especificados. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/32019274544276" target="_blank">Saiba mais</MuiLink></>,
        es: <>Abra y cierre automáticamente las inscripciones en función de las fechas y horas especificadas. <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/32019274544276" target="_blank">Más información</MuiLink></>,
        ja: <>指定された日時に基づいて登録を自動的に開始および終了します。<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/32019274544276" target="_blank">詳細</MuiLink></>,
    },
    "Registration can't open and close at the same time. Adjust the dates to allow more time for registration.": {
        pt: "A inscrição não pode abrir e fechar ao mesmo tempo. Ajuste as datas para permitir mais tempo para a inscrição.",
        es: "La inscripción no puede abrir y cerrar al mismo tiempo. Ajusta las fechas para permitir más tiempo para la inscripción.",
        ja: "登録は同時に開始および終了できません。登録に十分な時間を確保するために日付を調整してください。",
    },
    "warning_registration_open_after_close": {
        en: ({ open, close }) => <>After entry closes on {close}, it will <Typography variant="label1">re-open {open}.</Typography></>,
        pt: ({ open, close }) => <>Após o encerramento das inscrições em {close}, elas serão <Typography variant="label1">reabertas {open}.</Typography></>,
        es: ({ open, close }) => <>Después de que las inscripciones se cierren en {close}, se <Typography variant="label1">reabrirán {open}.</Typography></>,
        ja: ({ open, close }) => <>{close}でエントリーが終了した後、<Typography variant="label1">{open}に再開します。</Typography></>,
    },
    "All entries": {
        pt: "Todas as inscrições",
        es: "Todas las inscripciones",
        ja: "すべてのエントリー",
    },
    "No entries": {
        pt: "Nenhuma inscrição",
        es: "Sin inscripciones",
        ja: "エントリーなし",
    },
    "Athletes will appear here once they register. Alternatively you can add athletes into divisions manually.": {
        pt: "Os atletas aparecerão aqui assim que se inscreverem. Alternativamente, você pode adicionar atletas manualmente nas divisões.",
        es: "Los atletas aparecerán aquí una vez que se registren. Alternativamente, puedes añadir atletas a las divisiones manualmente.",
        ja: "選手は登録するとここに表示されます。また、選手を手動で部門に追加することもできます。",
    },
    "Back to divisions": {
        pt: "Voltar para divisões",
        es: "Volver a divisiones",
        ja: "部門に戻る",
    },
    "entries_selected": {
        en: ({ size }) => size === 1 ? `${size} entry selected` : `${size} entries selected`,
        pt: ({ size }) => size === 1 ? `${size} inscrição selecionada` : `${size} inscrições selecionadas`,
        es: ({ size }) => size === 1 ? `${size} inscripción seleccionada` : `${size} inscripciones seleccionadas`,
        ja: ({ size }) => `${size}のエントリーが選択されました`,
    },
    "Only waitlisted entries will be confirmed. Any payments will be processed upon confirmation.":{
        es: "Solo las inscripciones en lista de espera serán confirmadas. Cualquier pago se procesará al confirmar.",
        pt: "Apenas as inscrições em lista de espera serão confirmadas. Qualquer pagamento será processado após a confirmação.",
        ja: "ウェイトリストのエントリーのみが確認されます。確認後に支払いが処理されます。",
    },
    "Waitlisted":{
        es: "En lista de espera",
        pt: "Em lista de espera",
        ja: "ウェイトリスト",
    },
    "Pending payment": {
        pt: "Pagamento pendente",
        ja: "支払い保留中",
        es: "Pago pendiente",
    },
    "Paid": {
        pt: "Pago",
        ja: "支払済み",
        es: "Pagado",
    },
    "12 months of action sports' data in 1 place.": {
        pt: "12 meses de dados de esportes de ação em 1 lugar.",
        es: "12 meses de datos de deportes de acción en 1 lugar.",
        ja: "1か所に12か月分のアクションスポーツデータ。",
    },
    "Get your personalised Heat Sheet": {
        pt: "Obtenha sua grade de Heat Sheet",
        es: "Obtén tu hoja de Heat Sheet personalizada",
        ja: "Heat Sheetをカスタマイズ",
    },
    "Cancelled date": {
        pt: "Data de cancelamento",
        es: "Fecha de cancelación",
        ja: "キャンセル日",
    },
    "Injuries": {
        pt: "Lesões",
        es: "Lesiones",
        ja: "怪我",
    },
    "Log injury": {
        pt: "Registrar lesão",
        es: "Registrar lesión",
        ja: "怪我を記録",
    },
    "Logging an injury will freeze the chosen athlete’s points for a default of 52 weeks from their injury date, and then a further 52 weeks from their recovery date. You can change these dates at any time": {
        pt: "Registrar uma lesão congelará os pontos do atleta escolhido por padrão de 52 semanas a partir da data da lesão e, em seguida, mais 52 semanas a partir da data de recuperação. Você pode alterar essas datas a qualquer momento",
        es: "Registrar una lesión congelará los puntos del atleta elegido por defecto de 52 semanas a partir de la fecha de la lesión, y luego otras 52 semanas a partir de la fecha de recuperación. Puedes cambiar estas fechas en cualquier momento",
        ja: "怪我を記録すると、選択した選手のポイントが、怪我の日付からデフォルトで52週間、その後回復日付からさらに52週間凍結されます。これらの日付はいつでも変更できます",
    },
    "log_injury": {
        en: ({ name }) => `Log injury for ${name}`,
        pt: ({ name }) => `Registrar lesão para ${name}`,
        es: ({ name }) => `Registrar lesión para ${name}`,
        ja: ({ name }) => `${name}の怪我を記録`,
    },
    "update_injury": {
        en: ({ name }) => `Edit injury for ${name}`,
        pt: ({ name }) => `Editar lesão para ${name}`,
        es: ({ name }) => `Editar lesión para ${name}`,
        ja: ({ name }) => `${name}の怪我を編集`,
    },
    "The recovery date cannot be the same as the injury date": {
        pt: "A data de recuperação não pode ser a mesma que a data da lesão",
        es: "La fecha de recuperación no puede ser la misma que la fecha de la lesión",
        ja: "回復日は怪我の日付と同じにすることはできません",
    },
    "The recovery date cannot be before the injury date": {
        pt: "A data de recuperação não pode ser anterior à data da lesão",
        es: "La fecha de recuperación no puede ser anterior a la fecha de la lesión",
        ja: "回復日は怪我の日付より前にすることはできません",
    },
    "The injury date cannot be in the future": {
        pt: "A data da lesão não pode estar no futuro",
        es: "La fecha de la lesión no puede estar en el futuro",
        ja: "怪我の日付は未来にすることはできません",
    },
    "Please enter a valid date": {
        pt: "Por favor, insira uma data válida",
        es: "Por favor, introduce una fecha válida",
        ja: "有効な日付を入力してください",
    },
    "injury_logged_for_athlete": {
        en: ({ name }) => `Injury logged for ${name}`,
        pt: ({ name }) => `Lesão registrada para ${name}`,
        es: ({ name }) => `Lesión registrada para ${name}`,
        ja: ({ name }) => `${name}の怪我が記録されました`,
    },
    "injury_updated_for_athlete": {
        en: ({ name }) => `Injury updated for ${name}`,
        pt: ({ name }) => `Lesão atualizada para ${name}`,
        es: ({ name }) => `Lesión actualizada para ${name}`,
        ja: ({ name }) => `${name}の怪我が更新されました`,
    },
    "injury_deleted_for_athlete": {
        en: ({ name }) => `Injury deleted for ${name}`,
        pt: ({ name }) => `Lesão excluída para ${name}`,
        es: ({ name }) => `Lesión eliminada para ${name}`,
        ja: ({ name }) => `${name}の怪我が削除されました`,
    },
    "recovery_date_help": {
        en: <>Recovery date is automatically set to <b>52 weeks from the injury date</b> - but you can change this at any time. The athlete’s points will be frozen until this date.</>,
        pt: <>A data de recuperação é automaticamente definida para <b>52 semanas a partir da data da lesão</b> - mas você pode alterar isso a qualquer momento. Os pontos do atleta serão congelados até esta data.</>,
        es: <>La fecha de recuperación se establece automáticamente en <b>52 semanas a partir de la fecha de la lesión</b> - pero puedes cambiarla en cualquier momento. Los puntos del atleta se congelarán hasta esta fecha.</>,
        ja: <>回復日は自動的に<b>怪我の日付から52週間</b>に設定されますが、いつでも変更できます。この日まで選手のポイントは凍結されます。</>,
    },
    "recovery_date": {
        en: ({ date }) => `Recovery date: ${date}`,
        pt: ({ date }) => `Data de recuperação: ${date}`,
        es: ({ date }) => `Fecha de recuperación: ${date}`,
        ja: ({ date }) => `回復日：${date}`,
    },
    "recovery_end_date": {
        en: ({ date }) => `Recovery end date: ${date}`,
        pt: ({ date }) => `Data de término da recuperação: ${date}`,
        es: ({ date }) => `Fecha de finalización de la recuperación: ${date}`,
        ja: ({ date }) => `回復終了日：${date}`,
    },
    "in_recovery": {
        en: "in recovery",
        pt: "em recuperação",
        es: "en recuperación",
        ja: "回復中",
    },
    "recovered": {
        pt: "recuperado",
        es: "recuperado",
        ja: "回復済み",
    },
    "injured": {
        pt: "lesionado",
        es: "lesionado",
        ja: "怪我",
    },
    "No reported injuries": {
        pt: "Nenhuma lesão relatada",
        es: "No se han reportado lesiones",
        ja: "報告された怪我はありません",
    },
    "In recovery": {
        pt: "Em recuperação",
        es: "En recuperación",
        ja: "回復中",
    },
    "Injured": {
        pt: "Lesionado",
        es: "Lesionado",
        ja: "怪我",
    },
    "Injury status": {
        pt: "Status da lesão",
        es: "Estado de la lesión",
        ja: "怪我の状態",
    },
    "Delete injury": {
        pt: "Excluir lesão",
        es: "Eliminar lesión",
        ja: "怪我を削除",
    },
    "Delete injury?": {
        pt: "Excluir lesão?",
        es: "¿Eliminar lesión?",
        ja: "怪我を削除しますか？",
    },
    "This will unfreeze the athlete's frozen results and update the rankings.": {
        pt: "Isso descongelará os resultados congelados do atleta e atualizará as classificações.",
        es: "Esto descongelará los resultados congelados del atleta y actualizará las clasificaciones.",
        ja: "これにより、選手の凍結された結果が解除され、ランキングが更新されます。",
    },
    "I’m looking to find scores, draws or registrations in an organisation that runs on Liveheats":{
        pt: "Estou procurando encontrar pontuações, chaves ou inscrições em uma organização que funciona no Liveheats",
        es: "Estoy buscando encontrar puntuaciones, sorteos o inscripciones en una organización que funciona en Liveheats",
        ja: "Liveheatsで運営されている組織のスコア、ドロー、または登録を見つけたいです",
    },
    "I organise competitions and am looking for software tools to improve them":{
        pt: "Organizo competições e estou procurando ferramentas de software para melhorá-las",
        es: "Organizo competiciones y busco herramientas de software para mejorarlas",
        ja: "大会を主催しており、それを改善するためのソフトウェアツールを探しています",
    },
    "How will you use Liveheats?":{
        pt: "Como você usará o Liveheats?",
        es: "¿Cómo usarás Liveheats?",
        ja: "Liveheatsをどのように使用しますか？",
    },
    "Sharing isn't supported on this device.": {
        pt: "O compartilhamento não é suportado neste dispositivo.",
        es: "El uso compartido no es compatible con este dispositivo.",
        ja: "このデバイスでは共有はサポートされていません。",
    },
    "Sorry, sharing isn't working right now. Try downloading the image and sharing from there instead.": {
        pt: "Desculpe, o compartilhamento não está funcionando no momento. Tente baixar a imagem e compartilhar a partir daí.",
        es: "Lo siento, el uso compartido no está funcionando en este momento. Intente descargar la imagen y compartir desde allí en su lugar.",
        ja: "申し訳ありませんが、共有が現在機能していません。代わりに画像をダウンロードしてそこから共有してみてください。",
    },
    "activate_or_learn_more": {
        en: <>Activate now or <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032010632-Integrating-sponsors-into-your-Liveheats-pages" target="_blank" rel="noopener noreferrer">learn more</MuiLink> about unlocking more opportunities.</>,
        pt: <>Ative agora ou <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032010632-Integrating-sponsors-into-your-Liveheats-pages" target="_blank" rel="noopener noreferrer">saiba mais</MuiLink> sobre como desbloquear mais oportunidades.</>,
        es: <>Activa ahora o <MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032010632-Integrating-sponsors-into-your-Liveheats-pages" target="_blank" rel="noopener noreferrer">obtén más información</MuiLink> sobre cómo desbloquear más oportunidades.</>,
        ja: <>今すぐアクティブ化するか、<MuiLink underline="always" variant="inherit" href="https://support.liveheats.com/hc/en-us/articles/360032010632-Integrating-sponsors-into-your-Liveheats-pages" target="_blank" rel="noopener noreferrer">さらなる機会を開く方法</MuiLink>について詳しく知る。</>,
    },
    "Unlock premium sponsor exposure and revenue": {
        pt: "Desbloqueie exposição e receita premium de patrocinadores",
        es: "Desbloquea la exposición y los ingresos premium de los patrocinadores",
        ja: "プレミアムスポンサー露出と収益を解除",
    },
    "Give your sponsors consistent, high-impact visibility across your event, from scheduling to final results.": {
        pt: "Dê aos seus patrocinadores visibilidade consistente e de alto impacto em todo o seu evento, desde o agendamento até os resultados finais.",
        es: "Ofrezca a sus patrocinadores una visibilidad constante y de alto impacto en todo su evento, desde la programación hasta los resultados finales.",
        ja: "スケジュールから最終結果まで、イベント全体でスポンサーに一貫した、高いインパクトの露出を提供します。",
    },
    "Offer event-wide sponsorships or targeted division-specific placements.": {
        pt: "Ofereça patrocínios em todo o evento ou colocações específicas de divisão direcionadas.",
        es: "Ofrezca patrocinios en todo el evento o ubicaciones específicas de división dirigidas.",
        ja: "イベント全体のスポンサーシップまたはターゲットとなる部門固有の配置を提供します。",
    },
    "Ready to boost your sponsorship revenue?": {
        pt: "Pronto para aumentar sua receita de patrocínio?",
        es: "¿Listo para aumentar tus ingresos por patrocinio?",
        ja: "スポンサーシップ収益を増やす準備はできていますか？",
    },
    "Activate for this event": {
        pt: "Ativar para este evento",
        es: "Activar para este evento",
        ja: "このイベントでアクティブ化",
    },
    "Liveheats | Where action sports history is made": {
        pt: "Liveheats | Onde a história dos esportes de ação é feita",
        es: "Liveheats | Donde se hace historia en los deportes de acción",
        ja: "Liveheats | アクションスポーツの歴史が作られる場所",
    },
    "Liveheats powers better action sports events for surf, skate, snow and surf life saving. Used by local clubs, regional, state, and national federations around the world for live scoring, heat draws, registrations, rankings and more.": {
        pt: "O Liveheats potencializa eventos de esportes de ação melhores para surf, skate, snow e surf life saving. Usado por clubes locais, federações regionais, estaduais e nacionais em todo o mundo para pontuações ao vivo, sorteios de baterias, inscrições, classificações e muito mais.",
        es: "Liveheats potencia mejores eventos de deportes de acción para surf, skate, snow y surf life saving. Utilizado por clubes locales, federaciones regionales, estatales y nacionales de todo el mundo para puntuaciones en vivo, sorteos de heats, inscripciones, clasificaciones y más.",
        ja: "Liveheatsは、サーフ、スケート、スノー、サーフライフセービングのアクションスポーツイベントを強化します。世界中のローカルクラブ、地域、州、国の連盟がライブスコアリング、ヒートドロー、登録、ランキングなどに使用しています。",
    },
    "Login to Liveheats": {
        pt: "Faça login no Liveheats",
        es: "Iniciar sesión en Liveheats",
        ja: "Liveheatsにログイン",
    },
    "Access action sport events and live scoring, anytime, anywhere with Liveheats. Manage your events, results and rankings for surf, snow, skate, surf life saving and more.": {
        pt: "Acesse eventos de esportes de ação e pontuações ao vivo, a qualquer hora, em qualquer lugar com o Liveheats. Gerencie seus eventos, resultados e classificações para surf, snow, skate, surf life saving e muito mais.",
        es: "Accede a eventos de deportes de acción y puntuaciones en vivo, en cualquier momento y lugar con Liveheats. Gestiona tus eventos, resultados y clasificaciones para surf, snow, skate, surf life saving y más.",
        ja: "Liveheatsを使用して、いつでもどこでもアクションスポーツイベントとライブスコアリングにアクセスします。サーフ、スノー、スケート、サーフライフセービングなどのイベント、結果、ランキングを管理します。",
    },
    "Sign up to Liveheats": {
        pt: "Inscreva-se no Liveheats",
        es: "Regístrate en Liveheats",
        ja: "Liveheatsにサインアップ",
    },
    "Forgot your Liveheats password": {
        pt: "Esqueceu sua senha do Liveheats",
        es: "¿Olvidaste tu contraseña de Liveheats?",
        ja: "Liveheatsのパスワードを忘れた",
    },
    "Reset your Liveheats password": {
        pt: "Redefina sua senha do Liveheats",
        es: "Restablece tu contraseña de Liveheats",
        ja: "Liveheatsのパスワードをリセット",
    },
    "Liveheats is a leading action sport event management platform used by local clubs, regional, state, and national federations around the world.": {
        pt: "O Liveheats é uma plataforma líder de gerenciamento de eventos de esportes de ação usada por clubes locais, federações regionais, estaduais e nacionais em todo o mundo.",
        es: "Liveheats es una plataforma líder de gestión de eventos de deportes de acción utilizada por clubes locales, federaciones regionales, estatales y nacionales de todo el mundo.",
        ja: "Liveheatsは、世界中のローカルクラブ、地域、州、国の連盟によって使用されている主要なアクションスポーツイベント管理プラットフォームです。",
    },
    "Liveheats Privacy Policy": {
        pt: "Política de Privacidade do Liveheats",
        es: "Política de privacidad de Liveheats",
        ja: "Liveheatsプライバシーポリシー",
    },
    "Liveheats User Terms & Conditions": {
        pt: "Termos e Condições de Uso do Liveheats",
        es: "Términos y condiciones de uso de Liveheats",
        ja: "Liveheatsユーザー利用規約",
    },
    "Browse action sport competition organisers | Liveheats": {
        pt: "Navegue por organizadores de competições de esportes de ação | Liveheats",
        es: "Explora organizadores de competiciones de deportes de acción | Liveheats",
        ja: "アクションスポーツ競技主催者を閲覧 | Liveheats",
    },
    "Search for an action sport organisation or event on Liveheats. From local clubs to international federations, for surf, snow, skate, surf life saving and more.": {
        pt: "Procure uma organização ou evento de esportes de ação no Liveheats. De clubes locais a federações internacionais, para surf, snow, skate, surf life saving e muito mais.",
        es: "Busca una organización o evento de deportes de acción en Liveheats. Desde clubes locales hasta federaciones internacionales, para surf, snow, skate, surf life saving y más.",
        ja: "Liveheatsでアクションスポーツの組織やイベントを検索します。サーフ、スノー、スケート、サーフライフセービングなど、ローカルクラブから国際連盟まで。",
    },
    "org_home_page_meta_title": {
        en: ({ orgName }) => `${orgName} - Live scores, results, rankings, events | Liveheats`,
        pt: ({ orgName }) => `${orgName} - Pontuações ao vivo, resultados, classificações, eventos | Liveheats`,
        es: ({ orgName }) => `${orgName} - Puntuaciones en vivo, resultados, clasificaciones, eventos | Liveheats`,
        ja: ({ orgName }) => `${orgName} - ライブスコア、結果、ランキング、イベント | Liveheats`,
    },
    "org_home_page_meta_description": {
        en: ({ orgName, sportType }) => `${sportType} organisation ${orgName} is on Liveheats. Register for events and follow results, draws, rankings and live scoring.`,
        pt: ({ orgName, sportType }) => `Organização ${sportType} ${orgName} está no Liveheats. Inscreva-se em eventos e siga resultados, chaves, classificações e pontuações ao vivo.`,
        es: ({ orgName, sportType }) => `Organización ${sportType} ${orgName} está en Liveheats. Regístrate para eventos y sigue resultados, sorteos, clasificaciones y puntuaciones en vivo.`,
        ja: ({ orgName, sportType }) => `組織 ${sportType} ${orgName} はLiveheatsにあります。イベントに登録して、結果、ドロー、ランキング、ライブスコアリングをフォローします。`,
    },
    "org_home_page_meta_description_sport_type_other": {
        en: ({ orgName }) => `Organisation ${orgName} is on Liveheats. Register for events and follow results, draws, rankings and live scoring.`,
        pt: ({ orgName }) => `Organização ${orgName} está no Liveheats. Inscreva-se em eventos e siga resultados, chaves, classificações e pontuações ao vivo.`,
        es: ({ orgName }) => `Organización ${orgName} está en Liveheats. Regístrate en eventos y sigue resultados, sorteos, clasificaciones y puntuaciones en vivo.`,
        ja: ({ orgName }) => `組織 ${orgName} はLiveheatsにあります。イベントに登録して、結果、ドロー、ランキング、ライブスコアリングをフォローします。`,
    },
    "org_rankings_meta_title": {
        en: ({ orgName }) => `Rankings - ${orgName} | Liveheats`,
        pt: ({ orgName }) => `Classificações - ${orgName} | Liveheats`,
        es: ({ orgName }) => `Clasificaciones - ${orgName} | Liveheats`,
        ja: ({ orgName }) => `ランキング - ${orgName} | Liveheats`,
    },
    "org_rankings_meta_description": {
        en: ({ orgName }) => `See the latest ${orgName} rankings. Search by series and division.`,
        pt: ({ orgName }) => `Veja as últimas classificações de ${orgName}. Pesquise por série e divisão.`,
        es: ({ orgName }) => `Consulta las últimas clasificaciones de ${orgName}. Busca por serie y división.`,
        ja: ({ orgName }) => `最新の${orgName}ランキングをご覧ください。シリーズと部門で検索します。`,
    },
    "org_memberships_meta_title": {
        en: ({ orgName }) => `Memberships - ${orgName} | Liveheats`,
        pt: ({ orgName }) => `Membros - ${orgName} | Liveheats`,
        es: ({ orgName }) => `Membresías - ${orgName} | Liveheats`,
        ja: ({ orgName }) => `メンバーシップ - ${orgName} | Liveheats`,
    },
    "org_memberships_meta_description": {
        en: ({ orgName, firstOption }) => `See and register for a ${orgName} membership.${firstOption ? ` Membership options include ${firstOption} and more.` : ""}`,
        pt: ({ orgName, firstOption }) => `Veja e inscreva-se em uma ${orgName} associação.${firstOption ? ` As opções de associação incluem ${firstOption} e muito mais.` : ""}`,
        es: ({ orgName, firstOption }) => `Consulta y regístrate en una ${orgName} membresía.${firstOption ? ` Las opciones de membresía incluyen ${firstOption} y más.}` : ""}`,
        ja: ({ orgName, firstOption }) => `${orgName}のメンバーシップを確認して登録します。${firstOption ? ` メンバーシップオプションには、${firstOption}などがあります。` : ""}`,
    },
    "org_federated_meta_title": {
        en: ({ federationTerm, orgName }) => `${federationTerm}, ${orgName} | Liveheats`,
        pt: ({ federationTerm, orgName }) => `${federationTerm}, ${orgName} | Liveheats`,
        es: ({ federationTerm, orgName }) => `${federationTerm}, ${orgName} | Liveheats`,
        ja: ({ federationTerm, orgName }) => `${federationTerm}, ${orgName} | Liveheats`,
    },
    "org_federated_meta_description": {
        en: ({ federationTerm, orgName, firstChildOrgName }) => `See all the organisations that are part of ${orgName}, ${federationTerm}. ${firstChildOrgName} and more.`,
        pt: ({ federationTerm, orgName, firstChildOrgName }) => `Veja todas as organizações que fazem parte de ${orgName}, ${federationTerm}. ${firstChildOrgName} e muito mais.`,
        es: ({ federationTerm, orgName, firstChildOrgName }) => `Consulta todas las organizaciones que forman parte de ${orgName}, ${federationTerm}. ${firstChildOrgName} y más.`,
        ja: ({ federationTerm, orgName, firstChildOrgName }) => `${orgName}の一部であるすべての組織をご覧ください${federationTerm}。${firstChildOrgName}などが含まれます。`,
    },
    "org_federated_meta_description_no_child_orgs": {
        en: ({ federationTerm, orgName }) => `See all the organisations that are part of ${orgName}, ${federationTerm}.`,
        pt: ({ federationTerm, orgName }) => `Veja todas as organizações que fazem parte de ${orgName}, ${federationTerm}.`,
        es: ({ federationTerm, orgName }) => `Consulta todas las organizaciones que forman parte de ${orgName}, ${federationTerm}.`,
        ja: ({ federationTerm, orgName }) => `${orgName}の一部であるすべての組織をご覧ください${federationTerm}。`,
    },
    "org_event_list_meta_title": {
        en: ({ orgName }) => `Events - ${orgName} | Liveheats`,
        pt: ({ orgName }) => `Eventos - ${orgName} | Liveheats`,
        es: ({ orgName }) => `Eventos - ${orgName} | Liveheats`,
        ja: ({ orgName }) => `イベント - ${orgName} | Liveheats`,
    },
    "org_event_list_meta_description": {
        en: ({ orgName }) => `See all ${orgName} live, scheduled and past events.`,
        pt: ({ orgName }) => `Veja todos os eventos ao vivo, agendados e passados de ${orgName}.`,
        es: ({ orgName }) => `Consulta todos los eventos en vivo, programados y pasados de ${orgName}.`,
        ja: ({ orgName }) => `すべての${orgName}のライブ、予定された、および過去のイベントをご覧ください。`,
    },
    "org_membership_sign_on_meta_title": {
        en: ({ seriesName }) => `Register for ${seriesName} | Liveheats`,
        pt: ({ seriesName }) => `Inscreva-se em ${seriesName} | Liveheats`,
        es: ({ seriesName }) => `Regístrate en ${seriesName} | Liveheats`,
        ja: ({ seriesName }) => `${seriesName}に登録 | Liveheats`,
    },
    "org_membership_sign_on_meta_description": {
        en: ({ orgName, seriesName }) => `Login and register for ${orgName}, ${seriesName}.`,
        pt: ({ orgName, seriesName }) => `Faça login e inscreva-se em ${orgName}, ${seriesName}.`,
        es: ({ orgName, seriesName }) => `Inicia sesión y regístrate en ${orgName}, ${seriesName}.`,
        ja: ({ orgName, seriesName }) => `${orgName}、${seriesName}にログインして登録してください。`,
    },
    "event_with_status_meta_description": {
        en: ({ eventStatus, eventName, sportType, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - ${sportType} event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ eventStatus, eventName, sportType, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - Evento de ${sportType} em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ eventStatus, eventName, sportType, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - Evento de ${sportType} en ${eventDate} en ${eventLocation} por ${orgName}`,
        ja: ({ eventStatus, eventName, sportType, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${sportType}イベント「${eventName}」は${eventStatus}です。`,
    },
    "event_with_status_meta_description_sport_type_other": {
        en: ({ eventStatus, eventName, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - Event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ eventStatus, eventName, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ eventStatus, eventName, eventDate, eventLocation, orgName }) => `${eventStatus}: ${eventName} - Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        ja: ({ eventStatus, eventName, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${eventName}」は${eventStatus}です。`,
    },
    "event_with_status_meta_description_no_location": {
        en: ({ eventStatus, eventName, sportType, eventDate, orgName }) => `${eventStatus}: ${eventName} - ${sportType} event on ${eventDate} by ${orgName}`,
        pt: ({ eventStatus, eventName, sportType, eventDate, orgName }) => `${eventStatus}: ${eventName} - Evento de ${sportType} em ${eventDate} por ${orgName}`,
        es: ({ eventStatus, eventName, sportType, eventDate, orgName }) => `${eventStatus}: ${eventName} - Evento de ${sportType} em ${eventDate} por ${orgName}`,
        ja: ({ eventStatus, eventName, sportType, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${sportType}イベント「${eventName}」」は${eventStatus}です。`,
    },
    "event_with_status_meta_description_sport_type_other_no_location": {
        en: ({ eventStatus, eventName, eventDate, orgName }) => `${eventStatus}: ${eventName} - Event on ${eventDate} by ${orgName}`,
        pt: ({ eventStatus, eventName, eventDate, orgName }) => `${eventStatus}: ${eventName} - Evento em ${eventDate} por ${orgName}`,
        es: ({ eventStatus, eventName, eventDate, orgName }) => `${eventStatus}: ${eventName} - Evento em ${eventDate} por ${orgName}`,
        ja: ({ eventStatus, eventName, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${eventName}」は${eventStatus}です。`,
    },
    "event_meta_description": {
        en: ({ prefix, eventName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} for ${eventName} - ` : ""}${sportType} event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ prefix, eventName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ prefix, eventName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento de ${sportType} en ${eventDate} en ${eventLocation} por ${orgName}`,
        ja: ({ prefix, eventName, sportType, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${sportType}イベント「${eventName}」${prefix ? `の${prefix}` : ""}`,
    },
    "event_meta_description_sport_type_other": {
        en: ({ prefix, eventName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} for ${eventName} - ` : ""}Event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ prefix, eventName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ prefix, eventName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        ja: ({ prefix, eventName, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${eventName}${prefix ? `の${prefix}` : ""}`,
    },
    "event_meta_description_no_location": {
        en: ({ prefix, eventName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} for ${eventName} - ` : ""}${sportType} event on ${eventDate} by ${orgName}`,
        pt: ({ prefix, eventName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} por ${orgName}`,
        es: ({ prefix, eventName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} por ${orgName}`,
        ja: ({ prefix, eventName, sportType, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${sportType}イベント「${eventName}」${prefix ? `の${prefix}` : ""}`,
    },
    "event_meta_description_sport_type_other_no_location": {
        en: ({ prefix, eventName, eventDate, orgName }) => `${prefix ? `${prefix} for ${eventName} - ` : ""}Event on ${eventDate} by ${orgName}`,
        pt: ({ prefix, eventName, eventDate, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento em ${eventDate} por ${orgName}`,
        es: ({ prefix, eventName, eventDate, orgName }) => `${prefix ? `${prefix} para ${eventName} - ` : ""}Evento em ${eventDate} por ${orgName}`,
        ja: ({ prefix, eventName, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${eventName}${prefix ? `の${prefix}` : ""}`,
    },
    "event_division_results_meta_title":{
        en: ({ eventName }) => `Results - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Resultados - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Resultados - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `結果 - ${eventName} | Liveheats`,
    },
    "event_division_meta_description": {
        en: ({ prefix, eventName, divisionName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} for ${divisionName}, ${eventName} - ` : ""}${sportType} event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ prefix, eventName, divisionName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ prefix, eventName, divisionName, sportType, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} em ${eventLocation} por ${orgName}`,
        ja: ({ prefix, eventName, divisionName, sportType, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${divisionName}部門の${sportType}イベント「${eventName}」${prefix ? `の${prefix}` : ""}`,
    },
    "event_division_meta_description_sport_type_other": {
        en: ({ prefix, eventName, divisionName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} for ${divisionName}, ${eventName} - ` : ""}Event on ${eventDate} at ${eventLocation} by ${orgName}`,
        pt: ({ prefix, eventName, divisionName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        es: ({ prefix, eventName, divisionName, eventDate, eventLocation, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento em ${eventDate} em ${eventLocation} por ${orgName}`,
        ja: ({ prefix, eventName, divisionName, eventDate, eventLocation, orgName }) => `${orgName}主催の${eventDate}に${eventLocation}で開催される${divisionName}部門の${eventName}${prefix ? `の${prefix}` : ""}`,
    },
    "event_division_meta_description_no_location": {
        en: ({ prefix, eventName, divisionName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} for ${divisionName}, ${eventName} - ` : ""}${sportType} event on ${eventDate} by ${orgName}`,
        pt: ({ prefix, eventName, divisionName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} por ${orgName}`,
        es: ({ prefix, eventName, divisionName, sportType, eventDate, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento de ${sportType} em ${eventDate} por ${orgName}`,
        ja: ({ prefix, eventName, divisionName, sportType, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${divisionName}部門の${sportType}イベント「${eventName}」${prefix ? `の${prefix}` : ""}`,
    },
    "event_division_meta_description_sport_type_other_no_location": {
        en: ({ prefix, eventName, divisionName, eventDate, orgName }) => `${prefix ? `${prefix} for ${divisionName}, ${eventName} - ` : ""}Event on ${eventDate} by ${orgName}`,
        pt: ({ prefix, eventName, divisionName, eventDate, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento em ${eventDate} por ${orgName}`,
        es: ({ prefix, eventName, divisionName, eventDate, orgName }) => `${prefix ? `${prefix} para ${divisionName}, ${eventName} - ` : ""}Evento em ${eventDate} por ${orgName}`,
        ja: ({ prefix, eventName, divisionName, eventDate, orgName }) => `${orgName}主催の${eventDate}に開催される${divisionName}部門の${eventName}${prefix ? `の${prefix}` : ""}`,
    },
    "event_rego_meta_title": {
        en: ({ eventName }) => `Register for ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Inscreva-se em ${eventName} | Liveheats`,
        es: ({ eventName }) => `Regístrate en ${eventName} | Liveheats`,
        ja: ({ eventName }) => `${eventName}に登録 | Liveheats`,
    },
    "Division | Liveheats": {
        pt: "Divisão | Liveheats",
        es: "División | Liveheats",
        ja: "部門 | Liveheats",
    },
    "event_division_meta_title": {
        en: ({ divisionName, eventName }) => `${divisionName} - ${eventName} | Liveheats`,
        pt: ({ divisionName, eventName }) => `${divisionName} - ${eventName} | Liveheats`,
        es: ({ divisionName, eventName }) => `${divisionName} - ${eventName} | Liveheats`,
        ja: ({ divisionName, eventName }) => `${divisionName} - ${eventName} | Liveheats`,
    },
    "event_division_entries_meta_title": {
        en: ({ eventName }) => `Entries - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Inscrições - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Inscripciones - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `エントリー - ${eventName} | Liveheats`,
    },
    "Skate": {
        pt: "Skate",
        es: "Skate",
        ja: "スケート",
    },
    "Ski & Snowboard": {
        pt: "Esqui e Snowboard",
        es: "Esquí y snowboard",
        ja: "スキーとスノーボード",
    },
    "Surfing": {
        pt: "Surf",
        es: "Surf",
        ja: "サーフィン",
    },
    "Surf Life Saving": {
        pt: "Surf Life Saving",
        es: "Surf Life Saving",
        ja: "Surf Life Saving",
    },
    "Wakeboarding": {
        pt: "Wakeboarding",
        es: "Wakeboarding",
        ja: "ウェイクボード",
    },
    "Bodyboarding": {
        pt: "Bodyboarding",
        es: "Bodyboarding",
        ja: "ボディボード",
    },
    "BMX": {
        pt: "BMX",
        es: "BMX",
        ja: "BMX",
    },
    "Scooter": {
        pt: "Scooter",
        es: "Scooter",
        ja: "スクーター",
    },
    "Stand Up Paddle (SUP)": {
        pt: "Stand Up Paddle (SUP)",
        es: "Stand Up Paddle (SUP)",
        ja: "スタンドアップパドル (SUP)",
    },
    "Freeride Ski & Snowboard": {
        pt: "Esqui e Snowboard Freeride",
        es: "Esquí y snowboard freeride",
        ja: "フリースキーとスノーボード",
    },
    "Freeride Mountain Biking": {
        pt: "Mountain Biking Freeride",
        es: "Mountain Biking freeride",
        ja: "フリーマウンテンバイキング",
    },
    "Windsurfing": {
        pt: "Windsurfing",
        es: "Windsurfing",
        ja: "ウィンドサーフィン",
    },
    "See results": {
        pt: "Ver resultados",
        es: "Ver resultados",
        ja: "結果を見る",
    },
    "See entries": {
        pt: "Ver inscrições",
        es: "Ver inscripciones",
        ja: "エントリーを見る",
    },
    "See the draw": {
        pt: "Ver o sorteio",
        es: "Ver el sorteo",
        ja: "ドローを見る",
    },
    "event_club_leaderboard_meta_title": {
        en: ({ eventName }) => `Club Leaderboard - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Classificação do clube - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Clasificación del club - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `クラブリーダーボード - ${eventName} | Liveheats`,
    },
    "event_team_leaderboard_meta_title": {
        en: ({ eventName }) => `Team Leaderboard - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Classificação da equipe - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Clasificación del equipo - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `チームリーダーボード - ${eventName} | Liveheats`,
    },
    "See the team leaderboard": {
        pt: "Ver a classificação da equipe",
        es: "Ver la clasificación del equipo",
        ja: "チームリーダーボードを見る",
    },
    "See the club leaderboard": {
        pt: "Ver a classificação do clube",
        es: "Ver la clasificación del club",
        ja: "クラブリーダーボードを見る",
    },
    "See the round leaderboard":{
        pt: "Ver a classificação da rodada",
        es: "Ver la clasificación de la ronda",
        ja: "ラウンドリーダーボードを見る",
    },
    "event_round_leaderboard_meta_title": {
        en: ({ eventName }) => `Round Leaderboard - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Classificação da rodada - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Clasificación de la ronda - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `ラウンドリーダーボード - ${eventName} | Liveheats`,
    },
    "event_schedule_public_meta_title":{
        en: ({ eventName }) => `Schedule - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Programação - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Programación - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `スケジュール - ${eventName} | Liveheats`,
    },
    "See the event schedule": {
        pt: "Ver a programação do evento",
        es: "Ver el horario del evento",
        ja: "イベントスケジュールを見る",
    },
    "Athlete dashboard | Liveheats": {
        pt: "Painel do atleta | Liveheats",
        es: "Tablero del atleta | Liveheats",
        ja: "アスリートダッシュボード | Liveheats",
    },
    "user_event_athlete_entry_meta_title": {
        en: ({ eventName, athleteName }) => `Event entry - ${eventName} - ${athleteName}`,
        pt: ({ eventName, athleteName }) => `Inscrição no evento - ${eventName} - ${athleteName}`,
        es: ({ eventName, athleteName }) => `Inscripción en el evento - ${eventName} - ${athleteName}`,
        ja: ({ eventName, athleteName }) => `イベントエントリー - ${eventName} - ${athleteName}`,
    },
    "Personal settings | Liveheats": {
        pt: "Configurações pessoais | Liveheats",
        es: "Configuración personal | Liveheats",
        ja: "個人設定 | Liveheats",
    },
    "athlete_memberships_meta_title": {
        en: ({ athleteName }) => `Athlete Memberships - ${athleteName} | Liveheats`,
        pt: ({ athleteName }) => `Associações de atletas - ${athleteName} | Liveheats`,
        es: ({ athleteName }) => `Membresías de atletas - ${athleteName} | Liveheats`,
        ja: ({ athleteName }) => `アスリートメンバーシップ - ${athleteName} | Liveheats`,
    },
    "edit_athlete_profile_meta_title": {
        en: ({ athleteName }) => `Athlete Profile - ${athleteName} | Liveheats`,
        pt: ({ athleteName }) => `Perfil do Atleta - ${athleteName} | Liveheats`,
        es: ({ athleteName }) => `Perfil del Atleta - ${athleteName} | Liveheats`,
        ja: ({ athleteName }) => `アスリートプロフィール - ${athleteName} | Liveheats`,
    },
    "Stripe payments - Power up | Liveheats": {
        pt: "Pagamentos Stripe - Power up | Liveheats",
        es: "Pagos de Stripe - Power up | Liveheats",
        ja: "Stripe支払い - パワーアップ | Liveheats",
    },
    "athlete_injuries_meta_title": {
        en: ({ athleteName }) => `Athlete Injuries - ${athleteName} | Liveheats`,
        pt: ({ athleteName }) => `Lesões de atletas - ${athleteName} | Liveheats`,
        es: ({ athleteName }) => `Lesiones de atletas - ${athleteName} | Liveheats`,
        ja: ({ athleteName }) => `アスリートの怪我 - ${athleteName} | Liveheats`,
    },
    "athlete_activity_meta_title":{
        en: ({ athleteName }) => `Athlete Activity - ${athleteName} | Liveheats`,
        pt: ({ athleteName }) => `Atividade do atleta - ${athleteName} | Liveheats`,
        es: ({ athleteName }) => `Actividad del atleta - ${athleteName} | Liveheats`,
        ja: ({ athleteName }) => `アスリートアクティビティ - ${athleteName} | Liveheats`,
    },
    "Manage users - Organisation settings | Liveheats": {
        pt: "Gerenciar usuários - Configurações da organização | Liveheats",
        es: "Administrar usuarios - Configuración de la organización | Liveheats",
        ja: "ユーザーの管理 - 組織設定 | Liveheats",
    },
    "Organisation profile - Organisation settings | Liveheats": {
        pt: "Perfil da organização - Configurações da organização | Liveheats",
        es: "Perfil de la organización - Configuración de la organización | Liveheats",
        ja: "組織プロフィール - 組織設定 | Liveheats",
    },
    "Director dashboard | Liveheats": {
        pt: "Painel do diretor | Liveheats",
        es: "Tablero del director | Liveheats",
        ja: "ディレクターダッシュボード | Liveheats",
    },
    "Manage rankings": {
        pt: "Gerenciar Rankings",
        es: "Administrar Rankings",
        ja: "ランキングを管理",
    },
    "Manage athletes & teams | Liveheats": {
        pt: "Gerenciar atletas e equipes | Liveheats",
        es: "Administrar atletas y equipos | Liveheats",
        ja: "アスリートとチームを管理 | Liveheats",
    },
    "Plan and billing | Liveheats": {
        pt: "Plano e cobrança | Liveheats",
        es: "Plan y facturación | Liveheats",
        ja: "プランと請求 | Liveheats",
    },
    "Purchase credits & add-ons | Liveheats": {
        pt: "Comprar créditos e complementos | Liveheats",
        es: "Comprar créditos y complementos | Liveheats",
        ja: "クレジットとアドオンを購入 | Liveheats",
    },
    "Successfully purchased credits & add-ons | Liveheats": {
        pt: "Créditos e complementos comprados com sucesso | Liveheats",
        es: "Créditos y complementos comprados con éxito | Liveheats",
        ja: "クレジットとアドオンを正常に購入 | Liveheats",
    },
    "Manage memberships": {
        pt: "Gerenciar associações",
        es: "Administrar membresías",
        ja: "メンバーシップを管理",
    },
    "Judge dashboard": {
        pt: "Painel do juiz",
        es: "Tablero del juez",
        ja: "ジャッジダッシュボード",
    },
    "Event schedule": {
        pt: "Programação do evento",
        es: "Horario del evento",
        ja: "イベントスケジュール",
    },
    "Division draw": {
        pt: "Sorteio de divisão",
        es: "Sorteo de división",
        ja: "部門抽選",
    },
    "Division entries": {
        pt: "Entradas de divisão",
        es: "Entradas de división",
        ja: "部門エントリー",
    },
    "Division leaderboard": {
        pt: "Classificação de divisão",
        es: "Clasificación de división",
        ja: "部門リーダーボード",
    },
    "Division round leaderboard": {
        pt: "Classificação de divisão",
        es: "Clasificación de división",
        ja: "部門リーダーボード",
    },
    "Division result": {
        pt: "Resultado da divisão",
        es: "Resultado de la división",
        ja: "部門結果",
    },
    "Events reports": {
        pt: "Relatórios de eventos",
        es: "Informes de eventos",
        ja: "イベントレポート",
    },
    "Stripe payments": {
        pt: "Pagamentos Stripe",
        es: "Pagos de Stripe",
        ja: "Stripe支払い",
    },
    "Event dashboard | Liveheats": {
        pt: "Painel do evento | Liveheats",
        es: "Tablero del evento | Liveheats",
        ja: "イベントダッシュボード | Liveheats",
    },
    "event_dashboard_meta_title": {
        en: ({ eventName }) => `Event dashboard - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Painel do evento - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Tablero del evento - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `イベントダッシュボード - ${eventName} | Liveheats`,
    },
    "event_settings_meta_title": {
        en: ({ eventName }) => `Event settings - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Configurações do evento - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Configuración del evento - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `イベント設定 - ${eventName} | Liveheats`,
    },
    "Event settings | Liveheats": {
        pt: "Configurações do evento | Liveheats",
        es: "Configuración del evento | Liveheats",
        ja: "イベント設定 | Liveheats",
    },
    "event_schedule_heats_meta_title": {
        en: ({ eventName }) => `Event schedule heats - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Programação do evento heats - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Horario del evento heats - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `イベントスケジュールヒート - ${eventName} | Liveheats`,
    },
    "head_judge_meta_title": {
        en: ({ eventName }) => `Head judge - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Juiz chefe - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Juez principal - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `ヘッドジャッジ - ${eventName} | Liveheats`,
    },
    "Head judge | Liveheats": {
        pt: "Juiz chefe | Liveheats",
        es: "Juez principal | Liveheats",
        ja: "ヘッドジャッジ | Liveheats",
    },
    "event_schedule_meta_title": {
        en: ({ eventName }) => `Event schedule - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Programação do evento - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Horario del evento - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `イベントスケジュール - ${eventName} | Liveheats`,
    },
    "Event schedule | Liveheats": {
        pt: "Programação do evento | Liveheats",
        es: "Horario del evento | Liveheats",
        ja: "イベントスケジュール | Liveheats",
    },
    "Team manager dashboard | Liveheats": {
        pt: "Painel do gerente de equipe | Liveheats",
        es: "Tablero del gerente de equipo | Liveheats",
        ja: "チームマネージャーダッシュボード | Liveheats",
    },
    "Create new series | Liveheats": {
        pt: "Criar nova série | Liveheats",
        es: "Crear nueva serie | Liveheats",
        ja: "新しいシリーズを作成 | Liveheats",
    },
    "Edit series | Liveheats": {
        pt: "Editar série | Liveheats",
        es: "Editar serie | Liveheats",
        ja: "シリーズを編集 | Liveheats",
    },
    "event_entry_teams_meta_title": {
        en: ({ eventName }) => `Event entry - ${eventName} - teams`,
        pt: ({ eventName }) => `Inscrição no evento - ${eventName} - equipes`,
        es: ({ eventName }) => `Inscripción en el evento - ${eventName} - equipos`,
        ja: ({ eventName }) => `イベントエントリー - ${eventName} - チーム`,
    },
    "event_entry_athletes_meta_title": {
        en: ({ eventName }) => `Event entry - ${eventName} - athletes`,
        pt: ({ eventName }) => `Inscrição no evento - ${eventName} - atletas`,
        es: ({ eventName }) => `Inscripción en el evento - ${eventName} - atletas`,
        ja: ({ eventName }) => `イベントエントリー - ${eventName} - アスリート`,
    },
    "event_media_meta_title": {
        en: ({ eventName }) => `Event Media - ${eventName} | Liveheats`,
        pt: ({ eventName }) => `Mídia do evento - ${eventName} | Liveheats`,
        es: ({ eventName }) => `Mídia do evento - ${eventName} | Liveheats`,
        ja: ({ eventName }) => `イベントメディア - ${eventName} | Liveheats`,
    },
});
